import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import {
	refreshBindings,
	requestHandler,
} from '../../../../../service/fetcher.saga';

import {
	checkIfPartnerCustomerExist,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	watchUberEatsLink,
} from '../saga';
import {
	PRE_CHANGE_PARTNER_STATUS,
	PRE_CHANGE_PARTNER_STEP,
	SET_UBEREATS_STORES,
} from '../types';
import { activeStockManagement } from '../../../Components/ProductQuantity/service/saga';

const PARTNER_PREFIX = 'ubereats';
function* configUbereats() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_UBEREATS');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			success = true;
		} else if (currentStep === 'documentation') {
			success = true;
		} else if (currentStep === 'uuid_at_client') {
			const body = {
				partnerPrefix: PARTNER_PREFIX,
				id: bodySetStep.id_user,
				uuid: data.uuid,
				partnerId: bodySetStep.partner_id,
				step: currentStep,
			};
			let resCheck = yield call(checkIfPartnerCustomerExist, {
				partner_id: body.partnerId,
				partner_customer_id: body.uuid,
			});

			if (!resCheck) {
				errorMessage = 'partner.link_partner_check_error';
			} else if (resCheck && resCheck.exist === true) {
				errorMessage = 'partner.link_partner_customer_allready_exist';
			} else {
				yield put({
					type: 'PRE_GENERATE_TOKEN_2',
					body,
				});
				yield take('REQUEST_GENERATE_TOKEN_FINISHED');

				const { success: successProvisioning } = yield call(
					setUberEatsProvisioning
				);
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);

				yield call(watchUberEatsLink, false); // désactivation du link ubereats par default, il sera regénéré a l'export
				success = successProvisioning;
			}
		}

		if (currentStep === 'set_entities') {
			const { binding } = yield select();
			let paiementType = binding.paymentType.find(f => f.id === 'ubereats');
			let table = binding.floor.find(f => f.id === 'ubereats-1');
			let priceCategory = binding.paymentType.find(f => f.id === 'ubereats');

			let needInsert = !paiementType || !table || !priceCategory;

			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			if (!needInsert || insertSuccess) {
				let resultPaiementType;
				let resultFloor;
				let resultPriceCategory;
				if (needInsert) {
					yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

					let body = {
						prefix: PARTNER_PREFIX,
						id_payment_type: 'ubereats',
					};

					resultPaiementType = yield call(patchPartnerPaiementType, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_table: 'ubereats-1',
					};

					resultFloor = yield call(patchPartnerFloor, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_price_category: 'ubereats',
					};

					resultPriceCategory = yield call(patchPartnerPriceCategory, body);
				}

				if (
					!needInsert ||
					(resultPaiementType &&
						resultPaiementType.result &&
						resultFloor &&
						resultFloor.result &&
						resultPriceCategory &&
						resultPriceCategory.result)
				) {
					success = true;
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				bodySetStep.step = 'success';
				bodySetStep.status = 'inactif';
				success = true;
			}
		}

		if (success) {
			if (bodySetStep.step === 'success') {
				// désactivation du link ubereats par default, il sera regénéré a l'export
				yield call(watchUberEatsLink, false);
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
			}

			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error');
		}

		yield put({ type: 'PUT_CONFIG_UBEREATS_FINISHED', step: bodySetStep.step });
	}
}
export function* setUberEatsProvisioning() {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{},
		'initStoreProvisioning'
	);

	if (!result) {
		yield call(
			notify,
			I18n.t('partner.ubereats_wrong_provisioning'),
			'warning',
			10000
		);
	}
	return { success: !!result };
}

export function* setUberEatsAuthorization(code) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ code },
		'initAuthUberEats'
	);

	if (result) {
		yield put({ type: SET_UBEREATS_STORES, stores: result.stores });
		if (result.stores && result.stores.length) {
			yield call(
				notify,
				I18n.t('partner.ubereats_success_initialization'),
				'success',
				10000
			);
		} else {
			yield call(notify, I18n.t('partner.ubereats_no_store'), 'warning', 10000);
		}
	} else {
		yield call(
			notify,
			I18n.t('partner.ubereats_wrong_initialization'),
			'warning',
			10000
		);
	}
}

export default function* indexSaga() {
	yield all([configUbereats()]);
}
