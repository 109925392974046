import { createStore, compose, applyMiddleware } from 'redux';
import reducer from './reducers';
import { syncTranslationWithStore } from 'react-redux-i18n';
import createSagaMiddleware from 'redux-saga';
import IndexSagas from './sagas';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export default function configureStore() {
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const sagaMiddleware = createSagaMiddleware();

	const store = createStore(
		reducer(history),
		composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
	);

	syncTranslationWithStore(store);

	sagaMiddleware.run(IndexSagas);

	return store;
}
