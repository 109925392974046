import { put } from 'redux-saga/effects';
import trads from './';

export default function* i18nFlowSaga() {
	const langsArr = Object.keys(trads);
	let lang = window.navigator.language || 'fr';
	if (langsArr.indexOf(lang) === -1) {
		lang = 'fr';
	}
	yield put({ type: 'loadTranslation', translations: trads });
	yield put({ type: 'setLocal', locale: lang });
}
