import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button } from '@material-ui/core';
import { getProduct } from '../../../../../../service/export/selector';
import { compareObjects, deepEqual } from '../../../../../../../../utils';
import { bindActionCreators } from 'redux';
import { saveExtraProducts } from '../../../../../../service/export/actions';
import { PropTypes } from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirm } from '../../../../../../../../utils';
import * as actions from '../../../../../../service/export/actions';
import './style.scss';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const FormProduct = props => {
	const save = () => {
		const compare = compareObjects(props.product.extras, props.form);

		if (Object.keys(compare).length) {
			props.save(
				props.idExport,
				props.idSection,
				props.product.id_product,
				compare
			);
		}
	};

	const formatTaxRate = (rate = -1) => {
		if (rate === -1) return I18n.t('partner.productForm.vat_undefined');
		return rate * 100 + '%';
	};

	const onDeleteItem = (event, id, idProduct, idExport, idSection) => {
		event.stopPropagation();
		confirm(I18n.t('exports.formSection.confirm_delete_product')).then(() => {
			props.setSelected('product', '');
			props.setSelected('section', idSection);
			props.action.deleteProductToSection(id, idProduct, idExport, idSection);
		});
	};

	const resetProductExtra = _ => {
		confirm(I18n.t('exports.formProduct.reset_extra')).then(() => {
			props.action.resetProductExtra({
				idExport: props.idExport,
				idProduct: props.product.id_product,
			});
		});
	};

	return (
		<div className="form-product-container">
			<div className="form-product-container-header">
				<p className="section-label" id="product-label-with-tva">
					{
						/* I18n.t("partner.productForm.productSelected")} : */ props.product
							.name
					}{' '}
					<br></br>
					{props.product.product_type !== 2 ? (
						<span className="product-vat">
							- {I18n.t('partner.productForm.vat')} :{' '}
							{I18n.t('partner.productForm.locally') +
								' ' +
								formatTaxRate(props.product.rate)}{' '}
							/{' '}
							{I18n.t('partner.productForm.take_away') +
								' ' +
								formatTaxRate(props.product.rate_take_away)}{' '}
							-
						</span>
					) : null}
				</p>

				{props.haveExtra ? (
					<div>
						<Button
							onClick={resetProductExtra}
							color={'secondary'}
							variant="outlined"
							startIcon={<RotateLeftIcon />}
							className="remove-extras-button">
							<p>{I18n.t(`partner.productForm.reset_button`)}</p>
						</Button>
					</div>
				) : null}

				<div
					className="delete-section-container"
					onClick={event =>
						onDeleteItem(
							event,
							props.product.id,
							props.product.id_product,
							props.idExport,
							props.idSection
						)
					}>
					<p className="section-label section-label-red">
						{I18n.t('exports.formProduct.product_delete')}
					</p>
					<DeleteIcon className="delete-section-icon" />
				</div>
			</div>

			<div>{props.children}</div>
			<div className={'form-product-save'}>
				<Button
					variant="outlined"
					color={'primary'}
					onClick={save}
					disabled={props.disabledButton}>
					{I18n.t('general.save')}
				</Button>
			</div>
		</div>
	);
};

FormProduct.propTypes = {
	idExport: PropTypes.number.isRequired,
	idSection: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
	let productWithPriceInEuro = { ...props.product.extras };
	if (productWithPriceInEuro.hasOwnProperty('price')) {
		productWithPriceInEuro.price = (productWithPriceInEuro.price / 100).toFixed(
			2
		);
	}

	if (productWithPriceInEuro.hasOwnProperty('image')) {
		delete productWithPriceInEuro.image;
	}

	const form = { ...state.exports.formProduct };

	if (form.hasOwnProperty('image')) {
		delete form.image;
	}

	if (form.hasOwnProperty('price')) {
		form.price = parseFloat(form.price).toFixed(2);
	}

	return {
		form: form,
		disabledButton: deepEqual(productWithPriceInEuro, form),
		haveExtra: Object.keys(props.product.extras).length > 0,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
		save: bindActionCreators(saveExtraProducts, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(React.memo(FormProduct));
