import { requestHandler } from '../fetcher.saga';
import { put, call } from 'redux-saga/effects';
import { I18n } from 'react-redux-i18n';
import {
	HIDE_LOADER,
	RECEIVED_ALL,
} from '../../Components/Loader/service/types';
import { notify } from '../../Components/Notifier/services/saga';

export default function* firstLogin(db, refresh_token) {
	const result = yield call(
		requestHandler,
		'init',
		'POST',
		{
			db,
			refresh_token,
		},
		'',
		{ timeout: 120000 },
		true,
		I18n.t('firstLogin.init')
	);
	if (result.result) {
		return true;
	} else {
		if (result.error == 'SET_INFO_FAIL') {
			yield call(
				notify,
				I18n.t('firstLogin.errors.SET_INFO_FAIL'),
				'warning',
				30000
			);

			return true;
		} else {
			yield put({ type: RECEIVED_ALL });
			yield put({ type: HIDE_LOADER });
			yield put({
				type: 'THROW_ERROR',
				text: I18n.t('firstLogin.fail'),
				error: result.error,
			});

			return false;
		}
	}
}
