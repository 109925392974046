import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import {
	generateExternalId,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STATUS, PRE_CHANGE_PARTNER_STEP } from '../types';
import { displayInfoPartner } from '../../partners/saga';

const PARTNER_PREFIX = 'melba';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_MELBA');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'set_customer_id';
			bodySetStep.step = 'set_customer_id';
			success = true;
		}

		if (currentStep === 'set_customer_id') {
			let res = yield call(generateExternalId);
			if (res && res.success) {
				external_id = res.result;
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
				yield take('PATCH_PARTNER');
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
			currentStep = 'set_auth_link';
			bodySetStep.step = 'set_auth_link';
			success = true;
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					bodySetStep.step = 'success';
					bodySetStep.status = 'actif';
					success = true;
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS,
				body: bodySetStep,
			});
			if (bodySetStep.step === 'success') {
				yield call(displayInfoPartner, PARTNER_PREFIX);
			}
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_MELBA_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
