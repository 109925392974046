import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	set_restaurant_id: 2,
	set_table_and_paiement: 3,
	set_variable_config_channel: 3,
	set_auth_link: 3,
	set_partner_options: 3,
	unlink_partners: 3,
	set_api_mask: 3,
	success: 4,
};

const Rushour = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Rushour;
