import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Option from './Components/Option';
import { getVariableServer, sortBy } from '../../../../utils';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import { bindActionCreators } from 'redux';
import { postOption, deleteOption } from '../../service/partners/actions';
class Options extends React.Component {
	onChange = (option, value) => {
		const opt = this.props.options.find(o => o.name === option);
		if (value.active || opt.data_type === 'boolean') {
			this.props.post({
				id_partner: this.props.id_partner,
				option,
				value: value.value,
			});
		} else {
			this.props.delete({ id_partner: this.props.id_partner, option });
		}
	};

	toggleAdvancedSettings = (optionName, value) => {
		let children = this.props.options.filter(o => o.parent === optionName);
		for (let child of children) {
			if (value) {
				this.onChange(child.name, {
					active: value,
					value: value,
				});
			} else {
				this.props.delete({
					id_partner: this.props.id_partner,
					option: child.name,
				});
			}
		}
	};

	filterSwiftVersion = (acc, r) => {
		if (this.props.isSwift) {
			if (r.name === 'showNotificationPaymentLine') {
				r.label = I18n.t(
					`partner.option.name.showNotificationPaymentLine_swift`
				);
			}
			if (r.name === 'showPaymentStatus') {
				return acc;
			}
		}
		acc.push(r);

		return acc;
	};

	render() {
		if (this.props.option_disable == 1) {
			return null;
		}

		const optionsbyEvents = sortBy(this.props.options, 'event');
		return (
			<div className={'partner-option-container'}>
				<h2 className={'export-title'}>{I18n.t('partner.option.title')}</h2>
				<div className={'partner-option-subcontainer'}>
					{Object.keys(optionsbyEvents).length ? (
						Object.keys(optionsbyEvents).map(event => {
							let optionsEventByCategory = optionsbyEvents[event]

								.map(o => ({
									...o,
									children: [],
									label: I18n.t(`partner.option.name.${o.name}`),
								}))
								.reduce((acc, option, i, options) => {
									// remplissage des enfants dans les parents
									if (option.parent) {
										let indexParent = options.findIndex(
											o => o.name === option.parent
										);
										if (indexParent != -1) {
											let indexParentInAcc = acc.findIndex(
												o => o.name === option.parent
											);
											if (indexParentInAcc == -1) {
												acc.push(options[indexParent]);
												indexParentInAcc = acc.length - 1;
											}

											acc[indexParentInAcc].children.push(option);
										}
									} else {
										acc.push(option);
									}

									return acc;
								}, [])
								.filter(o => !o.parent)
								.sort((a, b) => a.category_weigth - b.category_weigth)
								.reduce(this.filterSwiftVersion, [])
								.reduce((acc, o) => {
									if (!acc.hasOwnProperty(o.category)) {
										acc[o.category] = [];
									}
									acc[o.category].push(o);
									return acc;
								}, {});
							return (
								<div key={event}>
									<p className="option-event-name">
										{' '}
										{I18n.t(`webhook.name.${event}`)}:
									</p>
									{Object.keys(optionsEventByCategory).map(category => (
										<>
											{' '}
											<p className="option-category-label">
												{I18n.t('partner.option.category.' + category)}
											</p>
											{optionsEventByCategory[category].map(option => (
												<Option
													key={option.name}
													option={option}
													onChange={this.onChange}
													toggleAdvancedSettings={this.toggleAdvancedSettings}
												/>
											))}
										</>
									))}
								</div>
							);
						})
					) : (
						<p className="partner-option-empty">
							{I18n.t('partner.option.empty')}
						</p>
					)}
					{this.props.children ? this.props.children : ''}
				</div>
			</div>
		);
	}
}

Options.propTypes = {
	prefix_partner: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
	const opt_disabled = getVariableServer(
		state.variableServer,
		'partner_opt_disable'
	);
	const opts = state.partners.hasOwnProperty(props.prefix_partner)
		? state.partners[props.prefix_partner].options
		: [];

	const id_partner = state.partners.hasOwnProperty(props.prefix_partner)
		? state.partners[props.prefix_partner].id
		: null;
	return {
		options: opts,
		id_partner,
		option_disable:
			opt_disabled == '1' && props.prefix_partner != 'celladdition',
		isSwift: state.service.user.isSwift,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		post: bindActionCreators(postOption, dispatch),
		delete: bindActionCreators(deleteOption, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Options);
