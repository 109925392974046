import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import './style.scss';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import PartnerContact from '../../PartnerContact';

const cardActifInactif = props => {
	let classNameCard = 'partnerconfig-partner-card ';
	if (!props.isActive) {
		classNameCard += 'inactif-card ';
	}
	if (
		props.prefix === 'ubereats' ||
		props.prefix === 'celladdition' ||
		props.prefix === 'deliverect' ||
		props.prefix === 'easilys'
	) {
		classNameCard += ' partner-with-url ';
	}

	let classes = '';
	if (props.error !== 0) {
		classes += ' logo-and-error';
	}

	const displayPartnerInfo = () => {
		props.dispatchDisplayPartnerInfo(props.prefix);
	};

	let LogoPart = (
		<React.Fragment>
			{' '}
			{props.logo ? (
				<div className="cardConfig-logo">
					<img src={props.logo} alt={`Logo ${props.username}`} />
				</div>
			) : (
				<p className="partnerconfig-partner-title">{props.username}</p>
			)}
		</React.Fragment>
	);

	const canConfigure =
		props.action.type === 'link' || props.action.type === 'external_link';

	function getLinkContent() {
		return (
			<Tooltip title={I18n.t('partner.tooltip_partner_edit')}>
				<div className="card_action">
					<SettingsIcon />
				</div>
			</Tooltip>
		);
	}

	return (
		<div className="partnerconfig-export-container">
			<div className="card_top_action">
				<Tooltip title={I18n.t('partner.tooltip_partner_info')}>
					<div className="card_action" onClick={displayPartnerInfo}>
						<InfoIcon />
					</div>
				</Tooltip>
				<div className="card_action_rigth">
					<PartnerContact type="card" prefix={props.prefix} />
					{canConfigure ? (
						props.action.type === 'link' ? (
							<Link to={props.action.data}>{getLinkContent()}</Link>
						) : (
							<a href={props.action.data}>{getLinkContent()}</a>
						)
					) : null}
				</div>
			</div>
			<div className={classNameCard}>
				{LogoPart}

				<div className="bottom-card">
					{/* <p>{props.username}</p> */}
					{props.changeStatus ? (
						<div>
							<label
								className={
									!props.isActive ? 'blue form-switch' : 'form-switch'
								}>
								<input
									type="checkbox"
									checked={props.isActive}
									onChange={props.changeStatus}
								/>
								<i />{' '}
								{props.isActive
									? I18n.t('partner.active')
									: I18n.t('partner.inactive')}
							</label>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

const mSTP = state => ({});

const mapDispatchToProps = dispatch => {
	return {
		dispatchDisplayPartnerInfo: prefix =>
			dispatch({ type: 'PARTNER_DISPLAY_INFO_PARTNER', prefix }),
	};
};
export default connect(mSTP, mapDispatchToProps)(cardActifInactif);
