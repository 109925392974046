import React, { Component } from 'react';
import Modal from '../../../../Components/Modal';
import EditPopup from '../../../../Components/EditPopup';
import PropTypes from 'prop-types';
import './style.scss';
import { checkCountryCode, confirm } from '../../../../utils';
import SortableCard from './Components/Card';
import { deepEqual } from '../../../../utils';
import { I18n } from 'react-redux-i18n';

import {
	SortableContainer,
	arrayMove,
	SortableElement,
} from 'react-sortable-hoc';
import AddPicture from './Components/AddPicture';
import { fetcher } from '../../../../service/fetcher.saga';
import { downloadFile, b64Blob } from '../../../../service/utils';
import Button from '../../../../Components/Button';
import config from '../../../../config';
import qrcode from './qrcode.svg';
import oeil from './oeil.svg';
import { connect } from 'react-redux';
class TabPictures extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pictures: [],
			openModal: false,
			openEditModal: false,
			imgModalSrc: '',
			imgModalName: '',
			imgModalDescription: '',
			newPicture: '',
			newPictureName: '',
			editName: false,
			lastWeight: 0,
			cropProperties: {},
			idSelected: null,
			pathCropped: null,
		};

		this.onChange = this.onChange.bind(this);
	}

	mountData = pictures => {
		let table = [];
		if (pictures) {
			table = pictures.map(picture => {
				return { ...picture };
			});
			table.sort((a, b) => {
				return a.weight - b.weight;
			});
		}
		this.setState({
			pictures: table,
			lastWeight: pictures.length > 0 ? table[table.length - 1].weight + 1 : 0,
		});
	};

	componentWillReceiveProps(nextProps) {
		if (!deepEqual(nextProps.pictures, this.props.pictures)) {
			this.mountData(nextProps.pictures);
		}
		if (nextProps.loadingQrCode && !this.props.loadingQrCode) {
			const { b64, type } = nextProps.qrcode;
			const blob = b64Blob(b64, type);
			downloadFile(blob, `qrCode.png`);
			this.props.initLoadingQrcode();
		}
	}

	componentDidMount() {
		this.mountData(this.props.pictures);
	}

	generateQrCode = async () => {
		const lang = checkCountryCode(window.navigator.language);
		const data = {
			type: 'image/png',
			lang
		};
		this.props.loadQrCode(data);
	};

	resortPictureWeight = pictures => {
		if (pictures) {
			pictures.sort((a, b) => {
				return a.weight - b.weight;
			});
			return pictures;
		}
	};

	deletePicture = id => {
		confirm(I18n.t('info.picture.confirm_delete')).then(() => {
			this.props.onDeletePicture(id);
		});
	};

	closeModal = () => {
		this.setState({
			openModal: false,
		});
	};

	closeEditModal = () => {
		this.setState({
			openEditModal: false,
		});
	};

	openModal = picture => {
		this.selectImage(picture);
		this.setState({
			openModal: true,
		});
	};

	openEditModal = picture => {
		this.selectImage(picture);
		this.setState({
			openEditModal: true,
		});
	};

	selectImage = picture => {
		this.setState({
			idSelected: picture.id ? picture.id : false,
			imgModalSrc: picture.path ? picture.path : '',
			imgModalName: picture.name,
			imgModalDescription: picture.description,
			pathCropped: picture.path_cropped ? picture.path_cropped : '',
			cropProperties: picture.crop_properties
				? JSON.parse(picture.crop_properties)
				: {},
		});
	};

	openNewPictureModal = () => {
		this.selectImage({});
		this.setState({
			openEditModal: true,
		});
	};

	onChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	onPictureChange = (id, path_cropped, name, description, crop_properties) => {
		const newPicturesTable = this.state.pictures.map(element => {
			if (element.id === id) {
				element.name = name;
				element.path_cropped = path_cropped;
				element.description = description;
				element.crop_properties = crop_properties;
			}
			return element;
		});
		this.props.onUpdatePicture(newPicturesTable);
	};

	onSetExistedPicture = (picture, name, description) => {
		const newPicture = {
			id: picture.id,
			name: name,
			path: picture.path,
			path_cropped: picture.path_cropped,
			weight: this.state.lastWeight,
			description: description,
			crop_properties: picture.crop_properties,
			env: 'info',
		};
		this.props.onAddPictureReused(newPicture);
	};

	onSubmit = async (path, path_cropped, name, description, cropOptions) => {
		if (!this.state.idSelected) {
			const newPicture = {
				name: name,
				path: path,
				path_cropped: path_cropped,
				weight: this.state.lastWeight,
				description: description,
				crop_properties: cropOptions,
				env: 'info',
			};
			const newWeight = this.state.lastWeight + 1;
			this.props.onAddPictureOnline(newPicture);
			this.setState({
				lastWeight: newWeight,
			});
		} else {
			this.onPictureChange(
				this.state.idSelected,
				path_cropped ? path_cropped : this.state.pathCropped,
				name,
				description,
				cropOptions
			);
			this.selectImage({});
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const newTable = arrayMove(this.state.pictures, oldIndex, newIndex).map(
			(item, index) => {
				item.weight = index;
				return item;
			}
		);
		this.setState({
			pictures: newTable,
		});
		this.props.onUpdatePicture(this.state.pictures);
	};

	render() {
		let menudigitalUrl = 'https://menu-digital.laddit.io/menu-digital/';
		if (window.location && !window.location.hostname.includes('laddit.io')) {
			menudigitalUrl = 'https://menu-digital.laddition.com/menu-digital/';
		}
		const hasImg = this.state.pictures.find(
			picture => !picture.path.includes('pdf')
		);
		let addPictureOption = {};
		if (!hasImg) {
			addPictureOption = {
				customAllowedTypes: ['.pdf'],
				customAllowedTypesInput: ',application/pdf',
			};
		}
		const SortableCardDraggable = SortableElement(({ value }) => (
			<SortableCard
				openModal={this.openModal}
				openEditModal={this.openEditModal}
				deletePicture={this.deletePicture}
				value={value}
				uncropped={true}
			/>
		));
		const SortableList = SortableContainer(({ items }) => {
			const hasPdf = items.find(item => item.path.includes('pdf'));
			return (
				<ul>
					{items.length >= 8 || hasPdf ? (
						''
					) : (
						<li className="add-picture-container">
							<AddPicture openNewPictureModal={this.openNewPictureModal} />
						</li>
					)}

					{items.map((value, index) => {
						return (
							<SortableCardDraggable
								key={`item-${index}`}
								index={index}
								value={value}
							/>
						);
					})}
				</ul>
			);
		});
		return (
			<div className="tab-container">
				<div className="container-title-load-qrcode">
					{/* <h1>{I18n.t('info.picture.title')}</h1> */}
					<h3>{I18n.t('info.picture.subtitle')}</h3>
					<div style={{ display: 'inherit' }}>
						<Button
							style={{ margin: '0 10px 0px 0' }}
							disabled={!this.state.pictures.length}
							onClick={e => {
								e.preventDefault();
								window.open(
									`${menudigitalUrl}${this.props.authId}`,
									'_blank' // <- This is what makes it open in a new window.
								);
							}}>
							<img
								src={oeil}
								alt="image oeil"
								style={{ marginLeft: 5, marginRight: 5 }}
							/>
							{I18n.t('menuOnline.goto')}
						</Button>
						<Button
							style={{ margin: 0 }}
							disabled={!this.state.pictures.length}
							onClick={this.generateQrCode}>
							<img
								src={qrcode}
								alt="image burger"
								style={{ marginLeft: 5, marginRight: 5 }}
							/>
							{I18n.t('menuOnline.loadQrcode')}
						</Button>
					</div>
				</div>
				<div className="row" />
				<div className="row list-container">
					<SortableList
						items={this.state.pictures ? this.state.pictures : null}
						onSortEnd={this.onSortEnd}
						axis="xy"
						pressDelay={200}
					/>
				</div>
				<Modal
					src={this.state.pathCropped}
					openModal={this.state.openModal}
					closeModal={this.closeModal}
					name={this.state.imgModalName}
					description={this.state.imgModalDescription}
				/>
				<EditPopup
					onSubmit={this.onSubmit}
					src={this.state.imgModalSrc}
					openModal={this.state.openEditModal}
					closeModal={this.closeEditModal}
					name={this.state.imgModalName}
					description={this.state.imgModalDescription}
					cropProperties={this.state.cropProperties}
					maxSize={1 * 1024 * 1024}
					onSetExistedPicture={this.onSetExistedPicture}
					cropDisabled={true}
					{...addPictureOption}
				/>
			</div>
		);
	}
}

TabPictures.defaultProps = {
	pictures: [],
	openModal: false,
	newPicture: '',
	newPictureName: '',
	editName: false,
	lastWeight: 0,
};

TabPictures.propTypes = {
	pictures: PropTypes.array,
	onAddPicture: PropTypes.func,
	onUpdatePicture: PropTypes.func,
	onDeletePicture: PropTypes.func,
};

const mapStateToProps = (state, props) => {
	return {
		authId: state.service.user.id,
	};
};

export default connect(mapStateToProps, null)(TabPictures);
