import ProductsSelectorReducer from '../Components/ProductsSelector/service/reducer';
import {
	reducerExport,
	reducerProductExtra,
	reducerFormProduct,
	reducerSupplementProduct,
	reducerExportStockActiveLive,
	reducerExportDisabledProducts,
} from './export/reducer';
import reducerSupplement from '../Components/SupplementExtra/service/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
	productsSelector: ProductsSelectorReducer,
	list: reducerExport,
	productExtra: reducerProductExtra,
	formProduct: reducerFormProduct,
	formSupplementProduct: reducerSupplementProduct,
	supplement: reducerSupplement,
	partnerStockActiveLive: reducerExportStockActiveLive,
	disabledProducts: reducerExportDisabledProducts,
});
