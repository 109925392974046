import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	set_api_data: 0,
	set_tickncook_account_id: 1,
	set_tickncook_kitchen_id: 2,
	set_auth_link: 3,
	set_send_feature: 3,
	set_api_mask: 3,
	trigger_menu_synchro: 3, // send menu manualy to ticncook
	success: 4,
};

const Tickncook = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Tickncook;
