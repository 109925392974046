import React, { Component } from 'react';
import * as actions from '../../../../../service/export/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductsSelector from '../../../../ProductsSelector';
import * as actionsSelectedItem from '../../../../../../Partners/Components/ProductsSelector/service/products/actions';
import * as actionsMenu from '../../../../../../Partners/Components/ProductsSelector/service/menus/actions';
import { I18n } from 'react-redux-i18n';
import CloseIcon from '../../../../../../../img/deleteIcon.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMove from 'array-move';
import { PropTypes } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import '../style.scss';
import { withSnackbar } from 'notistack';
import { confirm } from '../../../../../../../utils';
import { Button } from '@material-ui/core';

const HIDE_DESCRIPTION = [];
class FormSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			description: '',
			idSection: 0,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.idSection !== prevState.idSection) {
			return {
				title: nextProps.section.name,
				description: nextProps.section.description,
				idSection: nextProps.section.id,
			};
		}
		return null;
	}

	validProductSelector = selectedItems => {
		this.props.action.addProductsToSection(
			selectedItems,
			this.props.idSection,
			this.props.idExport
		);
	};

	setSelectedItems = () => {
		let tableProductSelected = {};

		tableProductSelected = this.props.section.products.reduce((acc, row) => {
			if (row.hasOwnProperty('id_menu')) {
				if (!acc.hasOwnProperty(row.id_menu)) {
					acc[row.id_menu] = [];
				}
				if (row.hasOwnProperty('id_product')) {
					acc[row.id_menu].push(row.id_product);
				}
			}

			return acc;
		}, {});
		this.props.actionMenu.select(this.props.idMenu);
		this.props.actionsSelectedItem.setSelected(tableProductSelected);
	};

	onDeleteItem = (event, id, idProduct, idExport, idSection) => {
		event.stopPropagation();
		confirm(I18n.t('exports.formSection.confirm_delete_product')).then(() => {
			this.props.action.deleteProductToSection(
				id,
				idProduct,
				idExport,
				idSection
			);
		});
	};

	onDeleteSection = (idSection, idExport) => {
		confirm(I18n.t('exports.formSection.confirm_delete_section')).then(() => {
			this.props.action.deleteSection(idSection, idExport);
			this.props.setSelected('section', '');
		});
	};

	onChange = (event, field) => {
		this.setState({
			[field]: event.target.value,
		});
	};

	updateSection = () => {
		if (this.state.title.length > 0) {
			this.props.action.updateSection(
				this.props.idSection,
				this.props.idExport,
				this.state.title,
				this.state.description || ''
			);
		} else {
			this.props.enqueueSnackbar(
				I18n.t('exports.formSection.section_name_empty_error'),
				{
					variant: 'warning',
				}
			);
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const newTable = arrayMove(
			this.props.section.products,
			oldIndex,
			newIndex
		).map((item, index) => {
			item.weight = index;
			return item;
		});

		this.props.action.reweightProduct(
			newTable,
			this.props.idSection,
			this.props.idExport
		);
	};

	onKeyDown = e => {
		if (e.key === 'Enter') {
			this.updateSection();
		}
	};

	render() {
		let table = [];

		if (this.props.section) {
			table = this.props.section.products.map(product => {
				const ps = this.props.products[product.id_product];
				const name = ps ? ps.name : '';
				return { ...product, name: name || '' };
			});
			table.sort((a, b) => {
				return a.weight - b.weight;
			});
		}

		const menuSelectorDisabled = this.props.sections.some(
			s => s.products.length
		);

		let saveIconClasses = 'save-section-icon';
		let btnSaveDisabled = true;
		if (
			this.state.title !== this.props.section.name ||
			this.state.description !== this.props.section.description
		) {
			saveIconClasses += ' save-section-icon-disabled';
			btnSaveDisabled = false;
		}

		return (
			<div className="form-section-container">
				<div className="section-header-container">
					<div className="input-name-container">
						<p className="section-label">
							{I18n.t('exports.formSection.section_name')}
						</p>
						<div className="input-name">
							<TextField
								value={
									this.state.title.includes(
										I18n.t('exports.sectionList.new_section')
									)
										? ''
										: this.state.title
								}
								placeholder={
									this.state.title.includes(
										I18n.t('exports.sectionList.new_section')
									)
										? this.state.title
										: ''
								}
								margin="normal"
								className="sectionName-textfield"
								onChange={e => this.onChange(e, 'title')}
								inputProps={{
									maxLength: 40,
								}}
								onKeyDown={this.onKeyDown}
							/>
						</div>
						{this.props.showDescription ? (
							<>
								<p className="section-label">
									{I18n.t('exports.formSection.section_description')}
								</p>
								<div className="input-name input-description">
									<TextField
										multiline
										rows={3}
										value={this.state.description}
										placeholder={I18n.t(
											'exports.sectionList.description_placeholder'
										)}
										className="sectionName-textfield"
										onChange={e => this.onChange(e, 'description')}
										inputProps={{
											maxLength: 255,
										}}
										onKeyDown={this.onKeyDown}
									/>
								</div>
							</>
						) : (
							''
						)}

						<Button
							style={{ marginTop: 10 }}
							variant="contained"
							color="primary"
							disabled={btnSaveDisabled}
							onClick={this.updateSection}>
							{I18n.t('general.save')}
						</Button>
						<p></p>
					</div>
					<div
						className="delete-section-container"
						onClick={() =>
							this.onDeleteSection(this.props.idSection, this.props.idExport)
						}>
						<p className="section-label section-label-red">
							{I18n.t('exports.formSection.section_delete')}
						</p>
						<DeleteIcon className="delete-section-icon" />
					</div>
				</div>
				<div className="product-selector-container">
					<ProductsSelector
						onValid={this.validProductSelector}
						setSelectedItems={this.setSelectedItems}
						menuSelectorDisabled={menuSelectorDisabled}
					/>
				</div>

				<div>
					<ul className="list-without-margin">
						{table && table.length ? (
							table.map((value, index) => {
								return (
									<li
										key={`item-${index}`}
										index={index}
										onClick={() =>
											this.props.setSelected('product', value.id_product)
										}>
										<hr />
										<div className="product-item-container">
											{value.name}{' '}
											<img
												src={CloseIcon}
												className="product-item-close-icon"
												onClick={event =>
													this.onDeleteItem(
														event,
														value.id,
														value.id_product,
														this.props.idExport,
														this.props.idSection
													)
												}
											/>
										</div>
									</li>
								);
							})
						) : (
							<p className={'product-empty'}>
								{I18n.t('exports.formSection.empty_product')}
							</p>
						)}
					</ul>
				</div>
			</div>
		);
	}
}

FormSection.propTypes = {
	idExport: PropTypes.number.isRequired,
	idSection: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
	const exp = state.exports.list.find(e => e.id === props.idExport);
	const sect = exp ? exp.sections.find(s => s.id === props.idSection) : {};

	return {
		sections: exp ? exp.sections : [],
		section: sect,
		showDescription: !HIDE_DESCRIPTION.includes(props.prefix),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
		actionsSelectedItem: bindActionCreators(actionsSelectedItem, dispatch),
		actionMenu: bindActionCreators(actionsMenu, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(FormSection));
