import { CLOSE_CONFIRM, SHOW_CONFIRM } from './types';

const initialState = {
	show: false,
	eventOnValid: null,
	eventOnCancel: null,
	paramsOnValid: {},
	paramsOnCancel: {},
	text: '',
	options: {},
	media: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_CONFIRM:
			return {
				show: true,
				eventOnValid: action.eventOnValid,
				eventOnCancel: action.eventOnCancel,
				paramsOnValid: action.paramsOnValid || {},
				paramsOnCancel: action.paramsOnCancel || {},
				text: action.text,
				media: action.media,
				options: action.options,
			};
		case CLOSE_CONFIRM:
			return {
				show: false,
				eventOnValid: null,
				eventOnCancel: null,
				paramsOnValid: {},
				paramsOnCancel: {},
				text: '',
				media: null,
				options: {},
			};
		default:
			return state;
	}
};
