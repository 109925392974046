import { createSelector } from 'reselect';
const findPartner = (state, props) => {
	return state.partners.hasOwnProperty(props.prefix)
		? state.partners[props.prefix]
		: {};
};

export const getPartnerByPrefix = createSelector(
	[findPartner],
	partner => partner
);
