import { put, select } from 'redux-saga/effects';
import { UPDATE_USER } from './types';

export function* setUser(decodedAccessToken, token) {
	const user = {
		id: decodedAccessToken.id,
		token: token,
		username: decodedAccessToken.username,
		email: decodedAccessToken.email,
		isPartner: decodedAccessToken.type === 'partner',
		partnerPrefix: decodedAccessToken.partnerPrefix,
		account_id: decodedAccessToken.account_id,
	};
	yield put({ type: UPDATE_USER, user });
}

export function* isPartner() {
	let state = yield select();
	if (state && state.service && state.service.user) {
		return state.service.user.isPartner || false;
	}
	return false;
}
