import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import config from '../../../../../config';

class ButtonPlayPlus extends Component {
	render() {
		return (
			<div className="print-ticket-container">
				<div className="image"></div>
				<div className="right">
					<p className="print-title">
						{I18n.t('partner.ladditionTicket.print_title')}
					</p>
					<p>{I18n.t('partner.ladditionTicket.print_p1')}</p>
					<p>{I18n.t('partner.ladditionTicket.print_p2')}</p>
					<p className="dimension">
						{I18n.t('partner.ladditionTicket.print_p3')}
					</p>
					<div className="buttons">
						<a
							href={config.PRINT_URL}
							target={'_blank'}
							rel="noopener noreferrer">
							{I18n.t('partner.ladditionTicket.print_btn_order')}
						</a>
						<a
							href={config.PRINT_URL + 'qrcode'}
							target={'_blank'}
							rel="noopener noreferrer">
							{I18n.t('partner.ladditionTicket.print_btn_config')}
						</a>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {};
};

export default connect(mapStateToProps)(ButtonPlayPlus);
