import React from 'react';
import { I18n } from 'react-redux-i18n';
import { PropTypes } from 'prop-types';

const Description = props => {
	const onChange = e => {
		props.onChange(e.target.name, e.target.value);
	};

	return (
		<div className={'form-product-description'}>
			<p
				className={
					props.toComplete
						? 'form-product-title pastille-after'
						: 'form-product-title'
				}>
				{I18n.t(`partner.productForm.${props.name}`)}
			</p>
			<textarea
				name={props.name}
				placeholder={
					props.placeholder
						? props.placeholder
						: I18n.t(`partner.productForm.${props.name}`)
				}
				value={props.value}
				onChange={onChange}
				maxLength={props.maxlength || 255}
			/>
		</div>
	);
};

Description.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	maxlength: PropTypes.number,
	placeholder: PropTypes.string,
};

export default React.memo(Description);
