import { createSelector } from 'reselect';
import { deepClone } from '../../../../../../utils';

const getCategories = state => state.exports.productsSelector.categories;
const getProducts = state => state.exports.productsSelector.products;
const getMenus = state => state.exports.productsSelector.menus;

export const getProductsList = createSelector([getProducts], products => {
	return { ...products.list };
});

export const getCategoriesList = createSelector([getCategories], categories => {
	return { ...categories.list };
});

export const getMenusList = createSelector([getMenus], menus => {
	return { ...menus.list };
});

export const getMenuSelected = createSelector([getMenus], menus => {
	return menus.selected;
});
export const getCategorySelected = createSelector(
	[getCategories],
	categories => {
		return categories.selected;
	}
);

export const getProductsSelected = createSelector([getProducts], products => {
	return products.selected;
});

export const parseProductsByMenu = createSelector(
	[getProductsList, getMenuSelected],
	(products, idMenu) => {
		return products.hasOwnProperty(idMenu) ? { ...products[idMenu] } : {};
	}
);

export const parseCategoryByMenu = createSelector(
	[getCategoriesList, getMenuSelected],
	(categories, idMenu) => {
		return categories.hasOwnProperty(idMenu) ? { ...categories[idMenu] } : {};
	}
);

export const getLoaded = createSelector(
	[getProducts, getCategories, getMenus],
	(products, categories, menus) => {
		return products.loaded && categories.loaded && menus.loaded;
	}
);

export const getSelectedItemNames = createSelector(
	getProductsSelected,
	getProductsList,
	(selectedItems, products) => {
		let names = {};
		for (let menu in selectedItems) {
			if (!names.hasOwnProperty(menu)) {
				names[menu] = {};
			}
			for (let id of selectedItems[menu]) {
				if (!names[menu].hasOwnProperty(id)) {
					if (products.hasOwnProperty(menu)) {
						let data = products[menu][id];
						if (data) {
							names[menu][id] = {
								id: data.id,
								name: data.name,
							};
						}
					}
				}
			}
			if (Object.keys(names[menu]).length == 0) {
				delete names[menu];
			}
		}

		for (let menu in names) {
			if (!selectedItems.hasOwnProperty(menu)) {
				delete names[menu];
			}
			for (let id in names[menu]) {
				if (!selectedItems[menu].includes(id)) {
					delete names[menu][id];
				}
			}
		}

		return names;
	}
);

export const getNbProductMenu = createSelector(
	[getProducts, getMenus],
	(products, menus) => {
		if (products.list.hasOwnProperty(menus.selected)) {
			return Object.values(products.list[menus.selected]).reduce((acc, p) => {
				acc += p.type === 'menu' ? 1 : 0;
				return acc;
			}, 0);
		} else {
			return 0;
		}
	}
);
