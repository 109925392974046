import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import Option from '../../../Components/Options/Components/Option';

class OptionPritQR extends Component {
	onChange = (name, value) => {
		this.props.setPrintQR(value.value);
	};

	render() {
		return (
			<div>
				<p className="option-event-name">
					{I18n.t('partner.option.category.other')}
				</p>
				<p className="option-category-label">
					{I18n.t('partner.option.category.ticket')}
				</p>
				<Option
					option={{
						data_type: 'boolean',
						value: this.props.laddition_ticket_qr,
						children: [],
						label: I18n.t('partner.ladditionTicket.optionPrintQR.label'),
						name: 'print_qr',
					}}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		laddition_ticket_qr: state.info.variable
			? state.info.variable.laddition_ticket_qr == 1
			: false,
	};
};

const mapDispatchToProps = dispatch => ({
	setPrintQR: value =>
		dispatch({ type: 'SET_PRINT_QR', value: value ? '1' : '0' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionPritQR);
