import { GET_PARTNER_DATA, RECEIVE_PARTNERS_DATA } from './types';
import { take, put, call, all, select } from 'redux-saga/effects';
import { requestHandler } from '../../../../service/fetcher.saga';
import { notify } from '../../../../Components/Notifier/services/saga';
import { I18n } from 'react-redux-i18n';

const PRE_REQUEST_GET_PARTNER_DATA = 'PRE_REQUEST_GET_PARTNER_DATA';
const REQUEST_GET_PARTNER_DATA_FINISHED = 'REQUEST_GET_PARTNER_DATA_FINISHED';

function* preGetPartnerData() {
	while (true) {
		const action = yield take(GET_PARTNER_DATA);
		if (action.prefix) {
			let url = '';
			switch (action.prefix) {
				case 'deliverect':
					url = 'deliverectBrandChannel';
					break;
				case 'mewsSystems':
					url = 'mews/all';
					break;
				default:
					url = 'data';
					break;
			}

			yield put({
				type: PRE_REQUEST_GET_PARTNER_DATA,
				body: {
					prefix: action.prefix,
					refreshToken: window.localStorage.refresh_token,
				},
				url,
			});
			const { result } = yield take(REQUEST_GET_PARTNER_DATA_FINISHED);
			yield put({
				type: RECEIVE_PARTNERS_DATA,
				data: parsePartnerData(result, action.prefix),
				prefix: action.prefix,
			});
		}
	}
}

function* watchGetPartnerData() {
	while (true) {
		const { body, url } = yield take(PRE_REQUEST_GET_PARTNER_DATA);

		const { result } = yield call(
			requestHandler,
			'partners',
			'POST',
			body,
			url
		);
		yield put({ type: REQUEST_GET_PARTNER_DATA_FINISHED, result });
	}
}

function parsePartnerData(data, partner) {
	switch (partner) {
		case 'deliverect':
			if (!data || !Array.isArray(data.channels) || !Array.isArray(data.brands))
				return data;

			data.brands = data.brands.map(b => ({
				id: b.brandId,
				name: b.name,
			}));
			data.channels = data.channels
				.map(c => ({
					id: c.channelId,
					name: c.name
						.toLowerCase()
						.split('_')
						.map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
						.join(' '),
				}))
				.sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				})
				.sort((a, b) => {
					if (
						data.priorityPartners.includes(a.id) &&
						!data.priorityPartners.includes(b.id)
					)
						return -1;
					if (
						!data.priorityPartners.includes(a.id) &&
						data.priorityPartners.includes(b.id)
					)
						return 1;

					if (
						data.priorityPartners.includes(a.id) &&
						data.priorityPartners.includes(b.id)
					)
						return data.priorityPartners.findIndex(id => id == a.id) <
							data.priorityPartners.findIndex(id => id == b.id)
							? -1
							: 1;

					return 0;
				});
			return data;
		case 'tickncook':
			return data && data.status === 'success' && data.responseData
				? data.responseData
				: [];
		case 'ubereats':
			if (data && data.responseData) {
				return { ...data.responseData };
			}
			return {};

		case 'rushour':
			if (!data || !Array.isArray(data.channels) || !Array.isArray(data.brands))
				return data;

			data.brands = data.brands.map(b => ({
				id: b.id,
				name: b.name,
			}));
			data.channels = data.channels
				.map(b => ({
					id: b.id,
					name: b.type.split('-')[0],
					brandId: b.brandId,
				}))
				.filter(b => b.name != 'laddition');
			return data;
		case 'mewsSystems':
			return {
				...data,
				services: data.services.filter(
					s => s.Data.Discriminator == 'Additional'
				),
			};
		default:
			return data;
	}
}

/**
 * Handle post get partner data stuff
 */
function* getPartnerDataAfter() {
	while (true) {
		const { data, prefix } = yield take('RECEIVE_PARTNERS_DATA');
		switch (prefix) {
			case 'mewsSystems':
				if (
					!data ||
					!data.accountCategories ||
					!data.accountCategories.length ||
					!data.outlets ||
					!data.outlets.length ||
					!data.services ||
					!data.services.length
				)
					yield notify(
						I18n.t('partner.mewsSystems.mewsConfigNotComplete'),
						'warning'
					);
				break;
			default:
				break;
		}
	}
}

export default function* indexSaga() {
	yield all([
		preGetPartnerData(),
		watchGetPartnerData(),
		getPartnerDataAfter(),
	]);
}
