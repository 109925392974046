import { put } from 'redux-saga/effects';
import { ENQUEUE_SNACKBAR } from './types';

export function* notify(label, type, duration = 5000) {
	yield put({
		type: ENQUEUE_SNACKBAR,
		notification: {
			key: new Date().getTime() + Math.random(),
			message: label,
			options: {
				variant: type,
				autoHideDuration: duration,
			},
		},
	});
}
