import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ExportList from './Component/ExportList';
import ExportModal from './Component/ExportModal';
import { getPartner } from '../../service/partners/selector';
import { bindActionCreators } from 'redux';
import * as actions from '../../service/export/actions';
import ExportCard from './Component/ExportList/Components/ExportCard';
import ModalExpensive from './Component/ModalExpensive';
import './style.scss';
import { getType, deepEqual } from '../../utils';
import { EXPORT_TYPE } from '../../../Partners/config';
import { withSnackbar } from 'notistack';
import { getLoaded } from '../ProductsSelector/Components/ProductsSelectorModal/selector';
import ProductsSelectorModal from '../ProductsSelector/Components/ProductsSelectorModal';
import * as actionsMenu from '../../Components/ProductsSelector/service/menus/actions';
import * as actionsProducts from '../../Components/ProductsSelector/service/products/actions';
import AddExport from './Component/AddExport';
import { getVariableServer } from '../../../../utils';
import Alert from '../../../../Components/Alert';
class Export extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: {},
			idExport: 0,
			openModalExpensive: false,
			openExportModal: false,
			openExportList: false,
			openSelector: false,
			menuSelectorDisabled: false,
		};
	}

	onClickExport = id => {
		const exp = this.props.listActive.find(e => e.id === id);

		if (exp) {
			this.setState(
				{
					nameExport: exp.name,
					sections: exp.sections,
					idExport: exp.id,
					menuSelectorDisabled: exp.sections.some(s => s.products.length),
				},
				() => {
					this.openExportModal();

					const type = getType(this.props.partner.prefix);
					if (type === EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS) {
						this.setSelectedItems();
					}
				}
			);
		}
	};

	setSelectedItems = () => {
		let tableProductSelected = {};
		let selectedMenu = false;

		tableProductSelected = this.state.sections[0].products.reduce(
			(acc, row) => {
				if (row.hasOwnProperty('id_menu')) {
					selectedMenu = row.id_menu;
					if (!acc.hasOwnProperty(row.id_menu)) {
						acc[row.id_menu] = [];
					}
					if (row.hasOwnProperty('id_product')) {
						acc[row.id_menu].push(row.id_product);
					}
				}

				return acc;
			},
			{}
		);
		if (selectedMenu) {
			this.props.actionMenu.select(selectedMenu);
		}

		this.props.actionProducts.setSelected(tableProductSelected);
	};

	onAddExport = () => {
		this.setState(
			{
				nameExport: null,
				sections: '',
				idExport: 0,
			},
			() => {
				this.openExportModal();
			}
		);
	};

	closeExportModal = () => {
		this.setState({
			openModalExpensive: false,
			openExportModal: false,
		});
	};

	openExportModal = () => {
		this.setState({
			openExportList: false,
			openExportModal: true,
			openModalExpensive: true,
		});
	};

	handleExport = (idExport, idPartner) => {
		this.props.action.activeExport(idExport, idPartner);
		this.setState({ openModalExpensive: false, openExportList: false });
	};

	deleteExport = idExport => {
		this.props.action.deleteExport(idExport);
	};

	openExportList = () => {
		this.setState({ openExportList: true, openModalExpensive: true });
	};

	closeExpensiveModal = () => {
		this.setState({
			openModalExpensive: false,
		});
	};

	createExport = () => {
		let otherExport = this.props.listActive.some(exp => {
			return exp.id_partner === this.props.partner.id && exp.is_current == 1;
		});

		const countUnamed = this.props.listActive.reduce((acc, exp) => {
			if (exp.name.includes(I18n.t('partner.export.unamed'))) {
				acc += 1;
			}
			return acc;
		}, 0);

		const type = getType(this.props.partner.prefix);

		let obj = {
			name:
				I18n.t('partner.export.unamed') +
				(countUnamed ? ` (${countUnamed + 1})` : ''),
			id_partner: this.props.partner.id,
			date_available: Math.floor(+new Date() / 1000),
			is_current: otherExport ? false : true,
			unique_section:
				type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS ||
				type === EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS,
		};

		//fermeture de la popup list si il n'y avais pas d'autre export
		if (this.props.listActive.length === 0) {
			this.closeExpensiveModal();
		}
		this.props.action.addExport(obj);
	};

	createExportBasedOn = id => {
		if (id) {
			let exportBased = this.props.list.find(exp => {
				return exp.id === parseInt(id);
			});

			if (exportBased) {
				const type = getType(this.props.partner.prefix);
				let baseName = I18n.t('partner.export.copy_of') + exportBased.name;
				const countSameName = this.props.listActive.reduce((acc, exp) => {
					if (exp.name.includes(baseName)) {
						acc += 1;
					}
					return acc;
				}, 0);
				let obj = {
					id: exportBased.id,
					name: baseName + (countSameName ? ` (${countSameName + 1})` : ''),
					id_partner: this.props.partner.id,
					date_available: Math.floor(+new Date() / 1000),
					is_current: false,
					unique_section:
						type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS ||
						type === EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS,
				};

				this.props.action.copyExport(obj);
			} else {
				this.props.enqueueSnackbar(I18n.t('partner.export.export_error'), {
					variant: 'warning',
				});
			}
		} else {
			this.props.enqueueSnackbar(I18n.t('partner.export.select_export_empty'), {
				variant: 'warning',
			});
		}
	};

	onExport = id => {
		if (
			this.props.partner &&
			this.props.partner.prefix &&
			this.props.partner.id
		) {
			this.props.action.generateExport(
				this.props.partner.prefix,
				this.props.partner.id,
				id
			);
		}
	};

	validSelector = selectedItems => {
		this.closeExpensiveModal();
		this.props.action.addProductsToSection(
			selectedItems,
			this.state.sections[0].id,
			this.state.idExport
		);
	};

	render() {
		const type = getType(this.props.partner.prefix);
		const { disabled = false } = this.props;
		const activeExport = this.props.listActive.find(exp => exp.is_current);
		let samePartnerType = Object.values(this.props.partners).reduce(
			(acc, partner) => {
				if (getType(partner.prefix) === type) {
					acc.push({ id: partner.id, prefix: partner.prefix });
				}
				return acc;
			},
			[]
		);

		if (
			this.props.export_disable == 1 ||
			(disabled && this.props.export_enable == 0)
		) {
			return null;
		}

		const title = (
			<h2 className={'export-title'}>
				{I18n.t('partner.export.menu_configuration')}
			</h2>
		);
		return (
			<div className={'export-container-menu'}>
				{activeExport ? (
					<React.Fragment>
						<div className={'export-container-menu-configuration'}>
							{title}
							<p onClick={this.openExportList} className="export-see-more">
								{I18n.t('partner.export.see_more')}
							</p>
						</div>
						{!activeExport.date_last_deployment ? (
							<Alert>{I18n.t('partner.export.helper_not_exported')}</Alert>
						) : null}

						<ExportCard
							export={activeExport}
							onClick={this.onClickExport}
							onExport={this.onExport}
							exportDisabled={!this.props.loaded}
							loading={!this.props.loaded}
							requiredKeys={this.props.requiredKeys}
							allProducts={this.props.products}
							allSupps={this.props.supplements}
							prefix={this.props.partner.prefix}
						/>
					</React.Fragment>
				) : (
					<React.Fragment>
						{title}
						<Alert>{I18n.t('partner.export.helper_no_export')}</Alert>

						<AddExport onAddExport={this.createExport} />
					</React.Fragment>
				)}

				<ModalExpensive
					open={this.state.openModalExpensive}
					onClose={this.closeExpensiveModal}
					fullWidth={this.state.openExportModal}>
					{this.state.openExportList ? (
						<ExportList
							listActive={this.props.listActive}
							list={this.props.list}
							onClickExport={this.onClickExport}
							handleExport={this.handleExport}
							deleteExport={this.deleteExport}
							onAddExport={this.createExport}
							onAddExportBasedOn={this.createExportBasedOn}
							closeExpensiveModal={this.closeExpensiveModal}
							samePartnerType={samePartnerType}
						/>
					) : this.props.loaded ? (
						type !== EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS ? (
							<ExportModal
								openSectionsModal={this.state.openExportModal}
								idExport={this.state.idExport}
								prefix={this.props.partner.prefix || ''}
								onClose={this.closeExportModal}
							/>
						) : (
							<ProductsSelectorModal
								open={true}
								onValid={this.validSelector}
								onCancel={this.closeExpensiveModal}
								menuSelectorDisabled={this.state.menuSelectorDisabled}
								idExport={this.state.idExport}
							/>
						)
					) : (
						<p className={'export-loading'}>
							{I18n.t('partner.export.loading')}
						</p>
					)}
				</ModalExpensive>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	let partner = getPartner(state, props);
	const exportDisabled = getVariableServer(
		state.variableServer,
		'partner_export_disable'
	);
	const exportEnable = getVariableServer(
		state.variableServer,
		'partner_export_enable'
	);
	return {
		partner,
		listActive: state.exports.list.filter(exp => exp.id_partner === partner.id),
		list: state.exports.list,
		partners: state.partners,
		loaded: getLoaded(state),
		requiredKeys: Object.values(
			state.partners[props.partner.prefix].keys || {}
		).reduce((acc, k) => {
			if (k.required && k.key_ !== 'name' && k.key_ !== 'price') {
				acc.push(k.key_);
			}
			return acc;
		}, []),
		products: state.exports.productsSelector.products.list,
		supplements: state.exports.supplement,
		export_disable:
			exportDisabled == '1' && props.partner.prefix != 'celladdition',
		export_enable: exportEnable == '1',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
		actionMenu: bindActionCreators(actionsMenu, dispatch),
		actionProducts: bindActionCreators(actionsProducts, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(Export));
