import { PARTNERS } from './config';

const baseUrl = '/partners/';

/**
 * Renvoie le type du partenaire (partner ou user)
 * @param {String} prefix - prefix du partenaire
 * @return {String} le type ou false si non défini
 */
export const getType = prefix => {
	if (PARTNERS[prefix]) {
		return PARTNERS[prefix].type;
	} else {
		return false;
	}
};

/**
 * Renvoie un tableau avec tout les partenaires et les composants à afficher. Utilisée pour générer les routes
 * @return {Object[]} tableau d'objet : {url, component}
 */
export const getPartners = () => {
	return Object.keys(PARTNERS).reduce((acc, key) => {
		if (PARTNERS[key].component || PARTNERS[key].onboarding) {
			acc.push({
				url: getPartnerRoute(key),
				component: PARTNERS[key].component,
				prefix: key,
			});
		}

		return acc;
	}, []);
};

/**
 * Retourne l'url pour accéder a la page d'un partenaire
 * @param {String} prefix - prefix du partenaire
 * @return {String} url de la page ou false si undefined
 */
export const getPartnerRoute = prefix => {
	if (PARTNERS[prefix]) {
		return baseUrl + prefix;
	} else {
		return false;
	}
};

export const getPartnerAction = prefix => {
	let partner = PARTNERS[prefix];
	if (partner) {
		if (partner.component) {
			return { type: 'link', data: baseUrl + prefix };
		} else if (partner.external_link) {
			return { type: 'external_link', data: partner.external_link };
		}
	}
	return false;
};

/**
 * Renvoie le prefix d'un partenaire grace a son URL
 * @param {String} route - url de la page partenaire
 * @return {String} prefix du partenaire ou false si non défini
 */
export const getPartnerPrefix = route => {
	const partnerStr = route.replace(`${baseUrl}`, '');
	for (var i in PARTNERS) {
		if (PARTNERS[i].name === partnerStr) {
			return i;
		}
	}

	return false;
};

/**
 * Compare 2 object and return a boolean (might be not performant due ton JSON.stringofy use)
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Boolean}
 */
export function deepEqual(obj1, obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}
