import React from 'react';
import { connect } from 'react-redux';
import Button from '../../../../Components/Button';
import SelectableItem from '../../../../Components/itemSelectable';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import { PRE_POST_MENU_TO_PARTNER } from '../../service/partners/types';
import { getMenus } from '../../service/partners/selector';
import './style.scss';

class MenuSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: this.props.primaryMenu,
			menus: [],
		};
	}

	handleChange = id => {
		this.setState({
			checked: id,
		});
	};

	sendMenuToPartner = () => {
		this.props.postMenu(this.state.checked);
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.primaryMenu !== prevState.checked &&
			prevState.checked === ''
		) {
			return {
				checked: nextProps.primaryMenu,
			};
		}
	}

	render() {
		return (
			<React.Fragment>
				<p className="description-menu-selector">
					{I18n.t('partner.description_menu_selector')}
				</p>
				<div className="list-menu-container">
					{this.props.menus.length > 0 ? (
						this.props.menus.map(menu => {
							return (
								<SelectableItem
									checked={menu.id === this.state.checked}
									onChange={() => this.handleChange(menu.id)}
									inputProps={{ 'aria-label': 'primary checkbox' }}
									name={menu.name}
									nbProduct={menu.nbProduct}
									id={menu.id}
									primaryMenu={this.props.primaryMenu}></SelectableItem>
							);
						})
					) : (
						<div className="sp sp-circle" />
					)}
				</div>
				<Button
					onClick={this.sendMenuToPartner}
					disabled={this.state.checked === ''}>
					{I18n.t('general.valid')}
				</Button>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const menus = getMenus(state, props);
	const primaryMenu = state.exports.productsSelector.menus.selected;
	return {
		menus,
		primaryMenu,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		postMenu: idMenu => {
			dispatch({ type: PRE_POST_MENU_TO_PARTNER, body: { idMenu } });
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSelector);
