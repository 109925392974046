//import menusSaga from "./menus/saga";
import partnersSaga from './partners/saga';
import partnersConfigSaga from './partnersConfig/saga';
import exportSaga from './export/saga';
import supplementSaga from '../Components/SupplementExtra/service/saga';
import productQuantity from '../Components/ProductQuantity/service/saga';
import partnerCongigPartnerSaga from './partnersConfig/partners/saga';
import partnerDataSaga from './partnersData/saga';
import externalIdAssignationSaga from '../Components/ExternalDataAssignation/service/saga';
import { all } from 'redux-saga/effects';

export default function* indexSaga() {
	yield all([
		partnersSaga(),
		partnersConfigSaga(),
		partnerDataSaga(),
		exportSaga(),
		supplementSaga(),
		productQuantity(),
		partnerCongigPartnerSaga(),
		externalIdAssignationSaga(),
	]);
}
