import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';

import {
	checkLiveness,
	generateIpadToken,
	initApiData,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
	triggerSynchroIpad,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'ladditionMenu';

function* configMenu() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_LADDITION_MENU');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			success = true;
		}

		if (currentStep === 'check_access_admin_menu') {
			success = true; // on a une vérification coté component deja
			currentStep = 'set_ipad_token';
			bodySetStep.step = 'set_ipad_token';
		}

		if (currentStep === 'set_ipad_token') {
			const ipadToken = yield call(generateIpadToken);
			if (ipadToken) {
				success = true;
				currentStep = 'set_api_data';
				bodySetStep.step = 'set_api_data';
			}
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				currentStep = 'check_liveness';
				bodySetStep.step = 'check_liveness';
			}
		}

		if (currentStep === 'check_liveness') {
			const isLive = yield call(checkLiveness);
			if (isLive) {
				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
			}
		}

		if (currentStep === 'set_auth_link') {
			const { service } = yield select();
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: service.user.account_id,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: {
						prefix: PARTNER_PREFIX,
						external_id: service.user.account_id,
					},
				});
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'set_api_menu_online';
				currentStep = 'set_api_menu_online';
			}
			bodySetStep.status = 'configuration';
		}

		if (currentStep === 'set_api_menu_online') {
			const { result } = yield call(
				requestHandler,
				'partnersConfig',
				'POST',
				{},
				'apiMenuOnlineVariable'
			);

			if (result && result.success) {
				success = true;
				bodySetStep.step = 'trigger_synchro';
				currentStep = 'trigger_synchro';
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'trigger_synchro') {
			yield call(triggerSynchroIpad);
			bodySetStep.status = 'actif';
			bodySetStep.step = 'success';
			success = true;
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_LADDITION_MENU_FINISHED',
			step: bodySetStep.step,
		});
	}
}

export default function* indexSaga() {
	yield all([configMenu()]);
}
