import React, { Component } from 'react';
//import './style.scss';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PRE_SUBMIT_PRODUCT_THRESHOLD } from './service/types';
import ButtonMui from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import compareVersions from 'compare-versions';
import { getVariableServer } from '../../../../utils';

class ProductQuantity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			product_threshold: null,
			active: null,
		};
	}

	openModal = () => {
		this.setState({
			open: true,
			product_threshold: this.props.threshold,
			active: this.props.active,
		});
	};

	closeModal = () => {
		this.setState({
			open: false,
		});
	};

	onChange = e => {
		this.setState({
			product_threshold: e.target.value >= 0 ? e.target.value : 0,
		});
	};

	onChangeActive = e => {
		this.setState({ active: e.target.checked });
	};

	onValid = e => {
		e.preventDefault();

		this.props.submitProductThreshold(
			this.state.active || false,
			this.state.product_threshold,
			this.props.prefix
		);
		this.closeModal();
	};

	render() {
		const requieredMinimalVersion = '4.10.8';

		if (!this.props.stockManagementStatus) return null;
		if (
			compareVersions(
				requieredMinimalVersion,
				this.props.ipadAppVersion || '0'
			) == 1
		)
			return null;

		const btnEnabled =
			this.state.product_threshold != null &&
			this.state.product_threshold !== this.props.id_payment_type;

		return (
			<div className="gestion-button-container">
				<div>
					<ButtonMui onClick={this.openModal} variant={'outlined'}>
						<Translate value={'product_quantity.button'} />
					</ButtonMui>
					<Dialog
						onClose={this.closeModal}
						open={this.state.open}
						id={'dialog-payment-types'}>
						<DialogTitle>
							<Translate value={'product_quantity.title'} />
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<Translate value={'product_quantity.help'} />
								<Translate value={'product_quantity.help_2'} />
							</DialogContentText>

							{this.state.active ? (
								<TextField
									label={I18n.t('product_quantity.input')}
									type="number"
									onChange={this.onChange}
									value={this.state.product_threshold}
									inputProps={{ style: { width: 40 } }}
								/>
							) : (
								// on affiche le switch pour activer pour blinder au cas ou l'option ne soit pas active pour le client.
								<div>
									<Translate value={'product_quantity.switch'} />
									<Switch
										checked={this.state.active}
										onChange={this.onChangeActive}
									/>
								</div>
							)}
						</DialogContent>
						<DialogActions>
							{' '}
							<ButtonMui onClick={this.closeModal}>
								<Translate value={'general.cancel'} />
							</ButtonMui>
							<ButtonMui
								onClick={this.onValid}
								disabled={!btnEnabled}
								color="primary"
								autoFocus>
								<Translate value={'general.valid'} />
							</ButtonMui>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	let rowThreshold = getVariableServer(
		state.variableServer,
		'product_quantity_threshold'
	);
	return {
		threshold: rowThreshold !== false ? rowThreshold : 1,
		stockManagementStatus: state.service.user.stockManagementStatus,
		active: state.service.user.partnerStockManagementStatus
			? state.service.user.partnerStockManagementStatus[props.prefix]
			: false,
		ipadAppVersion: state.service.user.ipadAppVersion,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitProductThreshold: (active, product_threshold, prefix) =>
			dispatch({
				type: PRE_SUBMIT_PRODUCT_THRESHOLD,
				active,
				product_threshold,
				prefix,
			}),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductQuantity);
