import React, { Component } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	set_token_koust: 1,
	set_stock_online: 2,
	set_auth_link: 2,
	success: 3,
};

const Koust = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Koust;
