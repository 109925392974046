import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './style.scss';
import PropTypes from 'prop-types';

class Btn extends Component {
	render() {
		const { icon: Icon, children, style, disabled, ...rest } = this.props;

		return (
			<div className="red-button" style={style}>
				<Button
					variant="contained"
					disabled={disabled}
					{...rest}>
					{Icon && <Icon style={{ marginRight: 8 }} />}
					{children}
				</Button>
			</div>
		);
	}
}

/*** 
 * Exemple d'implémentation
 * <Button
    variant="contained"
    className="submitButton"
    type="submit"
    disabled={false}
  >
    Texte
  </Button>
 * ***/

Btn.propTypes = {
	style: PropTypes.object,
	disabled: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.object,
	]),
	icon: PropTypes.elementType,
};

export default Btn;
