import React from 'react';
import Proptypes from 'prop-types';
import { List as ListMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ItemSelectable from './ItemSelectable';
import { Translate, I18n } from 'react-redux-i18n';
import { FixedSizeList as List } from 'react-window';
import { deepClone } from '../../../../../../../utils';
import { connect } from 'react-redux';
import { parseByMenu, ALL } from '../utils';
import { Checkbox } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as actions from '../../../service/products/actions';
import {
	getProductsSelected,
	getMenuSelected,
	getCategorySelected,
	getProductsList,
} from '../selector';
import SearchIcon from '../img/search.svg';
import ContentLoader from 'react-content-loader';
import { deepEqual } from '../../../../../utils';
import memoize from 'memoize-one';

const createItemData = memoize(
	(itemsSelected, menuSelected, displayedItems, sectionSelected) => ({
		itemsSelected,
		menuSelected,
		displayedItems,
		sectionSelected,
	})
);

function areEqual(prevProps, nextProps) {
	if (
		nextProps.data.sectionSelected === prevProps.data.sectionSelected &&
		nextProps.data.menuSelected === prevProps.data.menuSelected
	) {
		let itemPrev = deepClone(prevProps.data.displayedItems[prevProps.index]);
		let itemNext = deepClone(nextProps.data.displayedItems[nextProps.index]);
		if (itemPrev === itemNext) {
			let itemSelectedPrev = prevProps.data.itemsSelected.hasOwnProperty(
				prevProps.data.menuSelected
			)
				? prevProps.data.itemsSelected[prevProps.data.menuSelected].includes(
						itemPrev.id
				  )
				: false;
			let itemSelectedNext = nextProps.data.itemsSelected.hasOwnProperty(
				nextProps.data.menuSelected
			)
				? nextProps.data.itemsSelected[nextProps.data.menuSelected].includes(
						itemNext.id
				  )
				: false;

			if (itemSelectedNext === itemSelectedPrev) {
				return true;
			}
		}
	}
	return false;
}

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
	},
	itemList: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
});

let searchTimeOut;
class ItemSelectableList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: {},
			displayedItems: {},
			itemsBySection: {},
			menuSelected: null,
			sectionSelected: ALL,
			search: '',
			allSelected: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let state = {};

		if (nextProps.loaded) {
			//Changement de menu
			if (nextProps.menuSelected !== prevState.menuSelected) {
				state.menuSelected = nextProps.menuSelected;
				let productsByMenu = parseByMenu(
					nextProps.products,
					nextProps.menuSelected
				);

				state.itemsBySection = normalizeItemsByCategory(
					Object.values(productsByMenu)
				);
				state.items = Object.values(productsByMenu);
				state.displayedItems = Object.values(productsByMenu);
				state.allSelected = ItemSelectableList.isAllSelectedStatic(
					nextProps.itemsSelected[state.menuSelected],
					state.displayedItems
				);
				// changement de section
			} else if (nextProps.sectionSelected !== prevState.sectionSelected) {
				state.sectionSelected = nextProps.sectionSelected;
				let selectableItems = [];
				if (nextProps.sectionSelected == ALL) {
					let productsByMenu = parseByMenu(
						nextProps.products,
						nextProps.menuSelected
					);
					selectableItems = Object.values(productsByMenu);
				} else if (
					prevState.itemsBySection.hasOwnProperty(nextProps.sectionSelected)
				) {
					selectableItems = Object.values(
						prevState.itemsBySection[nextProps.sectionSelected]
					);
				}
				state.items = selectableItems;
				state.displayedItems = ItemSelectableList.onSearchResearch(
					selectableItems,
					prevState.search
				);
			}
		} else {
			return null;
		}
		return state;
	}

	componentDidUpdate() {
		if (this.state.menuSelected) {
			let allSelected = this.isAllSelected(
				this.props.itemsSelected[this.state.menuSelected],
				this.state.displayedItems
			);

			if (this.state.allSelected !== allSelected) {
				this.setState({ allSelected });
			}
		}
	}

	static onSearchResearch = (items, search) => {
		let searchItems = items.filter(item => {
			return item.name.toLowerCase().indexOf(search.toLowerCase()) != -1;
		});

		return searchItems;
	};

	Row = React.memo(({ index, style, data }) => {
		let item = deepClone(data.displayedItems[index]);
		let itemSelected = data.itemsSelected.hasOwnProperty(data.menuSelected)
			? data.itemsSelected[data.menuSelected].includes(item.id)
			: false;

		return (
			<div style={style} className="item-selectable-parent">
				<ItemSelectable
					id={item.id}
					onChange={this.onChangeItem}
					selected={itemSelected}
					name={item.name}
					menuSelected={data.menuSelected}
					price={item.price}
					sigle={this.props.sigle}
				/>
			</div>
		);
	}, areEqual);

	onChangeItem = (id, selected, idMenu) => {
		if (selected) {
			this.props.select(id, idMenu);
		} else {
			this.props.deselect(id, idMenu);
		}
	};

	handleSelectAll = e => {
		let displayedItemsIds = this.state.displayedItems.map(item => item.id);
		if (this.state.allSelected) {
			this.props.deselect(displayedItemsIds, this.props.menuSelected);
		} else {
			this.props.select(displayedItemsIds, this.props.menuSelected);
		}
	};

	isAllSelected(selected = [], selectable = []) {
		for (let item of selectable) {
			if (!selected.includes(item.id)) {
				return false;
			}
		}
		return true;
	}

	static isAllSelectedStatic(selected = [], selectable = []) {
		for (let item of selectable) {
			if (!selected.includes(item.id)) {
				return false;
			}
		}
		return true;
	}

	onSearchChange = event => {
		if (searchTimeOut) {
			clearTimeout(searchTimeOut);
		}
		this.setState(
			{
				search: event.target.value,
			},
			() => {
				searchTimeOut = setTimeout(() => {
					let displayedItems = ItemSelectableList.onSearchResearch(
						this.state.items,
						this.state.search
					);
					this.setState({ displayedItems });
				}, 500);
			}
		);
	};

	renderContentPlaceholder() {
		const nb = 14;
		const widthLine = 150;
		const widthBox = 20;
		const height = 20;
		const margin = 20;

		return (
			<ContentLoader
				height={550}
				width={700}
				speed={1}
				primaryColor="#f3f3f3"
				secondaryColor="#ecebeb">
				{renderContent(nb)}
			</ContentLoader>
		);

		function renderContent(count) {
			const content = [];
			for (let x = 0; x < count; x++) {
				let plusWidth = Math.floor(Math.random() * 300);
				let marginTop = x * (height + margin);
				//line
				content.push(
					<rect
						x="20"
						y={marginTop}
						rx="10"
						ry="10"
						width={widthLine + plusWidth}
						height={height}
					/>
				);
				//box
				content.push(
					<rect
						x="647"
						y={marginTop}
						rx="5"
						ry="5"
						width={widthBox}
						height={height}
					/>
				);
			}
			return content;
		}
	}

	renderSearchPlaceholder() {
		return (
			<div id="placeholder-search">
				<ContentLoader
					height={43}
					width={360}
					speed={1}
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb">
					<rect x="0" y="0" rx="8" ry="8" width="360" height="43" />
				</ContentLoader>
			</div>
		);
	}

	renderSelectAllPlaceholder() {
		return (
			<div style={{ width: 150, height: 20 }} id="placeholder-select-all">
				<ContentLoader
					width={200}
					speed={1}
					primaryColor="#f3f3f3"
					secondaryColor="#ecebeb">
					<rect x="0" y="0" rx="10" ry="10" width="160" height="20" />
					<rect x="179" y="0" rx="5" ry="5" width="20" height="20" />
				</ContentLoader>
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		let stringSelectAll = this.state.allSelected
			? 'partner.products_selector.deselect_all'
			: 'partner.products_selector.select_all';

		const data = createItemData(
			this.props.itemsSelected,
			this.state.menuSelected,
			this.state.displayedItems,
			this.state.sectionSelected
		);
		return (
			<React.Fragment>
				<div className={'section-products-header'}>
					<h2>
						{I18n.t('partner.products_selector.selectionable_list.title')}
					</h2>
				</div>
				<div className={'section-product-body'}>
					<div className="section-product-body-header">
						<div id={'search-container'}>
							{this.props.loaded ? (
								<React.Fragment>
									<img src={SearchIcon} alt="search icon" />
									<input
										id="standard-search"
										placeholder={I18n.t(
											'partner.products_selector.selectionable_list.search'
										)}
										type="text"
										onChange={this.onSearchChange}
										value={this.state.search}
									/>
								</React.Fragment>
							) : (
								this.renderSearchPlaceholder()
							)}
						</div>
						<div id={'select-all-container'}>
							{this.props.loaded ? (
								<div
									id={'select-all'}
									style={{
										display: this.state.displayedItems.length
											? 'initial'
											: 'none',
									}}>
									<Translate value={stringSelectAll} />
									<Checkbox
										checked={this.state.allSelected}
										onChange={this.handleSelectAll}
									/>
								</div>
							) : (
								this.renderSelectAllPlaceholder()
							)}
						</div>
					</div>

					<ListMaterial
						className={
							`${classes.root}` +
							' ' +
							`${classes.itemList}` +
							' ' +
							'productsSelector-products-list'
						}>
						{this.state.displayedItems && this.state.displayedItems.length ? (
							<List
								width={'100%'}
								height={10000}
								itemCount={this.state.displayedItems.length}
								itemSize={50}
								overscanRowCount={20}
								itemData={data}
								className="selectable-products-list">
								{this.Row}
							</List>
						) : (
							<div id={'item-selectable-empty'}>
								<p>
									<Translate
										value={
											'partner.products_selector.selectionable_list.no_product'
										}
									/>
								</p>
							</div>
						)}
					</ListMaterial>
					{this.props.loaded &&
					(!this.state.displayedItems || !this.state.displayedItems.length) ? (
						<div id={'item-selectable-empty'}>
							<p>
								<Translate
									value={
										'partner.products_selector.selectionable_list.no_selection'
									}
								/>
							</p>
						</div>
					) : null}
					{!this.props.loaded ? (
						<div id={'item-selectable-content-placeholder'}>
							{this.renderContentPlaceholder()}
						</div>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		products: getProductsList(state),
		itemsSelected: getProductsSelected(state),
		sectionSelected: getCategorySelected(state),
		menuSelected: getMenuSelected(state),
		sigle: state.info.variable ? state.info.variable.sigle : '€',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		select: bindActionCreators(actions.select, dispatch),
		deselect: bindActionCreators(actions.deselect, dispatch),
	};
};

function normalizeItemsByCategory(items) {
	return items.reduce((acc, row) => {
		if (row.type !== 'menu') {
			row.categories.forEach(category => {
				if (!acc.hasOwnProperty(category)) {
					acc[category] = {};
				}
				acc[category][row.id] = row;
			});
			if (row.categories.length === 0) {
				if (!acc.hasOwnProperty(0)) {
					acc[0] = {};
				}
				acc[0][row.id] = row;
			}
		} else {
			if (!acc.hasOwnProperty('menuId')) {
				acc['menuId'] = {};
			}
			acc['menuId'][row.id] = row;
		}

		return acc;
	}, {});
}

ItemSelectableList.propTypes = {
	loaded: Proptypes.bool.isRequired,
};
export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(ItemSelectableList)
);
