import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	agree_cgv: 2,
	check_existing_acount: 3,
	init_client_payplus_account: 4,
	download_app: 5,
	success: 5,
};

const TapToPay = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default TapToPay;
