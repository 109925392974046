import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import MenuSelector from '../../Components/MenuSelector';

class Barratio extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { partner } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<MenuSelector />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
	};
};

export default connect(mapStateToProps)(Barratio);
