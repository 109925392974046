import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PRE_PATCH_VARIABLE } from '../../Containers/VariableServer/service/types';
import { getVariableServer } from '../../utils';

class SelectVariableServer extends Component {
	render() {
		const { label, selected, list, keyVariable } = this.props;

		return (
			<div className="container-select-variable-server">
				<FormControl>
					<InputLabel id="select-mews-data-label">{label}</InputLabel>
					<Select
						className="select-mews-data"
						labelId="select-mews-data-label"
						value={selected}
						onChange={e =>
							this.props.onChange({ key: keyVariable, value: e.target.value })
						}>
						{list.map((item, index) => {
							return (
								<MenuItem key={index} value={item.id}>
									{item.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const variable = getVariableServer(state.variableServer, props.keyVariable);
	return {
		selected: variable || '',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: body => dispatch({ type: PRE_PATCH_VARIABLE, body }),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectVariableServer);
