import React, { Component } from 'react';
import './style.scss';
import ExportCard from './Components/ExportCard';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Translate, I18n } from 'react-redux-i18n';
import CloseIcon from '@material-ui/icons/Close';
import AddExport from '../AddExport';

class ExportList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exportSelected: 0,
		};
	}

	renderExports = exps => {
		let listActive = [];
		for (let exp of exps) {
			listActive.push(
				<ExportCard
					key={exp.id}
					export={exp}
					onClick={this.props.onClickExport}
					handleExport={this.props.handleExport}
					deleteExport={this.props.deleteExport}
				/>
			);
		}

		return listActive;
	};

	onChangeSelect = event => {
		this.setState({
			exportSelected: event.target.value,
		});
	};

	render() {
		const noActiveExports = this.props.listActive.filter(
			exp => !exp.is_current
		);
		let exportsByPartner = this.props.list.reduce((acc, l) => {
			if (l && l.sections && l.sections.length) {
				for (let s in this.props.samePartnerType) {
					let id = this.props.samePartnerType[s].id;
					if (l.id_partner === id) {
						if (!acc.hasOwnProperty(l.id_partner)) {
							acc[l.id_partner] = {};
						}
						if (acc.hasOwnProperty(l.id_partner)) {
							acc[l.id_partner][l.id] = l;
						}
					}
				}
			}
			return acc;
		}, {});
		let exportsByPartnersList = [];
		for (var t in exportsByPartner) {
			let part = this.props.samePartnerType.find(
				partner => partner.id === parseInt(t)
			);
			exportsByPartnersList.push(
				<optgroup
					key={part.prefix}
					label={I18n.t(`partner.prefix.${part.prefix}`)}>
					{Object.values(exportsByPartner[part.id]).map(te => {
						return (
							<option key={te.id} value={te.id}>
								{te.name}
							</option>
						);
					})}
				</optgroup>
			);
		}
		return (
			<div className={'export-list'}>
				<h2>
					<Translate value={'partner.export.list'} />
				</h2>
				<CloseIcon
					onClick={this.props.closeExpensiveModal}
					className="export-list-close-icon"
				/>
				{this.renderExports(noActiveExports)}
				{this.props.listActive.length < 10 ? (
					<div className="new-export-button-container">
						<AddExport onAddExport={this.props.onAddExport} />

						{exportsByPartnersList.length > 0 ? (
							<div
								className="add-export-container"
								onClick={() =>
									this.props.onAddExportBasedOn(this.state.exportSelected)
								}>
								<p>
									<Translate
										value={'partner.export.new_export_from_another_export'}
									/>
								</p>
								<select
									onClick={e => e.stopPropagation()}
									onChange={this.onChangeSelect}>
									<option value="">
										{I18n.t('partner.export.select_export')}
									</option>
									{exportsByPartnersList}
								</select>
								<p className="icon-add-export-card">
									<AddIcon />
								</p>
							</div>
						) : null}
					</div>
				) : (
					''
				)}
			</div>
		);
	}
}

ExportList.propTypes = {
	listActive: PropTypes.array.isRequired,
	onClickExport: PropTypes.func.isRequired,
	handleExport: PropTypes.func.isRequired,
	deleteExport: PropTypes.func.isRequired,
	onAddExport: PropTypes.func.isRequired,
};

export default ExportList;
