import React, { Component } from 'react';

import SimplePartner from '../index';

const Snapshift = props => {
	return (
		<SimplePartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}></SimplePartner>
	);
};

export default Snapshift;
