import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { bindActionCreators } from 'redux';
import { updateSession } from '../../Containers/VariableServer/service/actions';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import Button from '../../Components/Button';

class AllreadyInUse extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick = () => {
		this.props.updateSession();
	};

	render() {
		return (
			<div>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.props.open}
					className="variable-modal">
					<div className="variable-modal-container">
						<h2 id="modal-title">{I18n.t('general.warning')}</h2>
						<p id="simple-modal-description">
							{I18n.t('variable.multisession.label')}
						</p>
						<Button onClick={this.onClick}>
							{I18n.t('variable.multisession.button')}
						</Button>
					</div>
				</Modal>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		open: state.service.auth.multiSession,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateSession: bindActionCreators(updateSession, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllreadyInUse);
