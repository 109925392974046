import {
	call,
	put,
	take,
	all,
	select,
	actionChannel,
} from 'redux-saga/effects';
import { requestHandler, ENTITIES } from '../../../../service/fetcher.saga';

import {
	PRE_ADD_SECTION,
	ADD_SECTION,
	PRE_ADD_PRODUCTS_TO_SECTION,
	PRE_SAVE_PRODUCT_TO_SECTION,
	SAVE_PRODUCTS_TO_SECTION,
	ADD_PRODUCTS_TO_SECTION,
	PRE_DELETE_PRODUCT_TO_SECTION,
	DELETE_PRODUCT_TO_SECTION,
	PRE_SAVE_EXTRA_PRODUCT,
	SAVE_EXTRA_PRODUCT,
	PRE_DELETE_SECTION,
	DELETE_SECTION,
	PRE_PATCH_SECTION,
	PATCH_SECTION,
	PRE_PATCH_EXPORT,
	PATCH_EXPORT,
	PRE_PATCH_SECTION_WEIGHT,
	PATCH_SECTION_WEIGHT,
	PRE_PATCH_PRODUCT_WEIGHT,
	PATCH_PRODUCT_WEIGHT,
	PRE_GENERATE_EXPORT,
	GENERATE_EXPORT,
	ACTIVE_EXPORT,
	PRE_ACTIVE_EXPORT,
	PRE_DELETE_EXPORT,
	DELETE_EXPORT,
	PRE_ADD_EXPORT,
	ADD_EXPORT,
	DELETE_PRODUCT_EXTRA,
	PRE_DELETE_PRODUCT_EXTRA,
	PRE_POST_IPAD_CHANGE,
	POST_IPAD_CHANGE,
	COPY_EXPORT,
	ACTIVATE_UBEREATS_LINK,
	PRE_RESET_PRODUCT_EXTRA,
} from './types';
import { saveAs } from 'file-saver';
import { I18n, i18nReducer } from 'react-redux-i18n';
import { notify } from '../../../../Components/Notifier/services/saga';
import { showConfirm } from '../../../../Components/ConfirmRedux/service/saga';
import { priceEuroToCents } from '../../../../utils';
import { PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT } from '../partnersConfig/types';
import { buffers } from 'redux-saga';

const GENERATE = 'generate';
const GENERATE_FILE = 'generateFile';

const PRE_REQUEST_ADD_SECTION = 'PRE_REQUEST_ADD_SECTION';
const REQUEST_ADD_SECTION_FINISHED = 'REQUEST_ADD_SECTION_FINISHED';

const PRE_REQUEST_ADD_PRODUCTS_TO_SECTION =
	'PRE_REQUEST_ADD_PRODUCTS_TO_SECTION';
const REQUEST_ADD_PRODUCTS_TO_SECTION_FINISHED =
	'REQUEST_ADD_PRODUCTS_TO_SECTION_FINISHED';

const PRE_REQUEST_REMOVE_PRODUCT_TO_SECTION =
	'PRE_REQUEST_REMOVE_PRODUCT_TO_SECTION';
const REQUEST_REMOVE_PRODUCT_TO_SECTION_FINISHED =
	'REQUEST_REMOVE_PRODUCT_TO_SECTION_FINISHED';

const PRE_REQUEST_SAVE_EXTRA_PRODUCT = 'PRE_REQUEST_SAVE_EXTRA_PRODUCT';
const REQUEST_SAVE_EXTRA_PRODUCT_FINISHED =
	'REQUEST_SAVE_EXTRA_PRODUCT_FINISHED';
const PRE_REQUEST_DELETE_SECTION = 'PRE_REQUEST_DELETE_SECTION';
const REQUEST_DELETE_SECTION_FINISHED = 'REQUEST_DELETE_SECTION_FINISHED';

const PRE_REQUEST_PATCH_SECTION = 'PRE_REQUEST_PATCH_SECTION';
const REQUEST_PATCH_SECTION_FINISHED = 'REQUEST_PATCH_SECTION_FINISHED';

const PRE_REQUEST_PATCH_EXPORT = 'PRE_REQUEST_PATCH_EXPORT';
const REQUEST_PATCH_EXPORT_FINISHED = 'REQUEST_PATCH_EXPORT_FINISHED';

const PRE_REQUEST_PATCH_SECTION_WEIGHT = 'PRE_REQUEST_PATCH_SECTION_WEIGHT';
const REQUEST_PATCH_SECTION_WEIGHT_FINISHED =
	'REQUEST_PATCH_SECTION_WEIGHT_FINISHED';

const PRE_REQUEST_PATCH_PRODUCT_WEIGHT = 'PRE_REQUEST_PATCH_PRODUCT_WEIGHT';
const REQUEST_PATCH_PRODUCT_WEIGHT_FINISHED =
	'REQUEST_PATCH_PRODUCT_WEIGHT_FINISHED';

const PRE_REQUEST_GENERATE_EXPORT = 'PRE_REQUEST_GENERATE_EXPORT';
const REQUEST_GENERATE_EXPORT_FINISHED = 'REQUEST_GENERATE_EXPORT_FINISHED';

const PRE_REQUEST_ACTIVE_EXPORT = 'PRE_REQUEST_ACTIVE_EXPORT';
const REQUEST_ACTIVE_EXPORT_FINISHED = 'REQUEST_ACTIVE_EXPORT_FINISHED';

const PRE_REQUEST_DELETE_EXPORT = 'PRE_REQUEST_DELETE_EXPORT';
const REQUEST_DELETE_EXPORT_FINISHED = 'REQUEST_DELETE_EXPORT_FINISHED';

const PRE_REQUEST_ADD_EXPORT = 'PRE_REQUEST_ADD_EXPORT';
const REQUEST_ADD_EXPORT_FINISHED = 'REQUEST_ADD_EXPORT_FINISHED';

const PRE_REQUEST_DELETE_PRODUCT_EXTRA = 'PRE_REQUEST_DELETE_PRODUCT_EXTRA';
const REQUEST_DELETE_PRODUCT_EXTRA_FINISHED =
	'REQUEST_DELETE_PRODUCT_EXTRA_FINISHED';

const PRE_REQUEST_POST_IPAD_CHANGE = 'PRE_REQUEST_POST_IPAD_CHANGE';
const REQUEST_POST_IPAD_CHANGE_FINISHED = 'REQUEST_POST_IPAD_CHANGE_FINISHED';

const PRE_REQUEST_POST_COPY_EXPORT = 'PRE_REQUEST_POST_COPY_EXPORT';
const REQUEST_POST_COPY_EXPORT_FINISHED = 'REQUEST_POST_COPY_EXPORT_FINISHED';
const HANDLE_UBEREATS_POS_STATUS = 'HANDLE_UBEREATS_POS_STATUS';

const partnerFileExport = [];

export function* preAddSection() {
	while (true) {
		const action = yield take(PRE_ADD_SECTION);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_SECTION,
			body,
		});

		const { result } = yield take(REQUEST_ADD_SECTION_FINISHED);
		yield put({
			type: ADD_SECTION,
			body: {
				id: body.id,
				section: { ...body.section, id: result.id, products: [] },
			},
		});
	}
}

export function* watchAddSection() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_SECTION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SECTION,
			'POST',
			action.body.section
		);

		yield put({ type: REQUEST_ADD_SECTION_FINISHED, result });
	}
}

export function* preAddProductsToSection() {
	while (true) {
		const action = yield take(PRE_ADD_PRODUCTS_TO_SECTION);
		const { idSection, idExport, products } = action;

		const formatedProducts = yield call(
			formatSectionProduct,
			products,
			idSection,
			idExport
		);

		yield put({
			type: PRE_REQUEST_ADD_PRODUCTS_TO_SECTION,
			body: {
				products: formatedProducts,
				idExport: idExport,
				idSection: idSection,
			},
		});

		const { result } = yield take(REQUEST_ADD_PRODUCTS_TO_SECTION_FINISHED);
		yield put({
			type: ADD_PRODUCTS_TO_SECTION,
			body: { ...result, id_export: idExport },
		});
	}
}

export function* watchAddProductsToSection() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_PRODUCTS_TO_SECTION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SECTION,
			'PUT',
			action.body
		);
		yield put({ type: REQUEST_ADD_PRODUCTS_TO_SECTION_FINISHED, result });
	}
}

/**
 * Formate les produits issus du selecteur de produit pour les enregistrer dans une section
 */
function* formatSectionProduct(selectedItems, idSection, idExport) {
	let newProductsInSection = [];

	const store = yield select();

	let exp = store.exports.list.find(e => e.id == idExport);
	let products = store.exports.productsSelector.products.list;

	if (exp && exp.sections) {
		let section = exp.sections.find(s => s.id == idSection);

		if (section) {
			let lastWeight =
				section.products.length > 0
					? section.products[section.products.length - 1].weight + 1
					: 0;

			if (Object.keys(selectedItems).length) {
				const id_menu = Object.keys(selectedItems)[0];
				Object.values(products[id_menu]).map(product => {
					if (Object.values(selectedItems).length) {
						Object.values(selectedItems)[0].map(selectedItem => {
							if (product.id === selectedItem) {
								let p = {
									id_product: product.id,
									id_menu: product.id_menu,
									id_export: idExport,
									id_section: idSection,
									weight: lastWeight,
								};
								if (product && product.type) {
									p.type = 2;
								}
								newProductsInSection.push(p);
								lastWeight++;
							}
						});
					}
				});
			}
		}
	}

	return newProductsInSection;
}

export function* preRemoveProductToSection() {
	while (true) {
		const action = yield take(PRE_DELETE_PRODUCT_TO_SECTION);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_REMOVE_PRODUCT_TO_SECTION,
			id: body.id,
			idProduct: body.idProduct,
			idExport: body.idExport,
			idSection: body.idSection,
		});

		const { result } = yield take(REQUEST_REMOVE_PRODUCT_TO_SECTION_FINISHED);
		yield put({
			type: DELETE_PRODUCT_TO_SECTION,
			body,
		});
	}
}

export function* watchRemoveProductToSection() {
	while (true) {
		const action = yield take(PRE_REQUEST_REMOVE_PRODUCT_TO_SECTION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.PRODUCTS,
			'DELETE',
			{
				id: action.id,
				idExport: action.idExport,
				idSection: action.idSection,
				idProduct: action.idProduct,
			}
		);
		yield put({ type: REQUEST_REMOVE_PRODUCT_TO_SECTION_FINISHED, result });
	}
}

export function* preSaveExtra() {
	while (true) {
		const action = yield take(PRE_SAVE_EXTRA_PRODUCT);
		const {
			id_export,
			id_section,
			id_product,
			extras,
			id_menu_level,
			id_product_parent,
		} = action;

		if (extras.hasOwnProperty('price')) {
			extras.price = priceEuroToCents(extras.price);
		}
		yield put({
			type: PRE_REQUEST_SAVE_EXTRA_PRODUCT,
			body: {
				id_export,
				id_product: id_product,
				extras,
				id_menu_level,
				id_product_parent,
			},
		});
		const { result } = yield take(REQUEST_SAVE_EXTRA_PRODUCT_FINISHED);
		if (result) {
			yield put({
				type: SAVE_EXTRA_PRODUCT,
				data: {
					id_export,
					id_section,
					id_product,
					extras: result.extras,
					category_supplement: result.category_supplement,
					id_menu_level,
					id_product_parent,
				},
			});
		}
	}
}

export function* watchSaveExtra() {
	while (true) {
		const action = yield take(PRE_REQUEST_SAVE_EXTRA_PRODUCT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.PRODUCT_EXTRA,
			'POST',
			action.body
		);
		yield put({ type: REQUEST_SAVE_EXTRA_PRODUCT_FINISHED, result });
	}
}

export function* preRemoveSection() {
	while (true) {
		const action = yield take(PRE_DELETE_SECTION);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_DELETE_SECTION,
			id: body.id,
			idExport: body.idExport,
		});

		const { result } = yield take(REQUEST_DELETE_SECTION_FINISHED);
		yield put({
			type: DELETE_SECTION,
			body,
		});
	}
}

export function* watchRemoveSection() {
	while (true) {
		const action = yield take(PRE_REQUEST_DELETE_SECTION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SECTION,
			'DELETE',
			{ id: action.id, idExport: action.idExport }
		);
		yield put({ type: REQUEST_DELETE_SECTION_FINISHED, result });
	}
}

export function* prePatchSection() {
	while (true) {
		const action = yield take(PRE_PATCH_SECTION);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_SECTION,
			body: {
				id: body.idSection,
				name: body.name,
				description: body.description,
			},
		});
		const { result } = yield take(REQUEST_PATCH_SECTION_FINISHED);
		yield put({
			type: PATCH_SECTION,
			body,
		});
	}
}

export function* watchPatchSection() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_SECTION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SECTION,
			'PATCH',
			action.body
		);
		yield put({
			type: REQUEST_PATCH_SECTION_FINISHED,
			result,
		});
	}
}

export function* prePatchExport() {
	while (true) {
		const action = yield take(PRE_PATCH_EXPORT);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_EXPORT,
			body: { id: body.idExport, name: body.name, date_available: body.date },
		});
		const { result } = yield take(REQUEST_PATCH_EXPORT_FINISHED);
		yield put({
			type: PATCH_EXPORT,
			body,
		});
	}
}

export function* watchPatchExport() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_EXPORT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.ROOT,
			'PATCH',
			action.body
		);
		yield put({
			type: REQUEST_PATCH_EXPORT_FINISHED,
			result,
		});
	}
}

export function* prePatchSectionWeight() {
	while (true) {
		const action = yield take(PRE_PATCH_SECTION_WEIGHT);
		const { body } = action;
		let sectionLight = body.sections.map(section => {
			return { id: section.id, weight: section.weight };
		});
		yield put({
			type: PRE_REQUEST_PATCH_SECTION_WEIGHT,
			body: sectionLight,
			idExport: body.idExport,
		});
		const { result } = yield take(REQUEST_PATCH_SECTION_WEIGHT_FINISHED);
		yield put({
			type: PATCH_SECTION_WEIGHT,
			body: { ...body },
		});
	}
}

export function* watchPatchSectionWeight() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_SECTION_WEIGHT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SECTION_WEIGHT,
			'PATCH',
			{ sections: action.body, idExport: action.idExport }
		);
		yield put({
			type: REQUEST_PATCH_SECTION_WEIGHT_FINISHED,
			result,
		});
	}
}

export function* prePatchProductWeight() {
	while (true) {
		const action = yield take(PRE_PATCH_PRODUCT_WEIGHT);
		const { body } = action;
		let productLight = body.products.map(product => {
			return { id: product.id, weight: product.weight };
		});
		yield put({
			type: PRE_REQUEST_PATCH_PRODUCT_WEIGHT,
			body: productLight,
			idExport: body.idExport,
		});
		const { result } = yield take(REQUEST_PATCH_PRODUCT_WEIGHT_FINISHED);
		yield put({
			type: PATCH_PRODUCT_WEIGHT,
			body,
		});
	}
}

export function* watchPatchProductWeight() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_PRODUCT_WEIGHT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.PRODUCT_WEIGHT,
			'PATCH',
			{ products: action.body, idExport: action.idExport }
		);
		yield put({
			type: REQUEST_PATCH_PRODUCT_WEIGHT_FINISHED,
			result,
		});
	}
}
export function* preGenerateExport() {
	while (true) {
		const action = yield take(PRE_GENERATE_EXPORT);
		const { prefix_partner, id_partner, id_export } = action;

		const { service, partners } = yield select();
		const partner = partners[prefix_partner];
		if (!partner.active) {
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
				body: {
					status: 'actif',
					prefix: prefix_partner,
					id_user: service.user.id,
					partner_id: id_partner,
				},
				bypassExportCheck: true,
			});
			yield take('CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_RESULT');
		}

		// get products for the menu in store
		const store = yield select();
		const { list } = store.exports.productsSelector.products;
		const listSupp = store.exports.supplement.list;

		const exp = store.exports.list.find(exp => exp.id == id_export);

		let id_menu = false;
		if (exp && exp.sections && exp.sections.length) {
			for (let s of exp.sections) {
				if (s.products && s.products.length) {
					for (let p of s.products) {
						if (p.hasOwnProperty('id_menu')) {
							id_menu = p.id_menu;
							break;
						}
						if (id_menu) break;
					}
				}
				if (id_menu) break;
			}
		}

		yield put({
			type: PRE_REQUEST_GENERATE_EXPORT,
			body: {
				prefix_partner,
				id_partner,
				products: id_menu ? list[id_menu] : {},
				categories_supplement:
					id_menu && listSupp.hasOwnProperty(id_menu) ? listSupp[id_menu] : {},
			},
		});
		const { result } = yield take(REQUEST_GENERATE_EXPORT_FINISHED);

		if (prefix_partner === 'ubereats') {
			yield put({ type: HANDLE_UBEREATS_POS_STATUS });
		}

		if (
			result &&
			result.result &&
			result.result.jsonGeneration &&
			result.result.notifyMediation
		) {
			yield put({
				type: GENERATE_EXPORT,
				exp,
				prefix_partner,
			});

			yield call(notify, I18n.t('partner.export.generate_success'), 'success');
		} else {
			let errorMessageKey =
				!result || !result.result
					? 'partner.export.generate_fail'
					: !result.result.jsonGeneration
					? 'partner.export.generate_fail_generation'
					: 'partner.export.generate_fail_notif';

			let label = I18n.t(errorMessageKey);

			let code = getExportPartnerErrorCode(prefix_partner, result);
			if (code) {
				label = `${label} : ${I18n.t(
					'partner.export.generate_fail_notif_partner_error.' + code
				)}`;
			}

			yield call(notify, label, 'error', 10000);
		}
	}
}

export function* watchGenerateExport() {
	while (true) {
		const action = yield take(PRE_REQUEST_GENERATE_EXPORT);

		//const refresh_token = window.localStorage.refresh_token;
		let result;
		if (partnerFileExport.includes(action.body.prefix_partner)) {
			result = yield call(
				requestHandler,
				ENTITIES.EXPORT.ROOT,
				'POST',
				{ ...action.body },
				GENERATE_FILE,
				{ responseType: 'blob' }
			);
			saveAs(result.result, 'menu.xls');
		} else {
			result = yield call(
				requestHandler,
				ENTITIES.EXPORT.ROOT,
				'POST',
				{ ...action.body },
				GENERATE,
				{ timeout: 30000 }
			);
		}

		yield put({
			type: REQUEST_GENERATE_EXPORT_FINISHED,
			result,
		});
	}
}

function getExportPartnerErrorCode(prefix, err) {
	const error = err && err.result && err.result.notififyMediationError;
	if (error) {
		switch (prefix) {
			case 'deliveroo':
				return error.error && error.error.code ? error.error.code : null;
			default:
				return null;
		}
	}
}

export function* preActiveExport() {
	while (true) {
		const action = yield take(PRE_ACTIVE_EXPORT);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ACTIVE_EXPORT,
			body,
		});
		const { result } = yield take(REQUEST_ACTIVE_EXPORT_FINISHED);
		yield put({
			type: ACTIVE_EXPORT,
			body,
		});
	}
}

export function* watchActiveExport() {
	while (true) {
		const action = yield take(PRE_REQUEST_ACTIVE_EXPORT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.ACTIVE,
			'POST',
			{ id: action.body.idExport, id_partner: action.body.idPartner }
		);
		yield put({
			type: REQUEST_ACTIVE_EXPORT_FINISHED,
			result,
		});
	}
}

export function* preDeleteExport() {
	while (true) {
		const action = yield take(PRE_DELETE_EXPORT);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_DELETE_EXPORT,
			body,
		});
		const { result } = yield take(REQUEST_DELETE_EXPORT_FINISHED);
		yield put({
			type: DELETE_EXPORT,
			body,
		});
	}
}

export function* watchDeleteExport() {
	while (true) {
		const action = yield take(PRE_REQUEST_DELETE_EXPORT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.ROOT,
			'DELETE',
			{ id: action.body.idExport }
		);
		yield put({
			type: REQUEST_DELETE_EXPORT_FINISHED,
			result,
		});
	}
}

export function* preAddExport() {
	while (true) {
		const action = yield take(PRE_ADD_EXPORT);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_EXPORT,
			body,
		});
		const { result } = yield take(REQUEST_ADD_EXPORT_FINISHED);
		yield put({
			type: ADD_EXPORT,
			body: result,
		});
	}
}

export function* watchAddExport() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_EXPORT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.ROOT,
			'POST',
			action.body,
			'',
			{ timeout: 20000 }
		);
		yield put({
			type: REQUEST_ADD_EXPORT_FINISHED,
			result,
		});
	}
}

export function* preResetProductExtra() {
	while (true) {
		const { data } = yield take(PRE_RESET_PRODUCT_EXTRA);
		const { exports } = yield select();

		let extras = [];
		let finded = false;
		let idSection = null;
		for (let exp of exports.list) {
			if (exp.id === data.idExport) {
				for (const s of exp.sections) {
					for (const p of s.products) {
						if (p.id_product === data.idProduct) {
							extras = p.extras;
							idSection = s.id;
							finded = true;
							break;
						}
					}
					if (finded) break;
				}
			}
		}

		for (const key_extra in extras) {
			yield put({
				type: PRE_DELETE_PRODUCT_EXTRA,
				data: {
					idExport: data.idExport,
					idProduct: data.idProduct,
					idSection: idSection,
					key_: key_extra,
					idMenuLevel: null,
					idProductParent: null,
				},
			});
		}
		yield put({
			type: 'SET_FORM_VALUES',
			data: {},
		});
	}
}

export function* preDeleteProductExtra() {
	const requestChannel = yield actionChannel(
		PRE_DELETE_PRODUCT_EXTRA,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		const body = action.data;
		yield put({
			type: PRE_REQUEST_DELETE_PRODUCT_EXTRA,
			body,
		});
		const { result } = yield take(REQUEST_DELETE_PRODUCT_EXTRA_FINISHED);
		//FIXME:
		yield put({
			type: DELETE_PRODUCT_EXTRA,
			body,
		});
	}
}

export function* watchDeleteProductExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_DELETE_PRODUCT_EXTRA);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.PRODUCT_EXTRA,
			'DELETE',
			body
		);
		yield put({
			type: REQUEST_DELETE_PRODUCT_EXTRA_FINISHED,
			result,
		});
	}
}

export function* prePostIpadChange() {
	while (true) {
		const action = yield take(PRE_POST_IPAD_CHANGE);
		const body = action.data;
		yield put({
			type: PRE_REQUEST_POST_IPAD_CHANGE,
			body,
		});
		const { result } = yield take(REQUEST_POST_IPAD_CHANGE_FINISHED);

		/* yield put({
      type: POST_IPAD_CHANGE,
      body
    }); */
	}
}

export function* watchPostIpadChange() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_IPAD_CHANGE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.NOTIF_IPAD_CHANGES,
			'POST',
			body
		);
		window.location.reload();
		yield put({
			type: REQUEST_POST_IPAD_CHANGE_FINISHED,
			result,
		});
	}
}

export function* preCopyExport() {
	while (true) {
		const { data } = yield take(COPY_EXPORT);

		yield put({
			type: PRE_REQUEST_POST_COPY_EXPORT,
			body: data,
		});

		//TODO:
		const { exports, partners } = yield select();
		const expSource = exports.list.find(e => e.id == data.id);
		console.log({ source: expSource.id_partner, target: data.id_partner });
		if (expSource.id_partner != data.id_partner) {
			const prefixSource = Object.values(partners).find(
				p => p.id == expSource.id_partner
			).prefix;
			const prefixTarget = Object.values(partners).find(
				p => p.id == data.id_partner
			).prefix;

			console.log({ prefixSource, prefixTarget });
			const sourcePartnerEmpty = yield call(
				checkEmptySuppCatSuppExtra,
				exports.supplement.categorySupplementExtra,
				exports.supplement.supplementExtra,
				prefixSource
			);
			const targetPartnerEmpty = yield call(
				checkEmptySuppCatSuppExtra,
				exports.supplement.categorySupplementExtra,
				exports.supplement.supplementExtra,
				prefixTarget
			);
			console.log({ sourcePartnerEmpty, targetPartnerEmpty });
			if (!sourcePartnerEmpty && targetPartnerEmpty) {
				yield call(
					showConfirm,
					I18n.t('partner.export.copy_cat_supp_extra', {
						source: I18n.t('partner.prefix.' + prefixSource),
						target: I18n.t('partner.prefix.' + prefixTarget),
					}),
					'confirm',
					{
						eventOnValid: 'COPY_CAT_SUPP_EXTRA',
						paramsOnValid: {
							prefix_target: prefixTarget,
							prefix_source: prefixSource,
						},
						options: {
							cancelLabel: I18n.t('general.no'),
							confirmLabel: I18n.t('general.yes'),
						},
					}
				);
			}
		}

		const { result } = yield take(REQUEST_POST_COPY_EXPORT_FINISHED);
		yield put({
			type: ADD_EXPORT,
			body: result,
		});
	}
}

export function* watchCopyExport() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_COPY_EXPORT);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.COPY_EXPORT,
			'POST',
			body
		);
		yield put({ type: REQUEST_POST_COPY_EXPORT_FINISHED, result });
	}
}

function* checkEmptySuppCatSuppExtra(cats, supps, prefix) {
	if (
		Object.values(cats).some(cat =>
			cat.some(extra => extra.partner_prefix == prefix)
		) ||
		Object.values(supps).some(supp =>
			supp.some(extra => extra.partner_prefix == prefix)
		)
	)
		return false;

	return true;
}

function* copyCatSuppExtra() {
	while (true) {
		const { prefix_target, prefix_source } = yield take('COPY_CAT_SUPP_EXTRA');
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.ROOT,
			'POST',
			{ prefix_target, prefix_source },
			'suppCatSuppExtraCopy'
		);
	}
}

//TODO: refacto (coté med back?) pour avoir une réponse de l'activation TRUE OU FALSE
export function* handleUbereatsPosStatus() {
	while (true) {
		yield take(HANDLE_UBEREATS_POS_STATUS);
		const { service } = yield select();
		yield put({
			type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
			body: { partnerPrefix: 'ubereats', id: service.user.id },
		});
		const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');
		if (
			result &&
			result.responseData &&
			result.responseData.integration_enabled == false
		) {
			yield call(
				showConfirm,
				I18n.t('partner.export.activate_link_ubereats'),
				'confirm',
				{
					eventOnValid: ACTIVATE_UBEREATS_LINK,
					options: {
						cancelLabel: I18n.t('general.no'),
						confirmLabel: I18n.t('general.yes'),
					},
				}
			);
		}
	}
}

export function* notifyExportPartner(prefix) {
	const { result } = yield call(
		requestHandler,
		ENTITIES.EXPORT.ROOT,
		'POST',
		{ prefix },
		'notify-export'
	);
}

function* afterSuccessExport() {
	while (true) {
		const { exp, prefix_partner } = yield take(GENERATE_EXPORT);

		if (prefix_partner === 'deliveroo' && !exp.date_available) {
			const contentDeliveroo = yield call(getDeliverooContent);
			yield call(showConfirm, contentDeliveroo, 'info', {
				media: {
					type: 'img',
					url: 'https://cdn.laddition.com/23/connectordersdel.jpg',
				},
			});
		}
	}
}

function getDeliverooContent() {
	return [
		'<div>',
		`<h2>${I18n.t(
			'partner.deliveroo_export.title'
		)} <a href="https://restaurant-hub.deliveroo.net/" target='_blank' rel='noopener noreferrer'>Hub Deliveroo</a></h2>`,
		`<p>${I18n.t('partner.deliveroo_export.1')}</p>`,
		`<p>${I18n.t('partner.deliveroo_export.2')}</p>`,
		`<p>${I18n.t('partner.deliveroo_export.3')}</p>`,
		'</div>',
	].join('');
}

export default function* indexSaga() {
	yield all([
		preAddSection(),
		watchAddSection(),
		preAddProductsToSection(),
		watchAddProductsToSection(),
		preRemoveProductToSection(),
		watchRemoveProductToSection(),
		preSaveExtra(),
		watchSaveExtra(),
		preRemoveSection(),
		watchRemoveSection(),
		prePatchSection(),
		watchPatchSection(),
		prePatchExport(),
		watchPatchExport(),
		prePatchSectionWeight(),
		watchPatchSectionWeight(),
		prePatchProductWeight(),
		watchPatchProductWeight(),
		preGenerateExport(),
		watchGenerateExport(),
		preActiveExport(),
		watchActiveExport(),
		preDeleteExport(),
		watchDeleteExport(),
		preAddExport(),
		watchAddExport(),
		preDeleteProductExtra(),
		watchDeleteProductExtra(),
		prePostIpadChange(),
		watchPostIpadChange(),
		preCopyExport(),
		watchCopyExport(),
		handleUbereatsPosStatus(),
		afterSuccessExport(),
		preResetProductExtra(),
		copyCatSuppExtra(),
	]);
}
