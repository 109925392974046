import React from 'react';
import './index.scss';

const BackgroundCircle = () => (
	<div className="backgroundCircle-container">
		<svg>
			{/* cercle bleu */}
			<circle id="oval1" cx="-50" cy="50%" r="100" fill="#03CBFA" />{' '}
			{/* cercle bleu clair*/}
			<circle
				id="oval2"
				cx="4%"
				cy="63%"
				r="10"
				fill="rgba(3,203,250, 0.5)"
			/>{' '}
			{/* cercle jaune*/}
			<circle id="oval3" cx="100%" cy="95%" r="80" fill="#F9B868" />{' '}
			{/* cercle jaune clair*/}
			<circle
				id="oval6"
				cx="98%"
				cy="75%"
				r="8"
				fill="rgba(249,184,104, 0.5)"
			/>{' '}
			{/* cercle rouge*/}
			<circle id="oval4" cx="70%" cy="10%" r="15" fill="#e84249" />{' '}
			{/* cercle rouge clair*/}
			<circle
				id="oval5"
				cx="75%"
				cy="10%"
				r="5"
				fill="rgba(232,66,73, 0.5)"
			/>{' '}
			<circle
				className="circleDesktop"
				id="oval7"
				cx="35%"
				cy="105%"
				r="25"
				fill="rgba(232,66,73, 0.75)"
			/>{' '}
		</svg>
	</div>
);

export default BackgroundCircle;
