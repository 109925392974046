import {
	UPDATE_USER,
	UPDATE_STOCK_MANAGEMENT_STATUS,
	RECEIVE_UBEREATS_CLIENT_ID,
	UPDATE_PARTNER_STOCK_MANAGEMENT_STATUS,
	RECEIVE_USER,
} from './types';

const initialState = {
	id: null,
	token: null,
	username: null,
	email: null,
	isPartner: null,
	partnerPrefix: null,
	stockManagementStatus: null,
	partnerStockManagementStatus: null,
	ipadAppVersion: null,
};

const reducer = function userReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_USER:
			return Object.assign({}, state, {
				id: action.user.hasOwnProperty('id') ? action.user.id : state.id,
				account_id: action.user.hasOwnProperty('account_id')
					? action.user.account_id
					: state.account_id,
				token: action.user.hasOwnProperty('token')
					? action.user.token
					: state.token,
				username: action.user.hasOwnProperty('username')
					? action.user.username
					: state.username,
				email: action.user.hasOwnProperty('email')
					? action.user.email
					: state.email,
				isPartner: action.user.hasOwnProperty('isPartner')
					? action.user.isPartner
					: state.isPartner,
				partnerPrefix: action.user.hasOwnProperty('partnerPrefix')
					? action.user.partnerPrefix
					: state.partnerPrefix,
				ipadAppVersion: state.ipadAppVersion,
				isSwift: action.user.hasOwnProperty('isSwift')
					? action.user.isSwift
					: state.isSwift,
			});
		case UPDATE_STOCK_MANAGEMENT_STATUS:
			return {
				...state,
				stockManagementStatus: action.data.status,
				partnerStockManagementStatus: action.data.partnerStatus,
			};
		case UPDATE_PARTNER_STOCK_MANAGEMENT_STATUS:
			return {
				...state,
				partnerStockManagementStatus: {
					...state.partnerStockManagementStatus,
					[action.prefix]: action.active,
				},
			};
		case RECEIVE_UBEREATS_CLIENT_ID:
			return { ...state, ubereatsClientId: action.data };
		case RECEIVE_USER:
			return {
				...state,
				...action.data.user,
			};
		default:
			return state;
	}
};

export default reducer;
