import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import { getVariableServer } from '../../../../../utils';

class ButtonPlayPlus extends Component {
	onClick = e => {
		if (this.props.payplusActivated) return;

		this.props.activatePayPlus(this.props.prefix);
	};

	render() {
		const { payplusActivated } = this.props;
		return (
			<div className="button-payplus-container">
				{payplusActivated ? (
					<div className="pay-plus-activated">
						<span>{I18n.t('partner.ladditionTicket.payplus_activated')}</span>
						<p className={'pastilla'} />
					</div>
				) : (
					<button disabled={payplusActivated} onClick={this.onClick}>
						{I18n.t('partner.ladditionTicket.activate_payplus')}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const variableKey =
		props.prefix == 'ladditionTicket'
			? 'ladditionPayplusActivated'
			: 'ladditionPayplusActivatedClickAndCollect';
	const payplusActivated = getVariableServer(state.variableServer, variableKey);
	return {
		payplusActivated,
	};
};

const mapDispatchToProps = dispatch => ({
	activatePayPlus: prefix => dispatch({ type: 'ACTIVATE_PAY_PLUS', prefix }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPlayPlus);
