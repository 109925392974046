import React from 'react';
import { connect } from 'react-redux';
import { confirm, getVariableServer } from '../../../../utils';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import EditPopup from '../../../../Components/EditPopup';
import { patchVariable } from '../../../VariableServer/service/actions';
import { Button } from '@material-ui/core';

class ExportBanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			pictureModalOpen: false,
		};
	}

	submitPicture = async (path, path_cropped, ...other) => {
		this.props.saveBanner(path_cropped, this.props.prefix);
	};

	reUsePicture = img => {
		this.props.saveBanner(img.path_cropped, this.props.prefix);
	};

	deleteBanner = _ => {
		confirm(I18n.t('exports.banner.confirm_delete')).then(() => {
			this.props.saveBanner('', this.props.prefix);
		});
	};
	render() {
		return (
			<div className="export-banner-container">
				<h2 className="export-title">{I18n.t('exports.banner.title')}</h2>

				<p>{I18n.t('exports.banner.description')}</p>
				<div>
					{this.props.banner ? (
						<div className="banner-export-image">
							<img
								className="banner-export-mini"
								src={this.props.banner}
								alt="banner export"
							/>
							<Button
								variant={'outlined'}
								className="gestion-button-supplement"
								onClick={this.deleteBanner}>
								{I18n.t('general.delete')}
							</Button>
						</div>
					) : null}
					{!this.props.banner ? (
						<div
							className="banner-export-empty"
							onClick={() => this.setState({ pictureModalOpen: true })}>
							<p>{I18n.t('exports.banner.add')}</p>
						</div>
					) : null}
				</div>

				<EditPopup
					onSubmit={this.submitPicture}
					src={''}
					openModal={this.state.pictureModalOpen}
					closeModal={() => this.setState({ pictureModalOpen: false })}
					displayMetaData={false}
					onSetExistedPicture={this.reUsePicture}
					cropper={this.props.cropper || {}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const banner = getVariableServer(
		state.variableServer,
		props.prefix + 'ExportBanner'
	);

	return {
		banner,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveBanner: (url, prefix) =>
			dispatch(patchVariable({ key: prefix + 'ExportBanner', value: url })),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportBanner);
