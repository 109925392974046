import { RECEIVE_CATEGORIES, SELECT_CATEGORY } from './types';
import { ALL } from '../../Components/ProductsSelectorModal/utils';

const initialState = { list: {}, loaded: false, selected: ALL };
const reducerCategories = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_CATEGORIES:
			if (action.data) {
				newState.list = action.data;
				newState.loaded = true;
				return newState;
			}
			return newState;
		case SELECT_CATEGORY:
			if (action.hasOwnProperty('selected')) {
				newState.selected = action.selected;
			}
			return newState;
		default:
			return newState;
	}
};

export default reducerCategories;
