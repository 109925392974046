import React, { Component, PureComponent } from 'react';
import { I18n } from 'react-redux-i18n';
import { PropTypes } from 'prop-types';
import { deepEqual } from '../../../../../../../../utils';
import './style.scss';

class InputForm extends Component {
	constructor(props) {
		super(props);
		let inputAttr = {};
		inputAttr.inputType =
			!props.hasOwnProperty('type') || props.type === 'text'
				? 'text'
				: 'number';
		if (props.type === 'float') {
			inputAttr.step = '0.01';
		}

		if (props.hasOwnProperty('min')) {
			inputAttr.min = props.min;
		}
		if (props.hasOwnProperty('max')) {
			inputAttr.max = props.max;
		}
		this.state = {
			inputAttr,
		};
	}

	shouldComponentUpdate(nextProps) {
		if (deepEqual(this.props, nextProps)) {
			return false;
		}

		return true;
	}

	onChange = e => {
		let value = e.target.value;
		if (e.target.type === 'number') {
			value = value.replace(/-+/g, '');
			if (!this.props.value && value == '0.01' && this.props.placeholder) {
				let placeholder = this.props.placeholder.replace(',', '.');
				value = parseFloat(placeholder, 10) + 0.01;
				value = parseFloat(value).toFixed(2);
			}
		}
		this.props.onChange(e.target.name, value);
	};

	render() {
		const { inputType, ...inputAttrs } = this.state.inputAttr;
		return (
			<div className={'form-product-input'}>
				<p
					className={
						this.props.toComplete
							? 'form-product-title pastille-after'
							: 'form-product-title'
					}>
					{I18n.t(`partner.productForm.${this.props.name}`)}
				</p>
				<input
					name={this.props.name}
					placeholder={
						this.props.placeholder
							? this.props.placeholder
							: I18n.t(`partner.productForm.${this.props.name}`)
					}
					value={this.props.value}
					onChange={this.onChange}
					type={inputType}
					{...inputAttrs}
				/>
			</div>
		);
	}
}

InputForm.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
};

export default InputForm;
