import React from 'react';
import { I18n } from 'react-redux-i18n';
import { PropTypes } from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import './style.scss';

const VisibleForm = props => {
	const onChange = value => {
		if (props.disabled) return;
		let val = props.invert ? !value : value;
		props.onChange(props.name, val ? '1' : '0');
	};

	let value = props.value == 1;
	if (props.invert) {
		value = !value;
	}

	let classes = ['form-product-visibility'];
	if (props.disabled) {
		classes.push('form-product-visibility-disabled');
	}
	return (
		<div className={classes.join(' ')}>
			<p
				className={
					props.toComplete
						? 'form-product-title pastille-after'
						: 'form-product-title'
				}>
				{value ? props.labelVisible : props.labelNotVisible}
			</p>
			<Tooltip
				title={
					props.labelDisabled || I18n.t('partner.productForm.tooltip_visible')
				}>
				{value ? (
					<Visibility onClick={() => onChange(!value)} />
				) : (
					<VisibilityOff
						className={'form-product-visibility-off'}
						onClick={() => onChange(!value)}
					/>
				)}
			</Tooltip>
		</div>
	);
};

VisibleForm.propTypes = {
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	invert: PropTypes.bool,
	labelVisible: PropTypes.string,
	labelNotVisible: PropTypes.string,
	disabled: PropTypes.string,
	labelDisabled: PropTypes.string,
};

export default React.memo(VisibleForm);
