import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Proptypes from 'prop-types';
import * as color from '../../constant';
import './style.scss';

const styles = theme => ({
	margin: {
		margin: theme.spacing.unit,
	},
	bootstrapRoot: {
		'label + &': {
			marginTop: theme.spacing.unit * 3,
		},
	},
	bootstrapInput: {
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 15px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
	},
	bootstrapFormLabel: {
		fontSize: 18,
		color: 'rgba(0, 0, 0, 0.54)',
	},
	labelFocus: {
		color: color.RED_ADDITION + '!important',
	},
	labelOk: {
		color: color.GREEN_TERRASSE,
	},
	focused: {},
});

const genericInput = props => {
	const { classes } = props;
	const colorLabel = props.colorInput ? props.colorInput : null;
	let labelClasses = classes.bootstrapFormLabel;
	if (!!props.required) {
		labelClasses += ' pastille-after';
	}
	return (
		<FormControl
			className={classes.margin}
			style={{ width: props.width || 'auto' }}>
			<InputLabel
				shrink
				htmlFor={props.idInput}
				className={labelClasses}
				FormLabelClasses={{
					focused: colorLabel ? classes.labelOK : classes.labelFocus,
				}}
				style={{
					color: colorLabel,
				}}>
				{props.label}
			</InputLabel>
			<InputBase
				id={props.idInput}
				onChange={props.onChange}
				name={props.name}
				type={props.type}
				value={props.value}
				classes={{
					root: classes.bootstrapRoot,
					input: classes.bootstrapInput,
				}}
				error={!!props.error}
				onFocus={props.onFocus ? props.onFocus : null}
				onBlur={props.onBlur ? props.onBlur : null}
				inputProps={{ ...props.inputProps }}
				multiline={!!props.multiline}
				disabled={props.disabled ? props.disabled : null}
				rows={props.rows || 1}
			/>

			<div className={'input-error-label ' + props.errorClasses}>
				{props.error ? props.error : ''}
			</div>
		</FormControl>
	);
};

/***
 * Exemple d'utilisation
 * <Input
      label="labelText"
      type="email"
      name="email"
      required="required"
      idInput="email-input"
      onFocus={this.onFocus}
      onChange={this.onChange}
      onBlur={this.onBlur}
    />
 * 
 *  ***/

genericInput.propTypes = {
	classes: Proptypes.object.isRequired,
	label: Proptypes.string,
	type: Proptypes.string,
	name: Proptypes.string,
	required: Proptypes.bool,
	value: Proptypes.string,
	idInput: Proptypes.string,
	onChange: Proptypes.func,
};

export default withStyles(styles)(genericInput);
