import {
	PRE_POST_OPTION,
	PRE_DELETE_OPTION,
	PRE_POST_MENU_TO_PARTNER,
} from './types';

export const postOption = body => ({
	type: PRE_POST_OPTION,
	body,
});

export const deleteOption = body => ({
	type: PRE_DELETE_OPTION,
	body,
});

export const sendMenuToPartner = body => ({
	type: PRE_POST_MENU_TO_PARTNER,
	body,
});
