import React, { Component } from 'react';
import './style.scss';
import Dialog from '@material-ui/core/Dialog';
import { deepEqual } from '../../../../../../utils';

class ModalExpensive extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.myRef = React.createRef();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.open != this.props.open ||
			nextProps.fullWidth != this.props.fullWidth ||
			!deepEqual(nextProps.children.props, this.props.children.props)
		) {
			return true;
		}
		return false;
	}

	render() {
		if (this.props.fullWidth) {
			if (this.myRef.current) {
				this.myRef.current.offsetParent.scrollTo(0, 0);
			}
		}
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				maxWidth={'xl'}
				classes={{
					paper: this.props.fullWidth
						? 'modalExpensive modalExpensiveFull'
						: 'modalExpensive',
				}}>
				<div ref={this.myRef} className="expensive-container">
					{this.props.children}
				</div>
			</Dialog>
		);
	}
}

export default ModalExpensive;
