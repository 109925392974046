import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/fr';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Button from '../../../../../../Components/Button';
import { I18n } from 'react-redux-i18n';
import './style.scss';

const dateFormat = 'YYYY-MM-DD';
class DatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			range: {
				from: null,
				to: null,
			},
			errors: '',
		};
	}

	getInitialState() {
		return {
			from: undefined,
			to: undefined,
		};
	}

	handleDayClick = day => {
		const range = DateUtils.addDayToRange(day, this.state);
		const state = this.state;

		this.setState(range);

		if (state.range && !state.range.from && !state.range.to) {
			range.from = day;
		} else {
			range.to = day;
		}

		this.setState({
			range,
		});
	};

	handleResetClick = () => {
		this.setState(this.getInitialState());
	};

	submitClosingDate = () => {
		if (this.state.range && this.state.range.from && this.state.range.to) {
			this.props.submitClosingDate(
				moment(this.state.range.from).format(dateFormat),
				moment(this.state.range.to).format(dateFormat)
			);
		}
	};

	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		let lang = window.navigator.language || 'fr';
		return (
			<div className="container">
				{this.state.errors ? <p>{this.state.errors} </p> : ''}
				<DayPicker
					className="Selectable"
					localeUtils={MomentLocaleUtils}
					locale={lang}
					selectedDays={[from, { from, to }]}
					modifiers={modifiers}
					onDayClick={this.handleDayClick}
				/>
				<Button
					onClick={this.submitClosingDate}
					disabled={
						this.state.range.from && this.state.range.to ? false : true
					}>
					{I18n.t('general.add')}
				</Button>
			</div>
		);
	}
}

DatePicker.propTypes = {
	startDate: PropTypes.object,
	endDate: PropTypes.object,
};

export default DatePicker;
