import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Input from '../../../../../../Components/Input';
import Button from '../../../../../../Components/Button';

class AddPicture extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div onClick={this.props.openNewPictureModal}>
				<label htmlFor="file" className="label-file">
					<div className="new-picture-container">
						<div>
							<p className="icon-add-export">
								<AddIcon />
							</p>
							<p>{I18n.t('info.picture.new_picture')}</p>
						</div>
					</div>
				</label>
			</div>
		);
	}
}

export default AddPicture;
