import {
	RECEIVE_CATEGORY_SUPPLEMENT,
	RECEIVE_CATEGORY_SUPPLEMENT_EXTRA,
	RECEIVE_SUPPLEMENT_EXTRA,
	POST_CATEGORY_SUPPLEMENT_EXTRA,
	POST_SUPPLEMENT_EXTRA,
	DELETE_CATEGORY_SUPPLEMENT_EXTRA,
	DELETE_SUPPLEMENT_EXTRA,
	RESET_CATEGORY_SUPPLEMENT_EXTRA,
} from './types';
import { deepClone } from '../../../../../utils';

const initialState = {
	list: {},
	categorySupplementExtra: [],
	supplementExtra: [],
};

const reducerSupplement = (state = initialState, action) => {
	let newState = deepClone(state);
	switch (action.type) {
		case RECEIVE_CATEGORY_SUPPLEMENT:
			newState.list = action.data;
			return newState;

		case RECEIVE_CATEGORY_SUPPLEMENT_EXTRA:
			newState.categorySupplementExtra = action.data;
			return newState;

		case RECEIVE_SUPPLEMENT_EXTRA:
			newState.supplementExtra = action.data;
			return newState;

		case POST_CATEGORY_SUPPLEMENT_EXTRA:
			if (
				newState.categorySupplementExtra.hasOwnProperty(
					action.data.id_category_supplement
				)
				/* newState.categorySupplementExtra.find(
          cat =>
            cat.id_category_supplement === action.data.id_category_supplement &&
            cat.id_menu === action.data.id_menu
        ) */
			) {
				if (
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					].find(category => {
						return (
							category.key_ === action.data.key_ &&
							category.partner_prefix === action.data.partner_prefix
						);
					})
				) {
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					] = newState.categorySupplementExtra[
						action.data.id_category_supplement
					].map(cat => {
						if (
							cat.id_category_supplement ===
								action.data.id_category_supplement &&
							cat.id_menu === action.data.id_menu &&
							cat.key_ === action.data.key_
						) {
							cat.value = action.data.value;
						}
						return cat;
					});
				} else {
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					].push(action.data);
				}
			} else {
				if (
					newState.categorySupplementExtra[action.data.id_category_supplement]
				) {
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					].push(action.data);
				} else {
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					] = [];
					newState.categorySupplementExtra[
						action.data.id_category_supplement
					].push(action.data);
				}
			}

			return newState;
		case POST_SUPPLEMENT_EXTRA:
			if (action.data.path) {
				action.data.value = action.data.path;
				delete action.data.path;
			}
			if (
				newState.supplementExtra.hasOwnProperty(action.data.id_supplement)
				/* newState.supplementExtra.find(
          supp =>
            supp.id_supplement === action.data.id_supplement &&
            supp.id_menu === action.data.id_menu
        ) */
			) {
				if (
					newState.supplementExtra[action.data.id_supplement].find(
						supplement => {
							return (
								supplement.key_ === action.data.key_ &&
								supplement.partner_prefix === action.data.partner_prefix
							);
						}
					)
				) {
					newState.supplementExtra[
						action.data.id_supplement
					] = newState.supplementExtra[action.data.id_supplement].map(supp => {
						if (
							supp.id_supplement === action.data.id_supplement &&
							supp.id_menu === action.data.id_menu &&
							supp.key_ === action.data.key_
						) {
							supp.value = action.data.value;
						}
						return supp;
					});
				} else {
					newState.supplementExtra[action.data.id_supplement].push(action.data);
				}
			} else {
				if (newState.supplementExtra[action.data.id_supplement]) {
					newState.supplementExtra[action.data.id_supplement].push(action.data);
				} else {
					newState.supplementExtra[action.data.id_supplement] = [];
					newState.supplementExtra[action.data.id_supplement].push(action.data);
				}
			}

			return newState;

		case DELETE_SUPPLEMENT_EXTRA:
			if (newState.supplementExtra[action.data.id]) {
				newState.supplementExtra[action.data.id] = newState.supplementExtra[
					action.data.id
				].filter(
					extra =>
						extra.key_ !== action.data.key_ &&
						extra.partner_prefix !== action.data.partner_prefix
				);
			}
			return newState;

		case DELETE_CATEGORY_SUPPLEMENT_EXTRA:
			let { id_category_supplement, key_ } = action.data;
			if (
				id_category_supplement &&
				key_ &&
				newState.categorySupplementExtra[id_category_supplement]
			) {
				newState.categorySupplementExtra[
					id_category_supplement
				] = newState.categorySupplementExtra[id_category_supplement].filter(
					extra => extra.key_ != key_
				);
			}
			return newState;

		case RESET_CATEGORY_SUPPLEMENT_EXTRA:
			if (newState.categorySupplementExtra[action.data.id]) {
				newState.categorySupplementExtra[
					action.data.id
				] = newState.categorySupplementExtra[action.data.id].filter(
					cat =>
						!(
							cat.id_category_supplement == action.data.id &&
							cat.id_menu == action.data.id_menu
						)
				);
			}

			if (newState.list[action.data.id_menu])
				for (let blbl in newState.list[action.data.id_menu][action.data.id]
					.data) {
					if (
						newState.supplementExtra[
							newState.list[action.data.id_menu][action.data.id].data[blbl].id
						]
					) {
						newState.supplementExtra[
							newState.list[action.data.id_menu][action.data.id].data[blbl].id
						] = newState.supplementExtra[
							newState.list[action.data.id_menu][action.data.id].data[blbl].id
						].filter(
							supp =>
								!(
									supp.id_category_supplement == action.data.id &&
									supp.id_menu == action.data.id_menu
								)
						);
					}
				}

			return newState;

		default:
			return newState;
	}
};

export default reducerSupplement;
