import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import newMarker from './img/map-marker.png';
import L from 'leaflet';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import './style.scss';

const greenIcon = L.icon({
	iconUrl: newMarker,
	iconSize: [40, 40], // size of the icon
	iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
	shadowAnchor: [4, 62], // the same for the shadow
	popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

class Maps extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasLocation: false,
			lat: props.lat,
			lng: props.lng,
			moveMarker: props.moveMarker || false,
			zoom: 18,
			draggable: true,
		};
		this.refmarker = React.createRef();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.moveMarker !== prevState.moveMarker) {
			return {
				moveMarker: false,
			};
		}
		if (nextProps.lat !== prevState.lat && !prevState.moveMarker) {
			return {
				lat: nextProps.lat,
				lng: nextProps.lng,
			};
		}
		return null;
	}

	toggleDraggable = () => {
		this.setState({ draggable: !this.state.draggable });
	};

	updatePosition = () => {
		const { lat, lng } = this.refmarker.current.leafletElement.getLatLng();
		this.setState(
			{
				lat: lat,
				lng: lng,
				moveMarker: true,
			},
			() => {
				this.props.handleCoordinates(this.state.lat, this.state.lng);
			}
		);
	};

	render() {
		const lat = this.state.lat;
		const long = this.state.lng;
		const position = [lat, long];
		const markerPosition = [lat, long];

		return (
			<Map center={position} zoom={this.state.zoom}>
				<TileLayer url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
				<Marker
					draggable={this.state.draggable}
					onDragend={this.updatePosition}
					position={markerPosition}
					ref={this.refmarker}
					icon={greenIcon}>
					{this.props.showPopup ? (
						<Popup minWidth={90}>
							<span onClick={this.toggleDraggable}>
								{this.state.draggable ? 'DRAG MARKER' : 'MARKER FIXED'}
							</span>
						</Popup>
					) : (
						''
					)}
				</Marker>
			</Map>
		);
	}
	static defaultProps = {
		moveMarker: false,
		showPopup: true,
	};
}

Maps.proptype = {
	lat: PropTypes.number,
	lng: PropTypes.number,
	moveMarker: PropTypes.bool.isRequired,
	handleCoordinates: PropTypes.func.isRequired,
	showPopup: PropTypes.bool,
};

export default Maps;
