export const RECEIVE_INFO = 'RECEIVE_INFO';
export const PRE_PATCH_INFO = 'PRE_PATCH_INFO';
export const PATCH_INFO = 'PATCH_INFO';
export const PRE_REMOVE_PICTURE = 'PRE_REMOVE_PICTURE';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';
export const PRE_PATCH_ADDRESS = 'PRE_PATCH_ADDRESS';
export const PATCH_ADDRESS = 'PATCH_ADDRESS';
export const PRE_ADD_INFO_ADDRESS = 'PRE_ADD_INFO_ADDRESS';
export const ADD_INFO_ADDRESS = 'ADD_INFO_ADDRESS';
export const PATCH_PICTURE = 'PATCH_PICTURE';
export const PRE_PATCH_PICTURE = 'PRE_PATCH_PICTURE';
export const PATCH_CLOSING_DATE = 'PATCH_CLOSING_DATE';
export const PRE_PATCH_CLOSING_DATE = 'PRE_PATCH_CLOSING_DATE';
export const PRE_REMOVE_CLOSING_DATE = 'PRE_REMOVE_CLOSING_DATE';
export const REMOVE_CLOSING_DATE = 'REMOVE_CLOSING_DATE';
export const PRE_ADD_CLOSING_DATE = 'PRE_ADD_CLOSING_DATE';
export const ADD_CLOSING_DATE = 'ADD_CLOSING_DATE';
export const PRE_ADD_PICTURE = 'PRE_ADD_PICTURE';
export const ADD_PICTURE = 'ADD_PICTURE';
export const PRE_ADD_PICTURE_REUSED = 'PRE_ADD_PICTURE_REUSED';
export const ADD_PICTURE_REUSED = 'ADD_PICTURE_REUSED';
export const RECEIVE_PICTURES = 'RECEIVE_PICTURES';
export const RECEIVE_VARIABLE = 'RECEIVE_VARIABLE';
