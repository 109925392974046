import { all, call, put, take } from 'redux-saga/effects';
import {
	PRE_POST_CATEGORY_SUPPLEMENT_EXTRA,
	POST_CATEGORY_SUPPLEMENT_EXTRA,
	PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA,
	DELETE_CATEGORY_SUPPLEMENT_EXTRA,
	PRE_POST_SUPPLEMENT_EXTRA,
	POST_SUPPLEMENT_EXTRA,
	PRE_DELETE_SUPPLEMENT_EXTRA,
	DELETE_SUPPLEMENT_EXTRA,
	CHANGE_CHANGEMENT_IN_EXPORT,
	PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA,
	RESET_CATEGORY_SUPPLEMENT_EXTRA,
} from './types';
import { requestHandler, ENTITIES } from '../../../../../service/fetcher.saga';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { I18n } from 'react-redux-i18n';

const PRE_REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA';
const REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA_FINISHED =
	'REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA_FINISHED';
const PRE_REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA';
const REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA_FINISHED =
	'REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA_FINISHED';
const PRE_REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA';
const REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA_FINISHED =
	'REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA_FINISHED';

const PRE_REQUEST_POST_SUPPLEMENT_EXTRA = 'PRE_REQUEST_POST_SUPPLEMENT_EXTRA';
const REQUEST_POST_SUPPLEMENT_EXTRA_FINISHED =
	'REQUEST_POST_SUPPLEMENT_EXTRA_FINISHED';
const PRE_REQUEST_DELETE_SUPPLEMENT_EXTRA =
	'PRE_REQUEST_DELETE_SUPPLEMENT_EXTRA';
const REQUEST_DELETE_SUPPLEMENT_EXTRA_FINISHED =
	'REQUEST_DELETE_SUPPLEMENT_EXTRA_FINISHED';

function* prePostCategorySupplementExtra() {
	while (true) {
		const { data } = yield take(PRE_POST_CATEGORY_SUPPLEMENT_EXTRA);
		yield put({ type: PRE_REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA, body: data });

		const { result } = yield take(
			REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA_FINISHED
		);
		yield put({ type: POST_CATEGORY_SUPPLEMENT_EXTRA, data });
	}
}

function* watchPostCategorySupplementExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.CATEGORY_SUPPLEMENT_EXTRA,
			'POST',
			body
		);

		yield put({
			type: CHANGE_CHANGEMENT_IN_EXPORT,
		});

		if (result)
			yield put({
				type: REQUEST_POST_CATEGORY_SUPPLEMENT_EXTRA_FINISHED,
				result,
			});

		if (!result) yield call(notify, I18n.t('general.error'), 'error');
	}
}

function* preDeleteCategorySupplementExtra() {
	while (true) {
		const { data } = yield take(PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA);
		yield put({
			type: PRE_REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA,
			body: data,
		});

		yield take(REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA_FINISHED);

		yield put({ type: DELETE_CATEGORY_SUPPLEMENT_EXTRA, data });
	}
}

function* watchDeleteCategorySupplementExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.CATEGORY_SUPPLEMENT_EXTRA,
			'DELETE',
			body
		);
		yield put({
			type: CHANGE_CHANGEMENT_IN_EXPORT,
		});
		yield put({
			type: REQUEST_DELETE_CATEGORY_SUPPLEMENT_EXTRA_FINISHED,
			result,
		});
	}
}

function* preResetCategorySupplementExtra() {
	while (true) {
		const { data } = yield take(PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA);
		yield put({
			type: PRE_REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA,
			body: data,
		});

		yield take(REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA_FINISHED);
		yield put({
			type: CHANGE_CHANGEMENT_IN_EXPORT,
		});
		yield put({ type: RESET_CATEGORY_SUPPLEMENT_EXTRA, data });
	}
}

function* watchResetCategorySupplementExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA);
		yield call(
			requestHandler,
			ENTITIES.EXPORT.CATEGORY_SUPPLEMENT_EXTRA + '/reset',
			'POST',
			body
		);

		yield put({
			type: REQUEST_RESET_CATEGORY_SUPPLEMENT_EXTRA_FINISHED,
		});
	}
}

function* prePostSupplementExtra() {
	while (true) {
		const { data } = yield take(PRE_POST_SUPPLEMENT_EXTRA);
		let path = '';
		if (data.hasOwnProperty('path')) {
			path = data['path'];
			delete data['path'];
		}
		yield put({ type: PRE_REQUEST_POST_SUPPLEMENT_EXTRA, body: data });

		const { result } = yield take(REQUEST_POST_SUPPLEMENT_EXTRA_FINISHED);
		if (path) {
			data['path'] = path;
		}

		if (result) yield put({ type: POST_SUPPLEMENT_EXTRA, data });
		if (!result) yield call(notify, I18n.t('general.error'), 'error');
	}
}

function* watchPostSupplementExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_SUPPLEMENT_EXTRA);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SUPPLEMENT_EXTRA,
			'POST',
			body
		);
		yield put({
			type: CHANGE_CHANGEMENT_IN_EXPORT,
		});
		yield put({
			type: REQUEST_POST_SUPPLEMENT_EXTRA_FINISHED,
			result,
		});
	}
}

function* preDeleteSupplementExtra() {
	while (true) {
		const { data } = yield take(PRE_DELETE_SUPPLEMENT_EXTRA);
		yield put({
			type: PRE_REQUEST_DELETE_SUPPLEMENT_EXTRA,
			body: data,
		});

		const result = yield take(REQUEST_DELETE_SUPPLEMENT_EXTRA_FINISHED);
		yield put({ type: DELETE_SUPPLEMENT_EXTRA, data });
	}
}

function* watchDeleteSupplementExtra() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_DELETE_SUPPLEMENT_EXTRA);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EXPORT.SUPPLEMENT_EXTRA,
			'DELETE',
			body
		);
		yield put({
			type: CHANGE_CHANGEMENT_IN_EXPORT,
		});
		yield put({
			type: REQUEST_DELETE_SUPPLEMENT_EXTRA_FINISHED,
			result,
		});
	}
}

export default function* supplementExtraSaga() {
	yield all([
		prePostCategorySupplementExtra(),
		watchPostCategorySupplementExtra(),
		preDeleteCategorySupplementExtra(),
		watchDeleteCategorySupplementExtra(),
		preResetCategorySupplementExtra(),
		watchResetCategorySupplementExtra(),
		prePostSupplementExtra(),
		watchPostSupplementExtra(),
		preDeleteSupplementExtra(),
		watchDeleteSupplementExtra(),
	]);
}
