import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import Map from './Map';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { deepEqual, isEmpty } from '../../../../utils';
import { withSnackbar } from 'notistack';
import { findPosition } from '../../service/geo';
import LocationChoice from './LocationChoice';

const LOCAL_STATUS = {
	INIT: 'INIT',
	PROGRESS: 'PROGRESS',
	SUCCESS: 'SUCCESS',
	FAIL: 'FAIL',
};

class TabInfoRestau extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: {
				latitude: 0,
				longitude: 0,
				country: '',
				zip_code: '',
				state: '',
				city: '',
				street: '',
				number: '',
				custom_information: '',
			},
			info: {
				name: '',
				description: '',
				short_description: '',
				opening_date:
					'{"monday":[],"tuesday":[],"wednesday":[],"thursday":[],"friday":[],"saturday":[],"sunday":[]}',
			},
			localisationStatus: LOCAL_STATUS.INIT,
			locationChoices: [],
		};
	}

	mountData = (address, infoRestau) => {
		if (address) {
			this.setState({
				address: Object.assign({}, address, {
					latitude: address.latitude || 44.84843208627694,
					longitude: address.longitude || -0.5712181515991689,
				}),
			});
		}
		if (infoRestau) {
			this.setState({ info: Object.assign({}, infoRestau) });
		}
	};

	componentWillReceiveProps(nextProps) {
		this.mountData(nextProps.address, nextProps.infoRestau);
	}

	componentDidMount() {
		this.mountData(this.props.address, this.props.infoRestau);
	}

	handleCoordinates = (lat, long) => {
		let address = Object.assign({}, this.state.address);
		address.latitude = lat;
		address.longitude = long;
		this.setState({ address });
	};

	handleInputAddressChange = event => {
		this.updateInput('address', event.target.name, event.target.value);
	};

	handleInputInfoChange = event => {
		this.updateInput('info', event.target.name, event.target.value);
	};

	updateInput = (entity, prop, value) => {
		let stateToEdit = Object.assign({}, this.state[entity]);
		stateToEdit[prop] = value;
		this.setState({ [entity]: stateToEdit });
	};

	onValid = () => {
		this.props.onUpdateInfo(this.state.info, this.state.address);
	};

	requiredEmpty = () => {
		if (
			isEmpty(this.state.address.latitude) ||
			isEmpty(this.state.address.longitude) ||
			isEmpty(this.state.address.country) ||
			isEmpty(this.state.address.zip_code) ||
			isEmpty(this.state.address.city) ||
			isEmpty(this.state.address.street)
		) {
			return true;
		}
		return false;
	};

	tryLocalisation = async () => {
		if (
			!this.state.address.street ||
			!this.state.address.zip_code ||
			!this.state.address.city ||
			!this.state.address.country
		) {
			this.props.enqueueSnackbar(
				I18n.t('info.address.location_missing_fields'),
				{
					variant: 'warning',
				}
			);
			return;
		}
		this.setState({
			localisationStatus: LOCAL_STATUS.PROGRESS,
		});

		const result = await findPosition(this.state.address);

		setTimeout(() => {
			if (result && result.results && result.results.length) {
				if (result.results.length === 1) {
					this.setLatLong(result.results[0].geometry);
				} else {
					let locationChoices = result.results.map(l => ({
						text: l.formatted,
						coords: l.geometry,
					}));
					this.setState({
						locationChoices,
					});
				}
			} else {
				this.setState({
					localisationStatus: LOCAL_STATUS.FAIL,
				});
				this.props.enqueueSnackbar(I18n.t('info.address.location_failed'), {
					variant: 'warning',
				});
			}
		}, 1500);
	};

	setLatLong = coords => {
		this.setState({
			address: {
				...this.state.address,
				latitude: coords.lat,
				longitude: coords.lng,
			},
			localisationStatus: LOCAL_STATUS.SUCCESS,
		});

		setTimeout(() => {
			this.setState({
				localisationStatus: LOCAL_STATUS.INIT,
			});
		}, 7000);
	};

	displayLocateButton = state => {
		if (state === LOCAL_STATUS.INIT) {
			return (
				<button
					className={'locate-button  locate-button-init'}
					onClick={this.tryLocalisation}>
					{I18n.t('info.address.locate_button.init')}
				</button>
			);
		} else if (state === LOCAL_STATUS.PROGRESS) {
			return (
				<button
					className={'locate-button locate-button-progress'}
					disabled={true}>
					{I18n.t('info.address.locate_button.progress')}
				</button>
			);
		} else if (state === LOCAL_STATUS.SUCCESS) {
			return (
				<button
					className={'locate-button locate-button-success'}
					disabled={true}>
					{I18n.t('info.address.locate_button.success')}
				</button>
			);
		} else if (state === LOCAL_STATUS.FAIL) {
			return (
				<button className={'locate-button locate-button-fail'} disabled={true}>
					{I18n.t('info.address.locate_button.fail')}
				</button>
			);
		}
	};

	onValidChoice = index => {
		this.setLatLong(this.state.locationChoices[index].coords);
		this.onCloseValidChoice();
	};

	onCloseValidChoice = _ => {
		this.setState({ locationChoices: [] });
	};

	render() {
		const { address, info } = this.state;
		const buttonSaveDisabled =
			(deepEqual(address, this.props.address) &&
				deepEqual(info, this.props.infoRestau)) ||
			this.requiredEmpty();

		return (
			<div className="tab-container" id={'container-info-restau'}>
				<div>
					<Button
						disabled={buttonSaveDisabled}
						onClick={this.onValid}
						style={{ float: 'right' }}>
						{I18n.t('general.save')}
					</Button>
					<h1 id="infoRestau-name-value">{info.name}</h1>
				</div>

				{/* ADRESSE ET INFO PAET 1 */}
				<div id={'form-info-restau'}>
					<div id={'form-info-1'}>
						<div className={'form-section-50'}>
							<h2 className={'info-Restau-section-title'}>
								{I18n.t('info.address.address')}
							</h2>
							<div className={'form-section-row'}>
								<div className={'flex-item-20'}>
									<Input
										label={I18n.t('info.address.number')}
										type="text"
										name="number"
										value={address.number}
										onChange={this.handleInputAddressChange}
										width={80}
										inputProps={{ maxLength: 20 }}
									/>
								</div>
								<div className={'flex-item-80'}>
									<Input
										label={I18n.t('info.address.street')}
										type="text"
										name="street"
										value={address.street}
										onChange={this.handleInputAddressChange}
										width={357}
										inputProps={{ maxLength: 50 }}
										required={true}
									/>
								</div>
							</div>
							<div className={'form-section-row'}>
								<div className={'flex-item-33'}>
									<Input
										label={I18n.t('info.address.zip_code')}
										type="text"
										value={address.zip_code}
										name="zip_code"
										onChange={this.handleInputAddressChange}
										width={150}
										inputProps={{ maxLength: 20 }}
										required={true}
									/>
								</div>
								<div className={'flex-item-66'}>
									<Input
										label={I18n.t('info.address.city')}
										type="text"
										value={address.city}
										name="city"
										onChange={this.handleInputAddressChange}
										width={280}
										inputProps={{ maxLength: 50 }}
										required={true}
									/>
								</div>
							</div>
							<div className={'form-section-row'}>
								<div className={'flex-item-50'}>
									<Input
										label={I18n.t('info.address.state')}
										type="text"
										value={address.state}
										name="state"
										onChange={this.handleInputAddressChange}
										width={225}
										inputProps={{ maxLength: 50 }}
									/>
								</div>
								<div className={'flex-item-50'}>
									<Input
										label={I18n.t('info.address.country')}
										type="text"
										value={address.country}
										name="country"
										onChange={this.handleInputAddressChange}
										width={225}
										inputProps={{ maxLength: 50 }}
										required={true}
									/>
								</div>
							</div>
							<div className={'form-section-row'}>
								<div className={'flex-item-100'}>
									<Input
										label={I18n.t('info.address.custom_information')}
										type="text"
										value={address.custom_information}
										name="custom_information"
										onChange={this.handleInputAddressChange}
										width={464}
										inputProps={{ maxLength: 100 }}
									/>
								</div>
							</div>
							<h2 className={'info-Restau-section-title'}>
								{I18n.t('info.description')}
							</h2>

							<Input
								label={I18n.t('info.short_description')}
								type="text"
								value={info.short_description}
								name="short_description"
								onChange={this.handleInputInfoChange}
								width={464}
								inputProps={{ maxLength: 100 }}
							/>
						</div>

						{/* DESCRIPTION */}

						{/* MAP */}
						<div className={'form-section-50'}>
							{this.displayLocateButton(this.state.localisationStatus)}
							<Map
								lat={address.latitude}
								lng={address.longitude}
								handleCoordinates={this.handleCoordinates}
								showPopup={false}
								moveMarker={false}
							/>
							<div id="long-lat-display">
								<span>
									{I18n.t('info.address.longitude')} : {address.longitude}
								</span>{' '}
								{' / '}
								<span>
									{I18n.t('info.address.latitude')} : {address.latitude}
								</span>
							</div>
						</div>
					</div>

					{/* ADRESSE ET INFO PARTIE 2 */}
					<div id={'form-info-2'}>
						<div className={'form-description-container'}>
							<p className={'form-caracters-remaning'}>
								{800 -
									info.description.length +
									' ' +
									I18n.t('info.remaning_caracters')}
							</p>{' '}
							<Input
								label={I18n.t('info.about')}
								type="text"
								value={info.description}
								name="description"
								onChange={this.handleInputInfoChange}
								multiline={true}
								rows={5}
								width={'100%'}
								inputProps={{ maxLength: 800 }}
							/>
						</div>
					</div>
				</div>

				<LocationChoice
					list={this.state.locationChoices}
					onValid={this.onValidChoice}
					onCancel={this.onCloseValidChoice}
					open={this.state.locationChoices.length > 0}
				/>
			</div>
		);
	}
}

TabInfoRestau.propTypes = {
	address: PropTypes.object,
	infoRestau: PropTypes.object,
	onUpdateInfo: PropTypes.func,
};

export default withSnackbar(TabInfoRestau);
