import { fetcher } from '../../../service/fetcher.saga';

const GEO_URL = 'https://api.opencagedata.com/geocode/v1/json';

const GEO_API_KEY = 'd74ad6b9c0574822a9fa88b5f9d4a112';

export const findPosition = address => {
	const addressStr = `${address.number} ${address.street}  ${address.city}  ${address.zip_code}  ${address.state}  ${address.country}`;

	return fetcher(GEO_URL, 'GET', {
		q: addressStr,
		key: GEO_API_KEY,
		limit: 3,
		min_confidence: 7,
	});
};
