import {
	PRE_PATCH_INFO,
	PRE_REMOVE_PICTURE,
	PRE_PATCH_ADDRESS,
	PRE_PATCH_PICTURE,
	PRE_PATCH_CLOSING_DATE,
	PRE_REMOVE_CLOSING_DATE,
	PRE_ADD_CLOSING_DATE,
	PRE_ADD_PICTURE,
	PRE_ADD_INFO_ADDRESS,
	PRE_ADD_PICTURE_REUSED,
} from './types';

export const patchInfo = body => {
	return {
		type: PRE_PATCH_INFO,
		body,
	};
};

export const patchAddress = body => {
	return {
		type: PRE_PATCH_ADDRESS,
		body,
	};
};

export const addInfoAndAddress = (info, address) => {
	return {
		type: PRE_ADD_INFO_ADDRESS,
		body: { info, address },
	};
};

export const patchPicture = body => {
	return {
		type: PRE_PATCH_PICTURE,
		body,
	};
};

export const patchClosingDate = body => {
	return {
		type: PRE_PATCH_CLOSING_DATE,
		body,
	};
};

export const removeClosingDate = id => {
	return {
		type: PRE_REMOVE_CLOSING_DATE,
		id,
	};
};

export const removePicture = id => {
	return {
		type: PRE_REMOVE_PICTURE,
		id,
	};
};

export const addClosingDate = body => {
	return {
		type: PRE_ADD_CLOSING_DATE,
		body,
	};
};

export const addPicture = body => {
	return {
		type: PRE_ADD_PICTURE,
		body,
	};
};

export const addPictureReused = body => {
	return {
		type: PRE_ADD_PICTURE_REUSED,
		body,
	};
};
