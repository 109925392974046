import React from 'react';
import DeleteIcon from '@material-ui/icons/Clear';
import Proptypes from 'prop-types';

const ItemSelected = React.memo(props => {
	return (
		<p className="chip-item">
			<span className={'chip-name'}>{props.name}</span>
			{props.onDelete ? (
				<span
					onClick={() => props.onDelete(props.id, props.idMenu)}
					className={'chip-delete'}>
					<DeleteIcon />
				</span>
			) : null}
		</p>
	);
});

ItemSelected.propTypes = {
	loaded: Proptypes.bool.isRequired,
};

export default ItemSelected;
