import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	set_ipad_token: 0,
	set_api_data: 0,
	set_customer_id: 0,
	set_auth_link: 0,
	set_api_mask: 0,
	success: 1,
};

const CaLive = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default CaLive;
