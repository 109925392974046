import {
	call,
	put,
	take,
	all,
	select,
	actionChannel,
} from 'redux-saga/effects';
import { requestHandler, ENTITIES } from '../../../service/fetcher.saga';
import {
	PRE_PATCH_INFO,
	PRE_REMOVE_PICTURE,
	REMOVE_PICTURE,
	PATCH_INFO,
	PRE_PATCH_ADDRESS,
	PATCH_ADDRESS,
	PRE_PATCH_PICTURE,
	PATCH_PICTURE,
	PRE_PATCH_CLOSING_DATE,
	PATCH_CLOSING_DATE,
	PRE_REMOVE_CLOSING_DATE,
	REMOVE_CLOSING_DATE,
	PRE_ADD_CLOSING_DATE,
	ADD_CLOSING_DATE,
	PRE_ADD_PICTURE,
	ADD_PICTURE,
	PRE_ADD_INFO_ADDRESS,
	ADD_INFO_ADDRESS,
	PRE_ADD_PICTURE_REUSED,
	ADD_PICTURE_REUSED,
} from './types';
import {
	POST_SUPPLEMENT_EXTRA,
	CHANGE_CHANGEMENT_IN_EXPORT,
} from '../../Partners/Components/SupplementExtra/service/types';
import { SAVE_EXTRA_PRODUCT } from '../../Partners/service/export/types';
import { buffers } from 'redux-saga';
import { deepClone, priceEuroToCents } from '../../../utils';

const PRE_REQUEST_PATCH_INFO = 'PRE_REQUEST_PATCH_INFO';
const REQUEST_PATCH_INFO_FINISHED = 'REQUEST_PATCH_INFO_FINISHED';

const PRE_REQUEST_REMOVE_PICTURE = 'PRE_REQUEST_REMOVE_PICTURE';
const REQUEST_REMOVE_PICTURE_FINISHED = 'REQUEST_REMOVE_PICTURE_FINISHED';

const PRE_REQUEST_PATCH_ADDRESS = 'PRE_REQUEST_PATCH_ADDRESS';
const REQUEST_PATCH_ADDRESS_FINISHED = 'REQUEST_PATCH_INFO_ADDRESS_FINISHED';

const PRE_REQUEST_ADD_INFO_ADDRESS = 'PRE_REQUEST_ADD_INFO_ADDRESS';
const REQUEST_ADD_INFO_ADDRESS_FINISHED = 'REQUEST_ADD_INFO_ADDRESS_FINISHED';

const PRE_REQUEST_PATCH_PICTURE = 'PRE_REQUEST_PATCH_PICTURE';
const REQUEST_PATCH_PICTURE_FINISHED = 'REQUEST_PATCH_PICTURE_FINISHED';

const PRE_REQUEST_PATCH_CLOSING_DATE = 'PRE_REQUEST_PATCH_CLOSING_DATE';
const REQUEST_PATCH_CLOSING_DATE_FINISHED =
	'REQUEST_PATCH_CLOSING_DATE_FINISHED';

const PRE_REQUEST_REMOVE_CLOSING_DATE = 'PRE_REQUEST_REMOVE_CLOSING_DATE';
const REQUEST_REMOVE_CLOSING_DATE_FINISHED =
	'REQUEST_REMOVE_CLOSING_DATE_FINISHED';

const PRE_REQUEST_ADD_CLOSING_DATE = 'PRE_REQUEST_ADD_CLOSING_DATE';
const REQUEST_ADD_CLOSING_DATE_FINISHED = 'REQUEST_ADD_CLOSING_DATE_FINISHED';

const PRE_REQUEST_ADD_PICTURE = 'PRE_REQUEST_ADD_PICTURE';
const REQUEST_ADD_PICTURE_FINISHED = 'REQUEST_ADD_PICTURE_FINISHED';

const PRE_REQUEST_ADD_PICTURE_REUSED = 'PRE_REQUEST_ADD_PICTURE_REUSED';
const REQUEST_ADD_PICTURE_REUSED_FINISHED =
	'REQUEST_ADD_PICTURE_REUSED_FINISHED';

const PICTURE = 'picture';
const PICTURE_REUSED = 'picture/reused';
const ADDRESS = 'address';
const CLOSING_DATE = 'closingDate';
const INFO_RESTAU = 'infoRestaurant';

export function* prePatchInfo() {
	while (true) {
		const action = yield take(PRE_PATCH_INFO);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_INFO,
			body,
		});

		const { result } = yield take(REQUEST_PATCH_INFO_FINISHED);

		yield put({ type: PATCH_INFO, patch: body });
	}
}

export function* watchPatchInfo() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_INFO);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'PATCH',
			action.body,
			INFO_RESTAU
		);

		yield put({ type: REQUEST_PATCH_INFO_FINISHED, result });
	}
}

export function* preRemovePicture() {
	while (true) {
		const action = yield take(PRE_REMOVE_PICTURE);
		const { id } = action;
		yield put({
			type: PRE_REQUEST_REMOVE_PICTURE,
			id,
		});

		const { result } = yield take(REQUEST_REMOVE_PICTURE_FINISHED);
		yield put({
			type: REMOVE_PICTURE,
			id: id,
		});
	}
}

export function* watchRemovePicture() {
	while (true) {
		const action = yield take(PRE_REQUEST_REMOVE_PICTURE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'DELETE',
			{ id: action.id },
			PICTURE
		);
		yield put({ type: REQUEST_REMOVE_PICTURE_FINISHED, result });
	}
}

export function* prePostInfoAddress() {
	while (true) {
		const action = yield take(PRE_ADD_INFO_ADDRESS);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_INFO_ADDRESS,
			body,
		});

		const { result } = yield take(REQUEST_ADD_INFO_ADDRESS_FINISHED);

		let newInfoRestau = { infoRestau: body.info, address: body.address };
		newInfoRestau.infoRestau.id = result.id_info;
		newInfoRestau.address.id = result.id_address;
		yield put({ type: ADD_INFO_ADDRESS, data: newInfoRestau });
	}
}

export function* watchPostInfoAddress() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_INFO_ADDRESS);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'POST',
			action.body,
			INFO_RESTAU
		);

		yield put({ type: REQUEST_ADD_INFO_ADDRESS_FINISHED, result });
	}
}

export function* prePatchAddress() {
	while (true) {
		const action = yield take(PRE_PATCH_ADDRESS);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_ADDRESS,
			body,
		});

		const { result } = yield take(REQUEST_PATCH_ADDRESS_FINISHED);
		yield put({ type: PATCH_ADDRESS, patch: body });
	}
}

export function* watchPatchAddress() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_ADDRESS);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'PATCH',
			action.body,
			ADDRESS
		);

		yield put({ type: REQUEST_PATCH_ADDRESS_FINISHED, result });
	}
}

export function* prePatchPicture() {
	const requestChannel = yield actionChannel(
		PRE_PATCH_PICTURE,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_PICTURE,
			body,
		});

		const { result } = yield take(REQUEST_PATCH_PICTURE_FINISHED);
		yield put({ type: PATCH_PICTURE, patch: body });
	}
}

export function* watchPatchPicture() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_PICTURE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'PATCH',
			action.body,
			PICTURE
		);

		yield put({ type: REQUEST_PATCH_PICTURE_FINISHED, result });
	}
}

export function* prePatchClosingDate() {
	while (true) {
		const action = yield take(PRE_PATCH_CLOSING_DATE);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_CLOSING_DATE,
			body,
		});

		const { result } = yield take(REQUEST_PATCH_CLOSING_DATE_FINISHED);
		yield put({ type: PATCH_CLOSING_DATE, patch: body });
	}
}

export function* watchPatchClosingDate() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_CLOSING_DATE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'PATCH',
			action.body,
			CLOSING_DATE
		);

		yield put({ type: REQUEST_PATCH_CLOSING_DATE_FINISHED, result });
	}
}

export function* preRemoveClosingDate() {
	while (true) {
		const action = yield take(PRE_REMOVE_CLOSING_DATE);
		const { id } = action;
		yield put({
			type: PRE_REQUEST_REMOVE_CLOSING_DATE,
			id,
		});

		const { result } = yield take(REQUEST_REMOVE_CLOSING_DATE_FINISHED);
		yield put({
			type: REMOVE_CLOSING_DATE,
			id,
		});
	}
}

export function* watchRemoveClosingDate() {
	while (true) {
		const action = yield take(PRE_REQUEST_REMOVE_CLOSING_DATE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'DELETE',
			{ id: action.id },
			CLOSING_DATE
		);
		yield put({ type: REQUEST_REMOVE_CLOSING_DATE_FINISHED, result });
	}
}

export function* preAddClosingDate() {
	while (true) {
		const action = yield take(PRE_ADD_CLOSING_DATE);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_CLOSING_DATE,
			body,
		});

		const { result } = yield take(REQUEST_ADD_CLOSING_DATE_FINISHED);
		yield put({
			type: ADD_CLOSING_DATE,
			closingDate: { ...body, id: result },
		});
	}
}

export function* watchAddClosingDate() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_CLOSING_DATE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'POST',
			action.body,
			CLOSING_DATE
		);
		yield put({ type: REQUEST_ADD_CLOSING_DATE_FINISHED, result });
	}
}

export function* preAddPicture() {
	while (true) {
		const action = yield take(PRE_ADD_PICTURE);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_PICTURE,
			body,
		});

		const { result } = yield take(REQUEST_ADD_PICTURE_FINISHED);
		yield put({
			type: ADD_PICTURE,
			picture: { ...body, id: result.insertId },
		});
		if (body.env === 'supplement') {
			const data = {
				id_supplement: body.id_supplement,
				id_category_supplement: body.id_category_supplement,
				id_menu: body.id_menu,
				key_: 'image',
				value: body.path_cropped,
				partner_prefix: body.partner_prefix,
			};

			yield put({ type: POST_SUPPLEMENT_EXTRA, data });
			yield put({ type: CHANGE_CHANGEMENT_IN_EXPORT });
		}

		if (body.env === 'product') {
			const store = yield select();

			yield put({
				type: SAVE_EXTRA_PRODUCT,
				data: {
					id_export: body.id_export,
					id_product: body.id_product,
					extras: { image: store.exports.formProduct.image },
				},
			});
		}
	}
}

export function* watchAddPicture() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_PICTURE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'POST',
			action.body,
			PICTURE
		);
		yield put({ type: REQUEST_ADD_PICTURE_FINISHED, result });
	}
}

export function* preAddPictureReused() {
	while (true) {
		const action = yield take(PRE_ADD_PICTURE_REUSED);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_PICTURE_REUSED,
			body,
		});

		const { result } = yield take(REQUEST_ADD_PICTURE_REUSED_FINISHED);
		yield put({
			type: ADD_PICTURE_REUSED,
			picture: { ...body },
		});
	}
}

export function* watchAddPictureReused() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_PICTURE_REUSED);
		const { result } = yield call(
			requestHandler,
			ENTITIES.INFO,
			'POST',
			action.body,
			PICTURE_REUSED
		);
		yield put({ type: REQUEST_ADD_PICTURE_REUSED_FINISHED, result });
	}
}

export default function* indexSaga() {
	yield all([
		prePatchInfo(),
		watchPatchInfo(),
		preRemovePicture(),
		watchRemovePicture(),
		prePostInfoAddress(),
		watchPostInfoAddress(),
		prePatchAddress(),
		watchPatchAddress(),
		prePatchClosingDate(),
		watchPatchClosingDate(),
		preRemoveClosingDate(),
		watchRemoveClosingDate(),
		preAddClosingDate(),
		watchAddClosingDate(),
		preAddPicture(),
		watchAddPicture(),
		prePatchPicture(),
		watchPatchPicture(),
		preAddPictureReused(),
		watchAddPictureReused(),
	]);
}
