import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	set_ipad_token: 0,
	set_api_data: 0,
	set_customer_id: 1,
	set_auth_link: 1,
	set_auth_webview_loyalty_link: 1,
	set_api_discount: 1,
	set_api_payment_type: 1,
	set_api_mask: 1,
	success: 2,
};

const Zerosix = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Zerosix;
