import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.scss';
import {
	FormControlLabel,
	IconButton,
	Input,
	Paper,
	Switch,
	Button,
	InputAdornment,
} from '@material-ui/core';
import { getData } from '../../../service/partnersData/actions';
import { deepEqual } from '../../../utils';

class Tip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			enable: false,
			option: [],
		};
	}
	onChangeOption = (val, index) => {
		let opts = [...this.state.option];
		opts[index] = parseFloat(val);
		this.setState({ option: opts });
	};

	componentDidMount() {
		if (!this.props.partnerData) {
			this.props.getPartnerData();
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.partnerData && !state.loaded) {
			return {
				loaded: true,
				...props.partnerData,
			};
		}

		return null;
	}

	onSave = _ => {
		this.props.saveTips({
			enable: this.state.enable,
			option: this.state.option,
		});
	};

	canSave = _ => {
		const isDeppEqual = deepEqual(
			{
				enable: this.state.enable,
				option: this.state.option,
			},
			this.props.partnerData
		);

		const isEmpty = !this.state.option.length;

		const optionGeatherThan0 = this.state.option.every(v => v > 0);

		const optionAreValids = this.state.option.every(
			v => !parseFloat(v) != 'NaN'
		);

		return (
			this.props.partnerData &&
			!isDeppEqual &&
			!isEmpty &&
			optionGeatherThan0 &&
			optionAreValids
		);
	};

	render() {
		return (
			<div className="ticket-tip-container">
				<h2 className="export-title ticket-tip-title">
					{I18n.t('partner.ladditionTicket.tips.title')}
				</h2>

				<Paper>
					<div className="ticket-tip-container-inside">
						<FormControlLabel
							control={
								<Switch
									checked={this.state.enable}
									onChange={e => this.setState({ enable: e.target.checked })}
								/>
							}
							labelPlacement="start"
							label={I18n.t('partner.ladditionTicket.tips.switch')}
						/>

						{this.state.enable ? (
							<>
								{this.state.option.map((o, i) => {
									return (
										<div>
											<Input
												type="number"
												onChange={e => this.onChangeOption(e.target.value, i)}
												value={o}
												endAdornment={
													<InputAdornment position="end">€</InputAdornment>
												}
												inputProps={{
													step: '0.1',
													min: 0.1,
												}}
											/>
											<IconButton
												onClick={() =>
													this.setState({
														option: this.state.option.filter(
															(o, index) => index != i
														),
													})
												}>
												<DeleteIcon />
											</IconButton>
										</div>
									);
								})}
								{!this.state.option.length ? (
									<p style={{ fontStyle: 'italic' }}>
										{I18n.t('partner.ladditionTicket.tips.empty')}
									</p>
								) : null}
								{this.state.option.length < 3 ? (
									<p
										className="ticket-tip-add"
										onClick={() =>
											this.setState({ option: [...this.state.option, 1] })
										}>
										{I18n.t('partner.ladditionTicket.tips.add')}
									</p>
								) : (
									''
								)}
							</>
						) : (
							''
						)}

						<div style={{ marginTop: 50 }}>
							<Button
								variant="contained"
								onClick={this.onSave}
								color="primary"
								disabled={!this.canSave()}>
								{' '}
								{I18n.t('general.save')}
							</Button>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		partnerData: state.partnersData['ladditionTicket'],
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPartnerData: _ => dispatch(getData('ladditionTicket')),
		saveTips: body => dispatch({ type: 'SAVE_TICKET_TIPS', body }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tip);
