import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import './style.scss';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@material-ui/core';
import { I18n } from 'react-redux-i18n';
import theme from '../../theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
class Confirmation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmChecked: false,
		};
	}
	render() {
		const {
			confirmation,
			show,
			proceed,
			dismiss,
			cancel,
			options,
		} = this.props;
		const opts = options || {};

		const mediaContent = () => {
			if (!this.props.media) return null;

			if (this.props.media.type === 'img') {
				return (
					<img
						style={{ width: '95%' }}
						src={this.props.media.url}
						alt="media confirm box"
					/>
				);
			}
			if (this.props.media.type === 'video') {
				return (
					<video width="520" height="400" controls>
						<source
							src={this.props.media.url}
							autoPlay={this.props.media.autoplay || false}
							muted={this.props.media.muted || false}
							type="video/mp4"
						/>
						Your browser does not support the video tag.
					</video>
				);
			}
			return null;
		};

		return (
			<MuiThemeProvider theme={theme}>
				{' '}
				<Dialog open={show} onClose={dismiss}>
					<DialogTitle> {opts.title || I18n.t('confirmation')} </DialogTitle>
					<DialogContent>
						<DialogContentText>
							{typeof confirmation === 'string' ? (
								<ReactMarkdown
									children={confirmation}
									rehypePlugins={[rehypeRaw, remarkGfm]}
								/>
							) : (
								confirmation
							)}

							{mediaContent()}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						{opts.mustConfirm ? (
							<span>
								<input
									type="checkbox"
									value={!!this.state.confirmChecked}
									onChange={e =>
										this.setState({
											confirmChecked: !this.state.confirmChecked,
										})
									}
								/>{' '}
								{opts.mustConfirmLabel}
							</span>
						) : (
							''
						)}
						{!opts.hideCancel ? (
							<Button
								onClick={cancel}
								aria-label="Close"
								className={'button-cancel'}>
								{opts.cancelLabel ? opts.cancelLabel : I18n.t('general.cancel')}
							</Button>
						) : (
							''
						)}

						<Button
							onClick={proceed}
							color="primary"
							variant="contained"
							className={'button-valid'}
							disabled={opts.mustConfirm && !this.state.confirmChecked}>
							{opts.confirmLabel
								? opts.confirmLabel
								: I18n.t('general.confirm')}
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		);
	}
}

Confirmation.propTypes = {
	show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
	proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
	cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
	dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
	confirmation: PropTypes.string, // arguments of your confirm function
	options: PropTypes.shape({
		// arguments of your confirm function
		cancelLabel: PropTypes.string,
		confirmLabel: PropTypes.string,
		title: PropTypes.string,
		hideCancel: PropTypes.bool,
		mustConfirm: PropTypes.bool,
		mustConfirmLabel: PropTypes.string,
	}),
};

export default confirmable(Confirmation);
