import React from 'react';
import { I18n } from 'react-redux-i18n';
import OpenInNewIcon from '@material-ui/icons/Link';
import './style.scss';

export const ManageServicesLink = _ => {
	return (
		<a
			target={'_blank'}
			rel="noopener noreferrer"
			href={I18n.t('partner.mewsSystems.documentation.manageServices')}
			className="mewsDocLinks">
			<OpenInNewIcon />
			{I18n.t('partner.mewsSystems.labelLinkManageServices')}
		</a>
	);
};

export const UnderstandServicesLink = _ => {
	return (
		<a
			target={'_blank'}
			rel="noopener noreferrer"
			href={I18n.t('partner.mewsSystems.documentation.understandServices')}
			className="mewsDocLinks">
			<OpenInNewIcon />
			{I18n.t('partner.mewsSystems.labelUnderstandServices')}
		</a>
	);
};

export const ManageAccountingCategoriesLink = _ => {
	return (
		<a
			target={'_blank'}
			rel="noopener noreferrer"
			href={I18n.t(
				'partner.mewsSystems.documentation.manageAccountingCategories'
			)}
			className="mewsDocLinks">
			<OpenInNewIcon />
			{I18n.t('partner.mewsSystems.labelLinkAccountingCategories')}
		</a>
	);
};

export const ManageOutletsLink = _ => {
	return (
		<a
			target={'_blank'}
			rel="noopener noreferrer"
			href={I18n.t('partner.mewsSystems.documentation.manageOutlets')}
			className="mewsDocLinks">
			<OpenInNewIcon />
			{I18n.t('partner.mewsSystems.labelLinkOutlets')}
		</a>
	);
};

export const IntegrationPOSLink = _ => {
	return (
		<a
			target={'_blank'}
			rel="noopener noreferrer"
			href={I18n.t('partner.mewsSystems.documentation.integrationPOS')}
			className="mewsDocLinks">
			<OpenInNewIcon />
			{I18n.t('partner.mewsSystems.labelLinkIntegrationPOS')}
		</a>
	);
};
