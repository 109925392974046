import React, { Component } from 'react';
import './style.scss';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

class Alert extends Component {
	render() {
		let classes = ['m-alert'];
		let icon;
		switch (this.props.type) {
			case 'warning':
				classes.push('m-alert-warning');
				icon = <WarningIcon />;
				break;
			case 'success':
				classes.push('m-alert-success');
				icon = <CheckCircleOutlineIcon />;
				break;
			case 'error':
				classes.push('m-alert-error');
				icon = <ErrorIcon />;
				break;
			case 'info':
			default:
				classes.push('m-alert-info');
				icon = <InfoIcon />;
		}
		return (
			<div className={classes.join(' ')}>
				<span className="m-alert-icon">{icon} </span>
				{this.props.children}
			</div>
		);
	}
}

export default Alert;
