import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import {
	refreshBindings,
	requestHandler,
} from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import {
	initPartner,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	preGenerateLinkPartnerToCustomer,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	setActiveAuthLink,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';
import { activeStockManagement } from '../../../Components/ProductQuantity/service/saga';

function* configDood() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_DOOD');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			success = true;
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_customer_id') {
			yield call(handlePatchVariable, {
				key: 'doodCustomerId',
				value: data.customer_id,
			});

			const resultInit = yield call(initPartner, 'dood', true);
			//TODO: quand init partenaire sera refacto, afficher les messages d'erreur fourni par dood

			if (resultInit && resultInit.success === true) {
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: 'dood', external_id: bodySetStep.id_user },
				});

				yield take('PATCH_PARTNER');
				currentStep = 'set_table_payment_and_price_category';
				bodySetStep.step = 'set_table_payment_and_price_category';
				success = true;
			}
		}

		if (currentStep === 'set_table_payment_and_price_category') {
			const { binding } = yield select();
			let table = binding.floor.find(f => f.id === 'dood-1');
			let paiementType = binding.paymentType.find(f => f.id === 'dood');
			let priceCategory = binding.priceCategory.find(p => p.id === 'dood');

			let needInsert = !table || !paiementType || !priceCategory;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, 'dood');
			}

			// si la table et le moyen de paiement sont deja la OU qu'ils ont bien été insérés
			if (!needInsert || insertSuccess) {
				if (needInsert) yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

				let body = {
					prefix: 'dood',
					id_table: 'dood-1',
				};

				const resultFloor = yield call(patchPartnerFloor, body);

				body = {
					prefix: 'dood',
					id_payment_type: 'dood',
				};

				const resultPaiementType = yield call(patchPartnerPaiementType, body);

				body = {
					prefix: 'dood',
					id_price_category: 'dood-1',
				};

				const resultPriceCategory = yield call(patchPartnerPriceCategory, body);

				if (
					resultFloor &&
					resultFloor.result &&
					resultPaiementType &&
					resultPaiementType.result &&
					resultPriceCategory &&
					resultPriceCategory.result
				) {
					success = true;
					currentStep = 'set_auth_link';
					bodySetStep.step = 'set_auth_link';
				} else {
					errorMessage = 'partner.onboarding_common_errors.set_entities';
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.set_entities';
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: bodySetStep.id_user,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
				currentStep = 'set_partner_options';
				bodySetStep.step = 'set_partner_options';
				success = true;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, 'dood');
			if (resultInitOption) {
				success = true;
				bodySetStep.step = 'set_api_mask';
				currentStep = 'set_api_mask';
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: 'dood',
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}
		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_DOOD_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([configDood()]);
}
