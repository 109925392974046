import React, { Component } from 'react';
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import AddCircle from '@material-ui/icons/AddCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { List } from '@material-ui/core';
import * as actions from '../../../../../service/export/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input from '../../../../../../../Components/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { EXPORT_TYPE } from '../../../../../config';
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import '../style.scss';
import { PropTypes } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '../../../../../../../img/deleteIcon.svg';
import { confirm } from '../../../../../../../utils';

const styles = theme => ({
	root: {
		'&$selected, &$selected:focus, &$selected:hover': {
			backgroundColor: '#5ac8f5 !important',
		},
		'&:hover $secondaryAction': {
			visibility: 'inherit',
		},
	},
	divider: {
		borderBottom: `1px solid #fff`,
	},
	selected: {
		color: '#fff',
	},
	secondaryAction: {
		visibility: 'hidden',
	},
});

const DragHandle = sortableHandle(() => (
	<span>
		<MenuIcon className="menu-icon" />
	</span>
));

const SortableItem = sortableElement(({ value }) => (
	<li className="list-item">{value}</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul>{children}</ul>;
});

const DragProductHandle = sortableHandle(() => (
	<span>
		<MenuIcon className="menu-product-icon" />
	</span>
));

const SortableProductItem = sortableElement(({ value }) => (
	<div className="list-item">{value}</div>
));

const SortableProductContainer = sortableContainer(({ children }) => {
	return <ul>{children}</ul>;
});

class SectionList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newNameSection: '',
			open: false,
			lastWeight: 0,
			sectionsOpen: 0,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.type === EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS ||
			nextProps.type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS
		) {
			nextProps.setSelected('section', nextProps.sections[0].id);
			return {
				sectionsOpen: nextProps.sections[0].id,
			};
		}
		return null;
	}

	componentDidMount() {
		/* if (this.props && this.props.sections) {
      const clusterize = new Clusterize({
        scrollId: "scrollArea",
        contentId: "contentArea"
      });
    } */
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const newTable = arrayMove(this.props.sections, oldIndex, newIndex).map(
			(section, index) => {
				section.weight = index;
				return section;
			}
		);

		this.props.action.reweightSection(newTable, this.props.idExport);
	};

	onProductSortEnd = ({ oldIndex, newIndex }) => {
		const section = this.props.sections.find(
			sect => sect.id === this.state.sectionsOpen
		);
		const newTable = arrayMove(section.products, oldIndex, newIndex).map(
			(item, index) => {
				item.weight = index;
				return item;
			}
		);

		this.props.action.reweightProduct(
			newTable,
			section.id,
			this.props.idExport
		);
	};

	onSectionClick = (base, id) => {
		this.setState(
			{
				sectionsOpen: this.state.sectionsOpen === id ? 0 : id,
			},
			() => {
				this.props.setSelected(base, id);
			}
		);
	};

	onChange = event => {
		this.setState({
			newNameSection: event.target.value,
		});
	};

	onSendSection = () => {
		const lastWeight = this.props.sections.length
			? this.props.sections[this.props.sections.length - 1].weight
			: 0;

		const countUnamed = this.props.sections.reduce((acc, sect) => {
			if (sect.name.includes(I18n.t('exports.sectionList.new_section'))) {
				acc += 1;
			}
			return acc;
		}, 0);

		let newSection = {
			id_export: this.props.idExport,
			name:
				I18n.t('exports.sectionList.new_section') +
				(countUnamed ? ` (${countUnamed + 1})` : ''),
			weight: lastWeight + 1,
		};
		this.props.action.addSection(newSection, this.props.idExport);
		this.setState({
			newNameSection: '',
		});
	};

	onDeleteItem = (event, id, idProduct, idExport, idSection) => {
		event.stopPropagation();
		confirm(I18n.t('exports.formSection.confirm_delete_product')).then(() => {
			this.props.setSelected('product', '');
			this.props.setSelected('section', idSection);
			this.props.action.deleteProductToSection(
				id,
				idProduct,
				idExport,
				idSection
			);
		});
	};

	render() {
		let { type, classes } = this.props;
		let list = [];

		for (let section of this.props.sections) {
			let productList = [];
			if (section.products.length > 0) {
				let table = [];
				table = section.products.map(product => {
					return { ...product };
				});
				table.sort((a, b) => {
					return a.weight - b.weight;
				});
				table.map(product => {
					const ps = this.props.products[product.id_product];
					const name = ps ? ps.name : '';
					let attr = {};
					if (
						type === EXPORT_TYPE.SECTION_WITH_EXTRAS ||
						type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS
					) {
						attr.onClick = () => {
							this.props.setSelected('product', product.id_product);
						};
					}
					productList.push(
						<div className="product-item">
							<ListItem
								button
								selected={this.props.selectedProduct === product.id_product}
								className={
									this.props.selectedProduct === product.id_product
										? 'product-selected'
										: ''
								}
								{...attr}
								classes={{
									container: classes.root,
								}}>
								<ListItemIcon
									className={
										this.props.selectedProduct === product.id ? 'white' : ''
									}>
									<DragProductHandle />
								</ListItemIcon>
								<ListItemText inset primary={name} />
								<ListItemSecondaryAction
									className={classes.secondaryAction + ' close-icon-container'}>
									<img
										src={CloseIcon}
										className="product-item-close-icon"
										onClick={event =>
											this.onDeleteItem(
												event,
												product.id,
												product.id_product,
												this.props.idExport,
												section.id
											)
										}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							<Divider variant="middle" className="list-divider" />
						</div>
					);
				});
			}

			list.push(
				<div className="section-item-container">
					<ListItem
						button
						key={section.id}
						id={section.id}
						onClick={() => this.onSectionClick('section', section.id)}
						selected={
							type === EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS ||
							type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS
								? true
								: this.props.selectedSection === section.id
						}
						classes={classes}>
						<ListItemIcon
							className={
								this.props.selectedSection === section.id ? 'white' : ''
							}>
							<DragHandle />
						</ListItemIcon>
						<ListItemText
							primary={section.name}
							className={
								this.props.selectedSection === section.id ? 'white' : 'blue'
							}
						/>
						{this.state.sectionsOpen === section.id &&
						section.products.length > 0 ? (
							<ExpandLess
								className={
									this.props.selectedSection === section.id ? '' : 'blue'
								}
							/>
						) : (
							<ExpandMore
								className={
									this.props.selectedSection === section.id ? '' : 'blue'
								}
							/>
						)}
					</ListItem>
					<Divider className="list-divider" />
					<Collapse
						in={this.state.sectionsOpen === section.id}
						timeout="auto"
						unmountOnExit>
						<List
							component="div"
							disablePadding
							className="product-list-container">
							<SortableProductContainer
								onSortEnd={this.onProductSortEnd}
								useDragHandle>
								{productList.map((value, index) => (
									<SortableProductItem
										key={`item-${index}`}
										index={index}
										value={value}
									/>
								))}
							</SortableProductContainer>
						</List>
					</Collapse>
				</div>
			);
		}

		return (
			<div className="section-list-container">
				{type === EXPORT_TYPE.SECTION_WITH_EXTRAS ||
				type === EXPORT_TYPE.SECTION_WITHOUT_EXTRAS ? (
					<h2>{I18n.t('exports.sectionList.my_sections')}</h2>
				) : (
					<h2>{I18n.t('exports.sectionList.my_products')}</h2>
				)}
				<div className="section-list">
					<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
						{list.map((value, index) => (
							<SortableItem key={`item-${index}`} index={index} value={value} />
						))}
					</SortableContainer>
					{type === EXPORT_TYPE.SECTION_WITH_EXTRAS ||
					type === EXPORT_TYPE.SECTION_WITHOUT_EXTRAS ? (
						<React.Fragment>
							<div
								className="new-section-container"
								onClick={this.onSendSection}>
								<p>{I18n.t('exports.sectionList.add_section')} </p>
								<AddCircle onClick={this.onSendSection} />
							</div>
						</React.Fragment>
					) : null}
				</div>
			</div>
		);
	}
}

SectionList.propTypes = {
	idExport: PropTypes.number.isRequired,
	setSelected: PropTypes.func.isRequired,
	selectedSection: PropTypes.number.isRequired,
	selectedProduct: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
	const exp = state.exports.list.find(e => e.id === props.idExport);

	return {
		sections: exp ? exp.sections : [],
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SectionList));

/* export default (SectionList) */
