import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class PriceCategory extends Component {
	onChange = e => {
		this.props.onChange('selectedPriceCategory', e.target.value);
	};

	render() {
		return (
			<div>
				{this.props.list.length ? (
					<div className="label-priceCategory-select">
						{!this.props.hideLabel ? (
							<DialogContentText>
								<Translate value={'binding.select_price_category'} /> :
							</DialogContentText>
						) : null}
						<Select
							value={this.props.selected}
							onChange={this.onChange}
							displayEmpty
							onOpen={() =>
								this.props.removeListener ? this.props.removeListener() : null
							}
							onClose={
								this.props.addListener ? () => this.props.addListener() : null
							}>
							<MenuItem value="" disabled={!!this.props.required}>
								<i>{I18n.t('binding.none')}</i>
							</MenuItem>
							{this.props.list.map(p => (
								<MenuItem key={p.id} value={p.id}>
									{p.name}
								</MenuItem>
							))}
						</Select>
					</div>
				) : (
					<p className="paragraph-without-margin">
						<Translate value={'binding.empty_price_category'} />
					</p>
				)}
			</div>
		);
	}
}

PriceCategory.defaultProps = {
	selected: '0',
	list: [],
};

PriceCategory.propTypes = {
	selected: PropTypes.string,
	list: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default PriceCategory;
