import { createSelector } from 'reselect';

//selector test
const partnersSelector = state => {
	let partnersConfigurable = Object.values(state.partnersConfig).reduce(
		(acc, partner) => {
			if (partner.visibility) {
				acc[partner.partner_id] = partner;
			}
			return acc;
		},
		{}
	);
	return partnersConfigurable;
};

export const getPartnersSelector = createSelector(partnersSelector, partners =>
	Object.values(partners)
);

const partnerSelector = (state, props) => {
	return state.partnersConfig.hasOwnProperty(props.key_)
		? state.partnersConfig[props.key_]
		: {};
};

export const getPartnerById = createSelector(
	partnerSelector,
	partner => partner
);

/* const sortPartnersConfiguration = state => {
  let partnersConfiguration = [];
  Object.values(state.partnersConfig).forEach(partner => {
    if (partner.status === "configuration") {
      partnersConfiguration.push(partner);
    }
  });
  return partnersConfiguration;
};

export const getPartnersConfigurationSelector = createSelector(
  sortPartnersConfiguration,
  partner => partner
);

const sortPartnersDisabled = state => {
  let partnersDisabled = [];
  Object.values(state.partnersConfig).forEach(partner => {
    if (partner.status === "disabled") {
      partnersDisabled.push(partner);
    }
  });
  return partnersDisabled;
};

export const getPartnersDisabledSelector = createSelector(
  sortPartnersDisabled,
  partner => partner
);

const sortPartnersActifInactif = state => {
  let partnersActifInactif = [];
  Object.values(state.partnersConfig).forEach(partner => {
    if (partner.status !== "disabled" && partner.status !== "configuration") {
      partnersActifInactif.push(partner);
    }
  });
  return partnersActifInactif;
};

export const getPartnersActifInactifSelector = createSelector(
  sortPartnersActifInactif,
  partner => partner
); */

/* const sortPartners = state => {
  let partnersActifInactif = [];
  let partnersDisabled = [];
  let partnersConfiguration = [];
  Object.values(state.partnersConfig).forEach(partner => {
    if (partner.status === "disabled") {
      partnersDisabled.push(partner);
    } else if (partner.status !== "configuration") {
      partnersConfiguration.push(partner);
    } else {
      partnersActifInactif.push(partner);
    }
  });
  return { partnersActifInactif, partnersDisabled, partnersConfiguration };
};

export const getPartnersActifInactifSelector = createSelector(
  sortPartners,
  partner => partner.partnersActifInactif
);

export const getPartnersConfigurationSelector = createSelector(
  sortPartners,
  partner => partner.partnersConfiguration
);

export const getPartnersDisabledSelector = createSelector(
  sortPartners,
  partner => partner.partnersDisabled
); */
