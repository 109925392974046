import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Tooltip,
} from '@material-ui/core';
import { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartnerDocumentation } from '../../utils';
import HelpIcon from '@material-ui/icons/Help';
import './index.scss';

class PartnerContact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}
	show = _ => {
		this.setState({ show: true });
		if (this.props.onOpen) this.props.onOpen();
	};
	dismiss = _ => {
		this.setState({ show: false });
		if (this.props.onClose) this.props.onClose();
	};

	renderBtnCard = _ => {
		if (this.props.type === 'card') {
			return (
				<Tooltip title={I18n.t('partner.tooltip_partner_help')}>
					<div className="card_action card_action_help" onClick={this.show}>
						<HelpIcon />
					</div>
				</Tooltip>
			);
		} else {
			return (
				<div
					className="card_action card_action_help card_action_help_text"
					onClick={this.show}>
					{I18n.t('partner.tooltip_partner_help')}
				</div>
			);
		}
	};
	render() {
		let doc = getPartnerDocumentation(this.props.prefix);

		if (
			!doc &&
			(!this.props.contact || !Object.keys(this.props.contact).length)
		)
			return null;
		const name = I18n.t('partner.prefix.' + this.props.prefix);

		return (
			<>
				{this.renderBtnCard()}
				<Dialog open={this.state.show} onClose={this.dismiss}>
					<DialogTitle>
						{' '}
						{I18n.t('general.help')} {name}{' '}
					</DialogTitle>
					<DialogContent>
						{this.props.contact && Object.keys(this.props.contact).length ? (
							<div>
								<h2> {I18n.t('general.contact')} </h2>
								<ul>
									{Object.keys(this.props.contact).map(key => (
										<li>
											{key} : {this.props.contact[key]}
										</li>
									))}
								</ul>
							</div>
						) : null}

						{doc ? (
							<div>
								<h2>{I18n.t('general.documentation')} </h2>
								<p>
									<a
										target="blank"
										rel="noopener noreferrer"
										href={I18n.t(doc)}>
										{I18n.t('partner.documentation', { partner: name })}
									</a>
								</p>
							</div>
						) : null}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.dismiss}
							color="primary"
							variant="contained"
							className={'button-valid'}>
							{I18n.t('general.close')}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mSTP = (state, props) => {
	const { partnersConfig } = state;
	const partner = Object.values(partnersConfig).find(
		p => p.prefix === props.prefix
	);

	return {
		contact: (partner && partner.partnerContact) || null,
	};
};
export default connect(mSTP)(PartnerContact);
