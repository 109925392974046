export const RECEIVE_PARTNERS_CONFIG = 'RECEIVE_PARTNERS_CONFIG';
export const RECEIVE_PARTNERS_CONFIG_CLIENT = 'RECEIVE_PARTNERS_CONFIG_CLIENT';
export const RECEIVE_PARTNERS_CONFIG_STEP = 'RECEIVE_PARTNERS_CONFIG_STEP';
export const CHANGE_PARTNER_STEP = 'CHANGE_PARTNER_STEP';
export const CHANGE_PARTNER_STATUS = 'CHANGE_PARTNER_STATUS';
export const PRE_CHANGE_PARTNER_STEP = 'PRE_CHANGE_PARTNER_STEP';
export const PRE_CHANGE_PARTNER_STATUS = 'PRE_CHANGE_PARTNER_STATUS';
export const PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT =
	'PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT';
export const PRE_GENERATE_TOKEN = 'PRE_GENERATE_TOKEN';
export const GENERATE_TOKEN = 'GENERATE_TOKEN';
export const PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER =
	'PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER';
export const VALIDATION_LINK_PARTNER_TO_CUSTOMER =
	'VALIDATION_LINK_PARTNER_TO_CUSTOMER';
export const PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK =
	'PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK';
export const VALIDATION_CONNEXION_WITH_PARTNER_OK =
	'VALIDATION_CONNEXION_WITH_PARTNER_OK';
export const PRE_VALIDATION_PARTNER_CHECKING =
	'PRE_VALIDATION_PARTNER_CHECKING';
export const VALIDATION_PARTNER_CHECKING = 'VALIDATION_PARTNER_CHECKING';
export const PRE_POST_PARTNER_TO_MED_DEFAULT =
	'PRE_POST_PARTNER_TO_MED_DEFAULT';
export const PRE_PUT_PARTNER_LINK_AUTH = 'PRE_PUT_PARTNER_LINK_AUTH';
export const PRE_POST_PARTNER_CHECKING = 'PRE_POST_PARTNER_CHECKING';
export const PRE_POST_INITIALIZATION_PARTNER =
	'PRE_POST_INITIALIZATION_PARTNER';
export const PRE_SET_OPTION_PARTNER = 'PRE_SET_OPTION_PARTNER';
export const PRE_GET_CHECK_MENU = 'PRE_GET_CHECK_MENU';
export const GET_CHECK_MENU = 'GET_CHECK_MENU';
export const PRE_PATCH_PAIEMENT_TYPE = 'PRE_PATCH_PAIEMENT_TYPE';
export const PRE_PATCH_FLOOR = 'PRE_PATCH_FLOOR';
export const PRE_PATCH_PRICE_CATEGORY = 'PRE_PATCH_PRICE_CATEGORY';
export const PRE_GENERATE_TOKEN_CMD_EN_LIGNE =
	'PRE_GENERATE_TOKEN_CMD_EN_LIGNE';
export const PRE_REQUEST_POST_INITIALIZATION_PARTNER =
	'PRE_REQUEST_POST_INITIALIZATION_PARTNER';
export const PRE_POST_INITIALIZATION_MASK_PARTNER =
	'PRE_POST_INITIALIZATION_MASK_PARTNER';
export const SET_UBEREATS_STORES = 'SET_UBEREATS_STORES';
export const PUT_CONFIG_PARTNER = 'PUT_CONFIG_PARTNER';
export const PRE_CHANGE_PARTNER_CONNECTOR_STATUS =
	'PRE_CHANGE_PARTNER_CONNECTOR_STATUS';
export const CHANGE_PARTNER_CONNECTOR_STATUS =
	'CHANGE_PARTNER_CONNECTOR_STATUS';
