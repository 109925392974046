import React from 'react';
import Supplement from './Supplement';
import { I18n } from 'react-redux-i18n';
import InputEdit from './InputEdit';
import { Select, MenuItem, Collapse } from '@material-ui/core';
import ResetButton from './ResetButton';
import { connect } from 'react-redux';
import Checkbox from '../../../../../Components/CheckBox';

const CategorySupplement = ({
	id_menu,
	categorySupplement,
	categoryExtra,
	supplementExtra,
	onChangeCategorySupplement,
	onDeleteCategorySupplement,
	openImageModal,
	openFullSizeModal,
	onDeleteImage,
	prefix,
	...supplementFunctions
}) => {
	function onChangeDisabled(e) {
		onChangeCategorySupplement(
			categorySupplement.id,
			id_menu,
			'disabled',
			!categorySupplement.disabled
		);
	}

	function onChangeSelect(e) {
		onChange(e.target.name, e.target.value);
	}

	function onChange(prop, value) {
		onChangeCategorySupplement(categorySupplement.id, id_menu, prop, value);
	}

	let finded = [];
	if (categoryExtra.hasOwnProperty(categorySupplement.id)) {
		finded =
			categoryExtra[
				categorySupplement.id
			]; /* categoryExtra.find(
      cat =>
        cat.id_category_supplement === categorySupplement.id &&
        cat.id_menu === id_menu
    ); */

		if (finded) {
			categorySupplement.originalName = categorySupplement.name;
			for (let prop in finded) {
				categorySupplement[finded[prop]['key_']] = finded[prop]['value'];
			}
		}
	}

	let hasExtras =
		finded ||
		supplementExtra.some(
			se =>
				se.id_category_supplement == categorySupplement.id &&
				se.id_menu === id_menu
		);

	const categoryDisabled = categorySupplement.disabled == 1;
	return (
		<div className={'category-supplement-container'}>
			<div className={'category-supplement-info'}>
				<div>
					<Checkbox selected={!categoryDisabled} onClick={onChangeDisabled} />
				</div>

				<div className={'input-category-supplement'}>
					<div>
						<InputEdit
							value={categorySupplement.name}
							originalValue={categorySupplement.originalName}
							inputProps={{ type: 'text' }}
							onChange={value => {
								onChange('name', value);
							}}
							classes={'name'}
						/>
					</div>
					<div>
						{' '}
						{I18n.t('partner.supplements.type')} :{' '}
						<Select
							value={categorySupplement.type}
							name="type"
							onChange={onChangeSelect}>
							<MenuItem value={1}>
								{I18n.t('partner.supplements.types.1')}
							</MenuItem>
							<MenuItem value={2}>
								{I18n.t('partner.supplements.types.2')}
							</MenuItem>
							<MenuItem value={3}>
								{I18n.t('partner.supplements.types.3')}
							</MenuItem>
						</Select>
					</div>
				</div>

				<ResetButton
					onClick={() =>
						onDeleteCategorySupplement(categorySupplement.id, id_menu)
					}
					disabled={!hasExtras}
				/>
			</div>
			<div>
				<Collapse in={!categoryDisabled} className={'supplement-container'}>
					<p className={'label-supplement'}>
						<span>{I18n.t('partner.supplements.name')}</span>
						<span>{I18n.t('partner.supplements.image')}</span>
						<span>{I18n.t('partner.supplements.price')}</span>
					</p>
					{categorySupplement.data.map(supp => {
						return (
							<Supplement
								key={supp.id}
								id_menu={id_menu}
								id_category={categorySupplement.id}
								supplement={{ ...supp }}
								{...supplementFunctions}
								openImageModal={openImageModal}
								openFullSizeModal={openFullSizeModal}
								onDeleteImage={onDeleteImage}
								prefix={prefix}
							/>
						);
					})}
				</Collapse>
			</div>
		</div>
	);
};

const mapStateToProps = (state, props) => {
	return {
		categoryExtra: filterByPrefix(
			state.exports.supplement.categorySupplementExtra,
			props.prefix
		),
		supplementExtra: filterByPrefix(
			state.exports.supplement.supplementExtra,
			props.prefix
		),
	};
};

export const filterByPrefix = (obj, prefix) => {
	return Object.keys(obj).reduce((acc, k) => {
		let extras = obj[k].filter(o => o.partner_prefix === prefix);

		if (extras.length) acc[k] = extras;
		return acc;
	}, {});
};

export default connect(mapStateToProps)(CategorySupplement);
