import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

const Modal = props => {
	const noPropagation = e => {
		e.stopPropagation();
	};
	return (
		<div
			id="myModal"
			className={props.openModal ? 'modal display-block' : 'modal display-none'}
			onClick={props.closeModal}>
			<span className="modal-close">&times;</span>

			<div onClick={noPropagation}>
				<img className="modal-content" src={props.src} alt={props.name} />
				<div id="modal-caption">{props.name}</div>
				<div id="modal-caption">{props.description}</div>
			</div>
		</div>
	);
};

Modal.propTypes = {
	src: PropTypes.string,
	openModal: PropTypes.bool,
	closeModal: PropTypes.func,
	name: PropTypes.string,
};

export default Modal;
