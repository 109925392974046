import React, { Component } from 'react';
import './../style.scss';

class HeaderTabPictures extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: props.color,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.color !== prevState.color) {
			return {
				color: nextProps.color,
			};
		}
		return null;
	}

	render() {
		return (
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 15 18"
				enableBackground="new 0 0 15 18"
				className="pictures-icon"
				className={
					this.state.color === '#e84249'
						? 'pictures-icon red-color'
						: 'pictures-icon grey-color'
				}>
				<title>icon / calendar</title>
				<desc>Created with Sketch.</desc>
				<path
					d="M3.7,9c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8C5.4,12.8,3.7,11.1,3.7,9L3.7,9z M11.4,1.5H3.6
       C1.6,1.5,0,3.1,0,5v7.9c0,1.9,1.6,3.5,3.5,3.5h7.9c2,0,3.5-1.6,3.5-3.5V5C15,3.1,13.4,1.5,11.4,1.5L11.4,1.5z M3.6,2.4h7.9
       c1.5,0,2.7,1.2,2.7,2.7v7.9c0,1.5-1.2,2.7-2.7,2.7H3.6c-1.5,0-2.7-1.2-2.7-2.7V5C0.9,3.6,2.1,2.4,3.6,2.4L3.6,2.4z M12.2,3.5
       c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8S13,4.7,13,4.3C13,3.8,12.7,3.5,12.2,3.5L12.2,3.5z M12.3,9c0-2.7-2.2-4.8-4.8-4.8
       C4.8,4.1,2.7,6.3,2.7,9c0,2.7,2.2,4.8,4.8,4.8C10.2,13.8,12.3,11.6,12.3,9L12.3,9z"
				/>
			</svg>
		);
	}
}

export default HeaderTabPictures;
