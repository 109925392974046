import React, { Component } from 'react';
import moment from 'moment';
import Button from '../../../../../../Components/Button';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import imgArrowNext from '../../img/fleche-droit.svg';
import calendar from '../../img/calendar-big.svg';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from '../DatePicker';
import { animateScroll as scroll } from 'react-scroll';
import { withSnackbar } from 'notistack';

class ClosingDate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			restaurantId: props.restaurantId,
			closingDays: props.closingDays,
			validationDelay: props.validationDelay,
			addClosingDays: false,
			rangeToEdit: {
				from: null,
				to: null,
				id: null,
				position: null,
			},
		};
	}

	getSnapshotBeforeUpdate(nextProps, prevProps) {
		if (
			nextProps.closingDays !== prevProps.closingDays ||
			nextProps.validationDelay !== prevProps.validationDelay
		) {
			return {
				closingDays: prevProps.closingDays,
				validationDelay: prevProps.validationDelay,
			};
		}
		return null;
	}

	submitClosingDate = (startDate, endDate) => {
		let existingClosing = this.props.closingDate.find(closing => {
			return (
				moment(closing.start_date).format('YYYY-MM-DD') === startDate &&
				moment(closing.end_date).format('YYYY-MM-DD') === endDate
			);
		});
		if (existingClosing) {
			this.props.enqueueSnackbar(
				I18n.t('info.dates.error_double_closing_date'),
				{
					variant: 'warning',
				}
			);
		} else {
			const closingDate = {
				start_date: startDate,
				end_date: endDate,
			};
			this.props.submitClosingDate(closingDate);
		}
		this.setState({
			addClosingDays: false,
		});
	};

	handleDisplay = (e, index, rangeToEdit) => {
		e.preventDefault();
		scroll.scrollToBottom();
		this.setState({
			addClosingDays: true,
			rangeToEdit: {
				from: rangeToEdit ? rangeToEdit.day1 : null,
				to: rangeToEdit ? rangeToEdit.day2 : null,
				id: rangeToEdit ? rangeToEdit.id : null,
				position: index || index === 0 ? `index-${index}` : null,
			},
		});
	};
	cancelCalendar = e => {
		e.preventDefault();
		this.setState({
			addClosingDays: false,
		});
	};

	deleteClosingDay = (e, id, index) => {
		e.preventDefault();
		this.props.removeClosingDate(id);
	};

	render() {
		const displayAddClosingDays = rangeToEdit => {
			return (
				<div
					id="calendar"
					className={
						rangeToEdit && rangeToEdit.position ? rangeToEdit.position : ''
					}>
					<CloseIcon
						onClick={this.cancelCalendar}
						className="close-icon-calendar"
					/>
					<DatePicker
						restaurantId={this.state.restaurantId}
						onUpdateClosingDays={this.onUpdateClosingDays}
						token={this.props.token}
						className="date-picker"
						submitClosingDate={this.submitClosingDate}
					/>
				</div>
			);
		};

		const closingDays = () => {
			if (this.props.closingDate.length) {
				return this.props.closingDate.map((day, i) => {
					return (
						<div className="pure-g closing-days" key={`closing-days-${i}`}>
							<div className="start-date-container">
								<img src={calendar} className="calendar" alt="calendar" />
								<div className="start">
									<div className="select-styles">
										<button className="select" value={day.start_date}>
											{moment(day.start_date).format('DD/MM/YY')}
										</button>
									</div>
								</div>
							</div>
							<img src={imgArrowNext} className="arrow" alt="arrow" />
							<div className="end-date-container">
								<div className="end">
									<div className="select-styles">
										<button
											className="select"
											value={this.state.validationDelay}>
											{moment(day.end_date).format('DD/MM/YY')}
										</button>
									</div>
								</div>
								<CloseIcon
									onClick={e => this.deleteClosingDay(e, day.id, i)}
									className="close-icon"
								/>
							</div>
						</div>
					);
				});
			}
		};

		return (
			<div id="settings">
				<div>
					<div>
						<section className="content-closing-days">
							{this.props.closingDate.length ? (
								<div className="closing-days">
									<div className="start-title">
										<p className="subtitle">
											{I18n.t('info.dates.start_date')}
										</p>
									</div>
									<div className="end-title">
										<p className="subtitle">{I18n.t('info.dates.end_date')}</p>
									</div>
								</div>
							) : null}
							{closingDays()}
							<Button
								type="submit"
								className="is-red"
								onClick={e => this.handleDisplay(e, null)}>
								{I18n.t('info.dates.add_closing_date')}
							</Button>
							{this.state.addClosingDays === true
								? displayAddClosingDays()
								: ''}
						</section>
					</div>
				</div>
			</div>
		);
	}
}

ClosingDate.defaultProps = {};

ClosingDate.propTypes = {
	submitClosingDate: PropTypes.func.isRequired,
	cancelButton: PropTypes.bool,
	cancelEdit: PropTypes.func,
	idClosingDate: PropTypes.number,
};

export default withSnackbar(ClosingDate);
