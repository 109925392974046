import React from 'react';
import './style.scss';
export default function ({ children, link, outlined }) {
	return (
		<a
			target={'blank'}
			className={`partner-config-button ${outlined ? 'outlined' : ''}`}
			href={link}>
			{children}
		</a>
	);
}
