import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChannelList from './ChannelList';
import Button from '../../../../../Components/Button';
import './style.scss';
import AddButton from './AddButton';
import { I18n } from 'react-redux-i18n';

export default function Brand({
	brand,
	channels,
	onAddChannel,
	onDelete,
	onChangeChannel,
}) {
	const _channels =
		channels && channels.length && channels[0].hasOwnProperty('brandId')
			? channels.filter(c => c.brandId == brand.id)
			: channels;
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header">
				<Typography>{brand.name}</Typography>
				<Button onClick={() => onDelete('brand', brand.id)}>
					{' '}
					{I18n.t('general.delete')}
				</Button>
			</AccordionSummary>
			<AccordionDetails>
				<p>
					{' '}
					<AddButton
						list={_channels}
						selected={brand.channel ? Object.keys(brand.channel) : []}
						onAdd={id => {
							onAddChannel(id, brand.id);
						}}>
						{I18n.t('partner.brandChannel.add_channel')}
					</AddButton>
				</p>

				{brand.channel && Object.keys(brand.channel).length ? (
					<div>
						<h4>{I18n.t('partner.brandChannel.brand_channels')} </h4>
						<ChannelList
							list={brand.channel}
							channels={_channels}
							onDelete={(...params) => onDelete(...params, brand.id)}
							onChangeChannel={(...params) => {
								onChangeChannel(...params, brand.id);
							}}
							isBrand={true}
						/>
					</div>
				) : null}
			</AccordionDetails>
		</Accordion>
	);
}
