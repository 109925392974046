import React from 'react';
import { I18n } from 'react-redux-i18n';
import EditIcon from '@material-ui/icons/EditRounded';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import * as actions from '../../service/export/actions';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import './style.scss';

class ExportName extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editName: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.exportName !== nextProps.exportName && !prevState.editName) {
			return {
				exportName: nextProps.exportName,
			};
		}
		return null;
	}

	onChangeNameExport = event => {
		this.setState({
			editName: true,
			exportName: event.target.value,
		});
	};

	activeNameChangement = () => {
		this.setState(
			{
				editName: true,
			},
			() => {
				this.nameInput.focus();
			}
		);
	};

	validExportName = () => {
		if (this.props.exportName !== this.state.exportName) {
			if (this.state.exportName.length > 0) {
				this.props.action.updateExport(
					this.props.idExport,
					this.state.exportName
					//this.state.selectedDay
				);
				this.setState({
					editName: false,
				});
			} else {
				this.props.enqueueSnackbar(
					I18n.t('exports.exportModal.export_name_empty_error'),
					{
						variant: 'warning',
					}
				);
			}
		}
		this.setState({
			editName: false,
		});
	};

	onKeyDown = e => {
		if (e.key === 'Enter') {
			this.validExportName();
		}
	};

	render() {
		return (
			<div className="export-name-calendar-container">
				{!this.state.editName ? (
					<div className="text-content" onClick={this.activeNameChangement}>
						<p>{this.state.exportName}</p>
						<EditIcon className="export-modal-icon" />
					</div>
				) : (
					<div className="text-content">
						<TextField
							defaultValue={this.state.exportName}
							margin="normal"
							className="exportName-textfield"
							onChange={this.onChangeNameExport}
							inputRef={input => {
								this.nameInput = input;
							}}
							inputProps={{
								maxLength: 40,
							}}
							onKeyDown={this.onKeyDown}
						/>
						<SaveIcon
							onClick={this.validExportName}
							className="export-modal-icon"
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const exp = props.idExport
		? state.exports.list.find(e => e.id == props.idExport)
		: null;

	return {
		export: exp ? exp : {},
		exportName: exp ? exp.name : '',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(ExportName));
