import React from 'react';
import FormEvent from '../formEvent';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import Zoom from '@material-ui/core/Zoom';

class PopupEvent extends React.Component {
	handleClickAway = () => {
		this.props.onClose();
	};

	render() {
		return (
			<Zoom in={this.props.open}>
				<div id={'popup-event-overlay'} onClick={this.handleClickAway}>
					<div
						id={'popup-event-container'}
						onClick={e => {
							e.stopPropagation();
						}}>
						<div id={'popup-event-title'}>
							{I18n.t(`webhook.name.${this.props.eventWebhook.event}`)}
						</div>

						<div id={'popup-event'}>
							<FormEvent
								eventWebhook={this.props.eventWebhook}
								onSubmit={this.props.onSubmit}
								onDelete={this.props.onDelete}
								onCancel={this.props.onClose}
								onTestUrl={this.props.onTestUrl}
							/>
						</div>
					</div>
				</div>
			</Zoom>
		);
	}
}

export default PopupEvent;
