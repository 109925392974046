import {
	CANCEL_CONFIRM,
	CLOSE_CONFIRM,
	SHOW_CONFIRM,
	VALID_CONFIRM,
} from './types';
import { put, take, select, all } from 'redux-saga/effects';
import { I18n } from 'react-redux-i18n';

function* validConfirm() {
	while (true) {
		yield take(VALID_CONFIRM);
		const { confirm } = yield select();
		if (confirm.show && confirm.eventOnValid) {
			yield put({ type: confirm.eventOnValid, ...confirm.paramsOnValid });
		}
		yield put({ type: CLOSE_CONFIRM });
	}
}

function* cancelConfirm() {
	while (true) {
		yield take(CANCEL_CONFIRM);
		const { confirm } = yield select();
		if (confirm.show && confirm.eventOnCancel) {
			yield put({ type: confirm.eventOnCancel, ...confirm.paramsOnCancel });
		}
		yield put({ type: CLOSE_CONFIRM });
	}
}

/**
 * Affiche la fenetre de confirmation / Information
 * @param {String} text Texte principal de la popup
 * @param {Enum} type "confirm" ou "info"
 * @param {Object} params
 * @param {String} params.eventOnValid
 * @param {String} params.eventOnCancel
 * @param {Object} params.paramsOnValid
 * @param {Object} params.paramsOnCancel
 * @param {Object} params.options
 * @param {Bool} params.options.title
 * @param {Bool} params.options.hideCancel
 * @param {String} params.options.cancelLabel
 * @param {String} params.options.confirmLabel
 * @param {Object} params.media
 * @param {String} params.media.url
 * @param {String} params.media.type img ou video
 *
 */
export function* showConfirm(text, type = 'confirm', params = { options: {} }) {
	let action = { type: SHOW_CONFIRM, text, media: params.media };
	if (type === 'confirm') {
		action = {
			...action,
			...params,
			eventOnValid: params.eventOnValid,
			options: {
				cancelLabel: I18n.t('general.no'),
				confirmLabel: I18n.t('general.yes'),
				...params.options,
			},
		};
	} else if (type === 'info') {
		const { confirm } = yield select();
		let _text = '';
		if (confirm.show) {
			_text = `${confirm.text} \n ${text}`;
		} else {
			_text = text;
		}

		action = {
			...action,
			...params,
			text: _text,
			eventOnValid: params.eventOnValid,
			options: {
				title: I18n.t('general.information'),
				confirmLabel: I18n.t('general.close'),
				hideCancel: true,
				...params.options,
			},
		};
	}

	yield put(action);
}

export default function* confirmReduxSaga() {
	yield all([validConfirm(), cancelConfirm()]);
}
