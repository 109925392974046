import React, { Component, PureComponent } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TabInfoRestau from './Components/TabInfoRestau';
import TabDates from './Components/TabDates';
import TabPictures from './Components/TabPictures';
import { I18n } from 'react-redux-i18n';
import * as actions from './service/actions';
import * as color from '../../constant';
import { compareObjects, confirm } from '../../utils';
import HeaderTabDates from './Components/Header/TabDates';
import HeaderTabInfoRestau from './Components/Header/TabInfoRestau';
import HeaderTabPictures from './Components/Header/TabPictures';
import { withSnackbar } from 'notistack';
import { deepEqual } from '../Partners/utils';

const TABS = { INFORESTAU: 'INFORESTAU', DATES: 'DATES', PICTURES: 'PICTURES' };

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}
TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	selected: {
		color: color.RED_ADDITION,
	},
	indicator: {
		backgroundColor: color.RED_ADDITION,
	},
});

class Info extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: TABS.INFORESTAU,
			dates: {},
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (Object.keys(props.info).length > 0) {
			if (props.info.infoRestau) {
				if (props.info.infoRestau.opening_date) {
					if (!deepEqual(props.info.infoRestau.opening_date, state.dates)) {
						return {
							dates: props.info.infoRestau.opening_date,
						};
					}
				}
				return null;
			}
		}
		return null;
	}

	handleTabChange = value => {
		/* event.preventDefault(); */
		this.setState({ currentTab: value });
	};

	onUpdateInfo = (infoRestau, address) => {
		if (infoRestau.hasOwnProperty('id')) {
			const changementsAddress = compareObjects(
				this.props.info.address,
				address
			);
			const changementsInfo = compareObjects(
				this.props.info.infoRestau,
				infoRestau
			);

			if (Object.keys(changementsAddress).length) {
				changementsAddress.id = address.id;
				this.props.action.patchAddress(changementsAddress);
			}
			if (Object.keys(changementsInfo).length) {
				changementsInfo.id = this.props.info.infoRestau.id;
				this.props.action.patchInfo(changementsInfo);
			}
		} else {
			this.props.action.addInfoAndAddress(infoRestau, address);
		}
		this.showSuccess();
	};

	onAddPicture = picture => {
		if (picture) {
			this.props.action.addPicture(picture);
		}
	};

	onAddPictureReused = picture => {
		if (picture) {
			this.props.action.addPictureReused(picture);
		}
	};

	onDeletePicture = idPicture => {
		if (idPicture) {
			this.props.action.removePicture(idPicture);
		}
	};

	compareOldDataWithNewData = (oldArray, newArray) => {
		const changes = [];
		oldArray.forEach((item, index) => {
			const changement = compareObjects(oldArray[index], newArray[index]);
			if (Object.keys(changement).length !== 0) {
				changes.push({ ...changement, id: item.id });
			}
		});
		return changes;
	};

	onUpdatePicture = data => {
		let finalArray = this.props.info.picture.map(oldItem => {
			return data.find(newItem => oldItem.id === newItem.id);
		});
		const update = this.compareOldDataWithNewData(
			this.props.info.picture,
			finalArray
		);
		if (update && update.length) {
			update.forEach(upd => {
				this.props.action.patchPicture(upd);
			});
		}
	};

	onUpdateDates = data => {
		const formatedNewData = Object.keys(data).reduce((acc, day) => {
			let morning = data[day]['morning']
				? { morning: data[day]['morning'] }
				: false;
			let evening = data[day]['evening']
				? { evening: data[day]['evening'] }
				: false;

			acc[day] = [];
			if (morning) {
				acc[day].push(morning);
			}
			if (evening) {
				acc[day].push(evening);
			}
			return acc;
		}, {});

		this.props.action.patchInfo({
			id: this.props.info.infoRestau.id,
			opening_date: formatedNewData,
		});
		this.showSuccess();
	};

	onRemoveClosingDate = id => {
		confirm(I18n.t('info.dates.confirm_delete')).then(() => {
			this.props.action.removeClosingDate(id);
			this.showSuccess();
		});
	};

	onUpdateClosingDates = data => {
		this.props.action.patchClosingDate(data);
	};

	onAddClosingDates = closingDate => {
		if (closingDate) {
			this.props.action.addClosingDate(closingDate);
			this.showSuccess();
		}
	};

	showSuccess = () => {
		this.props.enqueueSnackbar(I18n.t('info.changements_success'), {
			variant: 'success',
		});
	};

	render() {
		const { classes, info } = this.props;
		const { currentTab } = this.state;
		const restaurantId = this.props.info.infoRestau
			? this.props.info.infoRestau.id
			: '';
		return (
			<div className="tabs-container">
				<div className={classes.root}>
					<ul className="header-container">
						<li
							className={
								this.state.currentTab === 'INFORESTAU' ? 'active' : ''
							}>
							<a onClick={() => this.handleTabChange(TABS.INFORESTAU)}>
								<HeaderTabInfoRestau
									color={
										this.state.currentTab === 'INFORESTAU'
											? '#e84249'
											: '#7e8198'
									}
								/>
								<p
									className={
										this.state.currentTab === 'INFORESTAU'
											? 'red-text'
											: 'grey-text'
									}>
									{I18n.t('info.title')}
								</p>
								<hr />
							</a>
						</li>
						<li
							className={
								this.state.currentTab === 'DATES'
									? 'active'
									: restaurantId
									? ''
									: 'disabled-section'
							}>
							<a onClick={() => this.handleTabChange(TABS.DATES)}>
								<HeaderTabDates
									color={
										this.state.currentTab === 'DATES' ? '#e84249' : '#7e8198'
									}
								/>
								<p>{I18n.t('info.dates.open_time')}</p>
								<hr />
							</a>
						</li>
						<li
							className={
								this.state.currentTab === 'PICTURES'
									? 'active'
									: restaurantId
									? ''
									: 'disabled-section'
							}>
							<a onClick={() => this.handleTabChange(TABS.PICTURES)}>
								<HeaderTabPictures
									color={
										this.state.currentTab === 'PICTURES' ? '#e84249' : '#7e8198'
									}
								/>
								<p>{I18n.t('info.picture.title')}</p>
								<hr />
							</a>
						</li>
					</ul>
					{/* Informations du restaurant */}
					{currentTab === TABS.INFORESTAU && (
						<TabContainer>
							<TabInfoRestau
								address={info.address}
								infoRestau={info.infoRestau}
								onUpdateInfo={this.onUpdateInfo}
							/>
						</TabContainer>
					)}
					{/* Plages horaires */}
					{currentTab === TABS.DATES && (
						<TabContainer>
							<TabDates
								openingHours={this.state.dates}
								closingDate={info.closingDate}
								onUpdateOpeningDate={this.onUpdateDates}
								onRemoveClosingDate={this.onRemoveClosingDate}
								onUpdateClosingDates={this.onUpdateClosingDates}
								onAddClosingDates={this.onAddClosingDates}
							/>
						</TabContainer>
					)}
					{/* Photos du restaurant */}
					{currentTab === TABS.PICTURES && (
						<TabContainer>
							<TabPictures
								pictures={info.picture}
								onAddPicture={this.onAddPicture}
								onAddPictureReused={this.onAddPictureReused}
								onUpdatePicture={this.onUpdatePicture}
								onDeletePicture={this.onDeletePicture}
							/>
						</TabContainer>
					)}
				</div>
			</div>
		);
	}
}

Info.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return { info: state.info };
};

const mapDispatchToProps = dispatch => {
	return { action: bindActionCreators(actions, dispatch) };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(withStyles(styles)(Info)));
