import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	changeProp,
	saveExtraProducts,
	deleteProductExtra,
} from '../../../../../../../../../service/export/actions';
import { addPicture } from '../../../../../../../../../../Info/service/actions';
import ImageModal from '../../../../../../../../../../../Components/EditPopup';
import { I18n } from 'react-redux-i18n';
import { PropTypes } from 'prop-types';
import Card from './Card';
import { confirm } from '../../../../../../../../../../../utils';
import Modal from '../../../../../../../../../../../Components/Modal';

class InputForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openEditModal: false,
			openModal: false,
		};
	}

	onChangePicture = (path, path_cropped, name, description, cropProperties) => {
		this.closeModal();
		this.save(path, path_cropped, cropProperties);
	};

	onSetProductExtraExistedPicture = picture => {
		this.closeModal();
		this.props.onChange(this.props.name, picture.path_cropped);
		this.props.saveExtraProducts(
			this.props.idExport,
			this.props.idSection,
			this.props.idProduct || this.props.product.id_product,
			{
				image: picture.id,
			}
		);
	};

	onDelete = () => {
		confirm(I18n.t('partner.productForm.confirm_delete_image')).then(() => {
			this.props.deleteProductExtra({
				idExport: this.props.idExport,
				idProduct: this.props.idProduct || this.props.product.id_product,
				idSection: this.props.idSection,
				key_: 'image',
			});
			this.props.onChange(this.props.name, null);
		});
	};

	save = (path, path_cropped, cropProperties) => {
		this.props.onChange(this.props.name, path_cropped);
		const picture = {
			name: this.props.selectorProduct.name,
			path,
			path_cropped,
			weight: 0,
			description: '',
			crop_properties: cropProperties,
			env: 'product',
			id_product: this.props.idProduct || this.props.product.id_product,
			id_section: this.props.idSection,
			id_export: this.props.idExport,
		};
		this.props.addPicture(picture);
		//this.props.addPicture()
		/*this.props.save(
      this.props.idExport,
      this.props.idSection,
      this.props.idProduct,
      { image: path_cropped }
    );*/
	};

	openModal = e => {
		this.setState({ openEditModal: true });
	};

	closeModal = e => {
		this.setState({ openEditModal: false });
	};

	onClickImage = e => {
		this.setState({ openModal: true });
	};

	closeViewModal = e => {
		this.setState({ openModal: false });
	};

	render() {
		let src = '';
		if (this.props.form[this.props.name] > 0) {
			let picture = this.props.pictures.find(pic => {
				return pic.id == this.props.form[this.props.name];
			});
			src = picture.path_cropped;
		} else {
			src = this.props.form[this.props.name];
		}
		return (
			<React.Fragment>
				<p
					className={
						this.props.toComplete
							? 'form-product-title pastille-after'
							: 'form-product-title'
					}>
					{I18n.t(`partner.productForm.${this.props.name}`)}{' '}
				</p>
				{this.props.form[this.props.name] ? (
					<Card
						src={src || ''}
						onDelete={this.onDelete}
						onClick={this.onClickImage}
					/>
				) : (
					<div>
						<p>{this.props.description || ''}</p>
						<button
							className={'form-product-image-import'}
							onClick={this.openModal}>
							{I18n.t('partner.productForm.import')}
						</button>
						<ImageModal
							onSubmit={this.onChangePicture}
							src={src || ''}
							openModal={this.state.openEditModal}
							closeModal={this.closeModal}
							displayMetaData={false}
							onSetExistedPicture={this.onSetProductExtraExistedPicture}
							{...this.props.imageModal}
						/>
					</div>
				)}
				<Modal
					src={this.props.form[this.props.name]}
					openModal={this.state.openModal}
					closeModal={this.closeViewModal}
				/>
			</React.Fragment>
		);
	}
}

InputForm.propTypes = {
	name: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
	return {
		form: state.exports.formProduct,
		pictures: state.info.pictures,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: bindActionCreators(changeProp, dispatch),
		//save: bindActionCreators(saveExtraProducts, dispatch),
		addPicture: bindActionCreators(addPicture, dispatch),
		deleteProductExtra: bindActionCreators(deleteProductExtra, dispatch),
		saveExtraProducts: bindActionCreators(saveExtraProducts, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InputForm);
