import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { checkUUIDV4 } from '../../../../../service/utils';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import {
	checkIfPartnerCustomerExist,
	initApiData,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'pongo';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_PONGO');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'set_api_data';
			bodySetStep.step = 'set_api_data';
			success = true;
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				bodySetStep.step = 'set_customer_id';
			}
		}

		if (currentStep === 'set_customer_id') {
			if (checkUUIDV4(data.customer_id)) {
				yield call(handlePatchVariable, {
					key: 'pongoHash',
					value: data.customer_id,
				});

				external_id = data.customer_id;

				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';

				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
			} else {
				errorMessage = 'partner.onboarding_common_errors.input_validator';
			}
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_api_mask';
					bodySetStep.step = 'set_api_mask';
					success = true;
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.status = 'actif';
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_PONGO_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
