import React, { Component } from 'react';
import './../style.scss';

class HeaderTabDates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: props.color,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.color !== prevState.color) {
			return {
				color: nextProps.color,
			};
		}
		return null;
	}

	render() {
		return (
			<svg
				width="15px"
				height="18px"
				viewBox="0 0 15 18"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				className="dates-icon">
				<title>icon / calendar</title>
				<desc>Created with Sketch.</desc>
				<g
					id="Réservation-Desktop"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd">
					<g
						id="Réservation_Client_04"
						transform="translate(-152.000000, -385.000000)">
						<g id="form" transform="translate(72.000000, 378.000000)">
							<g id="timeline">
								<g id="jour">
									<g
										id="icon-/-calendar"
										transform="translate(80.000000, 7.000000)">
										<g>
											<path
												d="M1,3.34210526 C0.723857625,3.34210526 0.5,3.56596289 0.5,3.84210526 L0.5,17 C0.5,17.2761424 0.723857625,17.5 1,17.5 L14,17.5 C14.2761424,17.5 14.5,17.2761424 14.5,17 L14.5,3.84210526 C14.5,3.56596289 14.2761424,3.34210526 14,3.34210526 L1,3.34210526 Z"
												id="Rectangle-2"
												stroke={this.state.color}
											/>
											<rect
												id="Rectangle-4"
												fill={this.state.color}
												x="0.9375"
												y="6.63157895"
												width="13.125"
												height="1"
											/>
											<rect
												id="Rectangle-4"
												fill={this.state.color}
												x="2.8125"
												y="9.47368421"
												width="1.875"
												height="1.89473684"
											/>
											<polygon
												id="Rectangle-4"
												fill={this.state.color}
												points="2.8125 13.2631579 4.6875 13.2631579 4.6875 15.1578947 2.8125 15.1578947"
											/>
											<polygon
												id="Rectangle-4-Copy"
												fill={this.state.color}
												points="10.3125 13.2631579 12.1875 13.2631579 12.1875 15.1578947 10.3125 15.1578947"
											/>
											<rect
												id="Rectangle-4"
												fill={this.state.color}
												x="6.5625"
												y="9.47368421"
												width="1.875"
												height="1.89473684"
											/>
											<path
												d="M1.875,0 L1.875,0 C2.39276695,-9.51122462e-17 2.8125,0.419733047 2.8125,0.9375 L2.8125,2.84210526 L0.9375,2.84210526 L0.9375,0.9375 C0.9375,0.419733047 1.35723305,9.51122462e-17 1.875,0 Z"
												id="Rectangle-3"
												fill={this.state.color}
											/>
											<path
												d="M5.625,0 L5.625,0 C6.14276695,-9.51122462e-17 6.5625,0.419733047 6.5625,0.9375 L6.5625,2.84210526 L4.6875,2.84210526 L4.6875,0.9375 C4.6875,0.419733047 5.10723305,9.51122462e-17 5.625,0 Z"
												id="Rectangle-3"
												fill={this.state.color}
											/>
											<path
												d="M9.375,0 L9.375,0 C9.89276695,-9.51122462e-17 10.3125,0.419733047 10.3125,0.9375 L10.3125,2.84210526 L8.4375,2.84210526 L8.4375,0.9375 C8.4375,0.419733047 8.85723305,9.51122462e-17 9.375,0 Z"
												id="Rectangle-3"
												fill={this.state.color}
											/>
											<path
												d="M13.125,0 L13.125,0 C13.642767,-9.51122462e-17 14.0625,0.419733047 14.0625,0.9375 L14.0625,2.84210526 L12.1875,2.84210526 L12.1875,0.9375 C12.1875,0.419733047 12.607233,9.51122462e-17 13.125,0 Z"
												id="Rectangle-3"
												fill={this.state.color}
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}

export default HeaderTabDates;
