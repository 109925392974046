import { buffers } from 'redux-saga';
import { actionChannel, all, call, put, take } from 'redux-saga/effects';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { RECEIVE_EXTERNAL_DATA } from './reducer';

const ENTITY = 'externalData';
function* patchExternalData() {
	const requestChannel = yield actionChannel(
		'PRE_PATCH_EXTERNAL_DATA',
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		const result = yield call(requestHandler, ENTITY, 'POST', action.data);
		if (result && result.result) {
			yield put({
				type: RECEIVE_EXTERNAL_DATA,
				data: result.result,
			});
		}
	}
}

export default function* indexSaga() {
	yield all([patchExternalData()]);
}
