import { call, take, all } from 'redux-saga/effects';
import { requestHandler } from '../../../service/fetcher.saga';

function* reportError() {
	while (true) {
		const { data } = yield take('REPORT_ERROR');
		yield call(requestHandler, 'error', 'POST', data, 'report');
	}
}

export default function* indexSaga() {
	yield all([reportError()]);
}
