import {
  REMOVE_PICTURE_ONLINE,
  PATCH_PICTURE_ONLINE,
  ADD_PICTURE_ONLINE,
  RECEIVE_VARIABLE,
  RECEIVE_PICTURES_ONLINE,
  ADD_PICTURE_ONLINE_REUSED,
  INIT_LOAD_QR_CODE,
  LOAD_QR_CODE,
} from "./types";

const initialState = { variable: { loadingQrCode: false } };
const reducerMenu = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case PATCH_PICTURE_ONLINE:
      if (action.patch) {
        if (newState.picture.length > 0) {
          newState.picture = newState.picture.map((pic) => {
            if (action.patch.id === pic.id) {
              let picWithNewWeight = { ...pic };
              picWithNewWeight.weight = action.patch.weight;
              return picWithNewWeight;
            } else {
              return pic;
            }
          });
        } else {
          return action.patch;
        }
      }
      return newState;
    case REMOVE_PICTURE_ONLINE:
      newState.picture = newState.picture.filter(
        (picture) => picture.id !== action.id
      );
      return newState;
    case ADD_PICTURE_ONLINE:
      if (action.picture) {
        newState.picture = [...newState.picture, action.picture];
      }
      return newState;
    case ADD_PICTURE_ONLINE_REUSED:
      if (action.picture) {
        newState.picture = [...newState.picture, action.picture];
      }
      return newState;
    case RECEIVE_VARIABLE:
      if (action.data) {
        newState.variable = action.data;
      }
      return newState;
    case RECEIVE_PICTURES_ONLINE:
      if (action.data) {
        newState.picture = action.data;
      }
      return newState;
    case LOAD_QR_CODE:
      newState.loadingQrCode = true;
      if (action.result) {
        const { b64, type } = action.result;
        newState.qrcode = {
          b64,
          type,
        };
      }
      return newState;
    case INIT_LOAD_QR_CODE:
      newState.loadingQrCode = false;
      return newState;
    default:
      return newState;
  }
};

export default reducerMenu;
