import React from 'react';
import { I18n } from 'react-redux-i18n';
import Button from './Button';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import './style.scss';
import config from '../../../../config';

class Config extends React.Component {
	render() {
		let partner_url_key = '';
		switch (this.props.prefix_partner) {
			case 'eviivo':
				partner_url_key = 'pms';
				break;
			default:
				partner_url_key = this.props.prefix_partner;
		}
		return (
			<div className={'partner-config-container'}>
				<h2 className={'export-title'}>
					{this.props.prefix_partner === 'celladdition'
						? I18n.t('partner.configuration_module.title_cc')
						: I18n.t('partner.configuration_module.title')}
				</h2>
				<div className={'partner-config-flex'}>
					<Button
						link={I18n.t('partner.integration_guide_pdf.' + partner_url_key)}
						outlined={true}>
						<div>
							{I18n.t('partner.configuration_module.guide')}
							<DescriptionOutlinedIcon />
						</div>
					</Button>
					{this.props.prefix_partner === 'celladdition' ? (
						<Button link={config.ADMIN_CELLADDITION}>
							<div>
								{I18n.t('partner.configuration_module.link_admin_cc')}{' '}
								<span style={{ marginLeft: 10 }}>{'>'}</span>
							</div>
						</Button>
					) : null}
				</div>
			</div>
		);
	}
}

export default Config;
