import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Input from '../../../../Components/Input';
import { I18n, Translate } from 'react-redux-i18n';
import { confirm, deepEqual } from '../../../../utils';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ParamLine from '../paramLine';
import AddButon from './addButton';
import UrlTester from './urlTester';
import { withSnackbar } from 'notistack';
import './style.scss';

class FormEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			method: '',
			url: '',
			headers: [],
			body: [],
			checkUrl: true,
			eventWebhook: {
				id: 0,
				idPartner: 0,
				name: '',
				availableParams: [],
				webhook: {},
			},
			showUrlTester: false,
			additionToken: 0,
			urlTester: true,
			checkUrlMarker: true,
			timestamp: 0,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.eventWebhook &&
			nextProps.eventWebhook.webhook &&
			!deepEqual(prevState.eventWebhook, nextProps.eventWebhook)
		) {
			let { method, url, params } = nextProps.eventWebhook.webhook;
			return {
				method: method ? method : 'POST',
				url: url ? url : '',
				headers: params ? params.filter(p => p.type === 'header') : [],
				body: params ? params.filter(p => p.type === 'params') : [],
				checkUrl: true,
				eventWebhook: nextProps.eventWebhook,
			};
		}

		return null;
	}

	onChangeMethod = event => {
		this.setState({ method: event.target.value });
	};

	onChange = event => {
		let date = new Date().getTime();
		let timestamp = date.toString().slice(-5);

		if (
			this.props.eventWebhook.webhook.url
				? this.props.eventWebhook.webhook.url !== event.target.value
				: event.target.value !== ''
		) {
			this.setState(
				{
					showUrlTester: true,
					url: event.target.value,
					urlTester: false,
					checkUrlMarker: false,
					timestamp,
				},
				this.checkUrlParams
			);
		} else {
			this.setState(
				{
					showUrlTester: false,
					checkUrlMarker: true,
					urlTester: true,
					url: event.target.value,
					timestamp,
				},
				this.checkUrlParams
			);
		}
	};

	/**
	 * Supprime un paramètre dans le state Header ou dans le state Body
	 * @param {Int} index - L'emplacement de notre paramètre dans le tableau
	 * @param {String} paramName - Le nom de la propriete contenant les paramètres dans le state
	 */
	onRemoveParam = (index, paramName) => {
		let params = this.state[paramName];
		params.splice(index, 1);
		this.setState({
			[paramName]: params,
		});
	};

	checkUrlParams = () => {
		let checkUrl = true;
		let url = this.state.url;
		if (url.indexOf(':') !== -1) {
			const items = this.getUrlParams(url);
			const availableParamsName = this.props.eventWebhook.availableParams.map(
				param => param.name
			);
			items.forEach(item => {
				if (!availableParamsName.includes(item)) {
					checkUrl = false;
				}
			});
		}
		this.setState({
			checkUrl,
		});
	};

	/**
	 * Return all params in a url - detect with ":" before
	 * exclude http:
	 * @param {String} url
	 * @returns {Object[]} array of param name (without ":")
	 */
	getUrlParams = url => {
		let params = [];
		let index = url.indexOf('//');
		if (index !== -1) {
			url = url.slice(index, url.length);
		}
		if (url.indexOf(':') !== -1) {
			const urlTable = url.split('/');
			params = urlTable.reduce((acc, item) => {
				if (item.indexOf(':') !== -1 && item.indexOf('http') == -1) {
					acc.push(item.substring(1));
				}
				return acc;
			}, params);
		}
		return params;
	};

	validForm = e => {
		e.preventDefault();

		if (!this.state.urlTester) {
			this.props.enqueueSnackbar(I18n.t('partner.invalid_url'), {
				variant: 'warning',
			});
			return;
		}

		let urlParamsFromPropsNames = [];
		let urlParamsFromProps = [];

		if (this.props.eventWebhook.id_webhook) {
			urlParamsFromProps = this.props.eventWebhook.webhook.params.filter(
				p => p.type === 'url'
			);

			urlParamsFromPropsNames = urlParamsFromProps.map(p => {
				let availableParam = this.props.eventWebhook.availableParams.find(
					ap => ap.id === p.id_webhook_params
				);
				return availableParam.name;
			});
		}

		// Récupération et comparaison des parametres de l'url avec les params urls de l'origine

		let urlParams = this.getUrlParams(this.state.url);

		urlParams.forEach(param => {
			if (!urlParamsFromPropsNames.includes(param)) {
				let availableParam = this.props.eventWebhook.availableParams.find(
					ap => ap.name === param
				);

				if (availableParam) {
					urlParamsFromProps.push({
						id: null,
						id_webhook_params: availableParam.id,
						key: '',
						value: '',
						type: 'url',
					});
				}
			}
		});

		let paramsToDelete = urlParamsFromPropsNames.reduce((acc, param) => {
			if (!urlParams.includes(param)) {
				acc.push(param);
			}
			return acc;
		}, []);
		let idsParamsToDelete = paramsToDelete.map(param => {
			let availableParam = this.props.eventWebhook.availableParams.find(
				ap => ap.name === param
			);
			return availableParam.id;
		});

		let urlParamsToSend = urlParamsFromProps.filter(
			param => !idsParamsToDelete.includes(param.id_webhook_params)
		);

		let paramsToSend = [...this.state.headers, ...urlParamsToSend];
		if (this.state.method != 'GET') {
			paramsToSend.push(...this.state.body);
		}

		this.closeModal();

		this.props.onSubmit({
			idWebhook: this.props.eventWebhook.id_webhook
				? this.props.eventWebhook.id_webhook
				: null,
			event: this.props.eventWebhook.event,
			url: this.state.url,
			method: this.state.method,
			params: paramsToSend,
		});
	};

	resetWebhook = e => {
		e.preventDefault();
		confirm(I18n.t('webhook.delete_confirm')).then(() => {
			this.props.onDelete(this.props.eventWebhook.id_webhook);
		});
	};

	/**
	 * Mis à jour du tableau de paramètre correspondant dans notre state
	 * @param {String} key - Propriété du paramètre
	 * @param {String} value - Value du paramètre
	 * @param {Int} id_webhook_params - Identifiant du paramètre disponible
	 * @param {Int} index - Emplacement de notre paramètre dans le tableau
	 * @param {paramName} - Le nom de la propriete contenant les paramètres dans le state
	 */
	onChangeParam = (key, value, id_webhook_params, index, paramName) => {
		const params = this.state[paramName].map((param, i) => {
			if (index === i) {
				param.key = key;
				param.value = value;
				param.id_webhook_params = id_webhook_params;
			}
			return param;
		});

		this.setState({ [paramName]: params });
	};

	addParam = (stateProp, type) => {
		this.setState({
			[stateProp]: [
				...this.state[stateProp],
				{
					id: null,
					id_webhook_params: null,
					key: '',
					value: '',
					type,
				},
			],
		});
	};

	addParamBody = e => {
		e.preventDefault();
		this.addParam('body', 'params');
	};

	addParamHeader = e => {
		e.preventDefault();
		this.addParam('headers', 'header');
	};

	sendTestRequest = async () => {
		let headers = this.parseParam(this.state.headers);

		let params = this.parseParam(this.state.body);

		let body = {
			url: this.state.url,
			method: this.state.method,
			params: params,
			headers: headers,
			timestamp: this.state.timestamp,
		};

		this.props.onTestUrl(body);
	};

	hashString = content => {
		var hash = 0;
		if (content.length == 0) return hash;
		for (let i = 0; i < content.length; i++) {
			let char = content.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash = hash & hash; // Convert to 32bit integer
		}
		return hash;
	};

	parseParam = table => {
		let result = table.reduce((acc, row) => {
			if (!row.id_webhook_params) {
				acc[row.key] = row.value;
			}
			return acc;
		}, {});
		return result;
	};

	validTokenForm = value => {
		let hash = this.hashString(this.state.timestamp);
		if (value == hash) {
			this.setState({
				urlTester: true,
				checkUrlMarker: true,
				timestamp: 0,
				showUrlTester: false,
			});

			this.props.enqueueSnackbar(I18n.t('partner.valid_token'), {
				variant: 'success',
			});
		} else {
			this.props.enqueueSnackbar(I18n.t('partner.invalid_token'), {
				variant: 'warning',
			});
		}
	};

	closeModal = () => {
		this.setState({
			showUrlTester: false,
			urlTester: true,
			checkUrlMarker: true,
		});
	};

	onCancel = () => {
		this.props.onCancel();
		this.closeModal();
	};

	render() {
		const { method, url } = this.state;
		const { eventWebhook } = this.state;
		let checkParams = true;
		[...this.state.headers, ...this.state.body].forEach(param => {
			if (param.id_webhook_params === null && param.value === '') {
				checkParams = false;
			}
			if (param.key === '') {
				checkParams = false;
			}
		});
		const disabledButton =
			this.state.method === '' ||
			this.state.url === '' ||
			!checkParams ||
			!this.state.urlTester;

		return (
			<div className="event-container">
				<div className="webhook-action">
					<Button onClick={this.onCancel}>{I18n.t('general.cancel')}</Button>
					<Button
						variant={'contained'}
						color={'primary'}
						disabled={disabledButton}
						size={'large'}
						className={'button-bold'}
						onClick={this.validForm}>
						{I18n.t('general.valid')}
					</Button>
				</div>
				<div>
					<form>
						<h2 className={'webhook-section-title'}>
							{I18n.t('partner.webhook')}
						</h2>
						<div>
							<FormControl>
								<InputLabel
									shrink
									htmlFor={`select-method-for-${eventWebhook.id}`}
									style={{ position: 'relative', top: 10 }}>
									{I18n.t('partner.method')}
								</InputLabel>
								<Select
									value={method}
									onChange={this.onChangeMethod}
									inputProps={{ id: `select-method-for-${eventWebhook.id}` }}
									className={'webhook-input-method'}>
									{['GET', 'POST', 'PATCH', 'PUT'].map(method => (
										<MenuItem
											key={`${eventWebhook.id}#${method}`}
											value={method}>
											{method}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<Input
								inputProps={{ size: '120' }}
								type="text"
								value={url}
								onChange={this.onChange}
								label={I18n.t('partner.url')}
								error={
									this.state.checkUrl ? '' : I18n.t('partner.error_param_url')
								}
								errorClasses={'input-error-label-fullwidth'}
							/>
							{this.state.checkUrlMarker ? (
								<CheckIcon className="checked-icon" />
							) : null}
						</div>

						<UrlTester
							open={this.state.showUrlTester}
							sendTestRequest={this.sendTestRequest}
							validTokenForm={this.validTokenForm}
						/>
						<div style={{ display: 'flex' }}>
							{/*HEADER & BODY */}
							<div className={'webhook-header-body'}>
								<div className={'webhook-section'}>
									<h2 className={'webhook-section-title'}>
										{I18n.t('partner.headers')}
									</h2>

									{this.state.headers.map((header, index) => (
										<ParamLine
											key={index}
											displayLabel={index === 0}
											paramKey={header['key']}
											paramValue={header['value']}
											id_webhook_params={header.id_webhook_params}
											availableParams={eventWebhook.availableParams}
											onChange={(key, value, id_webhook_params) => {
												this.onChangeParam(
													key,
													value,
													id_webhook_params,
													index,
													'headers'
												);
											}}
											onDelete={() => {
												this.onRemoveParam(index, 'headers');
											}}
										/>
									))}
									<AddButon onClick={this.addParamHeader} />
								</div>
								<div
									className={
										this.state.method != 'GET'
											? 'webhook-section'
											: 'display-none webhook-section'
									}>
									<h2 className={'webhook-section-title'}>
										{I18n.t('partner.params')}
									</h2>

									{this.state.body.map((body, index) => (
										<ParamLine
											key={index}
											displayLabel={index === 0}
											paramKey={body['key']}
											paramValue={body['value']}
											id_webhook_params={body.id_webhook_params}
											availableParams={eventWebhook.availableParams}
											onChange={(key, value, id_webhook_params) => {
												this.onChangeParam(
													key,
													value,
													id_webhook_params,
													index,
													'body'
												);
											}}
											onDelete={() => {
												this.onRemoveParam(index, 'body');
											}}
										/>
									))}
									<AddButon onClick={this.addParamBody} />
								</div>
							</div>
							{/*PARAMS */}
							<div className={'webhook-params'}>
								<h2 className={'webhook-section-title'}>
									{I18n.t(`partner.parameter`)}
								</h2>
								<p className={'webhook-param-description'}>
									<Translate value={'partner.param_description'} />
								</p>

								<div className={'webhook-param webhook-param-title'}>
									<p>{I18n.t(`partner.variable_description`)}</p>
									<p>{I18n.t(`partner.variable`)}</p>
								</div>
								{eventWebhook.availableParams.map(availableParam => {
									return (
										<div className={'webhook-param'} key={availableParam.id}>
											<p>
												{I18n.t(
													`webhook.availableParams.${availableParam.name}`
												)}
											</p>
											<p>:{availableParam.name}</p>
										</div>
									);
								})}
							</div>
						</div>
					</form>
				</div>

				<div>
					<Button
						color={'primary'}
						onClick={this.resetWebhook}
						className={'webhook-delete button-bold'}>
						{I18n.t('partner.button_delete')}
					</Button>
				</div>
			</div>
		);
	}
}

FormEvent.propTypes = {
	key: PropTypes.string,
	eventWebhook: PropTypes.object.isRequired,
	onSubmit: PropTypes.func,
	onDelete: PropTypes.func,
};

export default withSnackbar(FormEvent);
