import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

export default function Hint({ label }) {
	return (
		<Tooltip title={label || ''}>
			<SvgIcon aria-label="info-hint">
				<InfoIcon />
			</SvgIcon>
		</Tooltip>
	);
}
