import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';

import { connect } from 'react-redux';
import { confirm, getVariableServer } from '../../../../utils';
import ChannelList from './Components/ChannelList';
import BrandList from './Components/BrandList';
import AddButton from './Components/AddButton';
import { bindActionCreators } from 'redux';
import { patchVariable } from '../../../VariableServer/service/actions';
import { notify } from '../../../../Components/Notifier/services/actions';
import { Chip, Tooltip } from '@material-ui/core';

class BrandChanConfiguration extends Component {
	onAddItem = (type, id, id_brand) => {
		let newConf = this.props.orderConfigChannel
			? JSON.parse(JSON.stringify(this.props.orderConfigChannel))
			: {};

		if (type === 'brand') {
			let brand = this.props.partnerData.brands.find(b => b.id == id);
			if (!newConf.brand) newConf.brand = {};
			newConf.brand[id] = { id, name: brand ? brand.name : '' };
		} else {
			let chanFromData =
				this.props.partnerData.channels.find(c => c.id == id) || {};
			let newChannel = {
				id_table: null,
				id_price_category: null,
				id_payment_type: null,
				name: chanFromData ? chanFromData.name : null,
			};
			if (id_brand) {
				if (!newConf.brand[id_brand].hasOwnProperty('channel')) {
					newConf.brand[id_brand].channel = {};
				}
				if (!newConf.brand) newConf.brand = {};
				newConf.brand[id_brand].channel[id] = newChannel;
			} else {
				if (!newConf.channel) newConf.channel = {};
				newConf.channel[id] = newChannel;
			}
		}
		this.save(newConf);
	};

	onDeleteItem = (type, id, id_brand) => {
		confirm(I18n.t('partner.brandChannel.delete_confirmation')).then(() => {
			let newConf = JSON.parse(JSON.stringify(this.props.orderConfigChannel));
			if (id_brand) {
				delete newConf['brand'][id_brand].channel[id];
				if (
					Object.keys(delete newConf['brand'][id_brand].channel).length === 0
				) {
					delete newConf['brand'][id_brand].channel;
				}
			} else {
				delete newConf[type][id];
			}
			this.save(newConf);
		});
	};

	onChangeChannel = (key, value, id_channel, id_brand) => {
		let newConf = JSON.parse(JSON.stringify(this.props.orderConfigChannel));

		let mandatoryFields = [];
		// mandatoryFields.push('id_table')

		if (id_brand) {
			if (value) {
				newConf.brand[id_brand].channel[id_channel][key] = value;
			} else {
				newConf.brand[id_brand].channel[id_channel][key] = null;
			}
		} else {
			//mandatoryFields.push('id_payment_type');
			if (value) {
				newConf.channel[id_channel][key] = value;
			} else {
				newConf.channel[id_channel][key] = null;
			}
		}
		if (mandatoryFields.includes(key) && !value) {
			this.props.notify(
				I18n.t('partner.brandChannel.mandatory_field'),
				'warning'
			);
			return;
		}
		this.save(newConf);
	};

	save = json => {
		this.props.patch({
			key: this.props.prefix + 'OrderConfigChannel',
			value: JSON.stringify(json),
		});
	};

	render() {
		const { orderConfigChannel, partnerData, asso_disable } = this.props;

		if (asso_disable == 1) {
			return null;
		}

		if (!partnerData || (partnerData && partnerData.channels.length === 0)) {
			return (
				<h3 className={'export-title'}>
					{I18n.t('partner.brandChannel.error_empty')}
				</h3>
			);
		}

		return (
			<div id="brand-channel-container">
				{this.props.prefix === 'deliverect' ? (
					<>
						<h3 className={'export-title'}>
							{I18n.t('partner.brandChannel.title')}
						</h3>
						<h3 className={'export-title'}>
							{I18n.t('partner.brandChannel.title_channel')}
							<span style={{ marginLeft: 10 }}>
								<Tooltip title={I18n.t('partner.brandChannel.channel_tooltip')}>
									<Chip label="?" />
								</Tooltip>
							</span>
							<AddButton
								list={partnerData.channels}
								selected={Object.keys(orderConfigChannel.channel || {})}
								onAdd={id => {
									this.onAddItem('channel', id);
								}}>
								{I18n.t('general.add')}
							</AddButton>
						</h3>

						<ChannelList
							list={orderConfigChannel.channel || {}}
							channels={partnerData.channels}
							onDelete={(...params) => this.onDeleteItem(...params)}
							onChangeChannel={(...params) => {
								this.onChangeChannel(...params);
							}}
						/>
					</>
				) : (
					''
				)}

				<h3 className={'export-title'}>
					{I18n.t('partner.brandChannel.title_brand')}
					<span style={{ marginLeft: 10 }}>
						<Tooltip title={I18n.t('partner.brandChannel.brand_tooltip')}>
							<Chip label="?" />
						</Tooltip>
					</span>

					<AddButton
						list={partnerData.brands}
						selected={Object.keys(orderConfigChannel.brand || {})}
						onAdd={id => {
							this.onAddItem('brand', id);
						}}>
						{I18n.t('general.add')}
					</AddButton>
				</h3>
				<BrandList
					list={orderConfigChannel.brand || {}}
					channels={partnerData.channels}
					onAddChannel={(id, id_brand) => {
						this.onAddItem('channel', id, id_brand);
					}}
					onDelete={(...params) => {
						this.onDeleteItem(...params);
					}}
					onChangeChannel={(...params) => {
						this.onChangeChannel(...params);
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const data = state.partnersData[props.prefix];
	return {
		partnerData: data,
		orderConfigChannel: JSON.parse(
			getVariableServer(
				state.variableServer,
				props.prefix + 'OrderConfigChannel'
			)
		),
		asso_disable: getVariableServer(
			state.variableServer,
			'partner_asso_disable'
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		patch: bindActionCreators(patchVariable, dispatch),
		notify: bindActionCreators(notify, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BrandChanConfiguration);
