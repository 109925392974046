import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { List, Divider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Collapse } from 'react-collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { PropTypes } from 'prop-types';
import Supplement from './Supplement';
import './style.scss';
import { deepClone } from '../../../../../../../../../../utils';
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from 'react-sortable-hoc';
import MenuIcon from '@material-ui/icons/Menu';
import arrayMove from 'array-move';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const DragHandle = sortableHandle(() => (
	<span className="drag-category-icon">
		<MenuIcon className="menu-icon" />
	</span>
));

const SortableItem = sortableElement(({ value }) => (
	<li className="list-item">{value}</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul className="category-list-container">{children}</ul>;
});

const DragProductHandle = sortableHandle(() => (
	<span>
		<MenuIcon className="menu-product-icon" />
	</span>
));

const SortableProductItem = sortableElement(({ value }) => (
	<li className="list-item display-flex">{value}</li>
));

const SortableProductContainer = sortableContainer(({ children }) => {
	return <ul>{children}</ul>;
});

class CategorySupplement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			categoryOpen: 0,
			categoriesActive: [],
			categoriesEmpty: [],
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let categoriesActive = [];
		let categoriesEmpty = [];
		nextProps.list.map(category => {
			const find = nextProps.selected.find(act => {
				return act.id === category.id;
			});
			let categoryStore = deepClone(category);

			if (find) {
				if (nextProps.categoryExtra.hasOwnProperty(categoryStore.id)) {
					categoryStore = CategorySupplement.mergeElements(
						categoryStore,
						nextProps.categoryExtra[categoryStore.id]
					);
				}

				categoryStore.data = [...find.data];
				categoryStore.minimum = find.minimum;
				categoryStore.maximum = find.maximum;
				categoryStore.weight = find.weight;
				categoriesActive.push(categoryStore);
			} else {
				if (nextProps.categoryExtra.hasOwnProperty(categoryStore.id)) {
					categoryStore = CategorySupplement.mergeElements(
						categoryStore,
						nextProps.categoryExtra[categoryStore.id]
					);
				}
				categoriesEmpty.push(categoryStore);
			}
		});

		categoriesActive = categoriesActive.map(activeCat => {
			const reweightedCat = prevState.categoriesActive.find(
				c => c.id === activeCat.id
			);
			return {
				...activeCat,
				weight: reweightedCat ? reweightedCat.weight : activeCat.weight,
			};
		});

		return {
			categories: CategorySupplement.sortByWeight(nextProps.list),
			categoriesActive: CategorySupplement.sortByWeight(categoriesActive),
			categoriesEmpty: CategorySupplement.sortByWeight(categoriesEmpty),
		};
	}

	static mergeElements = (element1, element2) => {
		let newElement = element1;
		for (let prop in element2) {
			if (prop === 'name') {
				newElement.originalName = newElement[prop];
			}
			if (
				!newElement.hasOwnProperty(prop) ||
				(newElement.hasOwnProperty(prop) && element2[prop] !== null)
			) {
				newElement[prop] = element2[prop];
			}
		}
		return newElement;
	};

	static sortByWeight = categories => {
		let categoriesSort = categories.sort((a, b) => {
			return a.weight - b.weight;
		});
		return categoriesSort.map(cat => {
			cat.data = cat.data.sort((a, b) => {
				return a.weight - b.weight;
			});
			return cat;
		});
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		let newCategories = [...this.state.categoriesActive];
		const newTable = arrayMove([...newCategories], oldIndex, newIndex).map(
			(category, index) => {
				category.weight = index;
				const selected = {
					id: category.id,
					minimum: category.minimum,
					maximum: category.maximum,
					weight: category.weight,
					data: category.data.map(s => {
						return { ...s };
					}),
				};
				this.props.onChange(selected);
				return category;
			}
		);
		this.setState({
			categoriesActive: newTable,
		});
	};

	onProductSortEnd = (index, idCategory) => {
		let newCategories = [...this.state.categoriesActive];
		const category = newCategories.find(category => category.id === idCategory);

		if (category) {
			const newTable = arrayMove(
				category.data,
				index.oldIndex,
				index.newIndex
			).map((item, index) => {
				item.weight = index;
				return item;
			});
			category.data = newTable;

			const selected = {
				id: category.id,
				minimum: category.minimum,
				maximum: category.maximum,
				weight: category.weight,
				data: category.data.map(s => {
					return { ...s };
				}),
			};

			this.props.onChange(selected);
			this.setState({
				categoriesActive: newCategories,
			});
		}
	};

	handleToggle = (checked, idSupplement, idCategory) => {
		let selected = deepClone(
			this.props.selected.find(cat => cat.id === idCategory)
		);
		selected.data = selected.data.map(supp => {
			if (supp.id == idSupplement) {
				supp.active = checked ? 1 : 0;
			}
			return supp;
		});

		this.props.onChange(selected);
	};

	handleChangeMin = event => {
		event.stopPropagation();
		const idCategory = event.target.name;
		const selected = this.props.selected.find(cat => cat.id === idCategory);
		this.props.onChange({
			...selected,
			minimum: event.target.value,
			maximum:
				event.target.value > selected.maximum
					? event.target.value
					: selected.maximum,
		});
	};

	handleChangeMax = event => {
		event.stopPropagation();
		const idCategory = event.target.name;
		const selected = this.props.selected.find(cat => cat.id === idCategory);
		this.props.onChange({
			...selected,
			minimum:
				event.target.value < selected.minimum
					? event.target.value
					: selected.minimum,
			maximum: event.target.value,
		});
	};

	handleChangeRequired = event => {
		event.stopPropagation();
		const idCategory = event.target.getAttribute('idcategory');
		const selected = this.props.selected.find(cat => cat.id === idCategory);

		this.props.onChange({
			...selected,
			minimum: event.target.checked ? 1 : 0,
			maximum: 1,
		});
	};

	setActiveCategory = event => {
		const idCategory = event.target.getAttribute('idcategory');
		if (event.target.checked) {
			const category = this.props.list.find(cat => cat.id === idCategory);

			const lastCategory = this.state.categoriesActive.pop();

			if (category) {
				const selected = {
					id: category.id,
					minimum: category.minimum,
					maximum: category.maximum,
					weight: lastCategory ? lastCategory.weight + 1 : 0,
					data: category.data.map(s => {
						return {
							...s,
							active: s.active !== undefined ? s.active : 1,
						};
					}),
				};

				this.props.onChange(selected);
			}
		} else {
			this.props.onDeselect(idCategory);
		}
	};

	renderMenuItem = () => {
		let items = [];

		for (let i = 0; i <= 20; i++) {
			items.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		}

		return items;
	};

	onClick = id => {
		this.setState({
			categoryOpen: this.state.categoryOpen === id ? 0 : id,
		});
	};

	render() {
		let categoriesList = [];
		let productCategoryList = [];
		let categoriesEmptyList = [];
		let classNameTypeConfig = 'form-category-supplement-type-config';
		let classes = 'form-category-supplement-title';
		this.state.categoriesActive.map(category => {
			let idCategory = category.id;
			const find = this.props.selected.find(act => {
				return act.id === category.id;
			});

			let categoryStore = deepClone(category);

			if (find) {
				categoryStore = {
					...category,
					minimum: find.minimum,
					maximum: find.maximum,
					weight: find.weight,
					data: category.data.filter(supp => find.data.includes(supp.id)),
				};
				// put extra

				let data = categoryStore.data;
				data.sort((a, b) => {
					return a.weight - b.weight;
				});

				categoryStore.data = data
					? data.map((supp, index) => {
							let displayedSupp = deepClone(supp);
							//put extra
							if (
								this.props.supplementExtra.hasOwnProperty(category.id) &&
								this.props.supplementExtra[category.id].hasOwnProperty(supp.id)
							) {
								displayedSupp = {
									...displayedSupp,
									...this.props.supplementExtra[category.id][supp.id],
								};
							}
							return displayedSupp;
					  })
					: null;
			}

			productCategoryList = category.data.map((cat, index) => {
				let displayedSupp = deepClone(cat);
				let suppFinded;
				if (displayedSupp.active === 0) {
					suppFinded = 0;
				} else {
					suppFinded = 1;
				}
				//const suppFinded = displayedSupp.active ? displayedSupp.active : 1;

				//put extra
				if (
					this.props.supplementExtra.hasOwnProperty(category.id) &&
					this.props.supplementExtra[category.id].hasOwnProperty(cat.id)
				) {
					displayedSupp.originalName = displayedSupp.name;
					displayedSupp = {
						...displayedSupp,
						...this.props.supplementExtra[category.id][cat.id],
					};
				}
				const disabled = category.disabled == 1 || displayedSupp.disabled == 1;
				return (
					<div>
						<DragProductHandle />
						<Supplement
							key={index}
							supplement={displayedSupp}
							idCategory={category.id}
							checked={!!suppFinded && !disabled}
							onCheck={this.handleToggle}
							disabled={disabled}
						/>
					</div>
				);
			});

			let displayOriginalName =
				category.originalName && category.originalName !== category.name;
			categoriesList.push(
				<div key={category.id}>
					<div className={'form-category-supplement-info'}>
						<DragHandle />
						<div>
							{' '}
							<Checkbox
								checked={!!find}
								inputProps={{ idcategory: category.id }}
								onChange={this.setActiveCategory}
								disabled={category.disabled == 1}
								className="supplement-active-checkbox"
							/>
						</div>
						<div
							className="form-category-supplement-container"
							onClick={() => this.onClick(category.id)}>
							<p className={classes}>
								{category.name}{' '}
								{displayOriginalName ? <i>({category.originalName}) </i> : null}
								-{' '}
							</p>
							<p className={'form-category-supplement-type'}>
								{' '}
								{I18n.t('partner.supplements.types.' + category.type)}
							</p>
							{this.state.categoryOpen === category.id &&
							category.data.length > 0 ? (
								<ExpandLess />
							) : (
								<ExpandMore />
							)}
						</div>

						<div className={classNameTypeConfig}>
							{category.type !== 1 ? (
								<React.Fragment>
									<div> {I18n.t('partner.supplements.from')}</div>
									<div>
										<Select
											id={'min' + category.id}
											value={category.minimum}
											name={category.id}
											onChange={e => this.handleChangeMin(e)}
											disabled={!find || category.disabled == 1}>
											{this.renderMenuItem()}
										</Select>
									</div>
									<div>{I18n.t('partner.supplements.to')}</div>
									<div>
										<Select
											id={'max' + category.id}
											value={category.maximum}
											name={category.id}
											onChange={e => this.handleChangeMax(e)}
											disabled={!find || category.disabled == 1}>
											{this.renderMenuItem()}
										</Select>
									</div>
									<div> {I18n.t('partner.supplements.maximum_choices')}</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<p>{I18n.t('partner.supplements.required')}</p>
									<Switch
										checked={!!category.minimum}
										inputProps={{ idcategory: category.id }}
										onChange={e => this.handleChangeRequired(e)}
										disabled={!find || category.disabled == 1}
									/>
								</React.Fragment>
							)}
						</div>
					</div>
					<Collapse isOpened={this.state.categoryOpen === category.id}>
						<List>
							<SortableProductContainer
								onSortEnd={index => this.onProductSortEnd(index, idCategory)}
								useDragHandle>
								{productCategoryList.map((value, index) => (
									<SortableProductItem
										key={`item-${index}`}
										index={index}
										value={value}
									/>
								))}
							</SortableProductContainer>
						</List>
					</Collapse>
				</div>
			);
		});

		this.state.categoriesEmpty.map(category => {
			const find = this.props.selected.find(act => {
				return act.id === category.id;
			});
			let displayOriginalName =
				category.originalName && category.originalName !== category.name;
			categoriesEmptyList.push(
				<div key={category.id}>
					<div className={'form-category-supplement-info'}>
						<div>
							{' '}
							<Checkbox
								checked={!!find}
								inputProps={{ idcategory: category.id }}
								onChange={this.setActiveCategory}
								disabled={category.disabled == 1}
							/>
						</div>
						<p className={classes}>
							{category.name}{' '}
							{displayOriginalName ? <i>({category.originalName}) </i> : null}-{' '}
						</p>
						<p className={'form-category-supplement-type'}>
							{' '}
							{I18n.t('partner.supplements.types.' + category.type)}
						</p>
					</div>
				</div>
			);
		});

		return (
			<div>
				<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
					{categoriesList.map((value, index) => (
						<SortableItem key={`item-${index}`} index={index} value={value} />
					))}
				</SortableContainer>
				{categoriesEmptyList}
			</div>
		);
	}
}

CategorySupplement.propTypes = {
	selected: PropTypes.array.isRequired,
	list: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	onDeselect: PropTypes.func.isRequired,
};

export default CategorySupplement;
