import { SET_MULTI_SESSION } from './types';

const initialState = {
	multiSession: false,
};

const reducer = function loginReducer(state = initialState, action) {
	const newState = { ...state };

	switch (action.type) {
		case SET_MULTI_SESSION:
			newState.multiSession = action.multiSession;
			return newState;

		default:
			return state;
	}
};

export default reducer;
