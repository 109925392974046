import React from 'react';
import BooleanInput from './boolean';
import StringNumberInput from './stringNumber';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxCheckedIcon from '@material-ui/icons/CheckBox';

function getDefaultValue(type) {
	if (type === 'boolean') {
		return false;
	} else {
		return '';
	}
}

const Option = ({
	option,
	onChange,
	toggleAdvancedSettings,
	childLevel = 0,
}) => {
	let Input = null;
	let active = false;

	let value = getDefaultValue(option.data_type);
	if (option.data_type === 'boolean') {
		Input = BooleanInput;
		active = option.value == true;
		value = option.value == true;
	} else {
		Input = StringNumberInput;
		if (option.value !== null) {
			active = option.value == true;
			value = option.value == true;
		}
	}

	let classesNameOption = 'option-name';
	if (option.version_disabled) {
		classesNameOption += ' option-name-disabled';
	}

	const advancedMode = option.children.some(c => c.value != null);

	let classesContainerOption = 'option-container';
	if (childLevel) {
		classesContainerOption += ' option-container-child';
	}

	// valeure "visuelle" du bouton parent (qui est du coup désactivé)
	if (advancedMode) {
		value = option.children.some(c => c.value == 1);
	}

	return (
		<>
			<div className={classesContainerOption}>
				<div className={classesNameOption}>
					{childLevel ? (
						<span className="option-child-arrow">
							<KeyboardReturnIcon />
						</span>
					) : null}
					{option.label}{' '}
					{option.version_disabled ? (
						<span className="option-name-need_update">
							- {I18n.t(`partner.option.need_update`)}
						</span>
					) : null}
				</div>
				{!option.version_disabled &&
				option.children.length &&
				option.children.some(c => !c.version_disabled) ? (
					<span
						onClick={() =>
							toggleAdvancedSettings(option.name, advancedMode ? false : true)
						}
						className="option-settings-advanced">
						{I18n.t(`partner.option.advanced_settings`)}
						{advancedMode ? <CheckBoxCheckedIcon /> : <CheckBoxIcon />}
					</span>
				) : null}
				<div>
					{advancedMode ? (
						<p className="option-input-placeholder"></p>
					) : (
						<Input
							active={active}
							value={value}
							onChange={value => {
								onChange(option.name, value);
							}}
							type={option.data_type}
							disabled={option.version_disabled || advancedMode}
						/>
					)}
				</div>
			</div>
			<div>
				{advancedMode && !option.version_disabled
					? option.children.map(c => (
							<Option
								key={c.name}
								option={c}
								onChange={onChange}
								toggleAdvancedSettings={toggleAdvancedSettings}
								childLevel={childLevel + 1}
							/>
					  ))
					: null}
			</div>
		</>
	);
};

Option.propTypes = {
	option: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default Option;
