import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Option from '../../../../Components/Options/Components/Option';
import { connect } from 'react-redux';
import { getVariableServer } from '../../../../../../utils';
import { patchVariable } from '../../../../../VariableServer/service/actions';
import Alert from '../../../../../../Components/Alert';
import Switch from '@material-ui/core/Switch';
import { RECEIVE_PARTNERS_DATA } from '../../../../service/partnersData/types';

class OrderValidation extends Component {
	onChange = () => {
		this.props.saveOrderValidation(!this.props.orderValidation); // on inverse la valeur parce ue visuellement est elle inversée (question de label + comphréhensible)
	};
	setOrderManager = () => {
		const val = !!this.props.partnerData.order_manager_client_id ? false : true;

		this.props.saveOrderManager(val, {
			...this.props.partnerData,
			order_manager_client_id: val,
		});
	};

	render() {
		const { orderValidation, partnerData } = this.props;

		if (!partnerData) return '';

		const opt = {
			label: I18n.t('partner.ubereats_order_validation_switch'),
			name: 'uberEatsOderValidation',
			value: !orderValidation, // on inverse la valeur visuellement
			children: [],
			data_type: 'boolean',
		};

		return (
			<div className={'partner-option-container'}>
				<h2 className={'export-title'}>
					{I18n.t('partner.ubereats_order_validation_title')}{' '}
					<Switch
						checked={!!partnerData.order_manager_client_id}
						onChange={this.setOrderManager}
					/>
				</h2>
				<Alert type="info">
					{I18n.t('partner.ubereats_order_manager_info')}
				</Alert>
				{!!partnerData.order_manager_client_id ? (
					<div
						className={'partner-option-subcontainer'}
						style={{ marginTop: 10 }}>
						<Option key={''} option={opt} onChange={this.onChange} />
						<Alert type="info">
							{I18n.t('partner.ubereats_order_validation_info')}
						</Alert>
					</div>
				) : (
					''
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	let row = getVariableServer(state.variableServer, 'ubereatsOrderValidation');
	const data = state.partnersData['ubereats'];
	return {
		orderValidation: row == '1' ? true : false,
		partnerData: data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveOrderValidation: value =>
			dispatch(patchVariable({ key: 'ubereatsOrderValidation', value })),
		saveOrderManager: (orderManager, partnerData) =>
			dispatch({
				type: 'UBEREATS_SET_ORDER_MANAGER',
				orderManager,
				partnerData,
			}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderValidation);
