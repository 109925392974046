import ProductsReducer from './products/reducer';
import MenusReducer from './menus/reducer';
import CategoriesReducer from './categories/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
	products: ProductsReducer,
	menus: MenusReducer,
	categories: CategoriesReducer,
});
