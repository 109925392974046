import { getApiUrl, fetcher, getApiHeaders } from './fetcher.saga';

export const uploadFile = async file => {
	const data = new FormData();

	data.append('file', file);

	const result = await fetcher(getApiUrl() + '/upload', 'POST', data, {
		...getApiHeaders(),
		'x-guid': sessionStorage.getItem('guid'),
	});
	if (result) {
		if (result.success) {
			return { success: true, path: result.result };
		} else if (result.result && result.result.code) {
			return { success: false, code: result.result.code };
		} else {
			return { success: false };
		}
	}
	return { success: false };
};

export const deleteFile = async path => {
	const result = await fetcher(getApiUrl() + '/upload', 'DELETE', path, {
		...getApiHeaders(),
		'x-guid': sessionStorage.getItem('guid'),
	});
	if (result) {
		if (result.success) {
			return { success: true };
		} else if (result.result && result.result.code) {
			return { success: false, code: result.result.code };
		} else {
			return { success: false };
		}
	}
	return { success: false };
};

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export function b64toBlob(base64, contentType, sliceSize = 512) {
	var block = base64.split(';');
	// Get the content type of the image
	var contentType = block[0].split(':')[1] || '';
	// get the real base64 content of the file
	var b64Data = block[1].split(',')[1];

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

export function blobToFile(theBlob, extension) {
	return new File([theBlob], `photoCrop.${extension}`, {
		type: 'image/jpeg',
	});
}
