import { deepClone } from '../../../../../utils';
const initialState = {};
export const RECEIVE_EXTERNAL_DATA = 'RECEIVE_EXTERNAL_DATA';
const reducerExternalData = (state = initialState, action) => {
	let newState = deepClone(state);
	switch (action.type) {
		case RECEIVE_EXTERNAL_DATA:
			newState = action.data;
			return newState;
		default:
			return newState;
	}
};

export default reducerExternalData;
