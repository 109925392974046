import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import getCroppedImg from './cropImage';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/lab/Slider';
import Button from '@material-ui/core/Button';
import Input from '../Input';
import pictoSizePicture from '../../img/Image_simple.svg';
import imagePola from '../../img/Image_pola.svg';
import { I18n } from 'react-redux-i18n';
import Dropzone from 'react-dropzone';
import {
	uploadFile,
	b64toBlob,
	blobToFile,
	deleteFile,
} from '../../service/upload';
import { withSnackbar } from 'notistack';
//import * as nsfwjs from "nsfwjs";
import { connect } from 'react-redux';
import { SHOW_LOADER, HIDE_LOADER } from '../Loader/service/types';
import { deepEqual } from '../../utils';
import imageCompression from 'browser-image-compression';
import Gallery from './gallery';
import ErrorPicture from './imageError.png';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			src: '',
			crop: { x: 0, y: 0 },
			zoom: 1,
			aspect: 3 / 3,
			fileSelected: '',
			croppedAreaPixels: null,
			croppedImage: null,
			newPicture: false,
			name: '',
			description: '',
			imgIsPorn: false,
			key_: 0,
			pictureSelected: null,
			sliderDisabled: false,
			pictureReused: false,
			openGaleryModal: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.src !== nextProps.src && !prevState.newPicture) {
			return {
				src: nextProps.src,
				name: nextProps.name,
				description: nextProps.description,
				crop: {
					x: nextProps.cropProperties.x || 0,
					y: nextProps.cropProperties.y || 0,
				},
				zoom: nextProps.cropProperties.zoom || 1,
				idEditPicture: nextProps.idSelected,
			};
		}
		return null;
	}

	verifExtensionAndSize = file => {
		let extension = this.getExtension(file.name);

		if (
			!['.jpg', '.png', '.jpeg', ...this.props.customAllowedTypes].includes(
				extension.toLowerCase()
			)
		) {
			this.props.enqueueSnackbar(
				I18n.t('info.picture.bad_extension', { extension }),
				{
					variant: 'error',
				}
			);
			return false;
		}

		/* if (file.size > this.props.maxSize) {
      this.props.enqueueSnackbar(
        I18n.t("info.picture.too_heavy", { extension }),
        {
          variant: "error"
        }
      );
      return false;
    } */
		return true;
	};

	setNewSrc = file => {
		if (this.verifExtensionAndSize(file)) {
			let reader = new FileReader();
			reader.onloadend = () => {
				this.setState(
					{
						fileSelected: file,
						src: reader.result,
						newPicture: true,
						imgIsPorn: false, //true
					},
					() => {
						//this.predictPicture();
					}
				);
			};

			reader.readAsDataURL(file);
		}
	};

	/* predictPicture = async () => {
    this.props.showLoader(text: I18n.t("info.picture.nsfw_check"));
    const model = await nsfwjs.load();
    const imageToCheck = new Image();
    imageToCheck.crossOrigin = "anonymous";
    imageToCheck.src = this.state.src;
    const predictions = await model.classify(imageToCheck);
    this.checkPicture(predictions);
  }; */

	/* checkPicture = predictions => {
    let ifPorn = predictions.reduce((acc, predict) => {
      if (["Porn", "Sexy", "Hentai"].includes(predict.className)) {
        acc += predict.probability * 100;
      }
      return acc;
    }, 0);

    if (ifPorn >= 30) {
      this.setState({
        src: "",
        newPicture: false,
        zoom: 1,
        name: "",
        description: "",
        fileSelected: "",
        imgIsPorn: true
      });
      this.props.enqueueSnackbar(I18n.t("info.picture.picture_porn"), {
        variant: "error"
      });
      this.props.hideLoader();
    } else {
      this.setState({
        imgIsPorn: false
      });
      this.props.hideLoader();
    }
  }; */

	onDrop = files => {
		let file = files[0];
		this.setNewSrc(file);
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({ croppedAreaPixels });
	};

	showCroppedImage = async () => {
		const croppedImage = await getCroppedImg(
			this.state.src,
			this.state.croppedAreaPixels
		);
		return croppedImage;
	};

	handleClose = () => {
		this.setState({ croppedImage: null });
	};

	onChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	closeModal = () => {
		this.setState(
			{
				src: '',
				newPicture: false,
				zoom: 1,
				name: '',
				description: '',
				fileSelected: '',
				imgIsPorn: false,
				pictureSelected: null,
				sliderDisabled: false,
				pictureReused: false,
			},
			() => {
				this.props.closeModal();
			}
		);
	};

	handleChange = e => {
		let file = e.target.files[0];
		this.setNewSrc(file);
	};

	onZoomChange = zoom => {
		this.setState({ zoom });
	};

	handleImageUpload = async file => {
		return file; // on supprime pour tester sans
		var options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};
		try {
			const compressedFile = await imageCompression(file, options);
			return compressedFile;
		} catch (error) {
			console.log(error);
			return file;
		}
	};

	onSubmit = async event => {
		event.preventDefault();
		this.props.showLoader();

		// NEW PICTURE
		if (this.state.newPicture) {
			if (this.state.pictureReused) {
				this.props.onSetExistedPicture(
					this.state.pictureSelected,
					this.state.name,
					this.state.description
				);
			} else {
				let result = { success: true, path: '' };
				const extension = this.getExtension(this.state.fileSelected.name);
				if (!this.props.croppedOnly) {
					result = await uploadFile(
						blobToFile(
							await this.handleImageUpload(this.state.fileSelected),
							extension
						)
					);
				}
				let result2 = { success: true, path: result.path };
				if (!this.props.cropDisabled) {
					let imgCroppedFile = await this.getCroppedFile(extension);
					result2 = await uploadFile(
						blobToFile(await this.handleImageUpload(imgCroppedFile), extension)
					);
				}

				if (!result.success || !result2.success) {
					if (result.success && result.path) {
						await deleteFile({ path: result.path });
					}
					if (result2.success && result2.path) {
						await deleteFile({ path: result2.path });
					}

					let message = I18n.t('upload.fail');
					if (result.code) {
						message = I18n.t('upload.error_code.' + result.code);
					}
					this.props.enqueueSnackbar(message, {
						variant: 'warning',
					});
					return false;
				}
				let CdnPath = result.path;
				let CdnPath_cropped = result2.path;

				this.props.onSubmit(
					CdnPath,
					CdnPath_cropped,
					this.state.name,
					this.state.description,
					JSON.stringify({ ...this.state.crop, zoom: this.state.zoom })
				);
			}
		} else {
			// UPDATE

			let pathCropped = false;
			if (
				!deepEqual(this.state.crop, {
					x: this.props.cropProperties.x,
					y: this.props.cropProperties.y,
				}) ||
				this.state.zoom != this.props.cropProperties.zoom
			) {
				const extension = await this.getExtension(this.state.src);
				let imgCroppedFile = await this.getCroppedFile(extension);
				const result = await uploadFile(
					blobToFile(await this.handleImageUpload(imgCroppedFile), extension)
				);
				if (result.success) {
					pathCropped = result.path;
				}
			}
			this.props.onSubmit(
				false,
				pathCropped,
				this.state.name,
				this.state.description,
				JSON.stringify({ ...this.state.crop, zoom: this.state.zoom })
			);
		}

		this.setState({
			src: '',
			name: '',
			description: '',
			fileSelected: '',
		});
		this.props.hideLoader();
		this.closeModal();
	};

	getExtension = name => {
		const i = name.lastIndexOf('.');
		return name.slice(i);
	};

	getCroppedFile = async extension => {
		let cropped64 = await this.showCroppedImage();
		let imgCroppedBlob = b64toBlob(cropped64);
		return blobToFile(imgCroppedBlob, extension);
	};

	formatSize = size => {
		const mo = size / 1024 / 1024;
		if (mo >= 1) {
			if (Math.floor(mo) == mo) {
				return `${mo} Mo`;
			} else {
				return `${mo.toFixed(2)} Mo`;
			}
		} else {
			return `${mo * 1000} Ko`;
		}
	};

	sendSrc = picture => {
		let cropProperties = JSON.parse(picture.crop_properties);
		let fileFromSrc = new File([picture.path_cropped], picture.name, {
			type: 'image/jpeg',
		});

		this.setState({
			fileSelected: fileFromSrc,
			src: picture.path_cropped,
			newPicture: true,
			imgIsPorn: false, //true
			crop: { x: cropProperties[`x`], y: cropProperties[`y`] },
			zoom: cropProperties[`zoom`],
			key_: picture.id,
			pictureSelected: picture,
			sliderDisabled: true,
			pictureReused: true,
			name: picture.name,
		});
	};

	openGaleryModal = () => {
		this.setState({
			openGaleryModal: true,
		});
	};

	closeGaleryModal = () => {
		this.setState({
			openGaleryModal: false,
		});
	};

	addDefaultSrc = ev => {
		ev.target.src = ErrorPicture;
	};

	render() {
		const { displayMetaData } = this.props;
		let modalClasses = 'edit-modal';

		if (!displayMetaData) {
			modalClasses += ' edit-modal-small';
		}
		const btnValidDisabled =
			!this.state.src ||
			this.state.imgIsPorn ||
			!this.state.src.length ||
			(displayMetaData && (!this.state.name || !this.state.name.length));

		let miniaturePictureForGallery =
			this.props.pictures.length >= 4
				? [
						...this.props.pictures.slice(
							Math.max(this.props.pictures.length - 4, 1)
						),
				  ]
				: [...this.props.pictures];

		miniaturePictureForGallery = this.props.displayMetaData
			? miniaturePictureForGallery.map(minia => {
					if (this.props.picture.find(pic => pic.id === minia.id)) {
						minia.disabled = true;
						return minia;
					} else {
						minia.disabled = false;
						return minia;
					}
			  })
			: miniaturePictureForGallery.map(minia => {
					if (
						this.props.picture &&
						this.props.picture.find(pic => pic.id === minia.id)
					) {
						minia.disabled = false;
						return minia;
					} else {
						minia.disabled = false;
						return minia;
					}
			  });

		return this.props.openModal ? (
			<div
				id="myModal"
				className={
					this.props.openModal
						? 'edit-popup display-block'
						: 'edit-popup display-none'
				}>
				<span className="modal-close" onClick={this.closeModal}>
					&times;
				</span>
				<div className={modalClasses}>
					{this.state.src === '' ? (
						<div className="add-picture">
							<div className="add-picture-container">
								<Dropzone onDrop={this.onDrop}>
									{({ getRootProps, getInputProps, isDragActive }) => {
										return (
											<div
												{...getRootProps()}
												className={
													isDragActive
														? 'dropzone dropzone--isActive'
														: 'dropzone'
												}
											/>
										);
									}}
								</Dropzone>
								<img
									src={imagePola}
									alt="pictogramme"
									className="picto-pola"
									onError={this.addDefaultSrc}
								/>
								<p className="gliss-text">
									{I18n.t('info.picture.drag_drop_image')}
								</p>
								{I18n.t('info.picture.or')}
								<input
									accept={
										'image/x-png,image/jpeg,image/png' +
										this.props.customAllowedTypesInput
									}
									id="raised-button-file"
									type="file"
									className="input-file"
									onChange={event => this.handleChange(event)}
								/>
								<label htmlFor="raised-button-file">
									<Button
										component="span"
										variant="contained"
										color="primary"
										className="withBackground">
										{I18n.t('info.picture.import')}
									</Button>
								</label>
							</div>
						</div>
					) : (
						<div className="cropper">
							<div className="crop-container">
								{this.props.cropDisabled ? (
									this.state.src.includes('data:application/pdf;') ||
									this.state.src.includes('.pdf') ? (
										<iframe
											src={this.state.src}
											style={{
												maxHeight: '100%',
												height: '100%',
												margin: '10px 0',
											}}
										/>
									) : (
										<img
											src={this.state.src}
											style={{ maxHeight: '100%', margin: '10px 0' }}
										/>
									)
								) : (
									<Cropper
										key={this.state.key_}
										image={this.state.src}
										crop={this.state.crop}
										zoom={this.state.zoom}
										aspect={this.state.aspect}
										onCropChange={this.onCropChange}
										onCropComplete={this.onCropComplete}
										onZoomChange={this.onZoomChange}
										{...(this.props.cropper || {})}
									/>
								)}
							</div>
							<img src={this.state.src} className={'hidden'} />
							{this.props.cropDisabled ? null : (
								<div className="controls">
									<img
										src={pictoSizePicture}
										alt="pictogramme taille picture"
										className="small-size"
									/>
									<Slider
										disabled={this.state.sliderDisabled}
										value={this.state.zoom}
										min={1}
										max={3}
										step={0.1}
										aria-labelledby="Zoom"
										onChange={(e, zoom) => this.onZoomChange(zoom)}
									/>
									<img src={pictoSizePicture} className="big-size" />
								</div>
							)}
						</div>
					)}
					{this.props.cropDisabled ? null : miniaturePictureForGallery.length >
					  0 ? (
						<div className="galery-container">
							{miniaturePictureForGallery.map((minPic, index) => {
								return (
									<img
										onClick={
											minPic.disabled ? null : () => this.sendSrc(minPic)
										}
										className={
											minPic.disabled
												? 'item-disabled galery-item'
												: 'galery-item'
										}
										src={minPic.path_cropped}
										onError={this.addDefaultSrc}
										key={index}
									/>
								);
							})}
							<div
								onClick={this.openGaleryModal}
								className="galery-item galery-link-to">
								+
							</div>
						</div>
					) : null}

					{displayMetaData ? (
						<div className="input-container">
							<Input
								label={I18n.t('info.picture.name')}
								type="text"
								name="name"
								value={this.state.name}
								onChange={this.onChange}
								inputProps={{ maxLength: 40 }}
							/>
							<Input
								label={I18n.t('info.picture.description')}
								type="text"
								name="description"
								value={this.state.description}
								onChange={this.onChange}
								inputProps={{ maxLength: 100 }}
							/>
						</div>
					) : null}

					<div className="button-container">
						<Button onClick={this.closeModal} className="withoutBackground">
							{I18n.t('general.cancel')}
						</Button>
						<Button
							onClick={this.onSubmit}
							variant="contained"
							color="primary"
							className="withBackground"
							disabled={btnValidDisabled}>
							{I18n.t('general.valid')}
						</Button>
					</div>
					<Gallery
						open={this.state.openGaleryModal}
						handleClose={this.closeGaleryModal}
						pictures={this.props.pictures}
						picture={this.props.picture}
						handleSelectPicture={this.sendSrc}
						displayMetaData={this.props.displayMetaData}></Gallery>
					{/* <ImgDialog img={this.state.croppedImage} onClose={this.handleClose} /> */}
				</div>
			</div>
		) : null;
	}
}

Modal.defaultProps = {
	displayMetaData: true,
	croppedOnly: false,
	maxSize: 1 * 1024 * 1024,
	cropDisabled: false,
	customAllowedTypes: [],
};

Modal.propTypes = {
	src: PropTypes.string,
	openModal: PropTypes.bool,
	closeModal: PropTypes.func,
	name: PropTypes.string,
	displayMetaData: PropTypes.bool,
	croppedOnly: PropTypes.bool,
	maxSize: PropTypes.number,
	cropDisabled: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
	return {
		pictures: state.info.pictures || [],
		picture: state.info.picture || [],
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showLoader: () => {
			dispatch({ type: SHOW_LOADER });
		},
		hideLoader: () => {
			dispatch({ type: HIDE_LOADER });
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(Modal));
