import React from 'react';
import Proptypes from 'prop-types';
import ItemSelected from './ItemSelected';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../service/products/actions';
import { getMenusList, getSelectedItemNames } from '../selector';
import ContentLoader from 'react-content-loader';

let maxDisplayedItems = 10;
class ItemSelectedList extends React.Component {
	onDeleteItem = (id, idMenu) => {
		this.props.deleteItem(id, idMenu);
	};
	displayRows = rows => {
		let content = [];

		Object.keys(rows).forEach((idMenu, index) => {
			const arrayOfItems = Object.values(rows[idMenu]);

			if (index > 0) {
				content.push(<p style={{ marginTop: 40 }} />);
			}

			content.push(
				<p key={idMenu} className={'item-selected-menu'}>
					{this.props.menus.hasOwnProperty(idMenu)
						? this.props.menus[idMenu].name
						: I18n('partner.products_selector.selected_list.unknow_menu')}
				</p>
			);

			for (let i = 0; i < maxDisplayedItems && i < arrayOfItems.length; i++) {
				const item = arrayOfItems[i];

				content.push(
					<ItemSelected
						key={item.id}
						id={item.id}
						name={item.name}
						onDelete={this.onDeleteItem}
						idMenu={idMenu}
					/>
				);
			}

			if (arrayOfItems.length > maxDisplayedItems) {
				content.push(
					<ItemSelected
						key={0}
						id={0}
						name={I18n.t(
							'partner.products_selector.selected_list.x_items_selected',
							{ number: arrayOfItems.length - maxDisplayedItems }
						)}
					/>
				);
			}
		});

		return content;
	};

	renderContentPlaceholder() {
		return (
			<ContentLoader
				width={300}
				height={570}
				speed={1}
				primaryColor="#f3f3f3"
				secondaryColor="#ecebeb">
				{renderMenu(5)}
				{renderMenu(2, 5)}
			</ContentLoader>
		);

		function renderMenu(count, previousCount = 0) {
			let content = [];
			let baseMarge = 20 + 75 * previousCount;
			content.push(
				<rect x="10" y={baseMarge} rx="10" ry="10" width="100" height="20" />
			);

			baseMarge += 40;

			content = [...content, ...renderSelected(count, baseMarge)];
			return content;
		}

		function renderSelected(count, baseMarge) {
			const height = 50;
			const width = 280;
			const margin = 8;
			let content = [];
			for (let x = 0; x < count; x++) {
				let marginTop = x * (height + margin) + baseMarge;
				content.push(
					<rect
						x={margin}
						y={marginTop}
						rx="5"
						ry="5"
						width={width}
						height={height}
					/>
				);
			}

			return content;
		}
	}

	render() {
		return (
			<React.Fragment>
				<h2>{I18n.t('partner.products_selector.selected_list.title')}</h2>
				<div className={'section-selected-container'}>
					<div className={'section-selected'}>
						{this.props.loaded ? (
							Object.keys(this.props.selectedItemsWithName).length ? (
								this.displayRows(this.props.selectedItemsWithName)
							) : (
								<p className={'section-empty'}>
									<Translate
										value={
											'partner.products_selector.selected_list.no_selection'
										}
									/>
								</p>
							)
						) : (
							this.renderContentPlaceholder()
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		menus: getMenusList(state),
		selectedItemsWithName: getSelectedItemNames(state),
	};
};

ItemSelectedList.propTypes = {
	loaded: Proptypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => {
	return {
		deleteItem: bindActionCreators(actions.deselect, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelectedList);
