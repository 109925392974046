import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import Bindings from '../../Components/Binding';
import { I18n } from 'react-redux-i18n';
import Options from '../../Components/Options';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import Export from '../../Components/Export';
import { deepEqual } from '../../utils';
import ProductThreshold from '../../Components/ProductQuantity';
import SupplementExtra from '../../Components/SupplementExtra';

class Tabesto extends Component {
	shouldComponentUpdate(nextProps) {
		if (
			deepEqual(this.props.list, nextProps.list) &&
			deepEqual(this.props.variableServer, nextProps.variableServer)
		) {
			return false;
		}

		return true;
	}

	render() {
		const { partner } = this.props;

		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<Export {...this.props} />
				<div className="gestion-button-container">
					<div className="gestion-button">
						<Bindings prefix={partner.prefix} />
						<SupplementExtra prefix={partner.prefix} />
						{/* <ProductThreshold prefix={partner.prefix} /> */}
					</div>
				</div>

				<Options prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
		list: state.exports.list,
	};
};

export default connect(mapStateToProps)(Tabesto);
