import React from 'react';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import './style.scss';
import { bindActionCreators } from 'redux';
import Button from '../../../../Components/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { getVariableServer } from '../../../../utils';
import { patchVariable } from '../../../VariableServer/service/actions';

const exportPartners = ['celladdition', 'ubereats', 'deliverect'];

class GeneralOptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	openModal = () => {
		this.setState({ open: true });
	};
	closeModal = () => {
		this.setState({ open: false });
	};

	setOverrideOption = (type, value) => {
		let newKeyOverride = { ...this.props.keyOverride };
		if (!newKeyOverride.product) {
			newKeyOverride.product = {};
		}
		if (!newKeyOverride.category_supplement) {
			newKeyOverride.category_supplement = {};
		}
		if (!newKeyOverride.supplement) {
			newKeyOverride.supplement = {};
		}

		newKeyOverride.product[type] = value;
		newKeyOverride.category_supplement[type] = value;
		newKeyOverride.supplement[
			type === 'price' ? 'priceSupplement' : type
		] = value;
		this.props.patch({
			key: 'ipadSynchroKeyOverride',
			value: JSON.stringify(newKeyOverride),
		});
	};

	render() {
		// A adapter de façon intelligente lorsque cet écran servira a d'autre options sur d'autre types de partenaire
		const displayGeneralOptions = Object.keys(
			this.props.partners
		).some(prefix => exportPartners.includes(prefix));

		if (displayGeneralOptions) {
			return (
				<React.Fragment>
					<Button className={'general-option-button'} onClick={this.openModal}>
						{I18n.t('partner.general_options.button')}
					</Button>
					<Dialog open={this.state.open} onClose={this.closeModal}>
						<div className="general-option-container">
							<h2> {I18n.t('partner.general_options.title')}</h2>
							<h3>{I18n.t('partner.general_options.ipad_changes.title')}</h3>
							<p>
								{I18n.t('partner.general_options.ipad_changes.description')}
							</p>
							<p>
								{I18n.t(
									'partner.general_options.ipad_changes.label_product_name'
								)}
								:
								<input
									type="checkbox"
									checked={this.props.keyOverride.product.name || false}
									onChange={e => {
										this.setOverrideOption('name', e.target.checked);
									}}
								/>
							</p>
							<p>
								{I18n.t(
									'partner.general_options.ipad_changes.label_product_price'
								)}
								:
								<input
									type="checkbox"
									checked={this.props.keyOverride.product.price || false}
									onChange={e => {
										this.setOverrideOption('price', e.target.checked);
									}}
								/>
							</p>
						</div>
					</Dialog>
				</React.Fragment>
			);
		} else {
			return '';
		}
	}
}

const mapStateToProps = state => {
	let keyOverride = getVariableServer(
		state.variableServer,
		'ipadSynchroKeyOverride'
	);
	return {
		partners: state.partners,
		keyOverride: keyOverride
			? JSON.parse(keyOverride)
			: { product: {}, supplement: {}, category_supplement: {} },
	};
};

const mapDispatchToProps = dispatch => {
	return {
		patch: bindActionCreators(patchVariable, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralOptions);
