import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../Components/Button';
import { I18n } from 'react-redux-i18n';
import ProductsSelectorModal from './Components/ProductsSelectorModal';

class ProductsSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openModal: false,
		};
	}

	validModal = selectedItems => {
		this.cancelModal();
		this.props.onValid(selectedItems);
	};

	cancelModal = () => {
		this.setState({
			openModal: false,
		});
	};

	openModal = () => {
		this.props.setSelectedItems();
		this.setState({
			openModal: true,
		});
	};

	render() {
		return (
			<div>
				<Button disabled={false} onClick={this.openModal}>
					{I18n.t('partner.products_selector.import_products')}
				</Button>
				<ProductsSelectorModal
					open={this.state.openModal}
					onValid={this.validModal}
					onCancel={this.cancelModal}
					menuSelectorDisabled={!!this.props.menuSelectorDisabled}
				/>
			</div>
		);
	}
}

ProductsSelector.propTypes = {
	onValid: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
};

export default ProductsSelector;
