import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';

import {
	addAuthGroup,
	checkIfPartnerCustomerExist,
	checkLiveness,
	generateIpadToken,
	initApiData,
	initEntities,
	initExternalData,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
	triggerSynchroIpad,
} from '../saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { PRE_CHANGE_PARTNER_STATUS, PRE_CHANGE_PARTNER_STEP } from '../types';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { RECEIVE_PARTNERS_DATA } from '../../partnersData/types';

const PARTNER_PREFIX = 'mewsSystems';

const STEP_TO_VARIABLE_SERVER = {
	set_service_id: 'serviceIdTokenMews',
	set_outlet_id: 'outletIdMews',
	set_default_payment_id: 'defaultPaymentIdMews',
};

function* configMews() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_MEWS');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.mewsSystems
				? partners.mewsSystems.external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			success = true;
		} else if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_customer_id') {
			yield call(handlePatchVariable, {
				key: 'accessTokenMews',
				value: data.customer_id,
			});

			yield put({
				type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
				body: {
					partnerPrefix: PARTNER_PREFIX,
					id: data.userId,
				},
			});
			const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');
			if (result && result.responseData) {
				external_id = data.customer_id;
				let resCheck = yield call(checkIfPartnerCustomerExist, {
					partner_id: data.partnerId,
					partner_customer_id: external_id,
				});

				if (!resCheck) {
					errorMessage = 'partner.link_partner_check_error';
				} else if (resCheck && resCheck.exist === false) {
					success = true;
					bodySetStep.step = 'set_service_id';

					yield call(
						notify,
						I18n.t('partner.mews_credientials_verified'),
						'success'
					);

					yield put({
						type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
						body: { prefix: PARTNER_PREFIX, external_id },
					});
				} else {
					errorMessage = 'partner.link_partner_customer_allready_exist';
				}
			} else {
				yield call(
					notify,
					I18n.t('partner.onboarding_mewsSystems_errors.check_customer_id'),
					'error',
					10000
				);
			}
		}

		if (
			['set_service_id', 'set_outlet_id', 'set_default_payment_id'].includes(
				currentStep
			)
		) {
			if (data.selected) {
				yield call(handlePatchVariable, {
					key: STEP_TO_VARIABLE_SERVER[currentStep],
					value: data.selected,
				});
				success = true;
				if (currentStep === 'set_default_payment_id') {
					currentStep = 'set_ipad_token';
					bodySetStep.step = 'set_ipad_token';
				}
			} else {
				errorMessage = 'partner.onboarding_mewsSystems_errors.no_selection:';
			}
		}

		if (currentStep === 'set_ipad_token') {
			const ipadToken = yield call(generateIpadToken);
			if (ipadToken) {
				success = true;
				currentStep = 'set_api_data';
				bodySetStep.step = 'set_api_data';
			}
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				currentStep = 'check_liveness';
				bodySetStep.step = 'check_liveness';
			}
		}

		if (currentStep === 'check_liveness') {
			const isLive = yield call(checkLiveness);
			if (isLive) {
				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: external_id,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			bodySetStep.status = 'configuration';
			if (setApiMaskSuccess) {
				success = true;
				currentStep = 'set_auth_group';
				bodySetStep.step = 'set_auth_group';
			}
		}

		if (currentStep === 'set_auth_group') {
			const resultAuthGroup = yield call(addAuthGroup, ['pms']);
			if (resultAuthGroup && resultAuthGroup.success) {
				success = true;
				currentStep = 'set_entities';
				bodySetStep.step = 'set_entities';
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'set_entities') {
			const resultInitEntities = yield call(initEntities, PARTNER_PREFIX);
			if (resultInitEntities) {
				success = true;
				currentStep = 'link_external_data';
				bodySetStep.step = 'link_external_data';
			} else {
				errorMessage = 'partner.onboarding_common_errors.set_entities';
			}
		}

		if (currentStep === 'link_external_data') {
			const resultInitExternalData = yield call(
				initExternalData,
				PARTNER_PREFIX
			);

			if (resultInitExternalData) {
				success = true;
				currentStep = 'trigger_synchro';
				bodySetStep.step = 'trigger_synchro';
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'trigger_synchro') {
			yield call(triggerSynchroIpad);
			bodySetStep.status = 'actif';
			bodySetStep.step = 'success';
			success = true;
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_MEWS_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([configMews()]);
}
