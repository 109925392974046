import { RECEIVE_PARTNERS_DATA } from './types';

const initialState = {};
const reducerPartnersData = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_PARTNERS_DATA:
			if (action.prefix) {
				newState[action.prefix] = action.data;
			}
			return newState;

		default:
			return newState;
	}
};

export default reducerPartnersData;
