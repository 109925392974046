import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Options from '../../Components/Options';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import { deepEqual } from '../../utils';
import Bindings from '../../Components/Binding';

class Sunday extends Component {
	shouldComponentUpdate(nextProps) {
		if (deepEqual(this.props.list, nextProps.list)) {
			return false;
		}

		return true;
	}

	render() {
		const { partner } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<div className="gestion-button-container">
					<div className="gestion-button">
						<Bindings prefix={partner.prefix} />
					</div>
				</div>
				<Options prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
	};
};

export default connect(mapStateToProps)(Sunday);
