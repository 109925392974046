import React from 'react';
import { Switch, Input, Button } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';

class StringNumber extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: null,
			inputAttr: { inputType: 'text' },
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let state = { inputAttr: {} };

		state.inputAttr.inputType = !!nextProps.type ? 'number' : 'text';
		if (nextProps.type === 'float') {
			state.inputAttr.step = '0.01';
		}

		if (nextProps.value !== null && prevState.inputValue === null) {
			state.inputValue = nextProps.value;
		}
		return state;
	}

	getDefaultValue = type => {
		if (type === 'integer') {
			return 0;
		} else if (type === 'float') {
			return '0.00';
		} else {
			return '';
		}
	};

	onValidInput = e => {
		e.preventDefault();
		this.props.onChange({
			active: this.props.active,
			value: this.state.inputValue,
		});
	};

	onActive = e => {
		e.preventDefault();
		this.props.onChange({
			active: e.target.checked,
			value: this.getDefaultValue(this.props.type),
		});
		if (!e.target.checked) {
			this.setState({ inputValue: this.getDefaultValue(this.props.type) });
		}
	};

	onInputChange = e => {
		e.preventDefault();
		this.setState({ inputValue: e.target.value });
	};

	render() {
		const { inputType, ...inputAttr } = this.state.inputAttr;
		return (
			<React.Fragment>
				{this.props.active ? (
					<React.Fragment>
						<Input
							value={this.state.inputValue}
							onChange={this.onInputChange}
							type={inputType}
							{...inputAttr}
						/>
						<Button
							onClick={this.onValidInput}
							disabled={this.props.value === this.state.inputValue}>
							{I18n.t('general.save')}
						</Button>
					</React.Fragment>
				) : (
					''
				)}
				<Switch checked={this.props.active} onChange={this.onActive} />
			</React.Fragment>
		);
	}
}

export default React.memo(StringNumber);
