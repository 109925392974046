import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './style.scss';
import Button from '../../Components/Button';
import * as actions from '../../Containers/Partners/service/export/actions';

class VariableModal extends Component {
	constructor(props) {
		super(props);
	}

	onClick = () => {
		this.props.action.patchIpadChangeInExport({ value: false });
	};

	regenerateExport = exp => {
		if (exp.prefix_partner && exp.id_partner && exp.id_export) {
			this.props.action.generateExport(
				exp.prefix_partner,
				exp.id_partner,
				exp.id_export
			);
		}
	};

	render() {
		let exports_ = [];
		if (this.props.changement_notif && this.props.changement_notif.length) {
			this.props.changement_notif.forEach(exp => {
				const partner = this.props.partners.find(p => p.id === exp.id_partner);
				if (partner) {
					if (partner.id === exp.id_partner) {
						exports_.push({
							prefix_partner: partner.prefix,
							id_partner: exp.id_partner,
							id_export: exp.id,
							name: exp.name,
						});
					}
				}
			});
		}

		return (
			<div>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={exports_.length > 0 && !this.props.multiSession}
					className="variable-modal">
					<div className="variable-modal-container">
						<h2 id="modal-title">{I18n.t('variable.title_modal')}</h2>
						<p id="simple-modal-description">
							{I18n.t('variable.description_modal')}
						</p>

						{exports_ ? (
							<div>
								<p>{I18n.t('variable.impacted_export')} : </p>
								<ul>
									{exports_.map(exp => {
										return (
											<li key={exp.id_export} className="export-item">
												{I18n.t('partner.prefix.' + exp.prefix_partner)} -{' '}
												{exp.name}{' '}
												{exp.prefix_partner === 'ubereats' ? (
													<Button onClick={() => this.regenerateExport(exp)}>
														{I18n.t('variable.generate_new_export')}
													</Button>
												) : null}
											</li>
										);
									})}
								</ul>
							</div>
						) : null}

						<Button onClick={this.onClick}>{I18n.t('variable.got_it')}</Button>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	let changementNotif = state.exports.list.reduce((acc, exp) => {
		if (exp.notif_ipad_changes === true || exp.notif_ipad_changes === 1) {
			acc.push(exp);
		}
		return acc;
	}, []);
	return {
		partners: Object.values(state.partners),
		changement_notif: changementNotif,
		multiSession: state.service.auth.multiSession,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VariableModal);
