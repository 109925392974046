import React, { Component } from 'react';
import { connect } from 'react-redux';
//import PaymentAsso from "../../Components/Binding/PaymentAsso";
import { I18n } from 'react-redux-i18n';
import { getPartner, getExportActive } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';

class TTP extends Component {
	render() {
		const { partner } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<div className={'partner-option-container'}>
					<div
						style={{ textAlign: 'center' }}
						className={'partner-option-subcontainer'}>
						<p>
							<strong>{I18n.t('partner.tap_to_pay.success_popup')}</strong>
						</p>
						<p>{I18n.t('partner.tap_to_pay.page_download_app')}</p>
						<img
							width={400}
							src={'https://cdn.laddition.com/23/qr-code-tap-to-pay.png'}
							alt="qrcode laddition pay+"
						/>
					</div>
				</div>
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
		list: state.exports.list,
	};
};

export default connect(mapStateToProps)(TTP);
