import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeProp } from '../../../../../../../../service/export/actions';
import InputImage from './image';
import InputBoolean from './boolean';
import InputDescription from './description';
import InputText from './input';
import InputVisibility from './visible';
import { findProductInProductsSelector } from '../../../../../../../../service/export/selector';
import { getFormProduct } from './_selector';
import { getPartnerRequiredKeys } from '../../../../../../../../service/partners/selector';
import { I18n } from 'react-redux-i18n';

class InputMaster extends PureComponent {
	onChange = (name, value) => {
		this.props.onChange(name, value);
	};

	render() {
		const { value, name } = this.props;
		const placeholder = this.props.selectorProduct[this.props.name] || '';

		let input = null;
		let attrs = {
			name: name,
			onChange: this.onChange,
			value: value,
			toComplete: this.props.partnerKeys.indexOf(name) !== -1 && value === '',
		};
		switch (this.props.type) {
			case 'text':
				input = (
					<InputText
						{...attrs}
						placeholder={placeholder}
						minlength={this.props.min || 1}
						maxlength={this.props.max || 255}
					/>
				);
				break;
			case 'price':
				input = (
					<InputText
						{...attrs}
						type={'float'}
						min={0}
						placeholder={(placeholder / 100).toFixed(2).replace('.', ',')}
					/>
				);
				break;
			case 'description':
				input = (
					<InputDescription
						{...attrs}
						placeholder={placeholder}
						maxlength={255}
					/>
				);
				break;
			case 'boolean':
				input = <InputBoolean {...attrs} />;
				break;
			case 'visibility':
				let attrDisable = {};
				if (this.props.disableVisibility) {
					attrDisable = {
						disabled: true,
						labelDisabled: I18n.t(
							'partner.productForm.tooltip_visible_disabled'
						),
					};
				}
				input = (
					<InputVisibility
						hideLabel={this.props.hideLabel || false}
						invert={this.props.invert || false}
						labelVisible={this.props.labelVisible || ''}
						labelNotVisible={this.props.labelNotVisible || ''}
						{...attrDisable}
						{...attrs}
					/>
				);
				break;
			case 'image':
				input = <InputImage name={this.props.name} {...this.props} />;
				break;

			default:
				input = <InputText {...attrs} placeholder={placeholder} />;
		}
		return <div>{input}</div>;
	}
}

const mapStateToProps = (state, props) => {
	let value = getFormProduct(state, props);
	let selectorProduct = findProductInProductsSelector(
		state,
		props.product.id_menu,
		props.product.id_product
	);
	if (props.type === 'price') {
		const partner = state.partners[props.prefix];
		const partnerPriceCategory = partner && partner.id_price_category;
		const priceCategory =
			selectorProduct && selectorProduct.prices
				? selectorProduct.prices.find(pc => pc.id === partnerPriceCategory)
				: null;
		selectorProduct.price =
			(priceCategory && priceCategory.price) || selectorProduct.price;
	}
	return {
		value: value,
		formProduct: state.exports.formProduct,
		partnerKeys: getPartnerRequiredKeys(state.partners, props.prefix),
		selectorProduct,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: bindActionCreators(changeProp, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InputMaster);
