import {
	PRE_CHANGE_PARTNER_STEP,
	PRE_CHANGE_PARTNER_STATUS,
	PRE_GENERATE_TOKEN,
	PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER,
	PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK,
	PRE_VALIDATION_PARTNER_CHECKING,
	PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
	PRE_POST_PARTNER_TO_MED_DEFAULT,
	PRE_POST_PARTNER_CHECKING,
	PRE_POST_INITIALIZATION_PARTNER,
	PRE_GET_CHECK_MENU,
	PRE_PATCH_PAIEMENT_TYPE,
	PRE_PATCH_FLOOR,
	PRE_PATCH_PRICE_CATEGORY,
	PRE_GENERATE_TOKEN_CMD_EN_LIGNE,
	PRE_REQUEST_POST_INITIALIZATION_PARTNER,
	PRE_POST_INITIALIZATION_MASK_PARTNER,
	PRE_CHANGE_PARTNER_CONNECTOR_STATUS,
} from './types';

export const putPartnerStep = body => ({
	type: PRE_CHANGE_PARTNER_STEP,
	body,
});

export const putPartnerStatus = body => ({
	type: PRE_CHANGE_PARTNER_STATUS,
	body,
});

export const putPartnerConnectorStatus = body => ({
	type: PRE_CHANGE_PARTNER_CONNECTOR_STATUS,
	body,
});

export const generateToken = body => ({
	type: PRE_GENERATE_TOKEN,
	body,
});

export const generateTokenCmdEnLign = body => ({
	type: PRE_GENERATE_TOKEN_CMD_EN_LIGNE,
	body,
});

export const validLinkPartnerToCustomer = body => ({
	type: PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER,
	body,
});

export const validConnexionWithPartnerOk = body => ({
	type: PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK,
	body,
});

export const validPartnerChecking = body => ({
	type: PRE_VALIDATION_PARTNER_CHECKING,
	body,
});

export const putPartnerStatusToMedDefault = body => ({
	type: PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
	body,
});

export const postPartnerMedDefault = body => ({
	type: PRE_POST_PARTNER_TO_MED_DEFAULT,
	body,
});

export const postPartnerChecking = body => ({
	type: PRE_POST_PARTNER_CHECKING,
	body,
});

export const postInitializationPartner = body => ({
	type: PRE_POST_INITIALIZATION_PARTNER,
	body,
});

export const getCheckMenu = body => ({
	type: PRE_GET_CHECK_MENU,
	body,
});

export const patchPartnerPaiementType = body => ({
	type: PRE_PATCH_PAIEMENT_TYPE,
	body,
});

export const patchPartnerFloor = body => ({
	type: PRE_PATCH_FLOOR,
	body,
});

export const patchPartnerPriceCategory = body => ({
	type: PRE_PATCH_PRICE_CATEGORY,
	body,
});

export const postInitializationPartnerOnChangeStatus = body => ({
	type: PRE_REQUEST_POST_INITIALIZATION_PARTNER,
	body,
});

export const postInitializationPartnerMask = body => ({
	type: PRE_POST_INITIALIZATION_MASK_PARTNER,
	body,
});

export const redirectToExternalConfig = prefix => ({
	type: 'REDIRECT_EXTERNAL_CONFIG',
	prefix,
});
export const redirectToConnectorPayment = _ => ({
	type: 'REDIRECT_CONNECTOR_PAYMENT',
});
