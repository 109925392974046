import React, { Component } from 'react';
import { connect } from 'react-redux';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {
	getProduct,
	findProductInProductsSelector,
	getDefaultCategorySupplementForProductInMenu,
	getProductCategorySupplementForProductInMenu,
} from '../../../../../../../../service/export/selector';
import { getFormProduct } from '../Inputs/_selector';

import { bindActionCreators } from 'redux';
import { changeProp } from '../../../../../../../../service/export/actions';
import InputEdit from '../../../../../../../SupplementExtra/Components/InputEdit';
import {
	saveExtraProducts,
	deleteProductExtra,
} from '../../../../../../../../service/export/actions';
import ListMenuLevel from './ListMenuLevels';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { notify } from '../../../../../../../../../../Components/Notifier/services/actions';
import { confirm } from '../../../../../../../../../../utils';
import { Button } from '@material-ui/core';

class MenuLevels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idProduct: 0,
			menus: [],
			firstMenu: [],
			secondMenu: [],
			extras: [],
			firstMenuName: '',
			secondMenuName: '',
			firstMenuId: '',
			secondMenuId: '',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.selectorProduct.id !== prevState.idProduct) {
			if (nextProps.product.product_type === 2) {
				let menuLevelsRequired = [];
				let menuLevelsOptionnal = [];
				let menusLevelsCompletedOne = [];
				let menusLevelsCompletedTwo = [];
				let firstMenuId = '';
				let secondMenuId = '';
				let firstMenuName = '';
				let secondMenuName = '';
				let menusExtra = { ...nextProps.selectorProduct };
				let parseValue = '';
				if (nextProps.value) {
					parseValue = JSON.parse(nextProps.value);
				}

				if (parseValue.length > 0) {
					menusExtra.menuLevel = parseValue;
				}
				menusExtra.menuLevel.map(level => {
					if (level.required) {
						menuLevelsRequired.push(level);
					} else {
						menuLevelsOptionnal.push(level);
					}
				});

				if (menuLevelsOptionnal.length === 2) {
					menusLevelsCompletedOne = [
						...menuLevelsRequired,
						menuLevelsOptionnal[0],
					];
					firstMenuId = menusLevelsCompletedOne
						.map(m => {
							return m.id;
						})
						.sort()
						.join('');
					menusLevelsCompletedOne = MenuLevels.resort(menusLevelsCompletedOne);
					menusLevelsCompletedTwo = [
						...menuLevelsRequired,
						menuLevelsOptionnal[1],
					];
					secondMenuId = menusLevelsCompletedTwo
						.map(m => {
							return m.id;
						})
						.sort()
						.join('');
					menusLevelsCompletedTwo = MenuLevels.resort(menusLevelsCompletedTwo);

					if (
						nextProps.formProduct &&
						nextProps.formProduct['name_menuLevel']
					) {
						JSON.parse(nextProps.formProduct['name_menuLevel']).map(menu => {
							if (menu && menu[firstMenuId]) {
								firstMenuName = menu[firstMenuId];
							}
							if (menu && menu[secondMenuId]) {
								secondMenuName = menu[secondMenuId];
							}
						});
					} else {
						firstMenuName = MenuLevels.getMenuName(menusLevelsCompletedOne);
						secondMenuName = MenuLevels.getMenuName(menusLevelsCompletedTwo);
					}
				}

				menusExtra.menuLevel = MenuLevels.resort(menusExtra.menuLevel);

				return {
					menus: menusExtra,
					firstMenu: menusLevelsCompletedOne,
					secondMenu: menusLevelsCompletedTwo,
					idProduct: nextProps.selectorProduct.id,
					extras: parseValue,
					firstMenuId,
					secondMenuId,
					firstMenuName,
					secondMenuName,
				};
			}
		}

		return null;
	}

	static getMenuName = menuLevels => {
		return menuLevels.map(ml => ml.name).join('-');
	};

	static resort = data => {
		return data.sort((a, b) => {
			return a.weight - b.weight;
		});
	};

	onMenuChange = menuLevels => {
		let newMenus = { ...this.props.selectorProduct };
		newMenus.menuLevel = menuLevels;
		if (
			this.state.firstMenu &&
			this.state.firstMenu.length > 0 &&
			this.state.secondMenu &&
			this.state.secondMenu.length > 0
		) {
			let idModified = menuLevels
				.map(menu => {
					return menu.id;
				})
				.sort()
				.join('');
			if (idModified === this.state.firstMenuId) {
				this.setState({
					firstMenu: menuLevels,
				});
			}
			if (idModified === this.state.secondMenuId) {
				this.setState({
					secondMenu: menuLevels,
				});
			}
			let menuCombined = [...this.state.firstMenu, ...this.state.secondMenu];
			const unique = menuCombined.filter(
				(val, id, array) => array.indexOf(val) == id
			);
			this.props.onChange('menu_levels', JSON.stringify(unique));
			this.setState({
				menus: unique,
			});
		} else {
			this.props.onChange('menu_levels', JSON.stringify(menuLevels));
			this.setState({
				menus: newMenus,
			});
		}
	};

	onChange = (value, position) => {
		this.setState(
			{
				firstMenuName: position === 'first' ? value : this.state.firstMenuName,
				secondMenuName:
					position === 'second' ? value : this.state.secondMenuName,
			},
			() => {
				this.props.onChange(
					'name_menuLevel',
					JSON.stringify([
						{ [this.state.firstMenuId]: this.state.firstMenuName },
						{ [this.state.secondMenuId]: this.state.secondMenuName },
					])
				);
			}
		);
	};

	onDelete = () => {
		confirm(I18n.t('exports.menuLevel.reset_extra')).then(() => {
			this.props.deleteProductExtra({
				idExport: this.props.product.id_export,
				idProduct: this.props.product.id_product,
				idSection: this.props.product.id_section,
				key_: 'menu_levels',
				idMenuLevel: null,
				idProductParent: null,
			});

			setTimeout(() => {
				// c'est degeu mais ca marche pour refresh ... je m'en veux de faire ca mais je galere depuis 3h a trouver un truc pas moche a faire
				// Je payerais une biere a celui que ca dérange sur présentation du code <3
				this.props.setSelected('section', this.props.product.id_section);
				this.props.setSelected('product', this.props.product.id_product);
				this.props.setSelected('section', this.props.product.id_section);
				this.props.setSelected('product', this.props.product.id_product);
			}, 1000);
		});
	};

	render() {
		return (
			<div>
				{this.props.product.extras.menu_levels ? (
					<Button
						onClick={this.onDelete}
						color={'secondary'}
						variant="outlined"
						startIcon={<RotateLeftIcon />}
						className="remove-extras-button">
						<p>{I18n.t(`partner.productForm.reset_button_menu`)}</p>
					</Button>
				) : null}

				{this.props.product.product_type === 2 ? (
					this.state.firstMenu &&
					this.state.firstMenu.length > 0 &&
					this.state.secondMenu &&
					this.state.secondMenu.length > 0 ? (
						<div>
							<InputEdit
								value={this.state.firstMenuName}
								inputProps={{ type: 'text' }}
								onChange={value => {
									this.onChange(value, 'first');
								}}
								className="under-level-title"
							/>
							<ListMenuLevel
								{...this.props}
								menuLevels={this.state.firstMenu}
								extras={this.state.extras}
								sigle={this.props.sigle}
								onChange={this.onMenuChange}
								onPropChange={this.props.onChange}
								prefix={this.props.prefix}
								notify={this.props.notify}
								productExtra={this.props.productExtra}
								idProductMenu={this.state.idProduct}
								categorySupplementDefault={this.props.categorySupplementDefault}
								categorySupplementForProduct={
									this.props.categorySupplementForProduct
								}
								disableVisibility={this.props.disableVisibility}
								disabledProducts={this.props.disabledProducts}
							/>
							<InputEdit
								value={this.state.secondMenuName}
								inputProps={{ type: 'text' }}
								onChange={value => {
									this.onChange(value, 'second');
								}}
								className="under-level-title"
							/>
							<ListMenuLevel
								{...this.props}
								menuLevels={this.state.secondMenu}
								extras={this.state.extras}
								sigle={this.props.sigle}
								onChange={this.onMenuChange}
								onPropChange={this.props.onChange}
								prefix={this.props.prefix}
								notify={this.props.notify}
								productExtra={this.props.productExtra}
								idProductMenu={this.state.idProduct}
								categorySupplementDefault={this.props.categorySupplementDefault}
								categorySupplementForProduct={
									this.props.categorySupplementForProduct
								}
								disableVisibility={this.props.disableVisibility}
								disabledProducts={this.props.disabledProducts}
							/>
						</div>
					) : (
						<ListMenuLevel
							{...this.props}
							menuLevels={this.state.menus.menuLevel}
							extras={this.state.extras}
							sigle={this.props.sigle}
							onChange={this.onMenuChange}
							onPropChange={this.props.onChange}
							prefix={this.props.prefix}
							notify={this.props.notify}
							productExtra={this.props.productExtra}
							idProductMenu={this.state.idProduct}
							categorySupplementDefault={this.props.categorySupplementDefault}
							categorySupplementForProduct={
								this.props.categorySupplementForProduct
							}
							disableVisibility={this.props.disableVisibility}
							disabledProducts={this.props.disabledProducts}
						/>
					)
				) : (
					<div />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const product = findProductInProductsSelector(
		state,
		props.product.id_menu,
		props.product.id_product
	);

	let categorySupplementDefault = {};
	let categorySupplementForProduct = {};

	if (product.type === 'menu') {
		categorySupplementDefault = getDefaultCategorySupplementForProductInMenu(
			state,
			product
		);

		categorySupplementForProduct = getProductCategorySupplementForProductInMenu(
			state,
			product
		);
	}

	const partnersStockActiveLive = state.exports.partnerStockActiveLive;

	return {
		value: getFormProduct(state, props),
		formProduct: state.exports.formProduct,
		selectorProduct: product,
		sigle: state.info.variable ? state.info.variable.sigle : '€',
		productExtra: state.exports.productExtra,
		categorySupplementDefault,
		categorySupplementForProduct,
		disableVisibility:
			state.service.user.isSwift &&
			partnersStockActiveLive.includes(props.prefix), // supprimer si partnersStockActiveLive n'est pas utile, voir avec le temps (en gros si tous les partanaires peuvent gérer la modif du active)
		disabledProducts: state.exports.disabledProducts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: bindActionCreators(changeProp, dispatch),
		save: bindActionCreators(saveExtraProducts, dispatch),
		notify: bindActionCreators(notify, dispatch),
		deleteProductExtra: bindActionCreators(deleteProductExtra, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLevels);
