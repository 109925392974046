import React from 'react';
import { IconButton } from '@material-ui/core';
import ResetIcon from '@material-ui/icons/Restore';

export default ({ onClick, disabled }) => {
	const attrs = {};
	if (disabled) {
		attrs.style = { opacity: 0.5 };
		attrs.disabled = true;
	} else {
		attrs.onClick = onClick;
	}
	return (
		<div className={'supplement-reset-button'}>
			{' '}
			<IconButton {...attrs}>
				<ResetIcon />
			</IconButton>
		</div>
	);
};
