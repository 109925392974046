import fr from './fr';
import en from './en';
import es from './es';

export default {
	fr,
	//en,
	es,
	'es-ES': es,
	ca: es,
};
