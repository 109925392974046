import {
	CHANGE_PARTNER_STEP,
	CHANGE_PARTNER_STATUS,
	PRE_CHANGE_PARTNER_STATUS,
	PRE_CHANGE_PARTNER_STEP,
	PRE_GENERATE_TOKEN,
	GENERATE_TOKEN,
	PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER,
	VALIDATION_LINK_PARTNER_TO_CUSTOMER,
	PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK,
	VALIDATION_CONNEXION_WITH_PARTNER_OK,
	PRE_VALIDATION_PARTNER_CHECKING,
	VALIDATION_PARTNER_CHECKING,
	PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
	PRE_POST_PARTNER_TO_MED_DEFAULT,
	PRE_PUT_PARTNER_LINK_AUTH,
	PRE_POST_PARTNER_CHECKING,
	PRE_POST_INITIALIZATION_PARTNER,
	PRE_SET_OPTION_PARTNER,
	PRE_GET_CHECK_MENU,
	GET_CHECK_MENU,
	PRE_PATCH_PAIEMENT_TYPE,
	PRE_PATCH_FLOOR,
	PRE_PATCH_PRICE_CATEGORY,
	PRE_GENERATE_TOKEN_CMD_EN_LIGNE,
	PRE_CHANGE_PARTNER_CONNECTOR_STATUS,
	CHANGE_PARTNER_CONNECTOR_STATUS,
} from './types';
import { I18n } from 'react-redux-i18n';

import {
	CHANGE_ACTIVE_PARTNER,
	RECEIVE_PARTNERS,
} from '../../../Partners/service/partners/types';

import {
	take,
	put,
	call,
	all,
	actionChannel,
	select,
	takeLatest,
	race,
	fork,
} from 'redux-saga/effects';
import { requestHandler } from '../../../../service/fetcher.saga';
import { buffers, delay } from 'redux-saga';
import {
	ACTIVATE_UBEREATS_LINK,
	ADD_EXPORT,
	INIT_PARTNER_WITHOUT_MASK,
	PRE_ADD_EXPORT,
	PRE_DELETE_EXPORT,
	PRE_GENERATE_EXPORT,
} from '../export/types';
import Table from '../../Components/Binding/Components/Table';
import { notify } from '../../../../Components/Notifier/services/saga';
import { showConfirm } from '../../../../Components/ConfirmRedux/service/saga';
import videoActiveSocket from '../../../../video/inte_partenaires.mov';
import { RECEIVE_EXTERNAL_DATA } from '../../Components/ExternalDataAssignation/service/reducer';
import { handlePatchVariable } from '../../../VariableServer/service/saga';
import { getVariableServer } from '../../../../utils';
import { RestaurantRounded } from '@material-ui/icons';
import { notifyExportPartner } from '../export/saga';
import { RECEIVE_PARTNERS_DATA } from '../partnersData/types';

const ENTITY = 'partnersConfig';
const PRE_REQUEST_CHANGE_PARTNER_STEP = 'PRE_REQUEST_CHANGE_PARTNER_STEP';
const REQUEST_CHANGE_PARTNER_STEP_FINISHED =
	'REQUEST_CHANGE_PARTNER_STEP_FINISHED';
const PRE_REQUEST_CHANGE_PARTNER_STATUS = 'PRE_REQUEST_CHANGE_PARTNER_STATUS';
const REQUEST_CHANGE_PARTNER_STATUS_FINISHED =
	'REQUEST_CHANGE_PARTNER_STATUS_FINISHED';
const REQUEST_CHANGE_PARTNER_CONNECTOR_STATUS_FINISHED =
	'REQUEST_CHANGE_PARTNER_CONNECTOR_STATUS_FINISHED';
const PRE_REQUEST_GENERATE_TOKEN = 'PRE_REQUEST_GENERATE_TOKEN';
const REQUEST_GENERATE_TOKEN_FINISHED = 'REQUEST_GENERATE_TOKEN_FINISHED';
const PRE_REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER =
	'PRE_REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER';
const REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER_FINISHED =
	'REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER_FINISHED';
const PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK =
	'PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK';
const REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED =
	'REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED';
const PRE_REQUEST_VALIDATION_PARTNER_CHECKING =
	'PRE_REQUEST_VALIDATION_PARTNER_CHECKING';
const REQUEST_VALIDATION_PARTNER_CHECKING_FINISHED =
	'REQUEST_VALIDATION_PARTNER_CHECKING_FINISHED';
const PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT =
	'PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT';
const REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED =
	'REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED';
const PRE_REQUEST_POST_PARTNER_TO_MED_DEFAULT =
	'PRE_REQUEST_POST_PARTNER_TO_MED_DEFAULT';
const REQUEST_POST_PARTNER_TO_MED_DEFAULT_FINISHED =
	'REQUEST_POST_PARTNER_TO_MED_DEFAULT_FINISHED';
const PRE_REQUEST_PUT_PARTNER_LINK_AUTH = 'PRE_REQUEST_PUT_PARTNER_LINK_AUTH';
const REQUEST_PUT_PARTNER_LINK_AUTH_FINISHED =
	'REQUEST_PUT_PARTNER_LINK_AUTH_FINISHED';
const PRE_REQUEST_POST_PARTNER_CHECKING = 'PRE_REQUEST_POST_PARTNER_CHECKING';
const REQUEST_POST_PARTNER_CHECKING_FINISHED =
	'REQUEST_POST_PARTNER_CHECKING_FINISHED';
const PRE_REQUEST_POST_INITIALIZATION_PARTNER =
	'PRE_REQUEST_POST_INITIALIZATION_PARTNER';
// const PRE_REQUEST_POST_INITIALIZATION_PARTNER_MASK =
// 	'PRE_REQUEST_POST_INITIALIZATION_PARTNER_MASK';
const REQUEST_POST_INITIALIZATION_PARTNER_FINISHED =
	'REQUEST_POST_INITIALIZATION_PARTNER_FINISHED';
const REQUEST_POST_INITIALIZATION_PARTNER_MASK_FINISHED =
	'REQUEST_POST_INITIALIZATION_PARTNER_MASK_FINISHED';
const PRE_REQUEST_CHANGE_PARTNER_STATUS_UBER_EATS =
	'PRE_REQUEST_CHANGE_PARTNER_STATUS_UBER_EATS';
const PRE_REQUEST_SET_OPTION_PARTNER = 'PRE_REQUEST_SET_OPTION_PARTNER';
const REQUEST_OPTION_PARTNER_FINISHED = 'REQUEST_OPTION_PARTNER_FINISHED';
const PRE_REQUEST_GET_CHECK_MENU = 'PRE_REQUEST_GET_CHECK_MENU';
const REQUEST_GET_CHECK_MENU_FINISHED = 'REQUEST_GET_CHECK_MENU_FINISHED';
const PRE_REQUEST_PATCH_PAIEMENT_TYPE = 'PRE_REQUEST_PATCH_PAIEMENT_TYPE';
const REQUEST_PATCH_PAIEMENT_TYPE_FINISHED =
	'REQUEST_PATCH_PAIEMENT_TYPE_FINISHED';
const PRE_REQUEST_PATCH_FLOOR = 'PRE_REQUEST_PATCH_FLOOR';
const REQUEST_PATCH_FLOOR_FINISHED = 'REQUEST_PATCH_FLOOR_FINISHED';
const PRE_REQUEST_PATCH_PRICE_CATEGORY = 'PRE_REQUEST_PATCH_PRICE_CATEGORY';
const REQUEST_PATCH_PRICE_CATEGORY_FINISHED =
	'REQUEST_PATCH_PRICE_CATEGORY_FINISHED';
const PRE_REQUEST_GET_PARTNERS = 'PRE_REQUEST_GET_PARTNERS';
const PRE_REQUEST_GENERATE_TOKEN_CMD_EN_LIGNE =
	'PRE_REQUEST_GENERATE_TOKEN_CMD_EN_LIGNE';
const REQUEST_GENERATE_TOKEN_CMD_EN_LIGNE_FINISHED =
	'REQUEST_GENERATE_TOKEN_CMD_EN_LIGNE_FINISHED';

function* preChangePartnerStep() {
	const requestChannel = yield actionChannel(
		PRE_CHANGE_PARTNER_STEP,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);

		if (action.body) {
			yield put({
				type: PRE_REQUEST_CHANGE_PARTNER_STEP,
				body: action.body,
			});
			const { result } = yield take(REQUEST_CHANGE_PARTNER_STEP_FINISHED);
			yield put({
				type: CHANGE_PARTNER_STEP,
				data: result,
			});
		}
	}
}

function* watchChangePartnerStep() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_CHANGE_PARTNER_STEP);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'PUT',
			body,
			'configStep'
		);
		yield put({ type: REQUEST_CHANGE_PARTNER_STEP_FINISHED, result });
	}
}

function* preChangePartnerStatus() {
	const requestChannel = yield actionChannel(
		PRE_CHANGE_PARTNER_STATUS,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_CHANGE_PARTNER_STATUS,
				body: action.body,
			});
			const { result } = yield take(REQUEST_CHANGE_PARTNER_STATUS_FINISHED);
			yield put({
				type: CHANGE_PARTNER_STATUS,
				data: result,
			});
			if (
				(result && result.status === 'inactif') ||
				result.status === 'actif'
			) {
				yield put({
					type: CHANGE_ACTIVE_PARTNER,
					data: {
						prefix: action.body.prefix,
						active: result.status === 'actif',
					},
				});
			}
		}
	}
}

function* watchChangePartnerStatus() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_CHANGE_PARTNER_STATUS);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'PUT',
			body,
			'configStatus'
		);
		yield put({ type: REQUEST_CHANGE_PARTNER_STATUS_FINISHED, result });
	}
}

function* changePartnerConnectorStatus() {
	while (true) {
		const { body } = yield take(PRE_CHANGE_PARTNER_CONNECTOR_STATUS);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'PUT',
			body,
			'configConnectorStatus'
		);
		yield put({
			type: CHANGE_PARTNER_CONNECTOR_STATUS,
			data: result,
		});
	}
}

function* preChangePartnerStatusToMedDefault() {
	const requestChannel = yield actionChannel(
		PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			const isEnableDisable =
				action.body.status === 'actif' || action.body.status === 'inactif';

			let resultRequests = false;
			if (isEnableDisable) {
				const { partnersConfig } = yield select();
				const config = partnersConfig[action.body.partner_id];
				const isOnboarded = config && config.state === 'success';
				if (
					!action.bypassExportCheck &&
					isOnboarded &&
					action.body.status === 'actif' &&
					['ubereats', 'deliveroo'].includes(action.body.prefix)
				) {
					let haveExport = yield call(
						doesPartnerHaveExport,
						action.body.prefix
					);
					if (!haveExport) {
						yield call(
							notify,
							I18n.t('partner.need_export_to_activate'),
							'warning',
							10000
						);
						return false;
					}
				}

				const { partners } = yield select();

				const mustCheckIpadLiveness =
					partners &&
					partners[action.body.prefix] &&
					partners[action.body.prefix].haveMask;

				if (mustCheckIpadLiveness) {
					//const alive = yield call(checkLiveness, !isOnboarded);
					// if (!alive) {
					// 	yield put({
					// 		type: 'CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_RESULT',
					// 		success: false,
					// 	});
					// 	continue; // on arrete l'activation / désactivation ici
					// }
				}

				yield put({
					type: PRE_REQUEST_PUT_PARTNER_LINK_AUTH,
					body: action.body,
				});
				const result2 = yield take(REQUEST_PUT_PARTNER_LINK_AUTH_FINISHED);
				yield put({
					type: PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
					body: action.body,
				});
				const { result } = yield take(
					REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED
				);
				if (action.body.prefix === 'celladdition') {
					yield call(
						watchClickAndCollectLink,
						action.body.status === 'actif' ? true : false
					);
				}
				if (action.body.prefix === 'ubereats') {
					if (action.body.status !== 'actif') {
						yield call(
							watchUberEatsLink,
							action.body.status === 'actif' ? true : false
						);
					}

					if (
						action.body.status === 'actif' &&
						!action.bypassExportCheck && // on active pour faire un export, on va pas notifier de fair eun export hein !
						isOnboarded
					) {
						yield call(showConfirm, I18n.t('partner.must_do_export'), 'info');
					}
				}
				if (!result || (result && !result.resultGenerateMask)) {
					yield call(
						notify,
						I18n.t('partner.wrong_activation_desactivation'),
						'error'
					);
				}

				resultRequests =
					result &&
					result.resultActivePartner &&
					result.resultActivePartner.result === true &&
					result2.result &&
					result2.result.success === true;

				// on déclenche une synchro manuelle si c'est nécéssaire
				if (mustCheckIpadLiveness) {
					yield call(triggerSynchroIpad);
				}
			}

			if (!isEnableDisable || resultRequests) {
				yield put({
					type: PRE_CHANGE_PARTNER_STATUS,
					body: action.body,
				});
			}

			yield put({
				type: 'CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_RESULT',
				success: resultRequests,
			});
		}
	}
}

export function* doesPartnerHaveExport(prefix) {
	const { exports, partners } = yield select();
	let id_partner = partners && partners[prefix] ? partners[prefix].id : null;
	if (id_partner) {
		for (let e of exports.list) {
			if (
				e.id_partner === id_partner &&
				e.date_last_deployment &&
				e.date_last_deployment > 0
			)
				return true;
		}
	}
	return false;
}

export function* triggerSynchroIpad() {
	yield call(requestHandler, 'partners', 'POST', {}, 'synchroIpad');
}
export function* triggerSynchroMenu() {
	yield call(requestHandler, 'partners', 'POST', {}, 'synchroMenu');
}

function* preChangePartnerStatusUberEats() {
	while (true) {
		const action = yield take(PRE_REQUEST_CHANGE_PARTNER_STATUS_UBER_EATS);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
				body: action.body,
			});
			const { result } = yield take(
				REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED
			);
		}
	}
}

function* watchChangePartnerStatusToMedDefault() {
	while (true) {
		const { body } = yield take(
			PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT
		);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'PUT',
			body,
			'configStatusMedDefault'
		);
		yield put({
			type: REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED,
			result,
		});
	}
}

function* prePutPartnerToAuth() {
	const requestChannel = yield actionChannel(
		PRE_PUT_PARTNER_LINK_AUTH,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_PUT_PARTNER_LINK_AUTH,
				body: action.body,
			});
		}
	}
}

function* watchPutPartnerToAuth() {
	const requestChannel = yield actionChannel(
		PRE_REQUEST_PUT_PARTNER_LINK_AUTH,
		buffers.expanding()
	);
	while (true) {
		const { body } = yield take(requestChannel);
		const refresh_token = window.localStorage.refresh_token;
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'PUT',
			{ ...body, refresh_token },
			'partnerLinkAuth'
		);
		yield put({
			type: REQUEST_PUT_PARTNER_LINK_AUTH_FINISHED,
			result,
		});
	}
}

function* prePostPartnerToMedDefault() {
	const requestChannel = yield actionChannel(
		PRE_POST_PARTNER_TO_MED_DEFAULT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_POST_PARTNER_TO_MED_DEFAULT,
				body: action.body,
			});
			const { result } = yield take(
				REQUEST_POST_PARTNER_TO_MED_DEFAULT_FINISHED
			);
		}
	}
}

function* watchPostPartnerToMedDefault() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_PARTNER_TO_MED_DEFAULT);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'partnerMedDefault'
		);
		yield put({
			type: REQUEST_POST_PARTNER_TO_MED_DEFAULT_FINISHED,
			result,
		});
	}
}

//TODO: DELETE ?
function* preGenerateTokenCmdEnLgn() {
	while (true) {
		let idCmdEnLigne = '';
		const action = yield take(PRE_GENERATE_TOKEN_CMD_EN_LIGNE);
		if (action.body) {
			let stepInProgress = ['begin_config', 'set_room', 'success'];
			let step = action.body.step;
			if (step === 'begin_config') {
				const newBody = {
					active: 0,
					prefix: action.body.partnerPrefix,
					external_id: 0,
					id: action.body.partnerId,
				};
				yield put({
					type: PRE_POST_PARTNER_TO_MED_DEFAULT,
					body: newBody,
				});
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: stepInProgress[1],
				};
				yield put({
					type: PRE_REQUEST_GET_PARTNERS,
					body: { prefix: action.body.partnerPrefix },
				});
				yield take(RECEIVE_PARTNERS);
				yield put({
					type: INIT_PARTNER_WITHOUT_MASK,
					partnerPrefix: action.body.partnerPrefix,
				});
				const { result } = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
				if (
					result.status === 'success' &&
					result.data &&
					result.data[0] &&
					result.data[0].responseData &&
					result.data[0].responseData.result &&
					result.data[0].responseData.result.id
				) {
					idCmdEnLigne = result.data[0].responseData.result.id;
					yield put({
						type: PRE_REQUEST_GENERATE_TOKEN,
						body: { partnerId: action.body.partnerId, uuid: idCmdEnLigne },
					});
					const result2 = yield take(REQUEST_GENERATE_TOKEN_FINISHED);
					if (result2.result.success && action.body.partnerPrefix) {
						const newBody2 = {
							prefix: action.body.partnerPrefix,
							status: 'actif',
							id_user: idCmdEnLigne,
							partner_id: action.body.partnerId,
							uuid: idCmdEnLigne,
						};
						yield put({
							type: PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
							body: newBody2,
						});
						yield take(REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_FINISHED);

						yield put({
							type: PRE_CHANGE_PARTNER_STEP,
							body: newData,
						});
						const result3 = yield take(REQUEST_CHANGE_PARTNER_STEP_FINISHED);
						step = result3.result.step;
					}
				} else {
					yield call(
						notify,
						I18n.t('partner.config_error.init_partner'),
						'error'
					);
				}
			}

			if (step === 'set_partner') {
				let body = {
					partnerPrefix: action.body.partnerPrefix,
				};
				yield put({
					type: INIT_PARTNER_WITHOUT_MASK,
					body,
				});
				const { result } = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
				if (result.status === 'success') {
					idCmdEnLigne = result.result;
					yield put({
						type: PRE_REQUEST_GENERATE_TOKEN,
						body: { partnerId: action.body.partnerId, uuid: idCmdEnLigne },
					});
					const result2 = yield take(REQUEST_GENERATE_TOKEN_FINISHED);
					if (result2.result.success && action.body.partnerPrefix) {
						const newBody2 = {
							prefix: action.body.partnerPrefix,
							status: 'actif',
							id_user: idCmdEnLigne,
							partner_id: action.body.partnerId,
							uuid: idCmdEnLigne,
						};
						yield put({
							type: PRE_REQUEST_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
							body: newBody2,
						});
						const newData = {
							id_user: action.body.id,
							partner_id: action.body.partnerId,
							step: stepInProgress[2],
						};
						yield put({
							type: PRE_CHANGE_PARTNER_STEP,
							body: newData,
						});
						const result3 = yield take(REQUEST_CHANGE_PARTNER_STEP_FINISHED);
						step = result3.result.step;
					}
				}
			}
		}
	}
}

function* preGenerateToken() {
	while (true) {
		const action = yield take(PRE_GENERATE_TOKEN);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_GENERATE_TOKEN,
				body: {
					uuid: action.body.uuid,
					partnerId: action.body.partnerId,
				},
			});
			const { result } = yield take(REQUEST_GENERATE_TOKEN_FINISHED);
			if (result.success && action.body.partnerPrefix) {
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: action.body.step,
				};
				yield put({
					type: PRE_CHANGE_PARTNER_STEP,
					body: newData,
				});
				const newBody = {
					active: 0,
					prefix: action.body.partnerPrefix,
					external_id: action.body.uuid,
					step: action.body.step,
					id: action.body.partnerId,
				};
				yield put({
					type: PRE_POST_PARTNER_TO_MED_DEFAULT,
					body: newBody,
				});
			}
			yield put({
				type: GENERATE_TOKEN,
				data: result,
			});
		}
	}
}

//TODO: check si la fonction originale est utilisée autre part
function* preGenerateToken2() {
	while (true) {
		const action = yield take('PRE_GENERATE_TOKEN_2');
		if (action.body) {
			yield put({
				type: PRE_REQUEST_GENERATE_TOKEN,
				body: {
					uuid: action.body.uuid,
					partnerId: action.body.partnerId,
				},
			});
			const { result } = yield take(REQUEST_GENERATE_TOKEN_FINISHED);
			if (result.success && action.body.partnerPrefix) {
				const newBody = {
					active: 0,
					prefix: action.body.partnerPrefix,
					external_id: action.body.uuid,
					step: action.body.step,
					id: action.body.partnerId,
				};
				yield put({
					type: PRE_POST_PARTNER_TO_MED_DEFAULT,
					body: newBody,
				});
			}
			yield put({
				type: GENERATE_TOKEN,
				data: result,
			});
		}
	}
}

function* watchGenerateToken() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_GENERATE_TOKEN);
		const refresh_token = window.localStorage.refresh_token;
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			{ ...body, refresh_token },
			'generateToken'
		);
		yield put({ type: REQUEST_GENERATE_TOKEN_FINISHED, result });
	}
}

function* prePartnerChecking() {
	while (true) {
		const action = yield take(PRE_VALIDATION_PARTNER_CHECKING);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_VALIDATION_PARTNER_CHECKING,
				body: action.body,
			});
			const result = yield take(REQUEST_VALIDATION_PARTNER_CHECKING_FINISHED);
			if (result.result.data.status === 'success') {
				yield put({
					type: VALIDATION_PARTNER_CHECKING,
					data: { ...result, id: action.body.id },
				});
			} else {
				let labelError = 'partner.validation_partner_checking_error.';
				if (result.result.data.notAvailableOrigin) {
					labelError += 'not_available_origin';
				} else if (result.result.data.notAvailableSuite) {
					labelError += 'not_available_suite';
				} else {
					labelError += 'need_update';
				}
				yield call(
					notify,
					I18n.t('partner.prefix.' + action.body.prefix) +
						' : ' +
						I18n.t(labelError),
					'warning',
					10000
				);
			}
		}
	}
}

function* watchPartnerChecking() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_VALIDATION_PARTNER_CHECKING);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			{ ...body },
			'checking'
		);
		yield put({ type: REQUEST_VALIDATION_PARTNER_CHECKING_FINISHED, result });
	}
}

function* preValidationLinkPartnerToCustomer() {
	const requestChannel = yield actionChannel(
		PRE_VALIDATION_LINK_PARTNER_TO_CUSTOMER,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER,
				body: action.body,
			});
			const { result } = yield take(
				REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER_FINISHED
			);
			if (
				!result ||
				(!result.partnerCustomerId && action.body.state === 'callback_partner')
			) {
				yield call(
					notify,
					I18n.t('partner.prefix.' + action.body.prefix) +
						' : ' +
						I18n.t('partner.link_with_partner_missing'),
					'warning',
					10000
				);
			}

			if (result) {
				yield put({
					type: 'PATCH_PARTNER',
					data: {
						prefix: action.body.prefix,
						key: 'external_id',
						value: result.partnerCustomerId,
					},
				});
				yield put({
					type: VALIDATION_LINK_PARTNER_TO_CUSTOMER,
					data: result,
				});
			}
		}
	}
}

function* watchValidationLinkPartnerToCustomer() {
	while (true) {
		const { body } = yield take(
			PRE_REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER
		);
		const refresh_token = window.localStorage.refresh_token;
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'GET',
			{ ...body, refresh_token },
			'validLinkPartnerToCustomer'
		);
		yield put({
			type: REQUEST_VALIDATION_LINK_PARTNER_TO_CUSTOMER_FINISHED,
			result,
		});
	}
}

function* preValidationConnexionPartnerToCustomer() {
	const requestChannel = yield actionChannel(
		PRE_VALIDATION_CONNEXION_WITH_PARTNER_OK,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK,
				body: action.body,
			});
			const alive = true; // on ne fait plus de check liveness
			// const { alive } = yield take(
			// 	REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED
			// );
			// if (!alive && action.body.state === 'link_ipad_check') {
			// 	yield call(
			// 		notify,
			// 		I18n.t('partner.prefix.' + action.body.prefix) +
			// 			' : ' +
			// 			I18n.t('partner.connexion_with_partner_missing'),
			// 		'warning',
			// 		10000
			// 	);
			// }
			let resultReturn = {
				value: alive,
				partnerId: action.body.partner_id,
			};
			yield put({
				type: VALIDATION_CONNEXION_WITH_PARTNER_OK,
				data: resultReturn,
			});
		}
	}
}

function* watchValidationConnexionPartnerToCustomer() {
	while (true) {
		const { body } = yield take(
			PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK
		);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'GET',
			body,
			'validConnexionPartnerToCustomer'
		);
		yield put({
			type: REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED,
			alive: result && result.alive,
		});
	}
}

function* prePostPartnerSetUuid() {
	while (true) {
		const action = yield take(PRE_POST_PARTNER_CHECKING);
		if (action.body) {
			yield put({
				type: PRE_GENERATE_TOKEN,
				body: action.body,
			});
			yield take(REQUEST_GENERATE_TOKEN_FINISHED);
			yield put({
				type: 'REQUEST_SET_PROVISIONING',
			});
		}
	}
}

//TODO: refactor pour la passer en fonction* directement que l'on appelerai ?
function* watchPostPartnerChecking() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_PARTNER_CHECKING);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'partnerChecking'
		);
		yield put({ type: REQUEST_POST_PARTNER_CHECKING_FINISHED, result });
	}
}

function* prePostInitializationPartner() {
	while (true) {
		const action = yield take(PRE_POST_INITIALIZATION_PARTNER);
		if (action.body) {
			let stepInProgress = [
				'link_ipad_check',
				'init_mediation_ok',
				'option_partner_ok',
				'success',
			];
			let step = action.body.state;
			if (step === 'link_ipad_check') {
				const newBody = {
					prefix: action.body.partnerPrefix,
					status: 'actif',
					id_user: action.body.id,
					partner_id: action.body.partnerId,
				};
				yield put({
					type: PRE_REQUEST_CHANGE_PARTNER_STATUS_UBER_EATS,
					body: newBody,
				});
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: stepInProgress[1],
				};
				yield put({
					type: PRE_CHANGE_PARTNER_STEP,
					body: newData,
				});
				const { result } = yield take(REQUEST_CHANGE_PARTNER_STEP_FINISHED);
				step = result.step;
			}

			if (step === 'init_mediation_ok') {
				const newBody2 = {
					prefix: action.body.partnerPrefix,
				};
				yield put({
					type: PRE_REQUEST_SET_OPTION_PARTNER,
					body: newBody2,
				});
				let result2 = yield take(REQUEST_OPTION_PARTNER_FINISHED);
				result2 = result2.result;
				if (result2) {
					yield put({
						type: PRE_REQUEST_GET_PARTNERS,
						body: newBody2,
					});
					const newData = {
						id_user: action.body.id,
						partner_id: action.body.partnerId,
						step: stepInProgress[2],
					};
					yield put({
						type: PRE_CHANGE_PARTNER_STEP,
						body: newData,
					});
					const { result } = yield take(REQUEST_CHANGE_PARTNER_STEP_FINISHED);
					step = result.step;
				} else {
					yield call(
						notify,
						I18n.t('partner.wrong_option_partner'),
						'warning',
						10000
					);
				}
			}

			if (step === 'option_partner_ok') {
				const newBody3 = {
					prefix: action.body.partnerPrefix,
					customer_id: action.body.id,
					partner_id: action.body.partnerId,
					state: action.body.state,
				};
				yield put({
					type: PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK,
					body: newBody3,
				});
				let result3 = yield take(
					REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED
				);
				let valueConnexion = result3.alive;

				if (valueConnexion) {
					let resultReturn = {
						value: valueConnexion,
						partnerId: action.body.partnerId,
					};
					yield put({
						type: VALIDATION_CONNEXION_WITH_PARTNER_OK,
						data: resultReturn,
					});
				} else {
					yield call(checkForIpadDevice);
					yield call(
						notify,
						I18n.t('partner.connexion_with_partner_missing'),
						'warning',
						10000
					);
				}
			}
		}
	}
}

function* watchPostInitializationPartner() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_INITIALIZATION_PARTNER);
		//const refresh_token = window.localStorage.refresh_token;
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'partnerInitialization'
		);
		yield put({ type: REQUEST_POST_INITIALIZATION_PARTNER_FINISHED, result });
	}
}

function* preSetOptionPartner() {
	while (true) {
		const action = yield take(PRE_SET_OPTION_PARTNER);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_SET_OPTION_PARTNER,
				body: action.body,
			});
			const result = yield take(REQUEST_OPTION_PARTNER_FINISHED);

			if (result && result.result && result.result.options) {
				yield put({
					type: 'PATCH_PARTNER',
					data: {
						prefix: action.body.prefix,
						key: 'options',
						value: result.result.options,
					},
				});
			}
		}
	}
}

function* watchSetOptionPartner() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_SET_OPTION_PARTNER);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'optionPartner'
		);
		yield put({ type: REQUEST_OPTION_PARTNER_FINISHED, result });
	}
}

export function* setOptionPartner(partner_prefix) {
	yield put({
		type: PRE_SET_OPTION_PARTNER,
		body: { prefix: partner_prefix },
	});
	const { result } = yield take('REQUEST_OPTION_PARTNER_FINISHED');

	return result;
}

function* preGetCheckMenu() {
	while (true) {
		const action = yield take(PRE_GET_CHECK_MENU);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_GET_CHECK_MENU,
				body: action.body,
			});
			const { result } = yield take(REQUEST_GET_CHECK_MENU_FINISHED);
			const newBody = {
				partnerId: action.body.partnerId,
				...result,
			};
			yield put({
				type: GET_CHECK_MENU,
				data: newBody,
			});
		}
	}
}

function* watchGetCheckMenu() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_GET_CHECK_MENU);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'GET',
			body,
			'checkMenu'
		);
		yield put({ type: REQUEST_GET_CHECK_MENU_FINISHED, result });
	}
}

function* prePatchPaiementType() {
	while (true) {
		const action = yield take(PRE_PATCH_PAIEMENT_TYPE);
		if (action.body) {
			if (action.body.id_payment_type) {
				yield put({
					type: PRE_REQUEST_PATCH_PAIEMENT_TYPE,
					body: action.body,
				});
				const { result } = yield take(REQUEST_PATCH_PAIEMENT_TYPE_FINISHED);
				if (result) {
					const newData = {
						id_user: action.body.id,
						partner_id: action.body.partnerId,
						step: action.body.step,
					};
					yield put({
						type: PRE_CHANGE_PARTNER_STEP,
						body: newData,
					});
				}
			} else {
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: 'set_paiement',
				};
				yield put({
					type: PRE_CHANGE_PARTNER_STEP,
					body: newData,
				});
				yield call(notify, I18n.t('partner.paiement_empty'), 'warning', 10000);
			}
		}
	}
}

export function* patchPartnerPaiementType(body) {
	if (body) {
		yield put({
			type: PRE_REQUEST_PATCH_PAIEMENT_TYPE,
			body: body,
		});
		const { result } = yield take(REQUEST_PATCH_PAIEMENT_TYPE_FINISHED);
		if (result) {
			yield put({
				type: 'PATCH_PARTNER',
				data: {
					prefix: body.prefix,
					key: 'id_payment_type',
					value: body.id_payment_type,
				},
			});
		}
		return result;
	}
}

function* watchPatchPaiementType() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_PATCH_PAIEMENT_TYPE);
		const { result } = yield call(
			requestHandler,
			'binding',
			'PATCH',
			body,
			'paymentType'
		);
		yield put({ type: REQUEST_PATCH_PAIEMENT_TYPE_FINISHED, result });
	}
}

function* prePatchFloor() {
	while (true) {
		const action = yield take(PRE_PATCH_FLOOR);
		if (action.body) {
			if (action.body.id_table) {
				yield put({
					type: PRE_REQUEST_PATCH_FLOOR,
					body: action.body,
				});
				const { result } = yield take(REQUEST_PATCH_FLOOR_FINISHED);
				if (result) {
					const newData = {
						id_user: action.body.id,
						partner_id: action.body.partnerId,
						step: action.body.step,
					};
					yield put({
						type: PRE_CHANGE_PARTNER_STEP,
						body: newData,
					});
				}
			} else {
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: 'set_room',
				};
				yield put({
					type: PRE_CHANGE_PARTNER_STEP,
					body: newData,
				});
				yield call(notify, I18n.t('partner.room_empty'), 'warning', 10000);
			}
		}
	}
}

export function* patchPartnerFloor(body) {
	if (body) {
		yield put({
			type: PRE_REQUEST_PATCH_FLOOR,
			body: body,
		});
		const { result } = yield take(REQUEST_PATCH_FLOOR_FINISHED);
		if (result) {
			yield put({
				type: 'PATCH_PARTNER',
				data: { prefix: body.prefix, key: 'id_table', value: body.id_table },
			});
		}
		return result;
	}
}

function* watchPatchFloor() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_PATCH_FLOOR);
		const { result } = yield call(
			requestHandler,
			'binding',
			'PATCH',
			body,
			'floor'
		);
		yield put({ type: REQUEST_PATCH_FLOOR_FINISHED, result });
	}
}

function* prePatchPriceCategory() {
	while (true) {
		const action = yield take(PRE_PATCH_PRICE_CATEGORY);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_PATCH_PRICE_CATEGORY,
				body: action.body,
			});
			const { result } = yield take(REQUEST_PATCH_PRICE_CATEGORY_FINISHED);
			if (result) {
				const newData = {
					id_user: action.body.id,
					partner_id: action.body.partnerId,
					step: action.body.step,
				};
				yield put({
					type: PRE_CHANGE_PARTNER_STEP,
					body: newData,
				});
			}
		}
	}
}

export function* patchPartnerPriceCategory(body) {
	if (body) {
		yield put({
			type: PRE_REQUEST_PATCH_PRICE_CATEGORY,
			body: body,
		});
		const { result } = yield take(REQUEST_PATCH_PRICE_CATEGORY_FINISHED);
		if (result) {
			yield put({
				type: 'PATCH_PARTNER',
				data: {
					prefix: body.prefix,
					key: 'id_price_category',
					value: body.id_price_category,
				},
			});
		}
		return result;
	}
}

function* watchPatchPriceCategory() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_PATCH_PRICE_CATEGORY);
		const { result } = yield call(
			requestHandler,
			'binding',
			'PATCH',
			body,
			'priceCategory'
		);
		yield put({ type: REQUEST_PATCH_PRICE_CATEGORY_FINISHED, result });
	}
}

function* watchGetPartners() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_GET_PARTNERS);
		const refresh_token = window.localStorage.refresh_token;
		const { result } = yield call(requestHandler, 'partners', 'GET', {
			refresh_token,
		});
		const data = result;
		yield put({ type: RECEIVE_PARTNERS, data });
	}
}

export function* refreshPartners() {
	yield put({
		type: PRE_REQUEST_GET_PARTNERS,
		body: {},
	});
	yield take(RECEIVE_PARTNERS);
}

function* UbearEatsSetOrderManager() {
	const { orderManager, partnerData } = yield take(
		'UBEREATS_SET_ORDER_MANAGER'
	);
	yield call(handlePatchVariable, {
		key: 'ubereatsOrderManager',
		value: orderManager,
	});
	if (orderManager) yield call(mailUberEatsOrderManager);
	else
		yield call(handlePatchVariable, {
			key: 'ubereatsOrderValidation',
			value: 0,
		});

	yield call(watchUberEatsLink, partnerData.integration_enabled, partnerData);
	yield put({
		type: RECEIVE_PARTNERS_DATA,
		data: partnerData,
		prefix: 'ubereats',
	});
}

export function* watchUberEatsLink(active, partnerData = {}) {
	yield put({
		type: ACTIVATE_UBEREATS_LINK,
		partnerPrefix: 'ubereats',
		action: active ? 'enable' : 'disable',
		partnerData,
	});
}

export function* watchUberEatsLinkSaga() {
	while (true) {
		const { action, partnerData } = yield take(ACTIVATE_UBEREATS_LINK);
		yield put({
			type: INIT_PARTNER_WITHOUT_MASK,
			partnerPrefix: 'ubereats',
			action,
		});
		yield put({
			type: RECEIVE_PARTNERS_DATA,
			data: { integration_enabled: true, ...partnerData },
			prefix: 'ubereats',
		});
	}
}

export function* watchClickAndCollectLink(active) {
	yield put({
		type: INIT_PARTNER_WITHOUT_MASK,
		partnerPrefix: 'celladdition',
		action: active ? 'enable' : 'disable',
	});
	const { result } = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
	if (result && result.data && result.data[0]) {
		return {
			result: result.data[0].responseData.result.id,
			status: result.data[0].status,
		};
	} else {
		return {
			status: 'error',
			result: null,
		};
	}
}

export function* initPartner(prefix, active) {
	yield put({
		type: INIT_PARTNER_WITHOUT_MASK,
		partnerPrefix: prefix,
		action: active ? 'enable' : 'disable',
	});
	const { result } = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
	if (result && result.data && result.data[0]) {
		return {
			result: result.data[0].responseData.result,
			success: result.data[0].status === 'success',
		};
	} else {
		return {
			success: false,
			result: null,
		};
	}
}

export function* watchInitPartnerWithoutMask() {
	while (true) {
		const { partnerPrefix, action } = yield take(INIT_PARTNER_WITHOUT_MASK);
		const { service, partnersConfig } = yield select();

		let partnerId = null;
		for (let partner of Object.values(partnersConfig)) {
			if (partner.prefix === partnerPrefix) {
				partnerId = partner.partner_id;
				break;
			}
		}

		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			{
				partnerPrefix: partnerPrefix,
				partner_id: partnerId,
				action: action || 'enable',
				id: service.user.id,
				refresh_token: window.localStorage.refresh_token,
			},
			'initWithoutMask'
		);
		if (result.status !== 'success') {
			yield call(
				notify,
				I18n.t('partner.wrong_initialization'),
				'warning',
				10000
			);
		}
		yield put({ type: 'INIT_PARTNER_WITHOUT_MASK_FINISHED', result });
	}
}

/**
 * Insere un partenaire dans la base client a partir des infos dans med default puis recupere et store ce partenaire dans redux
 * @param {Object} data
 * @param {Object} data.partnerPrefix
 * @param {Object} data.partnerId
 */
export function* setPartnerMedDefaut(data) {
	const body = {
		active: 0,
		prefix: data.partnerPrefix,
		external_id: 0,
		id: data.partnerId,
	};
	yield put({
		type: PRE_POST_PARTNER_TO_MED_DEFAULT,
		body: body,
	});

	//TODO: externalise ?
	yield put({
		type: PRE_REQUEST_GET_PARTNERS,
		body: { prefix: data.partnerPrefix },
	});
	yield take(RECEIVE_PARTNERS);
}

// elle fait ce que fait preGenerateTOken2 mais sans le changement d'étape
export function* preGenerateLinkPartnerToCustomer(body) {
	yield put({
		type: PRE_REQUEST_GENERATE_TOKEN,
		body: body,
	});
	const { result } = yield take(REQUEST_GENERATE_TOKEN_FINISHED);
	return { success: result.success, result: result };
}

function* initRequestApiLoyaltyDiscount() {
	while (true) {
		let { prefix } = yield take('INIT_API_LOYALTY_DISCOUNT');
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix },
			'initLoyaltyDiscount'
		);
		yield put({ type: 'INIT_API_LOYALTY_DISCOUNT_FINISHED', result });
	}
}

export function* initApiLoyaltyDiscount(prefix) {
	yield put({ type: 'INIT_API_LOYALTY_DISCOUNT', prefix });
	let { result } = yield take('INIT_API_LOYALTY_DISCOUNT_FINISHED');
	return result && result.success;
}

function* initRequestApiLoyaltyPaymentType() {
	while (true) {
		let { prefix } = yield take('INIT_API_LOYALTY_PAYMENT_TYPE');
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix },
			'initLoyaltyPaymentType'
		);
		yield put({ type: 'INIT_API_LOYALTY_PAYMENT_TYPE_FINISHED', result });
	}
}

export function* initApiLoyaltyPaymentType(prefix) {
	yield put({ type: 'INIT_API_LOYALTY_PAYMENT_TYPE', prefix });
	let { result } = yield take('INIT_API_LOYALTY_PAYMENT_TYPE_FINISHED');

	return result && result.success;
}

/**
 * Va regarder si il existe une ligne dans link_partner_to_customer avec le combo partner_id / partner_customer_id, renvoie false si aucune ligne
 * @param {Object} body
 * @param {Int | String} body.partner_id
 * @param {Int | String} body.partner_customer_id
 * @returns object with "exist" : false if no occurency
 */
export function* checkIfPartnerCustomerExist(body) {
	const refresh_token = window.localStorage.refresh_token;
	yield put({
		type: 'PRE_REQUEST_CHECK_IF_PARTNER_CUSTOMER_EXIST',
		body: { ...body, refresh_token },
	});
	const { result } = yield take(
		'REQUEST_CHECK_IF_PARTNER_CUSTOMER_EXIST_FINISHED'
	);
	return result;
}

function* watchCheckIfPartnerCustomerExist() {
	while (true) {
		let { body } = yield take('PRE_REQUEST_CHECK_IF_PARTNER_CUSTOMER_EXIST');
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			body,
			'checkIfPartnerCustomerExist'
		);
		yield put({
			type: 'REQUEST_CHECK_IF_PARTNER_CUSTOMER_EXIST_FINISHED',
			result,
		});
	}
}

export function* isMulti() {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'GET',
		null,
		'isMulti'
	);
	return result && result.isMulti;
}

export function* setApiMask(data) {
	yield put({
		type: PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
		body: data,
	});
	let res = yield take('CHANGE_PARTNER_STATUS_TO_MED_DEFAULT_RESULT');
	return res.success;
}

export function* checkLiveness(showNotif = true) {
	yield put({
		type: PRE_REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK,
		body: {},
	});
	const resultLiveness = yield take(
		REQUEST_VALIDATION_CONNEXION_WITH_PARTNER_OK_FINISHED
	);

	if (showNotif && !resultLiveness.alive) {
		yield call(showConfirm, I18n.t('partner.must_sync_ipad'), 'info');
	}
	return true;
}

export function* generateIpadToken() {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ refreshToken: window.localStorage.refresh_token },
		'ipadToken'
	);
	let token = result && result.ipadToken ? result.ipadToken : false;

	if (!token) {
		yield call(
			notify,
			I18n.t('partner.onboarding_common_errors.general'),
			'error',
			10000
		);
	}

	return token;
}

export function* initApiData(prefix) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ prefix },
		'initApiData'
	);
	if (!result) {
		yield call(
			notify,
			I18n.t('partner.onboarding_common_errors.general'),
			'error',
			10000
		);
	}

	return result;
}

export function* initApiVariable(type, key, value) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ type, key, value },
		'apiVariable'
	);
	if (!result) {
		yield call(
			notify,
			I18n.t('partner.onboarding_common_errors.general'),
			'error',
			6000
		);
	} else {
		yield put({ type: 'UPATE_VARIABLE', key, value });
	}

	return result;
}

export function* addAuthGroup(groups) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ groups, refreshToken: window.localStorage.refresh_token },
		'userGroup'
	);

	return result;
}

export function* initEntities(prefix) {
	const resultInit = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ prefix: prefix },
		'initPartnerEntities'
	);
	return resultInit && resultInit.result && resultInit.result.success;
}

export function* initExternalData(prefix) {
	const { result } = yield call(
		requestHandler,
		'externalData',
		'POST',
		{ prefix: prefix },
		'init'
	);
	yield put({ type: RECEIVE_EXTERNAL_DATA, data: result.data.result });
	return result && result.success;
}

export function* setActiveAuthLink(active, user_id, partner_id) {
	yield put({
		type: PRE_REQUEST_PUT_PARTNER_LINK_AUTH,
		body: {
			actif: active ? 'actif' : 'inactif',
			id_user: user_id,
			partner_id,
		},
	});
	const result2 = yield take(REQUEST_PUT_PARTNER_LINK_AUTH_FINISHED);
	return true;
}

function* redirectToExternalConfig() {
	const { prefix } = yield take('REDIRECT_EXTERNAL_CONFIG');
	const env = yield call(getEnv);
	let urlRedirect = null;
	switch (prefix) {
		case 'laddition':
			urlRedirect = getReservationUrl(env);
			break;
		case 'ouilink':
			urlRedirect = getLadditionAchatUrl();
			yield call(activePartnerConfig, 'ouilink');
			break;
		default:
			break;
	}

	if (urlRedirect) {
		yield call(
			notify,
			I18n.t('partner.external_config_redirection'),
			'info',
			10000
		);
		yield delay(2500);
		window.location.href = urlRedirect;
	}
}

function* redirectToConnectorPayment() {
	yield take('REDIRECT_CONNECTOR_PAYMENT');
	let urlRedirect = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';

	if (urlRedirect) {
		yield call(
			notify,
			I18n.t('partner.configuration_connector.redirect'),
			'info'
		);
		yield delay(2500);
		window.location.href = urlRedirect;
	}
}

function getEnv() {
	if (window.location && window.location.hostname === 'localhost') {
		return 'local';
	} else if (
		window.location &&
		window.location.hostname.includes('laddit.io')
	) {
		return 'dev';
	} else {
		return 'prod';
	}
}

export function getReservationUrl(env) {
	if (env === 'local') {
		return 'http://localhost:3000?scope=la-resa';
	} else if (env === 'dev') {
		return 'https://reservation.laddit.io/admin/';
	} else {
		return 'https://reservation.laddition.com/admin/';
	}
}

export function getCALiveUrl(env) {
	if (env === 'local') {
		return 'http://localhost:49611/'; // je connais pas l'url mais bon osef en local
	} else if (env === 'dev') {
		return 'https://live.laddit.io/;';
	} else {
		return 'https://live.laddition.com/';
	}
}

function getReportingUrl(env) {
	if (env === 'local') {
		return 'http://localhost:49611/';
	} else if (env === 'dev') {
		return 'https://reporting.laddit.io/';
	} else {
		return 'https://reporting.laddition.com/';
	}
}

export function getLadditionAchatUrl() {
	return getReportingUrl(getEnv()) + 'ouiLink/?from=services';
}

const LIMIT_NOTIF_LEAD = 24 * 3600 * 1000; // 24h
function* handlePartnerProspect() {
	while (true) {
		const { prefix } = yield take('HANDLE_PARTNER_PROSPECT');

		const { variableServer } = yield select();
		let notifPartner = getVariableServer(variableServer, 'leadNotif' + prefix);
		const now = Date.now();

		let canNotif =
			!notifPartner ||
			(notifPartner && now - parseInt(notifPartner) > LIMIT_NOTIF_LEAD);

		const localeParam = { partner: I18n.t('partner.prefix.' + prefix) };
		if (canNotif) {
			yield call(
				showConfirm,
				I18n.t('partner.prospect_lead', localeParam),
				'confirm',
				{
					eventOnValid: 'QUERY_PARTNER_PROSPECT',
					paramsOnValid: { prefix },
				}
			);
		} else {
			yield call(
				showConfirm,
				I18n.t('partner.lead_partner_notif_ok', localeParam),
				'info'
			);
		}
	}
}

function* queryPartnerProspect() {
	while (true) {
		const { prefix } = yield take('QUERY_PARTNER_PROSPECT');
		const localeParam = { partner: I18n.t('partner.prefix.' + prefix) };
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix, partner_name: I18n.t('partner.prefix.' + prefix) },
			'prospect'
		);
		if (result) {
			yield call(
				notify,
				I18n.t('partner.lead_partner_notif_ok', localeParam),
				'success'
			);
			yield call(handlePatchVariable, {
				key: 'leadNotif' + prefix,
				value: Date.now(),
			});
		} else {
			yield call(
				notify,
				I18n.t('partner.lead_partner_notif_ko', localeParam),
				'warning'
			);
		}
	}
}

export function* queryPartnerIntegration(prefix, customer_id) {
	const localeParam = { partner: I18n.t('partner.prefix.' + prefix) };
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ prefix, partner_name: I18n.t('partner.prefix.' + prefix), customer_id },
		'integrationEmail'
	);
}

function* notifPartnerConnector() {
	while (true) {
		const { prefix } = yield take('NOTIF_PARTNER_CONNECTOR');
		yield call(showConfirm, I18n.t('partner.connector_confirm'), 'confirm', {
			eventOnValid: 'QUERY_NOTIF_PARTNER_CONNECTOR',
			paramsOnValid: { prefix },
		});
	}
}

function* queryNotifPartnerConnector() {
	while (true) {
		const { prefix } = yield take('QUERY_NOTIF_PARTNER_CONNECTOR');
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix },
			'notif-connector'
		);
		if (result) {
			yield call(notify, I18n.t('partner.connector_confirm_ok'), 'success');
		} else {
			yield call(notify, I18n.t('partner.connector_confirm_ko'), 'warning');
		}
	}
}

function* activePartnerConfig(prefix) {
	const { partnersConfig, service } = yield select();
	let partner = Object.values(partnersConfig).find(p => p.prefix === prefix);
	const id_user = service.user.id;
	const partner_id = partner.partner_id;

	yield put({
		type: PRE_CHANGE_PARTNER_STEP,
		body: {
			id_user,
			partner_id,
			step: 'success',
		},
	});
	yield put({
		type: PRE_CHANGE_PARTNER_STATUS,
		body: {
			id_user,
			partner_id,
			status: 'actif',
		},
	});

	yield all([take(CHANGE_PARTNER_STEP), take(CHANGE_PARTNER_STATUS)]);
}

function* checkForIpadDevice() {
	if (isDeviceiPad()) {
		yield call(showConfirm, I18n.t('partner.using_ipad'), 'info', {
			options: { confirmLabel: I18n.t('general.i_get_it') },
		});
	}
}

function isDeviceiPad() {
	return navigator.platform.match(/iPad/i) != null;
	return /iPad/.test(navigator.userAgent);
}

function* cancelNotePms() {
	while (true) {
		const { prefix, pmsId } = yield take('CANCEL_NOTE_PMS');

		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix, pmsId },
			'cancelNotePms'
		);

		if (result && result.success) {
			yield call(
				notify,
				I18n.t('partner.misterBooking.cancelNoteSuccess', { pmsId }),
				'success'
			);
		} else {
			yield call(
				notify,
				I18n.t('partner.misterBooking.cancelNoteFail', { pmsId }),
				'warning'
			);
		}
	}
}

export function* unlinkPartnerWS(prefix) {
	yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ prefix },
		'unlinkPartnerWS'
	);
}

export function* linkLoyaltyWebView(data) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		data,
		'webviewLoyaltyAuthLink'
	);

	return !!result;
}

export function* initAndGenerateExport(partner_prefix) {
	const { partners } = yield select();
	const partner = partners[partner_prefix];
	const exp = {
		name: 'Export init ' + partner_prefix,
		id_partner: partner.id,
		date_available: Math.floor(+new Date() / 1000),
		is_current: true,
		unique_section: false,
		sections: [],
		json: [
			{
				id: '1',
				name: 'section',
				weigth: 1,
				products: [
					{
						id: 'TARZAN-971-33',
						menu_levels: [],
						type: 'product',
						name: 'Produit',
						price: 2000,
						description: '',
						weight: 0,
						extraCategories: [],
						tax_rate: 1000,
						tax_rate_take_away: 550,
						disabled: false,
					},
				],
				isHub: false,
				required: true,
			},
		],
	};
	yield put({
		type: PRE_ADD_EXPORT,
		body: exp,
	});
	const resAdd = yield take(ADD_EXPORT);
	yield call(notifyExportPartner, partner_prefix);

	yield put({ type: PRE_DELETE_EXPORT, body: { idExport: resAdd.body.id } });
	yield take('DELETE_EXPORT');

	return true;
}

function* mailUberEatsOrderManager() {
	yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{},
		'ubereatsOrderManagerMail'
	);
}

export function* generateExternalId() {
	const { result } = yield call(
		requestHandler,
		'variable',
		'POST',
		{},
		'generateExternalId'
	);

	return { success: !!result, result };
}

function* saveTips() {
	while (true) {
		const { body } = yield take('SAVE_TICKET_TIPS');

		const { result } = yield call(
			requestHandler,
			'partners',
			'POST',
			{ refreshToken: window.localStorage.refresh_token, body },
			'payplusTip'
		);

		yield put({
			type: RECEIVE_PARTNERS_DATA,
			data: body,
			prefix: 'ladditionTicket',
		});
		if (result && result.ok)
			yield call(
				notify,
				I18n.t('partner.ladditionTicket.tips.save_success'),
				'success',
				10000
			);
		else
			yield call(
				notify,
				I18n.t('partner.ladditionTicket.tips.save_fail'),
				'warning',
				10000
			);
	}
}

export function* unLinkOrderPartners(id_user) {
	const { partners } = yield select();
	const partnersToUnlink = partners
		? Object.values(partners).filter(p =>
				['deliveroo', 'ubereats'].includes(p.prefix)
		  )
		: [];
	if (partnersToUnlink.length) {
		yield call(
			showConfirm,
			I18n.t('partner.unlink_order_partners'),
			'confirm',
			{
				eventOnValid: 'UNLINK_ORDER_PARTNERS',
				paramsOnValid: { partners: partnersToUnlink, id_user },
			}
		);
		yield race({
			finished: take('VALID_CONFIRM'),
			cancel: take('CANCEL_CONFIRM'),
		});
	}
}

function* unLinkOrderPartnersSaga() {
	const { partners, id_user } = yield take('UNLINK_ORDER_PARTNERS');
	for (const p of partners) {
		yield put({
			type: PRE_CHANGE_PARTNER_STATUS_TO_MED_DEFAULT,
			body: {
				status: 'inactif',
				prefix: p.prefix,
				id_user: id_user,
				partner_id: p.id,
			},
			bypassExportCheck: true,
		});
	}
}

/*


*/

export default function* indexSaga() {
	yield all([
		preChangePartnerStep(),
		watchChangePartnerStep(),
		preChangePartnerStatus(),
		watchChangePartnerStatus(),
		preGenerateToken(),
		watchGenerateToken(),
		preValidationLinkPartnerToCustomer(),
		watchValidationLinkPartnerToCustomer(),
		preValidationConnexionPartnerToCustomer(),
		watchValidationConnexionPartnerToCustomer(),
		prePartnerChecking(),
		watchPartnerChecking(),
		preChangePartnerStatusToMedDefault(),
		watchChangePartnerStatusToMedDefault(),
		prePostPartnerToMedDefault(),
		watchPostPartnerToMedDefault(),
		prePutPartnerToAuth(),
		watchPutPartnerToAuth(),
		prePostPartnerSetUuid(),
		watchPostPartnerChecking(),
		prePostInitializationPartner(),
		watchPostInitializationPartner(),
		preChangePartnerStatusUberEats(),
		preSetOptionPartner(),
		watchSetOptionPartner(),
		preGetCheckMenu(),
		watchGetCheckMenu(),
		prePatchPaiementType(),
		watchPatchPaiementType(),
		prePatchFloor(),
		watchPatchFloor(),
		prePatchPriceCategory(),
		watchPatchPriceCategory(),
		watchGetPartners(),
		preGenerateTokenCmdEnLgn(),
		watchUberEatsLinkSaga(),
		watchInitPartnerWithoutMask(),
		// watchPostInitializationPartnerMask(),
		//prePostInitPartnerMask(),
		initRequestApiLoyaltyDiscount(),
		initRequestApiLoyaltyPaymentType(),
		watchCheckIfPartnerCustomerExist(),
		preGenerateToken2(),
		redirectToExternalConfig(),
		redirectToConnectorPayment(),
		changePartnerConnectorStatus(),
		handlePartnerProspect(),
		queryPartnerProspect(),
		notifPartnerConnector(),
		queryNotifPartnerConnector(),
		cancelNotePms(),
		UbearEatsSetOrderManager(),
		saveTips(),
		unLinkOrderPartnersSaga(),
	]);
}
