import { PRE_UPDATE_SESSION, PRE_PATCH_VARIABLE } from './types';

export const patchVariable = body => {
	return {
		type: PRE_PATCH_VARIABLE,
		body,
	};
};

export const updateSession = () => ({ type: PRE_UPDATE_SESSION });
