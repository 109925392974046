import React, { Component, PureComponent } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { PRE_SUBMIT_BINDING } from './service/types';
import ButtonMui from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { getPartnerByPrefix } from './service/selector';
import PaymentType from './Components/PaymentType';
import PriceCategory from './Components/PriceCategory';
import Table from './Components/Table';
import { deepEqual } from '../../utils';
import { getVariableServer } from '../../../../utils';

class Biding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			selectedPaymentType: '',
			selectedTable: '',
			selectedPriceCategory: '',
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (deepEqual(this.props, nextProps) && deepEqual(this.state, nextState)) {
			return false;
		}

		return true;
	}

	openModal = () => {
		let selectedPaymentType = this.checkOrReplaceEntity(
			this.props.partner.id_payment_type,
			this.props.paymentTypes
		);
		let selectedTable = this.checkOrReplaceEntity(
			this.props.partner.id_table,
			this.props.floors
		);
		let selectedPriceCategory = this.checkOrReplaceEntity(
			this.props.partner.id_price_category,
			this.props.priceCategories
		);

		this.setState({
			open: true,
			selectedPaymentType,
			selectedTable,
			selectedPriceCategory,
		});
	};

	checkOrReplaceEntity = (id, list) => {
		if (!id) return id;
		if (!list.find(p => p.id == id) && list.length) {
			return list[0].id;
		} else {
			return id;
		}
	};

	closeModal = () => {
		this.setState({
			open: false,
			selectedPaymentType: '',
			selectedTable: '',
			selectedPriceCategory: '',
		});
	};

	onChange = (entity, value) => {
		this.setState({ [entity]: value });
	};

	onValid = e => {
		e.preventDefault();
		const asso = {
			prefix: this.props.prefix,
			id_payment_type: this.state.selectedPaymentType || '',
			id_table: this.state.selectedTable || '',
			id_price_category: this.state.selectedPriceCategory || '',
		};
		this.props.submitBinding(asso);
		this.closeModal();
	};

	render() {
		const {
			hidePriceCategory,
			hideTable,
			hidePayment,
			asso_disable,
		} = this.props;

		if (asso_disable == 1) {
			return null;
		}

		const btnDisabled =
			this.state.selected === '' ||
			this.state.selected === this.props.partner.id_payment_type;
		const name = I18n.t(`partner.prefix.${this.props.prefix}`);

		let labelButton = I18n.t('binding.button');
		let assos = [];
		if (!hidePriceCategory) {
			assos.push(I18n.t('binding.button_label_price'));
		}
		if (!hideTable) {
			assos.push(I18n.t('binding.button_label_table'));
		}
		if (!hidePayment) {
			assos.push(I18n.t('binding.button_label_payment'));
		}
		labelButton += ` ${assos.join(', ')}`;

		return (
			<div className="gestion-button-container">
				<div>
					<ButtonMui onClick={this.openModal} variant={'outlined'}>
						{labelButton}
					</ButtonMui>
					<Dialog
						onClose={this.closeModal}
						open={this.state.open}
						id={'dialog-payment-types'}>
						<DialogTitle>
							<Translate value={'binding.title'} partner={name} />
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<Translate value={'binding.help'} partner={name} />
							</DialogContentText>
							{hidePayment ? null : (
								<PaymentType
									selected={this.state.selectedPaymentType || ''}
									list={this.props.paymentTypes}
									onChange={this.onChange}
									required={true}
								/>
							)}

							{hideTable ? null : (
								<Table
									selected={this.state.selectedTable || ''}
									list={this.props.floors}
									onChange={this.onChange}
									required={true}
								/>
							)}

							{hidePriceCategory ? null : (
								<PriceCategory
									selected={this.state.selectedPriceCategory || ''}
									list={this.props.priceCategories}
									onChange={this.onChange}
								/>
							)}
						</DialogContent>
						<DialogActions>
							{' '}
							<ButtonMui onClick={this.closeModal}>
								<Translate value={'general.cancel'} />
							</ButtonMui>
							<ButtonMui
								onClick={this.onValid}
								disabled={btnDisabled}
								color="primary"
								autoFocus>
								<Translate value={'general.valid'} />
							</ButtonMui>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		);
	}
}

Biding.defaultProps = {
	prefix: '0',
};

Biding.propTypes = {
	prefix: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
	const assoDisabled = getVariableServer(
		state.variableServer,
		'partner_asso_disable'
	);
	return {
		paymentTypes: state.binding.paymentType,
		floors: state.binding.floor,
		priceCategories: state.binding.priceCategory,
		partner: getPartnerByPrefix(state, props),
		asso_disable: assoDisabled == '1' && props.prefix != 'celladdition',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		submitBinding: body => dispatch({ type: PRE_SUBMIT_BINDING, body }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Biding);
