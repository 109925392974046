import React from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { Switch, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirm } from '../../../../../../../../utils';
import ExportName from '../../../../../ExportName';
import ButtonComponent from '../../../../../../../../Components/Button';
import moment from 'moment';

const PARTNER_EXPORT_CHAR_LENGTH = {
	deliveroo: { min: 2, max: 120 },
	rushour: { min: 2, max: 120 },
};

const ExportCard = props => {
	const onActive = event => {
		event.preventDefault();
		event.stopPropagation();
		props.handleExport(props.export.id, props.export.id_partner);
	};

	const onDelete = e => {
		confirm(I18n.t('partner.export.confirm_delete')).then(() => {
			props.deleteExport(props.export.id);
		});
	};

	const onClick = e => {
		e.preventDefault();
		props.onClick(props.export.id);
	};

	let disabledButtonConfirmation = false;
	if (props.export.sections.length > 0) {
		let isSectionEmpty = props.export.sections.map(section => {
			if (section.products.length <= 0) {
				return true;
			} else {
				return false;
			}
		});
		if (isSectionEmpty.indexOf(false) !== -1) {
			disabledButtonConfirmation = false;
		} else {
			disabledButtonConfirmation = true;
		}
	} else {
		disabledButtonConfirmation = true;
	}

	const onExport = e => {
		e.preventDefault();
		let baseText = (
			<div>
				<p>
					{props.prefix === 'deliveroo'
						? I18n.t('partner.export.deliveroo_export_info')
						: ''}
				</p>
				<p>
					{!props.export.changement_in_export
						? I18n.t('partner.export.no_modification')
						: I18n.t('partner.export.confirm_export')}
				</p>
			</div>
		);

		if (!props.exportDisabled && PARTNER_EXPORT_CHAR_LENGTH[props.prefix]) {
			const min = PARTNER_EXPORT_CHAR_LENGTH[props.prefix].min || 1;
			const max = PARTNER_EXPORT_CHAR_LENGTH[props.prefix].max || 255;

			const lengthCharErrors = checkProductsCharLength(min, max);
			if (lengthCharErrors.length) {
				const errors = (
					<div>
						<p>
							{I18n.t('partner.checkMinCharLength.title', {
								min,
								max,
							})}
						</p>

						{lengthCharErrors.map(e => (
							<p>
								- {I18n.t('partner.checkMinCharLength.' + e.type)} : {e.product}
							</p>
						))}
						<p>{I18n.t('partner.checkMinCharLength.change_product')}</p>
						<p>{I18n.t('partner.checkMinCharLength.change_supp')}</p>
					</div>
				);
				confirm(errors, {
					hideCancel: true,
					confirmLabel: I18n.t('general.close'),
					title: I18n.t('general.warning'),
				}).then(() => {});
				return;
			}
		}

		if (!props.exportDisabled && props.requiredKeys.length > 0) {
			let notComplete = [];
			let idMenu;
			props.export.sections.forEach(section => {
				section.products.forEach(product => {
					idMenu = product.id_menu;
					props.requiredKeys.forEach(required => {
						if (!product.extras.hasOwnProperty(required)) {
							Object.values(props.allProducts[idMenu] || {}).map(
								productWithName => {
									if (productWithName.id === product.id_product) {
										if (notComplete.indexOf(productWithName.name) === -1) {
											notComplete.push(productWithName.name);
										}
									}
								}
							);
						}
					});
				});
			});

			let p = (
				<div>
					{notComplete.length > 0 ? (
						<p>
							{I18n.t('partner.export.incompleted_product')}
							<ul>
								{notComplete.map(p => (
									<li>{p}</li>
								))}
							</ul>
						</p>
					) : null}
					{baseText}
				</div>
			);
			confirm(p).then(() => {
				props.onExport(props.export.id);
			});
		} else {
			let p = baseText;
			confirm(p).then(() => {
				props.onExport(props.export.id);
			});
		}
	};

	const formatDate = timestamp => {
		if (timestamp) {
			const dateFormated = moment(timestamp * 1000).format(
				`DD/MM/YYYY ${I18n.t('partner.export.date_last_deployment_to')} HH:mm`
			);
			return `${I18n.t(
				'partner.export.date_last_deployment'
			)} : ${dateFormated}`;
		} else {
			return null;
		}
	};

	const checkProductsCharLength = (min, max) => {
		let errors = [];
		let idMenu;
		const fieldsToCheck = ['name'];
		props.export.sections.forEach(section => {
			section.products.forEach(product => {
				idMenu = product.id_menu;
				const productFromDb =
					props.allProducts[product.id_menu][product.id_product];
				fieldsToCheck.forEach(f => {
					const err = checkProductProp(
						f,
						min,
						max,
						'product',
						product.extras,
						productFromDb
					);
					if (err) errors.push(err);
				});
			});
		});

		Object.values(props.allSupps.list[idMenu] || {}).forEach(catSupp => {
			let catSuppExtra =
				props.allSupps.categorySupplementExtra[catSupp.id] || [];
			catSuppExtra = catSuppExtra.reduce(
				(acc, e) => ({ ...acc, [e.key_]: e.value }),
				{}
			);
			fieldsToCheck.forEach(f => {
				const err = checkProductProp(
					f,
					min,
					max,
					'cat_supp',
					catSuppExtra,
					catSupp
				);
				if (err) errors.push(err);
			});

			catSupp.data.forEach(supp => {
				let suppExtra = props.allSupps.supplementExtra[supp.id] || [];
				suppExtra = suppExtra.reduce(
					(acc, e) => ({ ...acc, [e.key_]: e.value }),
					{}
				);
				fieldsToCheck.forEach(f => {
					const err = checkProductProp(f, min, max, 'supp', suppExtra, supp);
					if (err) errors.push(err);
				});
			});
		});

		return errors;
	};

	const checkProductProp = (prop, min, max, type, productExp, productDb) => {
		if (
			(productExp[prop] &&
				(productExp[prop].length < min || productExp[prop].length > max)) ||
			(!productExp[prop] &&
				(productDb[prop].length < min || productDb[prop].length > max))
		) {
			return {
				type,
				field: prop,
				product: productExp[prop]
					? `${productExp[prop]} (${productDb[prop]})`
					: productDb[prop],
			};
		}
		return null;
	};

	return (
		<div className={'export-card-container'}>
			<div className={'export-card-left'}>
				{/*  <h2>
          {props.export.name.length < 50
            ? props.export.name
            : props.export.name.slice(0, 50) + "..."}
        </h2> */}
				<ExportName idExport={props.export.id} />
				<div className={'export-card-active'}>
					{props.export.is_current ? (
						<React.Fragment>
							<p> {I18n.t('partner.export.active')}</p>{' '}
							<p className={'pastilla'} />
						</React.Fragment>
					) : (
						<React.Fragment>
							<p> {I18n.t('partner.put_active')}</p>
							<Switch value={false} onChange={onActive} />
						</React.Fragment>
					)}
				</div>
			</div>
			<div className={'export-card-right'}>
				<div className={'export-card-date'}>
					{formatDate(props.export.date_last_deployment)}
				</div>
				<div className={'export-card-actions'}>
					{' '}
					<Button
						color={'primary'}
						onClick={onClick}
						className="secondary-button">
						{I18n.t('general.edit')}
					</Button>{' '}
					{!props.export.is_current ? (
						<IconButton onClick={onDelete}>
							<DeleteIcon />
						</IconButton>
					) : (
						<ButtonComponent
							onClick={onExport}
							disabled={props.exportDisabled || disabledButtonConfirmation}
							className="primary-button">
							{props.prefix === 'ubereats'
								? I18n.t('exports.exportModal.to_export')
								: I18n.t('exports.exportModal.to_deploy')}
							{props.loading ? <div className="sp sp-circle" /> : null}
						</ButtonComponent>
					)}
				</div>
			</div>
		</div>
	);
};

ExportCard.propTypes = {
	export: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	handleExport: PropTypes.func,
};

export default ExportCard;
