import {
	PRE_ADD_SECTION,
	CHANGE_PROP,
	ADD_PRODUCTS_TO_SECTION,
	DELETE_PRODUCT_TO_SECTION,
	PRE_ADD_PRODUCTS_TO_SECTION,
	PRE_SAVE_PRODUCT_TO_SECTION,
	PRE_DELETE_PRODUCT_TO_SECTION,
	PRE_SAVE_EXTRA_PRODUCT,
	PRE_DELETE_SECTION,
	PRE_PATCH_SECTION,
	PRE_PATCH_EXPORT,
	PRE_PATCH_SECTION_WEIGHT,
	PRE_PATCH_PRODUCT_WEIGHT,
	PRE_GENERATE_EXPORT,
	PRE_ACTIVE_EXPORT,
	PRE_DELETE_EXPORT,
	PRE_ADD_EXPORT,
	SET_SUPPLEMENT_MINIMUM,
	SET_SUPPLEMENT_MAXIMUM,
	SET_CATEGORY_ACTIVE,
	SET_SUPPLEMENT_ACTIVE,
	SET_CATEGORY_INACTIVE,
	SET_SUPPLEMENT_INACTIVE,
	PRE_DELETE_PRODUCT_EXTRA,
	PRE_POST_IPAD_CHANGE,
	COPY_EXPORT,
	PRE_RESET_PRODUCT_EXTRA,
} from './types';

export const addSection = (section, id) => ({
	type: PRE_ADD_SECTION,
	body: { section, id },
});

export const changeProp = (prop, value) => ({ type: CHANGE_PROP, prop, value });

export const addProductsToSection = (products, idSection, idExport) => ({
	type: PRE_ADD_PRODUCTS_TO_SECTION,
	idSection,
	idExport,
	products,
});

export const deleteProductToSection = (id, idProduct, idExport, idSection) => ({
	type: PRE_DELETE_PRODUCT_TO_SECTION,
	body: { id, idProduct, idExport, idSection },
});

export const deleteSection = (id, idExport) => ({
	type: PRE_DELETE_SECTION,
	body: { id, idExport },
});

export const updateSection = (idSection, idExport, name, description) => ({
	type: PRE_PATCH_SECTION,
	body: { idSection, idExport, name, description },
});

export const updateExport = (idExport, name, date) => ({
	type: PRE_PATCH_EXPORT,
	body: { idExport, name, date },
});

export const reweightSection = (sections, idExport) => ({
	type: PRE_PATCH_SECTION_WEIGHT,
	body: { sections, idExport },
});

export const reweightProduct = (products, idSection, idExport) => ({
	type: PRE_PATCH_PRODUCT_WEIGHT,
	body: { products, idSection, idExport },
});

export const activeExport = (idExport, idPartner) => ({
	type: PRE_ACTIVE_EXPORT,
	body: { idExport, idPartner },
});

export const deleteExport = idExport => ({
	type: PRE_DELETE_EXPORT,
	body: { idExport },
});

export const addExport = newExport => ({
	type: PRE_ADD_EXPORT,
	body: { ...newExport },
});

/* export const saveProductsToSection = body => ({
  type: PRE_SAVE_PRODUCT_TO_SECTION,
  body
}); */

export const saveExtraProducts = (
	id_export,
	id_section,
	id_product,
	extras,
	id_menu_level,
	id_product_parent
) => ({
	type: PRE_SAVE_EXTRA_PRODUCT,
	id_export,
	id_section,
	id_product,
	extras,
	id_menu_level,
	id_product_parent,
});

export const generateExport = (prefix_partner, id_partner, id_export) => ({
	type: PRE_GENERATE_EXPORT,
	prefix_partner,
	id_partner,
	id_export,
});

//SUPPLEMENT
export const setMinSupplement = (value, idCategory) => ({
	type: SET_SUPPLEMENT_MINIMUM,
	value,
	idCategory,
});

export const setMaxSupplement = (value, idCategory) => ({
	type: SET_SUPPLEMENT_MAXIMUM,
	value,
	idCategory,
});

export const setCategoryActive = category => ({
	type: SET_CATEGORY_ACTIVE,
	category,
});

export const setSupplementActive = (supplement, idCategory) => ({
	type: SET_SUPPLEMENT_ACTIVE,
	supplement,
	idCategory,
});

export const setCategoryInactive = idCategory => ({
	type: SET_CATEGORY_INACTIVE,
	idCategory,
});

export const setSupplementInactive = (idSupplement, idCategory) => ({
	type: SET_SUPPLEMENT_INACTIVE,
	idSupplement,
	idCategory,
});

export const deleteProductExtra = data => ({
	type: PRE_DELETE_PRODUCT_EXTRA,
	data,
});

export const patchIpadChangeInExport = data => ({
	type: PRE_POST_IPAD_CHANGE,
	data,
});

export const copyExport = data => ({
	type: COPY_EXPORT,
	data,
});

export const resetProductExtra = data => ({
	type: PRE_RESET_PRODUCT_EXTRA,
	data,
});
