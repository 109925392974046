import React, { Component } from 'react';
//import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartner } from './../service/partners/selector';
import { PARTNERS, prefixToMachine } from '../config';
import CardToConfigure from '../../../Components/PartnerCard/CardToConfigure';
import CardActifInactif from '../../../Components/PartnerCard/CardActifInactif';
import { confirm } from '../../../utils';
import { bindActionCreators } from 'redux';
import {
	putPartnerStatusToMedDefault,
	putPartnerStep,
	redirectToExternalConfig,
	validPartnerChecking,
} from '../service/partnersConfig/actions';
const prefixExternalConfig = ['laddition', 'ouilink'];

class PartnerGlobalContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!prevProps.partner.versionCheck &&
			this.props.partner &&
			this.props.partner.versionCheck &&
			this.props.partner.state === 'initial'
		) {
			this.changeStatus(
				this.props.partner.partner_id,
				'configuration',
				this.props.partner.prefix
			);
		}
	}

	changeStatus = (id, status, prefix = null) => {
		const data = {
			id_user: this.props.user.id,
			partner_id: id,
			status,
			prefix,
		};

		this.props.putStatusMedDefault(data);
	};

	setConfiguration = (min_version, prefix, id, status) => {
		if (min_version) {
			this.checkIfPartnerChecking(prefix, id);
		} else {
			this.changeStatus(id, status, prefix);
		}
		if (this.props.partner && this.props.partner.step == null) {
			this.props.putPartnerStepAction({
				id_user: this.props.user.id,
				partner_id: id,
				step: 'initial',
			});
		}
	};

	checkIfPartnerChecking = (prefix, id) => {
		this.props.validationPartnerChecking({
			prefix,
			id,
		});
	};

	showPopupContactSupport = _ => {
		confirm(I18n.t('partner.call_partner_support'), {
			title: I18n.t('general.information'),
			confirmLabel: I18n.t('general.close'),
			hideCancel: true,
		});
	};

	render() {
		const { partner } = this.props;

		if (!partner || (partner && !PARTNERS[partner.prefix]))
			window.location = '/';

		if (partner && PARTNERS[partner.prefix]) {
			if (partner.status === 'configuration') {
				let OnBoarding = prefixToMachine[partner.prefix];
				return (
					<OnBoarding setBlurBackground={() => {}} key_={partner.partner_id} />
				);
			} else if (partner.status === 'disabled') {
				const isConfigurable =
					!!prefixToMachine[partner.prefix] ||
					prefixExternalConfig.includes(partner.prefix);

				const configure = part => {
					const internalConfig = prefixToMachine[part.prefix];
					const externalConfig = prefixExternalConfig.includes(part.prefix);
					if (!!internalConfig) {
						this.setConfiguration(
							part.min_version,
							part.prefix,
							part.partner_id,
							'configuration'
						);
					} else if (!!externalConfig) {
						this.props.redirectToExternalConfig(part.prefix);
					} else {
						this.showPopupContactSupport();
					}
				};

				return (
					<CardToConfigure
						key={partner.partner_id}
						username={I18n.t(`partner.prefix.${partner.prefix}`)}
						prefix={partner.prefix}
						description={partner.description}
						isConfigurable={isConfigurable}
						configure={() => configure(partner)}
					/>
				);
			} else if (PARTNERS[partner.prefix].component) {
				let Settings = PARTNERS[partner.prefix].component;
				return <Settings prefix={partner.prefix} />;
			} else {
				return (
					<CardActifInactif
						key={partner.partner_id}
						isActive={partner.status === 'actif' ? 1 : 0}
						username={I18n.t(`partner.prefix.${partner.prefix}`)}
						prefix={partner.prefix}
						error={0}
						changeStatus={null}
						action={{}}
					/>
				);
			}
		}
	}
}

const mapStateToProps = (state, props) => {
	const prefix = props.match.params.prefix;
	const partnerConfig = Object.values(state.partnersConfig).find(
		p => p.prefix === prefix
	);

	return {
		partner: partnerConfig,
		user: state.service.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		redirectToExternalConfig: bindActionCreators(
			redirectToExternalConfig,
			dispatch
		),
		putStatusMedDefault: bindActionCreators(
			putPartnerStatusToMedDefault,
			dispatch
		),
		putPartnerStepAction: bindActionCreators(putPartnerStep, dispatch),
		validationPartnerChecking: bindActionCreators(
			validPartnerChecking,
			dispatch
		),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PartnerGlobalContainer);
