import { Machine, interpret, assign } from 'xstate';

const baseMachine = Machine({
	id: 'baseMachine',
	initial: 'initial',
	context: {
		retries: 0,
		stepp: 0,
	},
	states: {
		initial: {
			on: {
				CONFIGURE: {
					target: 'generate_token',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
				GENERATED: {
					target: 'token_at_partner',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 2 };
					}),
				},
				TOKEN_AT_PARTNER: {
					target: 'callback_partner',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 3 };
					}),
				},
			},
		},
		generate_token: {
			on: {
				GENERATED: {
					target: 'token_generated',
				},
				REJECT: 'failure',
			},
		},
		token_generated: {
			on: {
				CHECK_PARTNER: {
					target: 'token_at_partner',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
				REJECT: 'failure',
			},
		},
		token_at_partner: {
			on: {
				TOKEN_AT_PARTNER: {
					target: 'callback_partner',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
			},
		},
		callback_partner: {
			on: {
				SUCCESS_LONG_CONFIG: {
					target: 'callback_partner_ok',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
				SUCCESS: {
					target: 'success',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
			},
		},
		callback_partner_ok: {
			on: {
				SUCCESS: {
					target: 'link_ipad',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
			},
		},
		link_ipad: {
			on: {
				SUCCESS: {
					target: 'link_ipad_check',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
			},
		},
		link_ipad_check: {
			on: {
				SUCCESS: {
					target: 'success',
					actions: assign((context, event) => {
						return { stepp: context.stepp + 1 };
					}),
				},
			},
		},
		success: {
			type: 'final',
		},
		failure: {
			on: {
				RETRY: {
					target: 'initial',
					actions: assign({
						retries: (context, event) => context.retries + 1,
					}),
				},
			},
		},
	},
});

export default baseMachine;
