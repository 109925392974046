import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { refreshBindings } from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import {
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	preGenerateLinkPartnerToCustomer,
	refreshPartners,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	unLinkOrderPartners,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'rushour';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_RUSHOUR');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		let external_id = yield call(getExternalId, PARTNER_PREFIX);

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			// On fait une premiere link auth parce que on a besoin de la liaison pour le webhook de retour init
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: bodySetStep.id_user,
			});

			if (res && res.success) {
				success = true;
			}
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_restaurant_id') {
			yield call(refreshPartners);
			external_id = yield call(getExternalId, PARTNER_PREFIX);
			if (external_id && external_id != 0) {
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
				yield take('PATCH_PARTNER');

				success = true;
				bodySetStep.step = 'set_table_and_paiement';
			} else {
				yield call(
					notify,
					I18n.t('partner.onboarding_rushour_errors.set_restaurant_id'),
					'error'
				);
			}
		}

		if (currentStep === 'set_table_and_paiement') {
			const { binding } = yield select();
			let table = binding.floor.find(f => f.id === 'rushour-1');
			let paiementType = binding.paymentType.find(f => f.id === 'rushour');

			let needInsert = !table || !paiementType;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			// si la table et le moyen de paiement sont deja la OU qu'ils ont bien été insérés
			if (!needInsert || insertSuccess) {
				if (needInsert) yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

				let body = {
					prefix: PARTNER_PREFIX,
					id_table: 'rushour-1',
				};

				const resultFloor = yield call(patchPartnerFloor, body);

				body = {
					prefix: PARTNER_PREFIX,
					id_payment_type: 'rushour',
				};

				const resultPaiementType = yield call(patchPartnerPaiementType, body);

				if (
					resultFloor &&
					resultFloor.result &&
					resultPaiementType &&
					resultPaiementType.result
				) {
					success = true;
					currentStep = 'set_variable_config_channel';
					bodySetStep.step = 'set_variable_config_channel';
				} else {
					errorMessage = 'partner.onboarding_deliverect_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_deliverect_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_variable_config_channel') {
			let res1 = yield call(handlePatchVariable, {
				key: 'rushourOrderConfigChannel',
				value: JSON.stringify({ channel: {}, payment: {}, brand: {} }),
			});

			if (res1) {
				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: external_id,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
				currentStep = 'set_partner_options';
				bodySetStep.step = 'set_partner_options';
				success = true;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				success = true;
				bodySetStep.step = 'unlink_partners';
				currentStep = 'unlink_partners';
			}
		}

		if (currentStep === 'unlink_partners') {
			yield call(unLinkOrderPartners, data.userId);

			currentStep = 'set_api_mask';
			bodySetStep.step = 'set_api_mask';
			success = true;
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_RUSHOUR_FINISHED',
			step: bodySetStep.step,
		});
	}
}

function* getExternalId(prefix) {
	const { partners } = yield select();
	return partners && partners[prefix] ? partners[prefix].external_id : null;
}

export default function* indexSaga() {
	yield all([config()]);
}
