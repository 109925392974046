import {
	PRE_POST_CATEGORY_SUPPLEMENT_EXTRA,
	PRE_POST_SUPPLEMENT_EXTRA,
	PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA,
	PRE_DELETE_SUPPLEMENT_EXTRA,
	PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA,
} from './types';

export const postCategorySupplementExtra = data => ({
	type: PRE_POST_CATEGORY_SUPPLEMENT_EXTRA,
	data,
});

export const deleteCategorySupplementExtra = data => ({
	type: PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA,
	data,
});

export const resetCategorySupplementExtra = data => ({
	type: PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA,
	data,
});

export const postSupplementExtra = data => ({
	type: PRE_POST_SUPPLEMENT_EXTRA,
	data,
});

export const deleteSupplementExtra = data => ({
	type: PRE_DELETE_SUPPLEMENT_EXTRA,
	data,
});
