import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/EditRounded';
import Input from '../../../../../../Components/Input';
import { renderComponent } from 'recompose';
import ErrorPicture from '../../imageError.png';

class SortableItem extends Component {
	constructor(props) {
		super(props);
	}

	addDefaultSrc = ev => {
		ev.target.src = ErrorPicture;
	};

	render() {
		return (
			<li className="card">
				<div className="gallery" key={this.props.value.id}>
					<div className="card-grid">
						<button
							className="edit-picture-button"
							onClick={() => this.props.openEditModal(this.props.value)}>
							<EditIcon />
						</button>
						<a onClick={() => this.props.openModal(this.props.value)}>
							<img
								src={this.props.value.path_cropped}
								alt={this.props.value.name}
								onError={this.addDefaultSrc}
							/>
						</a>
					</div>

					<div className="card-description">
						<div>
							<p className="content-name">{this.props.value.name}</p>
							<p className="content-description">
								{this.props.value.description}
							</p>
						</div>

						<div className="trash-container">
							<a
								href="#"
								onClick={() => this.props.deletePicture(this.props.value.id)}>
								<DeleteIcon />
							</a>
						</div>
					</div>
				</div>
			</li>
		);
	}
}

SortableItem.propTypes = {
	openModal: PropTypes.func,
	deletePicture: PropTypes.func,
	value: PropTypes.object,
};

export default SortableItem;
