import Deliveroo from './Deliveroo';
import CommandeEnLigne from './CommandeEnLigne';
import UberEats from './UberEats';
export const FORM_PARTNERS = {
	celladdition: CommandeEnLigne,
	deliverect: CommandeEnLigne,
	deliveroo: Deliveroo,
	otter: CommandeEnLigne,
	ubereats: UberEats,
	tabesto: CommandeEnLigne,
	rushour: CommandeEnLigne,
};
