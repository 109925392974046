import {
	RECEIVE_PRODUCTS,
	SELECT_PRODUCTS,
	DESELECT_PRODUCTS,
	SET_SELECTED_PRODUCT,
} from './types';
import { deepClone } from '../../../../../../utils';

const initialState = { list: {}, loaded: false, selected: {} };

const productsReducer = (state = initialState, action) => {
	let newState = deepClone(state);
	let { ids, idMenu } = action;
	if (action.ids && action.idMenu) {
		ids = Array.isArray(ids) ? ids : [ids];
	}

	switch (action.type) {
		case RECEIVE_PRODUCTS:
			if (action.data) {
				newState.list = action.data;
				newState.loaded = true;
				return newState;
			}
			return newState;
		case SELECT_PRODUCTS:
			if (!newState.selected.hasOwnProperty(idMenu)) {
				newState.selected[idMenu] = [];
			}

			for (let id of ids) {
				if (newState.selected[idMenu].indexOf(id) === -1) {
					newState.selected[idMenu].push(id);
				}
			}
			return newState;
		case DESELECT_PRODUCTS:
			if (
				newState.selected[idMenu] &&
				newState.selected[idMenu].some(id => ids.includes(id))
			) {
				newState.selected[idMenu] = newState.selected[idMenu].filter(
					itemId => !ids.includes(itemId)
				);

				if (Object.keys(newState.selected[idMenu]).length == 0) {
					delete newState.selected[idMenu];
				}
			}
			return newState;
		case SET_SELECTED_PRODUCT:
			newState.selected = action.body;
			return newState;
		default:
			return newState;
	}
};

export default productsReducer;
