import { take, put, call, race } from 'redux-saga/effects';
import { isPartner } from '../service/user/saga';
import { push } from 'connected-react-router';
import { UPDATE_USER } from '../service/user/types';
import { retrieveVariable } from '../service/fetcher.saga';

const ROOT = '/';
const access = {
	partner: ['eventWebhook'],
	other: ['partners', 'info', 'menu'],
};

export default function* RouterFlowSaga() {
	while (true) {
		yield take(UPDATE_USER);
		yield call(retrieveVariable);
		let pathname = window.location.pathname;
		if (pathname.includes('/redirect')) {
			yield put({
				type: 'HANDLE_REDIRECT',
				location: { ...window.location },
			});
		}
	}
}

function hasAccess(isPartner, pathname) {
	let accessPath = isPartner ? access.partner : access.other;
	return accessPath.some(path => pathname.includes(path));
}

function* redirectHome() {
	yield put(push('/'));
}
