import {
	RECEIVE_EXPORT,
	RECEIVE_PRODUCT_EXTRA,
	ADD_SECTION,
	CHANGE_PROP,
	SET_FORM_VALUES,
	ADD_PRODUCTS_TO_SECTION,
	DELETE_PRODUCT_TO_SECTION,
	SAVE_EXTRA_PRODUCT,
	DELETE_SECTION,
	PATCH_SECTION,
	PATCH_EXPORT,
	PATCH_SECTION_WEIGHT,
	PATCH_PRODUCT_WEIGHT,
	ACTIVE_EXPORT,
	DELETE_EXPORT,
	ADD_EXPORT,
	SET_SUPPLEMENT_FORM_VALUES,
	SET_SUPPLEMENT_MINIMUM,
	SET_SUPPLEMENT_MAXIMUM,
	SET_SUPPLEMENT_ACTIVE,
	SET_CATEGORY_ACTIVE,
	SET_SUPPLEMENT_INACTIVE,
	SET_CATEGORY_INACTIVE,
	GENERATE_EXPORT,
	DELETE_PRODUCT_EXTRA,
	RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE,
	RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS,
} from './types';
import { deepClone } from '../../../../utils';
import { CHANGE_CHANGEMENT_IN_EXPORT } from '../../Components/SupplementExtra/service/types';

const initialStateExport = [];
export const reducerExport = (state = initialStateExport, action) => {
	let newState = deepClone(state);
	switch (action.type) {
		case RECEIVE_EXPORT:
			if (action.data) {
				newState = action.data;
			}
			return newState;
		case ADD_SECTION:
			if (action.body.section && action.body.id) {
				newState = newState.map(exp => {
					if (exp.id === action.body.id) {
						exp.sections = [...exp.sections, action.body.section];
						exp.changement_in_export = true;
					}
					return exp;
				});
			}

			return newState;
		case ADD_PRODUCTS_TO_SECTION:
			if (action.body) {
				if (
					action.body.id_export &&
					action.body.id_section &&
					action.body.products
				) {
					newState = newState.map(exp => {
						if (exp.id === action.body.id_export) {
							exp.sections = exp.sections.map(section => {
								if (section.id === action.body.id_section) {
									section.products = action.body.products;
								}

								return section;
							});
							exp.changement_in_export = true;
						}
						return exp;
					});
				}
			}
			return newState;
		case DELETE_PRODUCT_TO_SECTION:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.sections = exp.sections.map(section => {
							if (section.id === action.body.idSection) {
								section.products = section.products.filter(
									product => product.id_product !== action.body.idProduct
								);
							}
							return section;
						});
					}
					return exp;
				});
			}
			return newState;

		case SAVE_EXTRA_PRODUCT:
			if (action.data.extras) {
				let isCategorySuppProductInMenu =
					action.data.id_menu_level && action.data.id_product_parent;
				newState = newState.map(exp => {
					if (exp.id === action.data.id_export) {
						exp.changement_in_export = true;
						exp.sections = exp.sections.map(section => {
							section.products = section.products.map(product => {
								if (
									isCategorySuppProductInMenu &&
									action.data.id_product_parent === product.id_product
								) {
									if (
										!product.product_in_menu_category_supplement.hasOwnProperty(
											action.data.id_menu_level
										)
									) {
										product.product_in_menu_category_supplement[
											action.data.id_menu_level
										] = {};
									}
									product.product_in_menu_category_supplement[
										action.data.id_menu_level
									][action.data.id_product] = action.data.category_supplement;
								} else if (
									!isCategorySuppProductInMenu &&
									product.id_product === action.data.id_product
								) {
									product.extras = { ...product.extras, ...action.data.extras };
									product.category_supplement = action.data.hasOwnProperty(
										'category_supplement'
									)
										? action.data.category_supplement
										: product.category_supplement;
								}
								return product;
							});
							return section;
						});
					}
					return exp;
				});
			}

			return newState;

		case DELETE_SECTION:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.sections = exp.sections.filter(
							section => section.id !== action.body.id
						);
					}
					return exp;
				});
			}
			return newState;
		case PATCH_SECTION:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.sections = exp.sections.map(section => {
							if (section.id === action.body.idSection) {
								section.name = action.body.name;
								section.description = action.body.description;
							}
							return section;
						});
					}
					return exp;
				});
			}
			return newState;
		case PATCH_EXPORT:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.name = action.body.name;
						exp.date_available = action.body.date;
					}
					return exp;
				});
			}
			return newState;
		case PATCH_SECTION_WEIGHT:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.sections = action.body.sections;
					}
					return exp;
				});
			}
			return newState;
		case PATCH_PRODUCT_WEIGHT:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id === action.body.idExport) {
						exp.changement_in_export = true;
						exp.sections = exp.sections.map(section => {
							if (section.id === action.body.idSection) {
								section.products = action.body.products;
							}
							return section;
						});
					}
					return exp;
				});
			}
			return newState;
		case ACTIVE_EXPORT:
			if (action.body) {
				newState = newState.map(exp => {
					if (exp.id_partner === action.body.idPartner) {
						if (exp.id === action.body.idExport) {
							exp.is_current = true;
						} else {
							exp.is_current = false;
						}
					}
					return exp;
				});
			}
			return newState;
		case DELETE_EXPORT:
			if (action.body) {
				newState = newState.filter(exp => exp.id !== action.body.idExport);
			}
			return newState;
		case ADD_EXPORT:
			if (action.body) {
				newState = [...newState, action.body];
			}
			return newState;
		case CHANGE_CHANGEMENT_IN_EXPORT:
			newState = newState.map(exp => {
				if (exp.is_current === 1) {
					exp.changement_in_export = true;
				}
				return exp;
			});

			return newState;
		case GENERATE_EXPORT:
			newState = newState.map(exp => {
				if (exp.id === action.exp.id) {
					exp.changement_in_export = false;
					exp.date_last_deployment = parseInt(Date.now() / 1000);
				}
				return exp;
			});
			return newState;

		case DELETE_PRODUCT_EXTRA:
			newState = newState.map(exp => {
				if (exp.id == action.body.idExport) {
					exp.sections = exp.sections.map(section => {
						if (section.id == action.body.idSection) {
							section.products = section.products.map(product => {
								if (action.body.idProductParent && action.body.idMenuLevel) {
									if (product.id_product == action.body.idProductParent) {
										if (
											product.product_in_menu_category_supplement &&
											product.product_in_menu_category_supplement[
												action.body.idMenuLevel
											] &&
											product.product_in_menu_category_supplement[
												action.body.idMenuLevel
											][action.body.idProduct]
										) {
											delete product.product_in_menu_category_supplement[
												action.body.idMenuLevel
											][action.body.idProduct];
										}
									}
								} else {
									if (product.id_product == action.body.idProduct) {
										if (action.body.key_ === 'category_supplement') {
											product.category_supplement = null;
										} else {
											delete product.extras[action.body.key_];
										}
									}
								}

								return product;
							});
						}
						return section;
					});
				}
				return exp;
			});
			return newState;

		default:
			return newState;
	}
};

const initialStateProductExtra = {};
export const reducerProductExtra = (
	state = initialStateProductExtra,
	action
) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_PRODUCT_EXTRA:
			if (action.data) {
				newState = action.data;
			}
			return newState;
		default:
			return newState;
	}
};

const initialStateFormProduct = {};
export const reducerFormProduct = (state = initialStateFormProduct, action) => {
	let newState = { ...state };
	switch (action.type) {
		case CHANGE_PROP:
			if (action.prop) {
				newState[action.prop] = action.value;
			}
			return newState;

		case SET_FORM_VALUES:
			const formValues = Object.keys(action.data);
			if (formValues.includes('price')) {
				let result = { ...action.data };
				if (action.data['price']) {
					result['price'] = (action.data['price'] / 100).toFixed(2);
				}
				return result;
			}
			return { ...action.data };
		// case DELETE_PRODUCT_EXTRA: //TODO: reutiliser peut etre pour fix la suppresion extra key_ === image
		// 	if (action.body) {
		// 		if (newState[action.body.key_]) {
		// 			delete newState[action.body.key_];
		// 		}
		// 	}
		// 	return newState;
		default:
			return newState;
	}
};

const initialStateSupplementFormProduct = [];
export const reducerSupplementProduct = (
	state = initialStateSupplementFormProduct,
	action
) => {
	let newState = [...state];
	switch (action.type) {
		case SET_SUPPLEMENT_FORM_VALUES:
			return [...action.data];
		case SET_SUPPLEMENT_MINIMUM:
			if (action) {
				newState = newState.map(cat => {
					if (cat.id === action.idCategory) {
						cat.minimum = action.value;
						if (cat.minimum > cat.maximum) {
							cat.maximum = cat.minimum;
						}
					}
					return cat;
				});
			}
			return newState;
		case SET_SUPPLEMENT_MAXIMUM:
			if (action) {
				newState = newState.map(cat => {
					if (cat.id === action.idCategory) {
						cat.maximum = action.value;
						if (cat.maximum < cat.minimum) {
							cat.minimum = cat.maximum;
						}
					}
					return cat;
				});
			}
			return newState;
		case SET_SUPPLEMENT_ACTIVE:
			if (action) {
				newState = newState.map(cat => {
					if (cat.id === action.idCategory) {
						cat.data = [...cat.data, action.supplement];
					}
					return cat;
				});
			}
			return newState;
		case SET_CATEGORY_ACTIVE:
			if (action) {
				newState.push(action.category);
			}
			return newState;
		case SET_SUPPLEMENT_INACTIVE:
			if (action) {
				newState = newState.map(cat => {
					if (cat.id === action.idCategory) {
						cat.data = cat.data.filter(dat => dat.id !== action.idSupplement);
					}
					return cat;
				});
			}
			return newState;
		case SET_CATEGORY_INACTIVE:
			if (action) {
				newState = newState.filter(cat => cat.id !== action.idCategory);
			}
			return newState;
		default:
			return newState;
	}
};

const EXPORT_PARTNERS_STOCK_ACTIVE_LIVE = [];
export const reducerExportStockActiveLive = (
	state = EXPORT_PARTNERS_STOCK_ACTIVE_LIVE,
	action
) => {
	let newState = [...state];
	switch (action.type) {
		case RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE:
			if (action.data) {
				newState = action.data;
			}
			return newState;
		default:
			return newState;
	}
};

const EXPORT_PARTNERS_DISABLED_PRODUCTS = [];
export const reducerExportDisabledProducts = (
	state = EXPORT_PARTNERS_DISABLED_PRODUCTS,
	action
) => {
	let newState = [...state];
	switch (action.type) {
		case RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS:
			return action.data || [];

		default:
			return newState;
	}
};
