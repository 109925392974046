import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		common: {
			black: 'rgba(41, 41, 41, 1)',
			white: '#fff',
		},
		background: {
			paper: '#fff',
			default: '#fafafa',
		},
		primary: {
			light: 'rgba(237, 107, 112, 1)',
			main: 'rgba(232, 66, 73, 1)',
			dark: 'rgba(188, 44, 50, 1)',
			contrastText: '#fff',
		},
		secondary: {
			light: 'rgba(79, 83, 158, 1)',
			main: 'rgba(38, 41, 87, 1)',
			dark: 'rgba(38, 41, 87, 1)',
			contrastText: '#fff',
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
			contrastText: '#fff',
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
		},
	},
	overrides: {
		MuiButton: {
			root: { textTransform: 'capitalize' },
			contained: {
				'&$disabled': {
					backgroundColor: 'rgba(232, 66, 73, 0.5)',
					color: 'white',
				},
			},
		},
		MuiSlider: {
			track: {
				backgroundColor: '#cecece',
				height: '4px',
			},
			trackAfter: {
				opacity: 1,
			},
		},
		MuiSwitch: {
			colorSecondary: {
				'&$checked': {
					track: {
						backgroundColor: 'red',
					},
					color: '#5ac8f5',
				},
			},
			root: {
				'&$checked': {
					track: {
						backgroundColor: 'green',
					},
				},
			},
		},
	},
});
