import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	set_api_data: 0,
	documentation: 1,
	set_brand_id: 2,
	set_auth_link: 2,
	set_entities: 3,
	set_partner_options: 3,
	set_api_mask: 3,
	success: 4,
};

const Deliveroo = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Deliveroo;
