import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormEvent from './Components/formEvent';
import * as actions from './service/actions';
import { bindActionCreators } from 'redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { I18n } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import PopupEvent from './Components/PopupEvent';

class EventWebhook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: 'ALL',
			categories: ['ALL'],
			popupOpen: false,
			eventSelected: {},
		};
	}

	handleExpand = panel => (event, expanded) => {
		event.preventDefault();
		this.setState({
			expanded: expanded ? panel : false,
		});
	};

	submitWebhookPartner = eventWebhook => {
		this.handleClosePopup();
		const newEventWebhook = {
			url: eventWebhook.url,
			method: eventWebhook.method,
			params: eventWebhook.params,
		};
		if (eventWebhook.idWebhook) {
			newEventWebhook.id = eventWebhook.idWebhook;
			this.props.action.patchWebhook(newEventWebhook);
		} else {
			newEventWebhook.event = eventWebhook.event;
			newEventWebhook.idPartner = this.props.user.id;
			this.props.action.addWebhook(newEventWebhook);
		}
	};

	deleteWebhookPartner = id => {
		this.handleClosePopup();
		this.props.action.removeWebhook(id);
	};

	onClickEvent = id => {
		let eventSelected = this.props.events.find(e => e.id === id);

		eventSelected.availableParams = eventSelected.availableParams.filter(
			param =>
				!param.partner_prefix ||
				param.partner_prefix === this.props.user.partnerPrefix
		);

		this.setState({
			popupOpen: true,
			eventSelected: eventSelected,
		});
	};

	handleClosePopup = () => {
		this.setState({
			popupOpen: false,
			eventSelected: {},
		});
	};

	testUrl = data => {
		if (data) {
			const labels = {
				success: I18n.t('partner.success_test_request'),
				error: I18n.t('partner.error_test_request'),
			};
			this.props.action.requestUrlTester(data, labels);
		}
	};

	render() {
		const { events } = this.props;
		const { expanded, categories } = this.state;

		return (
			<div className="content-container">
				{categories.map(category => {
					const selected = expanded === category;
					const classTitle =
						'event-category-title ' +
						(selected ? 'event-category-title-selected' : '');
					return (
						<ExpansionPanel
							key={category}
							expanded={selected}
							onChange={this.handleExpand(category)}
							className={'event-category-panel'}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>
									<span className={classTitle}>
										{' '}
										{I18n.t(`webhook.categories.all`)}
									</span>
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
									}}>
									{events && events.length
										? events.map(eventWebhook => {
												return (
													<div
														key={eventWebhook.id}
														onClick={() => {
															this.onClickEvent(eventWebhook.id);
														}}
														className={'event-paper'}>
														<span>
															{' '}
															{I18n.t(`webhook.name.${eventWebhook.event}`)}
														</span>
													</div>
												);
										  })
										: I18n.t('webhook.no_event')}
								</div>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					);
				})}

				<PopupEvent
					open={this.state.popupOpen}
					eventWebhook={this.state.eventSelected}
					onSubmit={this.submitWebhookPartner}
					onDelete={this.deleteWebhookPartner}
					onClose={this.handleClosePopup}
					onTestUrl={this.testUrl}
				/>
			</div>
		);
	}
}

EventWebhook.propTypes = {};

const mapStateToProps = state => {
	return {
		events: state.events,
		user: state.service.user,
	};
};

const mapDispatchToProps = dispatch => {
	return { action: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventWebhook);
