import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import CardConfig from '../index';
import { interpret } from 'xstate';
import BaseMachine from '../../../XStateMachine/baseMachine';
import { InputBase } from '@material-ui/core';
import Button from '../../../Button/';
import Expand from 'react-expand-animated';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {
	putPartnerStep,
	putPartnerStatus,
	generateToken,
	validLinkPartnerToCustomer,
	validConnexionWithPartnerOk,
	postPartnerMedDefault,
	putPartnerStatusToMedDefault,
} from '../../../../Containers/Partners/service/partnersConfig/actions';
import { bindActionCreators } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withSnackbar } from 'notistack';
import { getPartnerById } from '../../../../Containers/Partners/selector';
import CheckIcon from '@material-ui/icons/Check';
import { Fade } from 'react-slideshow-image';
import { Media, Player } from 'react-media-player';
import { animateScroll as scroll, scroller } from 'react-scroll';
import PartnerContact from '../../../PartnerContact';

const stepToValue = {
	initial: 0,
	generate_token: 1,
	token_at_partner: 2,
	callback_partner: 3,
	callback_partner_ok: 3,
	link_ipad: 4,
	link_ipad_check: 5,
};

const properties = {
	transitionDuration: 500,
	infinite: false,
	indicators: true,
	autoplay: false,
};

class SimplePartner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			current: BaseMachine.initial,
			token: '',
			partnerOK: false,
			configLaunch: false,
			open: false,
		};
		this.service = interpret(BaseMachine).onTransition(current =>
			this.setState({ current })
		);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		this.service.start();
		this.checkIfPartenaireOk();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.partnerOK !== this.state.partnerOK &&
			prevProps.config.partnerCustomerId !==
				this.props.config.partnerCustomerId &&
			this.props.config.state === 'callback_partner'
		) {
			this.sendNewState();
			this.addNewPartnersClient();
		}
		if (
			prevProps.config.state !== this.props.config.state ||
			prevState.configLaunch !== this.state.configLaunch
		) {
			this.checkExpand();
		}
		if (
			prevProps.config.connexionPartner !==
				this.props.config.connexionPartner &&
			this.props.config.connexionPartner === true
		) {
			this.sendNewState();
		}
	}

	componentWillUnmount() {
		this.service.stop();
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.partnerOK == false && !!props.config.partnerCustomerId) {
			return {
				partnerOK: true,
			};
		}
		if (props.config.token) {
			if (props.config.token !== state.token) {
				return {
					token: props.config.token,
				};
			}
		}
		return null;
	}

	removeListener = () => {
		document.removeEventListener('mousedown', this.handleClickOutside);
	};

	addListener = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
	};

	continueConfigure = e => {
		this.launchConfig();
		this.sendNewState();
	};

	byPassSocketValidation = [
		'snapshift',
		'pennylane',
		'netresto',
		'deepsight',
		'yokitup',
		'skello',
		'theometrics',
		'1055',
	];

	sendNewState = () => {
		let data = {
			id_user: this.props.user.id,
			partner_id: this.props.config.partner_id,
		};
		switch (this.props.config.state) {
			case 'initial':
				this.service.send('CONFIGURE');
				data.step = 'generate_token';
				this.props.putStep(data);
				break;
			case 'generate_token':
				if (this.state.token) {
					this.service.send('CHECK_PARTNER');
					data.step = 'token_at_partner';
					this.props.putStep(data);
				}
				break;

			case 'token_at_partner':
				this.service.send('TOKEN_AT_PARTNER');
				data.step = 'callback_partner';
				this.props.putStep(data);
				break;
			case 'callback_partner':
				if (this.byPassSocketValidation.includes(this.props.config.prefix)) {
					this.service.send('SUCCESS');
					data.step = 'success';
					this.props.putStep(data);
					data.status = 'actif';
					this.props.putStatus(data);
					this.props.setBlurBackground(false);
					this.props.enqueueSnackbar(
						I18n.t('partner.prefix.' + this.props.config.prefix) +
							' : ' +
							I18n.t('partner.partner_ok'),
						{
							variant: 'success',
						}
					);
				} else {
					this.service.send('SUCCESS_LONG_CONFIG');
					data.step = 'callback_partner_ok';
					this.props.putStep(data);
					this.props.enqueueSnackbar(I18n.t('partner.link_with_partner_ok'), {
						variant: 'success',
					});
				}
				break;
			case 'callback_partner_ok':
				this.service.send('SUCCESS');
				data.step = 'link_ipad';
				this.props.putStep(data);
				break;
			case 'link_ipad':
				this.service.send('SUCCESS');
				data.step = 'link_ipad_check';
				this.props.putStep(data);
				break;
			case 'link_ipad_check':
				this.service.send('SUCCESS');
				data.step = 'success';
				this.props.putStep(data);
				data.status = 'actif';
				this.props.putStatus(data);
				this.props.setBlurBackground(false);
				this.props.setApiMask({
					...data,
					prefix: this.props.config.prefix,
					status: 'actif',
				});
				this.props.enqueueSnackbar(
					I18n.t('partner.prefix.' + this.props.config.prefix) +
						' : ' +
						I18n.t('partner.partner_ok'),
					{
						variant: 'success',
					}
				);
				break;
		}
	};

	checkExpand = () => {
		this.openCloseExpand(false);
		if (this.props.config.steps) {
			for (let step of this.props.config.steps) {
				if (step.step === this.props.config.state) {
					if (JSON.parse(step.externe_link).value && this.state.configLaunch) {
						this.openCloseExpand(true);
					}
				}
			}
		}
	};

	addNewPartnersClient = () => {
		const data = {
			active: 1,
			prefix: this.props.config.prefix,
			external_id: this.props.config.partnerCustomerId,
			id: this.props.config.partner_id,
		};
		this.props.addNewPartnerClient(data);
	};

	launchConfig = () => {
		this.props.setBlurBackground(true, this.wrapperRef);
		document.addEventListener('mousedown', this.handleClickOutside);
		if (!this.state.configLaunch) {
			this.setState({
				configLaunch: true,
			});
		}
	};

	checkIfPartenaireOk = () => {
		if (this.props.config.state === 'callback_partner') {
			this.props.validationLinkPartnerToCustomer({
				id_user: this.props.user.id,
				partner_id: this.props.key_,
				prefix: this.props.config.prefix,
				state: this.props.config.state,
			});
		}
	};

	checkIfConnexionWithPartnerOk = () => {
		this.props.validationConnexionWithPartnerOk({
			customer_id: this.props.config.partnerCustomerId,
			partner_id: this.props.key_,
			prefix: this.props.config.prefix,
			state: this.props.config.state,
		});
	};

	tokenCopied = () => {
		this.props.enqueueSnackbar(I18n.t('partner.token_copied'), {
			variant: 'success',
		});
	};

	generateToken = () => {
		this.props.generateTk({ partnerId: this.props.key_ });
		let data = {
			id_user: this.props.user.id,
			partner_id: this.props.config.partner_id,
		};
		data.step = 'token_at_partner';
		this.props.putStep(data);
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			document.removeEventListener('mousedown', this.handleClickOutside);
			this.props.setBlurBackground(false);
			this.setState({
				configLaunch: false,
			});
		}
	}

	openCloseExpand = isOpen => {
		if (isOpen) {
			scroller.scrollTo('scrollToExpand', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuad',
				offset: -50,
			});
		}
		this.setState({
			open: isOpen,
		});
	};

	headerContent = step => {
		const partner_name = I18n.t('partner.prefix.' + this.props.config.prefix);
		return (
			<div className="step-header">
				<h3>
					{I18n.t('partner.step.step')} {step}
					<PartnerContact
						type="card"
						prefix={this.props.config.prefix}
						onOpen={this.removeListener}
						onClose={this.addListener}
					/>
				</h3>
				{this.props.config.logo ? (
					<img src={this.props.config.logo} alt={this.props.key_} />
				) : (
					<p className="partnerconfig-partner-title">{partner_name}</p>
				)}
			</div>
		);
	};

	inputForTokenContent = () => {
		return (
			<div className="input-container">
				{this.state.token ? (
					<CopyToClipboard text={this.state.token}>
						<Button className="input-button" onClick={this.tokenCopied}>
							{I18n.t('partner.copy_token')}
						</Button>
					</CopyToClipboard>
				) : (
					<Button className="input-button" onClick={this.generateToken}>
						{I18n.t('partner.generate_token')}
					</Button>
				)}
				<InputBase id="" onChange={''} type="text" value={this.state.token} />
			</div>
		);
	};

	tokenAtPartnerContent = () => {
		let token = this.state.token ? this.state.token : this.props.token;
		return (
			<div className="content-partner-token">
				<p className="content-card-p">
					{this.props.config.prefix === 'guest'
						? I18n.t('partner.enter_key_at_partner')
						: I18n.t('partner.enter_token_at_partner')}
					{}
				</p>
				{token ? (
					<CopyToClipboard text={token}>
						<Button className="input-button" onClick={this.tokenCopied}>
							{I18n.t('partner.copy_token')}
						</Button>
					</CopyToClipboard>
				) : null}
			</div>
		);
	};

	callBackPartnerContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.waiting_for_partner')}
				</p>
				<div className="button-partner-container">
					{this.state.token ? (
						<CopyToClipboard text={this.state.token}>
							<Button className="input-button" onClick={this.tokenCopied}>
								{I18n.t('partner.copy_token')}
							</Button>
						</CopyToClipboard>
					) : null}
					<Button className="input-button" onClick={this.checkIfPartenaireOk}>
						{I18n.t('partner.check_link_partner_to_customer')}
					</Button>
				</div>
			</div>
		);
	};

	callBackPartnerOkContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.waiting_for_partner')}
				</p>
				<div className="button-partner-container">
					<Button className="input-button-disabled">
						{I18n.t('partner.check_link_partner_to_customer')} <CheckIcon />
					</Button>
				</div>
			</div>
		);
	};

	linkIpadContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">{I18n.t('partner.active_link_ipad')}</p>
			</div>
		);
	};

	linkIpadCheckContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.check_connexion_with_partner')}
				</p>
				<Button
					className="input-button"
					onClick={this.checkIfConnexionWithPartnerOk}>
					{I18n.t('partner.check_connexion')}
				</Button>
			</div>
		);
	};

	slideshow = slides => {
		let slidesJSX = [];
		const images = require.context('../../../../img', true);
		for (let slide of slides) {
			slidesJSX.push(
				<div className="each-fade">
					<div className="image-container">
						{slide.indexOf('http') !== -1 ? (
							<img src={slide} />
						) : (
							<img src={images(`./${slide}.png`)} />
						)}
					</div>
				</div>
			);
		}
		return (
			<div className="slide-container">
				<Fade {...properties}>{slidesJSX}</Fade>
			</div>
		);
	};

	content = state => {
		switch (state) {
			case 'initial':
				if (this.props.config.logo)
					return (
						<div className="cardConfig-logo">
							<img src={this.props.config.logo} alt={this.props.key_} />
						</div>
					);
				const partner_name = I18n.t(
					'partner.prefix.' + this.props.config.prefix
				);
				return <p className="partnerconfig-partner-title">{partner_name}</p>;

			case 'generate_token':
				return (
					<div>
						{this.headerContent(1)}
						{this.inputForTokenContent()}
					</div>
				);
			case 'token_at_partner':
				return (
					<div>
						{this.headerContent(2)}
						{this.tokenAtPartnerContent()}
					</div>
				);
			case 'callback_partner':
				return (
					<div>
						{this.headerContent(3)}
						{this.callBackPartnerContent()}
					</div>
				);
			case 'callback_partner_ok':
				return (
					<div>
						{this.headerContent(3)}
						{this.callBackPartnerOkContent()}
					</div>
				);
			case 'link_ipad':
				return (
					<div>
						{this.headerContent(4)}
						{this.linkIpadContent()}
					</div>
				);
			case 'link_ipad_check':
				return (
					<div>
						{this.headerContent(5)}
						{this.linkIpadCheckContent()}
					</div>
				);
			default:
				return (
					<div className="cardConfig-logo">
						<img src={this.props.config.logo} alt={this.props.key_} />
					</div>
				);
		}
	};

	contentExpand = (stepp, position, externe_link) => {
		let description = [];
		if (JSON.parse(stepp.description))
			if (JSON.parse(stepp.description).length > 0) {
				description = JSON.parse(stepp.description);
				description = description.map((desc, index) => {
					return (
						<p key={index}>
							•{' '}
							<ReactMarkdown
								children={desc}
								rehypePlugins={[rehypeRaw, remarkGfm]}
							/>
						</p>
					);
				});
			}
		let classNameExpand = '';
		switch (position) {
			case 1:
				classNameExpand = externe_link
					? 'expand-content after-center-link'
					: 'expand-content after-center';
				break;
			case 2:
				classNameExpand = externe_link
					? 'expand-content after-right-link'
					: 'expand-content after-right';
				break;
			default:
				classNameExpand = externe_link
					? 'expand-content after-left-link'
					: 'expand-content after-left';
				break;
		}
		const images = require.context('../../../../img', true);
		return (
			<div className={classNameExpand}>
				{externe_link ? (
					<div className="expand-external-content">
						{externe_link.key_ === 'image' ? (
							externe_link.value.indexOf('http') !== -1 ? (
								<img src={externe_link.value} alt={externe_link.key_} />
							) : (
								<img src={images(`./${externe_link.value}.png`)} />
							)
						) : externe_link.key_ === 'video_youtube' ? (
							<iframe src={externe_link.value} />
						) : externe_link.key_ === 'slideshow' ? (
							this.slideshow(externe_link.value)
						) : externe_link.key_ === 'video' ? (
							<Media>
								<div className="media">
									<div className="media-player">
										<Player
											ref={c => (this._mediaPlayer = c)}
											src={externe_link.value}
											mozallowfullscreen=""
											webkitallowfullscreen=""
											allowfullscreen=""
										/>
									</div>
								</div>
							</Media>
						) : null}
					</div>
				) : null}
				<div className="expand-external-description">
					<h4>{stepp.title}</h4>
					{description ? description : null}
				</div>
			</div>
		);
	};

	expandContainer = stepp => {
		let externe_link = '';
		if (this.props.config.steps) {
			for (let step of this.props.config.steps) {
				if (step.step === this.props.config.state) {
					externe_link = JSON.parse(step.externe_link);
					const position = this.props.index % 3;
					let classNameExpand = '';
					switch (position) {
						case 1:
							classNameExpand = externe_link
								? 'expand-center-link'
								: 'expand-center';
							break;
						case 2:
							classNameExpand = externe_link
								? 'expand-right-link'
								: 'expand-right';
							break;
						default:
							classNameExpand = externe_link
								? 'expand-left-link'
								: 'expand-left';
							break;
					}
					return (
						<Expand open={this.state.open} className={classNameExpand}>
							{this.contentExpand(step, position, externe_link)}
						</Expand>
					);
				}
			}
		}
	};

	nextStepButtonContent = () => {
		return (
			<p className="configuration-button-next" onClick={this.continueConfigure}>
				{I18n.t('partner.step.next_step')}
			</p>
		);
	};

	nextStepButton = state => {
		switch (state) {
			case 'initial':
				return (
					<p
						className="configuration-button-next"
						onClick={
							this.props.config.state === 'initial'
								? this.continueConfigure
								: this.launchConfig
						}>
						{this.props.config.state === 'initial'
							? I18n.t('partner.begin_configuration')
							: I18n.t('partner.continue_configuration')}
					</p>
				);
			case 'generate_token':
				return (
					<p className="configuration-button-next disabled">
						{I18n.t('partner.step.next_step')}
					</p>
				);

			case 'token_at_partner':
				return this.nextStepButtonContent();
			case 'callback_partner':
				return (
					<p className="configuration-button-next disabled">
						{I18n.t('partner.step.next_step')}
					</p>
				);
			case 'callback_partner_ok':
				return this.nextStepButtonContent();
			case 'link_ipad':
				return this.nextStepButtonContent();
			default:
				return <p className="configuration-button-next" />;
		}
	};

	render() {
		return (
			<div
				className={
					this.state.configLaunch
						? 'expand-container z-index5'
						: 'expand-container'
				}
				ref={this.setWrapperRef}>
				<CardConfig
					key={this.props.key_}
					step={stepToValue[this.props.config.state]}
					maxStep={this.props.config.max_step}
					configLaunch={this.state.configLaunch}
					nextStepButton={
						this.state.configLaunch
							? this.nextStepButton(this.props.config.state)
							: this.nextStepButton('initial')
					}>
					{this.state.configLaunch
						? this.content(this.props.config.state)
						: this.content('initial')}
				</CardConfig>
				{this.state.configLaunch
					? this.expandContainer(stepToValue[this.props.config.state])
					: this.expandContainer(0)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const config = getPartnerById(state, props);
	const user = state.service.user;
	const prefix = state.partnersConfig[props.key_].prefix;
	const partner = state.partners[prefix]; // retrieve current partner
	const token = partner ? partner.token_customer : null; // get current partner token

	return {
		config,
		user,
		token,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		putStep: bindActionCreators(putPartnerStep, dispatch),
		putStatus: bindActionCreators(putPartnerStatus, dispatch),
		addNewPartnerClient: bindActionCreators(postPartnerMedDefault, dispatch),
		generateTk: bindActionCreators(generateToken, dispatch),
		validationLinkPartnerToCustomer: bindActionCreators(
			validLinkPartnerToCustomer,
			dispatch
		),
		validationConnexionWithPartnerOk: bindActionCreators(
			validConnexionWithPartnerOk,
			dispatch
		),
		setApiMask: bindActionCreators(putPartnerStatusToMedDefault, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(SimplePartner));
