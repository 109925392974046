import React, { Component } from 'react';
import Proptypes from 'prop-types';
import './style.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import { confirm } from '../../../../utils';
import { I18n } from 'react-redux-i18n';
import Input from '../../../../Components/Input';

class paramLine extends Component {
	onChangeKey = e => {
		e.preventDefault();
		this.props.onChange(
			e.target.value.replace(/\s/g, ''),
			this.props.paramValue,
			this.props.id_webhook_params
		);
	};
	onChangeValue = e => {
		e.preventDefault();
		let id_webhook_params = null;
		let value = e.target.value;
		const param = this.props.availableParams.find(
			param => param.name === value.substring(1)
		);
		if (param) {
			value = '';
			id_webhook_params = param.id;
		}
		this.props.onChange(this.props.paramKey, value, id_webhook_params);
	};

	onRemoveParam = e => {
		confirm(I18n.t('webhook.confirm_delete_param')).then(() => {
			this.props.onDelete();
		});
	};

	render() {
		const {
			paramKey,
			paramValue,
			availableParams,
			id_webhook_params,
		} = this.props;

		const paramFinded = id_webhook_params
			? availableParams.find(param => param.id === id_webhook_params)
			: false;

		let paramError = false;
		const value = paramFinded ? ':' + paramFinded.name : paramValue;
		if (value && value.indexOf(':') !== -1) {
			const availableParamsName = this.props.availableParams.map(
				param => param.name
			);
			if (!availableParamsName.includes(value.substring(1))) {
				paramError = value.substring(1);
			}
		}
		let labelError = paramError
			? I18n.t('partner.error_param', { param: paramError })
			: labelError;
		return (
			<div className={'param-line'}>
				<Input
					value={paramKey}
					onChange={this.onChangeKey}
					label={this.props.displayLabel ? I18n.t('partner.key') : null}
				/>
				<Input
					value={value}
					onChange={this.onChangeValue}
					inputProps={{ list: 'availableParams' }}
					label={this.props.displayLabel ? I18n.t('partner.value') : null}
					error={labelError}
					inputProps={{ size: '45' }}
				/>
				<datalist id={'availableParams'}>
					{availableParams.map(param => {
						return <option key={param.id} value={`:${param.name}`} />;
					})}
				</datalist>
				<span className={'param-line-delete'}>
					{' '}
					<CancelIcon onClick={this.onRemoveParam} />
				</span>
			</div>
		);
	}
}

paramLine.defaultProps = {
	paramKey: '',
	paramValue: '',
	availableParams: [],
	id_webhook_params: null,
};

paramLine.propTypes = {
	paramKey: Proptypes.string,
	paramValue: Proptypes.string,
	onChange: Proptypes.func.isRequired,
	availableParams: Proptypes.array,
	onDelete: Proptypes.func.isRequired,
};

export default paramLine;
