import React, { Component } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	set_customer_id: 2,
	set_table_payment_and_price_category: 3,
	set_auth_link: 3,
	set_partner_options: 3,
	set_api_mask: 3,
	success: 4,
};

const Dood = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Dood;
