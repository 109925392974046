import React from 'react';
import { Switch } from '@material-ui/core';

const Bool = ({ value, onChange, disabled }) => {
	const onChangeInput = e => {
		e.preventDefault();
		onChange({ active: e.target.checked, value: e.target.checked });
	};
	return (
		<React.Fragment>
			<Switch checked={!!value} onChange={onChangeInput} disabled={disabled} />
		</React.Fragment>
	);
};

export default React.memo(Bool);
