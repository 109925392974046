import { createSelector } from 'reselect';
import { getPartnerPrefix } from '../../utils';

const findPartner = (state, props) => {
	return state.partners.hasOwnProperty(props.prefix)
		? state.partners[props.prefix]
		: {};
};

export const getPartner = createSelector([findPartner], partner => partner);

/**
 * Get partner required keys minus price and name
 */
export const getPartnerRequiredKeys = (partners, prefix) => {
	let keys = partners[prefix].keys || {};
	return Object.values(keys).reduce((acc, k) => {
		if (k.required && k.key_ !== 'name' && k.key_ !== 'price') {
			acc.push(k.key_);
		}
		return acc;
	}, []);
};

export const getExportActive = (props, partner) => {
	return props.find(l => {
		return (
			l.id_partner === partner.id &&
			(l.is_current === 1 || l.is_current === true)
		);
	});
};

export const getMenus = (state, props) => {
	let menus = state.exports.productsSelector.menus.list;
	let productsInMenu = state.exports.productsSelector.products.list;
	let result = [];
	for (let menu in menus) {
		let OneMenu = { ...menus[menu] };
		if (productsInMenu[menu]) {
			OneMenu.nbProduct = Object.keys(productsInMenu[menu]).length;
		}
		result.push(OneMenu);
	}
	return result;
};
