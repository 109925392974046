import React from 'react';
import CheckEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/CheckBox';

const checkbox = props => {
	const style = {
		checkIcon: {
			cursor: 'pointer',
			padding: '12px',
			color: 'rgba(38, 41, 87, 1)',
		},
		emptyIcon: {
			cursor: 'pointer',
			padding: '12px',
			color: 'rgba(0, 0, 0, 0.54)',
		},
	};

	return props.selected ? (
		<CheckIcon style={style.checkIcon} onClick={props.onClick} />
	) : (
		<CheckEmptyIcon style={style.emptyIcon} onClick={props.onClick} />
	);
};

export default checkbox;
