import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
//import PaymentAsso from "../../Components/Binding/PaymentAsso";
import { I18n } from 'react-redux-i18n';
import Options from '../../Components/Options';
import { getPartner, getExportActive } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import Export from '../../Components/Export';
import SupplementManager from '../../Components/SupplementExtra';
import ProductThreshold from '../../Components/ProductQuantity';
import PaymentAsso from '../../Components/Binding';
import ExportBanner from '../../Components/ExportBanner';
import Alert from '../../../../Components/Alert';
import Config from '../../Components/Config';

class Deliveroo extends Component {
	render() {
		const { partner, exportActive } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<Export {...this.props} />
				<Alert type="info">
					{I18n.t('partner.export.deliveroo_info_1_min')}
				</Alert>
				<div className="gestion-button">
					<SupplementManager prefix={partner.prefix} />
					<PaymentAsso prefix={partner.prefix} />
				</div>
				<div className="gestion-button">
					<ProductThreshold prefix={partner.prefix} />
				</div>
				<ExportBanner prefix={partner.prefix} cropper={{ aspect: 16 / 9 }} />
				<Options prefix_partner={partner.prefix || ''} />
				<Config prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
		list: state.exports.list,
	};
};

export default connect(mapStateToProps)(Deliveroo);
