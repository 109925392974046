import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { showConfirm } from '../../../../../Components/ConfirmRedux/service/saga';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { displayInfoPartner, getDisplayPartnerInfo } from '../../partners/saga';

import {
	generateExternalId,
	generateIpadToken,
	initApiData,
	initApiLoyaltyDiscount,
	initApiLoyaltyPaymentType,
	initApiVariable,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	preGenerateLinkPartnerToCustomer,
	queryPartnerIntegration,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'Dvore';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_DVORE');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.como ? partners[PARTNER_PREFIX].external_id : null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: PARTNER_PREFIX,
				partnerId: data.partnerId,
			});

			currentStep = 'set_ipad_token';
			bodySetStep.step = 'set_ipad_token';
			success = true;
		}

		if (currentStep === 'set_ipad_token') {
			const ipadToken = yield call(generateIpadToken);
			if (ipadToken) {
				success = true;
				currentStep = 'set_api_data';
				bodySetStep.step = 'set_api_data';
			}
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				currentStep = 'set_customer_id';
				bodySetStep.step = 'set_customer_id';
			}
		}

		if (currentStep === 'set_customer_id') {
			let res = yield call(generateExternalId);
			if (res && res.success) {
				external_id = res.result;
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
				yield take('PATCH_PARTNER');
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
			currentStep = 'set_auth_link';
			bodySetStep.step = 'set_auth_link';
			success = true;
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: external_id,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);

				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				currentStep = 'success';
				bodySetStep.step = 'success';
				bodySetStep.status = 'actif';
				success = true;
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			if (bodySetStep.step === 'success') {
				yield call(queryPartnerIntegration, PARTNER_PREFIX, external_id);
				yield call(
					showConfirm,
					`${I18n.t('partner.generated_customer_id')} : ${
						bodySetStep.id_user
					}. ${I18n.t('partner.partner_notified')}`,
					'info'
				);
			}
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error');
		}

		yield put({ type: 'PUT_CONFIG_DVORE_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
