import {
  call,
  put,
  take,
  all,
  select,
  actionChannel,
} from "redux-saga/effects";
import { requestHandler, ENTITIES } from "../../../service/fetcher.saga";
import {
  PRE_REMOVE_PICTURE_ONLINE,
  REMOVE_PICTURE_ONLINE,
  PRE_PATCH_PICTURE_ONLINE,
  PATCH_PICTURE_ONLINE,
  PRE_ADD_PICTURE_ONLINE,
  ADD_PICTURE_ONLINE,
  PRE_ADD_PICTURE_ONLINE_REUSED,
  ADD_PICTURE_ONLINE_REUSED,
  PRE_LOAD_QR_CODE,
  PRE_REQUEST_LOAD_QR_CODE,
  REQUEST_LOAD_QR_CODE_FINISHED,
  LOAD_QR_CODE,
} from "./types";

import { buffers } from "redux-saga";

const PRE_REQUEST_REMOVE_PICTURE_ONLINE = "PRE_REQUEST_REMOVE_PICTURE_ONLINE";
const REQUEST_REMOVE_PICTURE_ONLINE_FINISHED =
  "REQUEST_REMOVE_PICTURE_ONLINE_FINISHED";

const PRE_REQUEST_PATCH_PICTURE_ONLINE = "PRE_REQUEST_PATCH_PICTURE_ONLINE";
const REQUEST_PATCH_PICTURE_ONLINE_FINISHED =
  "REQUEST_PATCH_PICTURE_ONLINE_FINISHED";

const PRE_REQUEST_ADD_PICTURE_ONLINE = "PRE_REQUEST_ADD_PICTURE_ONLINE";
const REQUEST_ADD_PICTURE_ONLINE_FINISHED =
  "REQUEST_ADD_PICTURE_ONLINE_FINISHED";

const PRE_REQUEST_ADD_PICTURE_ONLINE_REUSED =
  "PRE_REQUEST_ADD_PICTURE_ONLINE_REUSED";
const REQUEST_ADD_PICTURE_ONLINE_REUSED_FINISHED =
  "REQUEST_ADD_PICTURE_ONLINE_REUSED_FINISHED";

const PICTURE_ONLINE_ONLINE = "pictureOnlineMenu";
const PICTURE_ONLINE_ONLINE_REUSED = `${PICTURE_ONLINE_ONLINE}/reused`;
const QR_CODE = `qrcode`;

export function* preRemovePictureOnline() {
  while (true) {
    const action = yield take(PRE_REMOVE_PICTURE_ONLINE);
    const { id } = action;
    yield put({
      type: PRE_REQUEST_REMOVE_PICTURE_ONLINE,
      id,
    });

    const { result } = yield take(REQUEST_REMOVE_PICTURE_ONLINE_FINISHED);
    yield put({
      type: REMOVE_PICTURE_ONLINE,
      id: id,
    });
  }
}

export function* watchRemovePictureOnline() {
  while (true) {
    const action = yield take(PRE_REQUEST_REMOVE_PICTURE_ONLINE);
    const { result } = yield call(
      requestHandler,
      ENTITIES.INFO,
      "DELETE",
      { id: action.id },
      PICTURE_ONLINE_ONLINE
    );
    yield put({ type: REQUEST_REMOVE_PICTURE_ONLINE_FINISHED, result });
  }
}

export function* prePatchPictureOnline() {
  const requestChannel = yield actionChannel(
    PRE_PATCH_PICTURE_ONLINE,
    buffers.expanding()
  );
  while (true) {
    const action = yield take(requestChannel);
    const { body } = action;
    yield put({
      type: PRE_REQUEST_PATCH_PICTURE_ONLINE,
      body,
    });

    const { result } = yield take(REQUEST_PATCH_PICTURE_ONLINE_FINISHED);
    yield put({ type: PATCH_PICTURE_ONLINE, patch: body });
  }
}

export function* watchPatchPictureOnline() {
  while (true) {
    const action = yield take(PRE_REQUEST_PATCH_PICTURE_ONLINE);
    const { result } = yield call(
      requestHandler,
      ENTITIES.INFO,
      "PATCH",
      action.body,
      PICTURE_ONLINE_ONLINE
    );

    yield put({ type: REQUEST_PATCH_PICTURE_ONLINE_FINISHED, result });
  }
}

export function* preAddPictureOnline() {
  while (true) {
    const action = yield take(PRE_ADD_PICTURE_ONLINE);
    const { body } = action;
    yield put({
      type: PRE_REQUEST_ADD_PICTURE_ONLINE,
      body,
    });

    const { result } = yield take(REQUEST_ADD_PICTURE_ONLINE_FINISHED);
    yield put({
      type: ADD_PICTURE_ONLINE,
      picture: { ...body, id: result.insertId },
    });
  }
}

export function* watchAddPictureOnline() {
  while (true) {
    const action = yield take(PRE_REQUEST_ADD_PICTURE_ONLINE);
    const { result } = yield call(
      requestHandler,
      ENTITIES.INFO,
      "POST",
      action.body,
      PICTURE_ONLINE_ONLINE
    );
    yield put({ type: REQUEST_ADD_PICTURE_ONLINE_FINISHED, result });
  }
}

export function* preAddPictureReusedOnline() {
  while (true) {
    const action = yield take(PRE_ADD_PICTURE_ONLINE_REUSED);
    const { body } = action;
    yield put({
      type: PRE_REQUEST_ADD_PICTURE_ONLINE_REUSED,
      body,
    });

    const { result } = yield take(REQUEST_ADD_PICTURE_ONLINE_REUSED_FINISHED);
    yield put({
      type: ADD_PICTURE_ONLINE_REUSED,
      picture: { ...body },
    });
  }
}

export function* watchAddPictureReusedOnline() {
  while (true) {
    const action = yield take(PRE_REQUEST_ADD_PICTURE_ONLINE_REUSED);
    const { result } = yield call(
      requestHandler,
      ENTITIES.INFO,
      "POST",
      action.body,
      PICTURE_ONLINE_ONLINE_REUSED
    );
    yield put({ type: REQUEST_ADD_PICTURE_ONLINE_REUSED_FINISHED, result });
  }
}

export function* preLoadQrCode() {
  while (true) {
    const action = yield take(PRE_LOAD_QR_CODE);
    const { body } = action;
    yield put({
      type: PRE_REQUEST_LOAD_QR_CODE,
      body,
    });

    const { result } = yield take(REQUEST_LOAD_QR_CODE_FINISHED);
    yield put({
      type: LOAD_QR_CODE,
      result,
    });
  }
}

export function* watchLoadQrCode() {
  while (true) {
    const action = yield take(PRE_REQUEST_LOAD_QR_CODE);
    const { result } = yield call(
      requestHandler,
      ENTITIES.INFO,
      "POST",
      action.body,
      QR_CODE
    );
    yield put({ type: REQUEST_LOAD_QR_CODE_FINISHED, result });
  }
}

export default function* indexSaga() {
  yield all([
    preRemovePictureOnline(),
    watchRemovePictureOnline(),
    preAddPictureOnline(),
    watchAddPictureOnline(),
    prePatchPictureOnline(),
    watchPatchPictureOnline(),
    preAddPictureReusedOnline(),
    watchAddPictureReusedOnline(),
    preLoadQrCode(),
    watchLoadQrCode(),
  ]);
}
