import {
	RECEIVE_INFO,
	PATCH_INFO,
	REMOVE_PICTURE,
	PATCH_PICTURE,
	PATCH_ADDRESS,
	PATCH_CLOSING_DATE,
	REMOVE_CLOSING_DATE,
	ADD_CLOSING_DATE,
	ADD_PICTURE,
	ADD_INFO_ADDRESS,
	RECEIVE_VARIABLE,
	RECEIVE_PICTURES,
	ADD_PICTURE_REUSED,
} from './types';
import { combineReducers } from 'redux';

const initialState = { variable: {} };
const reducerInfo = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_INFO:
			newState = action.data;
			return newState;
		case ADD_INFO_ADDRESS:
			newState.infoRestau = action.data.infoRestau;
			newState.address = action.data.address;
			return newState;

		case PATCH_PICTURE:
			if (action.patch) {
				if (newState.picture.length > 0) {
					newState.picture = newState.picture.map(pic => {
						if (action.patch.id === pic.id) {
							let picWithNewWeight = { ...pic };
							picWithNewWeight.weight = action.patch.weight;
							return picWithNewWeight;
						} else {
							return pic;
						}
					});
				} else {
					return action.patch;
				}
			}
			return newState;
		case REMOVE_PICTURE:
			newState.picture = newState.picture.filter(
				picture => picture.id !== action.id
			);
			return newState;
		case PATCH_INFO:
			if (action.patch) {
				newState.infoRestau = { ...newState.infoRestau, ...action.patch };
			}
			return newState;
		case PATCH_ADDRESS:
			if (action.patch) {
				newState.address = { ...newState.address, ...action.patch };
			}
			return newState;
		case PATCH_CLOSING_DATE:
			if (action.patch) {
				newState.closingDate = newState.closingDate.map(date => {
					if (date.id_closing_date === action.patch.id_closing_date) {
						return { ...date, ...action.patch };
					}
					return date;
				});
			}
			return newState;
		case ADD_CLOSING_DATE:
			if (action.closingDate) {
				newState.closingDate = [...newState.closingDate, action.closingDate];
			}
			return newState;
		case ADD_PICTURE:
			if (action.picture) {
				if (action.picture.env === 'info') {
					newState.picture = [...newState.picture, action.picture];
				}
				newState.pictures = [...newState.pictures, action.picture];
			}
			return newState;
		case ADD_PICTURE_REUSED:
			if (action.picture) {
				newState.picture = [...newState.picture, action.picture];
			}
			return newState;
		case REMOVE_CLOSING_DATE:
			newState.closingDate = newState.closingDate.filter(
				date => date.id !== action.id
			);
			return newState;

		case RECEIVE_VARIABLE:
			if (action.data) {
				newState.variable = action.data;
			}
			return newState;
		case 'UPATE_VARIABLE':
			if (action.key) {
				newState.variable[action.key] = action.value;
			}
			return newState;
		case RECEIVE_PICTURES:
			if (action.data) {
				newState.pictures = action.data;
			}
			return newState;
		default:
			return newState;
	}
};

export default reducerInfo;
