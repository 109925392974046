import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import {
	refreshBindings,
	requestHandler,
} from '../../../../../service/fetcher.saga';
import { checkUUIDV4 } from '../../../../../service/utils';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import {
	checkIfPartnerCustomerExist,
	initApiData,
	initApiVariable,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'tabesto';
const GET_TIMEOUT = '60';

function* configTabesto() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_TABESTO');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.tabesto ? partners.tabesto.external_id : null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'set_api_data';
			bodySetStep.step = 'set_api_data';
			success = true;
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				currentStep = 'set_api_get_timeout';
				bodySetStep.step = 'set_api_get_timeout';
				success = true;
			}
		}

		if (currentStep === 'set_api_get_timeout') {
			const successInitVar = yield call(
				initApiVariable,
				'variable',
				'api_get_timeout',
				GET_TIMEOUT
			);
			if (successInitVar) {
				success = true;
				bodySetStep.step = 'documentation';
			}
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_token_tabesto') {
			data.token_tabesto = data.token_tabesto ? data.token_tabesto.trim() : '';
			if (data.token_tabesto) {
				if (checkUUIDV4(data.token_tabesto)) {
					yield call(handlePatchVariable, {
						key: 'tabestoToken',
						value: data.token_tabesto,
					});

					yield put({
						type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
						body: { prefix: PARTNER_PREFIX, external_id: data.token_tabesto },
					});
					yield take('PATCH_PARTNER');

					yield put({
						type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
						body: {
							partnerPrefix: 'tabesto',
							id: data.userId,
						},
					});
					const { result } = yield take(
						'REQUEST_POST_PARTNER_CHECKING_FINISHED'
					);

					if (result && result.status === 'success' && result.responseData) {
						let resCheck = yield call(checkIfPartnerCustomerExist, {
							partner_id: data.partnerId,
							partner_customer_id: data.token_tabesto,
						});

						if (!resCheck) {
							errorMessage = 'partner.link_partner_check_error';
						} else if (resCheck && resCheck.exist === false) {
							yield call(
								notify,
								I18n.t('partner.tabesto_credientials_verified'),
								'success'
							);

							success = true;
						} else {
							errorMessage = 'partner.link_partner_customer_allready_exist';
						}
					} else {
						errorMessage =
							'partner.onboarding_tabesto_errors.set_token_tabesto';
					}
				} else {
					errorMessage = 'partner.onboarding_common_errors.input_validator';
				}
			} else {
				errorMessage = 'partner.mandatory_field';
			}
		}

		if (currentStep === 'set_entities') {
			const { binding } = yield select();
			let table = binding.floor.find(f => f.id === 'tabesto-1');
			let paiementType = binding.paymentType.find(f => f.id === 'tabesto');
			let priceCategory = binding.paymentType.find(f => f.id === 'tabesto-1');

			let needInsert = !table || !paiementType || !priceCategory;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			if (!needInsert || insertSuccess) {
				if (needInsert) yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

				let body = {
					prefix: PARTNER_PREFIX,
					id_table: 'tabesto-1',
				};

				const resultFloor = yield call(patchPartnerFloor, body);

				body = {
					prefix: PARTNER_PREFIX,
					id_payment_type: 'tabesto',
				};

				const resultPaiementType = yield call(patchPartnerPaiementType, body);

				body = {
					prefix: PARTNER_PREFIX,
					id_price_category: 'tabesto-1',
				};

				const resultPriceCategory = yield call(patchPartnerPriceCategory, body);

				if (
					resultFloor &&
					resultFloor.result &&
					resultPaiementType &&
					resultPaiementType.result &&
					resultPriceCategory &&
					resultPriceCategory.result
				) {
					success = true;
					currentStep = 'set_auth_link';
					bodySetStep.step = 'set_auth_link';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
					success = true;
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				success = true;
				bodySetStep.step = 'set_api_mask';
				currentStep = 'set_api_mask';
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.status = 'actif';
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_TABESTO_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([configTabesto()]);
}
