import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';

import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { RECEIVE_PARTNERS_DATA } from '../../partnersData/types';

import {
	checkIfPartnerCustomerExist,
	initApiData,
	initEntities,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
	triggerSynchroMenu,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'tickncook';

function* configTickncook() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_TICKNCOOK');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;
		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'set_api_data';
			bodySetStep.step = 'set_api_data';
			success = true;
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				bodySetStep.step = 'set_tickncook_account_id';
			}
		}

		if (currentStep === 'set_tickncook_account_id') {
			if (data.customer_id) {
				yield call(handlePatchVariable, {
					key: 'tickncookAccountId',
					value: data.customer_id,
				});
				yield put({
					type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
					body: {
						partnerPrefix: PARTNER_PREFIX,
						id: data.userId,
					},
				});
				const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');
				if (
					result &&
					result.status === 'success' &&
					result.responseData &&
					result.responseData.length
				) {
					yield put({
						type: RECEIVE_PARTNERS_DATA,
						data: result.responseData,
						prefix: PARTNER_PREFIX,
					});

					success = true;
				} else {
					yield call(
						notify,
						I18n.t('partner.onboarding_common_errors.check_customer_id'),
						'error',
						10000
					);
					success = false;
				}
			} else {
				errorMessage = 'partner.mandatory_field';
			}
		}

		if (currentStep === 'set_tickncook_kitchen_id') {
			external_id = data.customer_id;
			yield put({
				type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
				body: { prefix: PARTNER_PREFIX, external_id },
			});

			yield take('PATCH_PARTNER');

			let resCheck = yield call(checkIfPartnerCustomerExist, {
				partner_id: data.partnerId,
				partner_customer_id: external_id,
			});

			if (!resCheck) {
				errorMessage = 'partner.link_partner_check_error';
			} else if (resCheck && resCheck.exist === false) {
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
				success = true;
			} else {
				errorMessage = 'partner.link_partner_customer_allready_exist';
			}

			success = true;
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_send_feature';
					bodySetStep.step = 'set_send_feature';
					success = true;
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_send_feature') {
			const resultInitEntities = yield call(initEntities, PARTNER_PREFIX);
			if (resultInitEntities) {
				success = true;
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
			} else {
				errorMessage = 'partner.onboarding_common_errors.set_entities';
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'trigger_menu_synchro';
				currentStep = 'trigger_menu_synchro';
			}
		}

		if (currentStep === 'trigger_menu_synchro') {
			yield call(triggerSynchroMenu);

			bodySetStep.status = 'actif';
			bodySetStep.step = 'success';

			success = true;
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_TICKNCOOK_FINISHED',
			step: bodySetStep.step,
		});
	}
}

export default function* indexSaga() {
	yield all([configTickncook()]);
}
