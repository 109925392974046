import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { displayInfoPartner } from '../../partners/saga';

import {
	generateExternalId,
	initApiLoyaltyDiscount,
	initApiLoyaltyPaymentType,
	linkLoyaltyWebView,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

function* configComo() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_COMO');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.como ? partners.como.external_id : null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			let res1 = yield call(handlePatchVariable, {
				key: 'comoDiscountId',
				value: 'loyalty-1',
			});
			let res2 = yield call(handlePatchVariable, {
				key: 'comoGiftCardPaymentTypeId',
				value: 'como-g',
			});
			if (res1 && res2) {
				success = true;
			}
		} else if (currentStep === 'set_joining_item') {
			let res1 = yield call(handlePatchVariable, {
				key: 'comoJoiningItemId',
				value: data.joining_item,
			});

			if (res1) {
				success = true;
			}
		} else if (currentStep === 'set_api_key') {
			yield call(handlePatchVariable, {
				key: 'comoApiKey',
				value: data.api_key,
			});

			yield put({
				type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
				body: { partnerPrefix: 'como', id: data.userId, uuid: external_id },
			});
			const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');

			//TODO: refactor reponse coté api ou med back pour recevoir un truc ou false (comme uber eats)
			if (
				result &&
				result.responseData &&
				result.responseData.status === 'ok' &&
				result.responseData.apiKeyStatus === 'active'
			) {
				success = true;

				yield call(notify, I18n.t('partner.api_key_verified'), 'success');
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_branch_pos_id') {
			let res = yield call(generateExternalId);
			if (res && res.success) {
				external_id = res.result;
				let res1 = yield call(handlePatchVariable, {
					key: 'comoBranchId',
					value: external_id,
				});
				let res2 = yield call(handlePatchVariable, {
					key: 'comoPosId',
					value: external_id,
				});

				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: 'como', external_id },
				});

				if (res1 && res2) {
					success = true;
					currentStep = 'set_liaison_como';
					bodySetStep.step = 'set_liaison_como';
				}
			}
		}

		if (currentStep === 'set_liaison_como') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_liaison_webview_loyalty';
					bodySetStep.step = 'set_liaison_webview_loyalty';
					success = true;
				}
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_liaison_webview_loyalty') {
			if (external_id) {
				const refresh_token = window.localStorage.refresh_token;
				let successLink = yield call(linkLoyaltyWebView, {
					refresh_token,
					partnerId: data.partnerId,
					uuid: data.userId,
				});

				if (successLink) {
					currentStep = 'set_api_discount';
					bodySetStep.step = 'set_api_discount';
					success = true;
				} else {
					errorMessage = 'partner.onboarding_como_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_api_discount') {
			let successApiCall = yield call(initApiLoyaltyDiscount, 'como');
			if (successApiCall) {
				currentStep = 'set_api_payment_type';
				bodySetStep.step = 'set_api_payment_type';
				success = true;
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_api_payment_type') {
			let successApiCall = yield call(initApiLoyaltyPaymentType, 'como');
			if (successApiCall) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: 'como',
			});

			if (setApiMaskSuccess) {
				currentStep = 'success';
				bodySetStep.step = 'success';
				bodySetStep.status = 'actif';
				success = true;
			}
		}
		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			if (bodySetStep.step === 'success') {
				yield call(displayInfoPartner, 'como');
			}
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error');
		}

		yield put({ type: 'PUT_CONFIG_COMO_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([configComo()]);
}
