import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './types';

export const enqueueSnackbar = notification => ({
	type: ENQUEUE_SNACKBAR,
	notification: {
		key: new Date().getTime() + Math.random(),
		...notification,
	},
});

export const notify = (label, type, duration = 5000) => {
	const notif = {
		message: label,
		options: {
			variant: type,
			autoHideDuration: duration,
		},
	};
	return enqueueSnackbar(notif);
};

export const removeSnackbar = key => ({
	type: REMOVE_SNACKBAR,
	key,
});
