import {
	RECEIVE_PAYMENT_TYPE,
	RECEIVE_FLOOR,
	RECEIVE_PRICE_CATEGORY,
	RECEIVE_FLOOR_ALL,
} from './types';

const PRODUCT_TYPES = [
	{
		id: 1,
		name: 'Entrée',
	},
	{
		id: 2,
		name: 'Viande',
	},
	{
		id: 3,
		name: 'Poisson',
	},
	{
		id: 4,
		name: 'Végétarien',
	},
	{
		id: 5,
		name: 'Sandwich',
	},
	{
		id: 6,
		name: 'Pizza/pates',
	},
	{
		id: 7,
		name: 'Dessert',
	},
	{
		id: 8,
		name: 'Fromage',
	},
	{
		id: 9,
		name: 'Autre plat',
	},
	{
		id: 101,
		name: 'Cocktail',
	},
	{
		id: 102,
		name: 'Spiritueux',
	},
	{
		id: 103,
		name: 'Vin',
	},
	{
		id: 104,
		name: 'Bière',
	},
	{
		id: 105,
		name: 'Eau',
	},
	{
		id: 106,
		name: 'Soda',
	},
	{
		id: 107,
		name: 'Autre boisson froide',
	},
	{
		id: 108,
		name: 'Café',
	},
	{
		id: 109,
		name: 'Autre boisson chaude',
	},
	{
		id: 201,
		name: 'Menu',
	},
	{
		id: 301,
		name: 'Supplément',
	},
	{
		id: 302,
		name: 'Sous-supplément',
	},
];

const initialState = {
	paymentType: [],
	floor: [],
	floorAll: [],
	priceCategory: [],
	productType: PRODUCT_TYPES,
};

const BindingReducer = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_PAYMENT_TYPE:
			if (action.data) {
				newState.paymentType = [...action.data];
			}
			return newState;
		case RECEIVE_FLOOR:
			if (action.data) {
				newState.floor = [...action.data];
			}
			return newState;
		case RECEIVE_FLOOR_ALL:
			if (action.data) {
				newState.floorAll = [...action.data];
			}
			return newState;
		case RECEIVE_PRICE_CATEGORY:
			if (action.data) {
				newState.priceCategory = [...action.data];
			}
			return newState;
		default:
			return newState;
	}
};

export default BindingReducer;
