import React, { Component, PureComponent } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	generate_customer_id: 0,
	set_auth_link: 0,
	success: 1,
};
const Dionysols = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}></ComplexPartner>
	);
};

export default Dionysols;
