import Konami from 'react-konami-code';
import React, { Component } from 'react';
import './index.scss';
import logo from './img/logo.png';

class CustomBubbles extends Component {
	render = () => (
		<Konami timeout={2000}>
			<div className="custom-bubble-container">
				<img className="logo" alt="laddition" src={logo} id="meteor1" />
				<img className="logo" alt="laddition" src={logo} id="meteor2" />
				<img className="logo" alt="laddition" src={logo} id="meteor3" />
				<img className="logo" alt="laddition" src={logo} id="meteor4" />
				<img className="logo" alt="laddition" src={logo} id="meteor5" />
			</div>
		</Konami>
	);
}

export default CustomBubbles;
