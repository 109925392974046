import React, { Component, PureComponent } from 'react';

import SimplePartner from './index';

const FoodReport = props => {
	return (
		<SimplePartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}></SimplePartner>
	);
};

export default FoodReport;
