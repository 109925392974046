import React from 'react';
import Button from '@material-ui/core/Button';
import CategorySupplement from './CategorySupplement';
import { I18n } from 'react-redux-i18n';
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeftRounded';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import * as action from '../../../../../../../../service/export/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	getProduct,
	findProductInProductsSelector,
} from '../../../../../../../../service/export/selector';
import './style.scss';
import { PropTypes } from 'prop-types';
import {
	deepEqual,
	deepClone,
	confirm,
} from '../../../../../../../../../../utils';
import { Tooltip } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';
import { filterByPrefix } from '../../../../../../../SupplementExtra/Components/CategorySupplement';

class SupplementContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			selected: [],
			list: [],
			categoryExtra: {},
			supplementExtra: {},
			idProduct: '',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.product &&
			nextProps.product.id_product !== prevState.idProduct
		) {
			return {
				open: false,
				idProduct: nextProps.product.id_product,
			};
		}
		return null;
	}

	openSupplements = () => {
		const id_menu = this.props.product.id_menu;
		const list = this.props.categorySupplement.list.hasOwnProperty(id_menu)
			? Object.values(this.props.categorySupplement.list[id_menu])
			: [];

		const selected = this.reduceCats(
			this.props.product.category_supplement || [],
			list
		).sort((a, b) => {
			return a.weight - b.weight;
		});

		const categoryExtra = Object.values(
			this.props.categorySupplement.categorySupplementExtra
		).reduce((acc, cat) => {
			if (cat.length && cat[0].id_menu == id_menu) {
				const categorySupp = cat.reduce(
					(acc2, rowExtra) => {
						acc2[rowExtra.key_] = rowExtra.value;
						return acc2;
					},
					{ id_category_supplement: cat[0].id_category_supplement, id_menu }
				);
				acc[categorySupp.id_category_supplement] = categorySupp;
			}
			return acc;
		}, {});

		let supplementExtra = Object.values(
			this.props.categorySupplement.supplementExtra
		).reduce((acc, supp) => {
			if (supp.length && supp[0].id_menu && supp[0].id_menu == id_menu) {
				if (!acc.hasOwnProperty(supp[0].id_category_supplement)) {
					acc[supp[0].id_category_supplement] = {};
				}
				const newSupp = supp.reduce(
					(acc2, row) => {
						acc2[row.key_] = row.value;
						return acc2;
					},
					{
						id_category_supplement: supp[0].id_category_supplement,
						id_menu,
						id_supplement: supp[0].id_supplement,
					}
				);
				acc[newSupp.id_category_supplement][newSupp.id_supplement] = newSupp;
			}

			return acc;
		}, {});

		this.setState({
			open: true,
			list,
			selected,
			categoryExtra,
			supplementExtra,
		});
	};

	closeSupplements = () => {
		this.setState({ open: false, selected: [] });
	};

	/**
	 * When a change occur in a category supp : select, change min/max, select/deselect supp
	 * @param {Object} categorySupp - object category supp de type {id, minimum, maximum, data : [...id]}
	 */
	onChange = categorySupp => {
		let selected = deepClone(this.state.selected);
		if (selected.find(cat => cat.id === categorySupp.id)) {
			selected = selected.map(cat => {
				if (cat.id === categorySupp.id) {
					return categorySupp;
				}
				return cat;
			});
		} else {
			selected.push(categorySupp);
		}

		this.setState({ selected });
	};

	/**
	 * On Category supp deselect
	 * @param {String} idCategorySupp - id of supplement category deselected
	 */
	onDeselect = idCategorySupp => {
		this.setState({
			selected: this.state.selected.filter(cat => cat.id != idCategorySupp),
		});
	};

	onDelete = () => {
		confirm(I18n.t('partner.supplements.remove_supplement_extra_confirm')).then(
			() => {
				this.props.action.deleteProductExtra({
					idExport: this.props.idExport,
					idProduct: this.props.idProduct || this.props.product.id_product,
					idSection: this.props.idSection,
					key_: 'category_supplement',
					idMenuLevel: this.props.product.id_menu_level,
					idProductParent: this.props.idProductMenu,
				});
				this.closeSupplements();
			}
		);
	};

	save = () => {
		const selectedBefore = this.reduceCats(
			this.props.product.category_supplement || [],
			this.state.list
		);
		const compareSupp = deepEqual(selectedBefore, this.state.selected);

		if (!compareSupp) {
			let selectedSorted = this.state.selected.sort((a, b) => {
				return a.weight - b.weight;
			});

			let category_supplement = this.retrieveCats(
				selectedSorted,
				this.state.list
			);
			// insure that all supp have "active prop"
			category_supplement = category_supplement.map(cat => {
				cat.data = cat.data.map(supp => {
					if (!supp.hasOwnProperty('active')) {
						supp.active = 1;
					}
					return supp;
				});
				return cat;
			});

			const compare = {
				category_supplement: JSON.stringify(category_supplement),
			};
			this.props.action.saveExtraProducts(
				this.props.idExport,
				this.props.idSection,
				this.props.product.id_product,
				compare,
				this.props.context &&
					this.props.context == 'productInMenu' &&
					this.props.product.id_menu_level
					? this.props.product.id_menu_level
					: null,
				this.props.idProductMenu
			);
		}
		this.closeSupplements();
	};

	/**
	 * Minimize cat supp
	 */
	reduceCats = (categories = [], list) => {
		return categories.reduce((acc, cat) => {
			if (list.find(c => c.id === cat.id)) {
				let supps = cat.data.map(s => {
					return { ...s };
				});
				acc.push({
					id: cat.id,
					minimum: cat.minimum,
					maximum: cat.maximum,
					weight: cat.weight,
					data: supps,
				});
			}

			return acc;
		}, []);
	};

	/**
	 * Get minimized cat supp and return populated
	 */
	retrieveCats = (selected, list) => {
		return selected.map(cat => {
			const category = list.find(c => c.id === cat.id);
			let supps = [...cat.data];
			return {
				...category,
				minimum: cat.minimum,
				maximum: cat.maximum,
				weight: cat.weight,
				data: supps,
			};
		});
	};

	render() {
		const productInMenu =
			this.props.context && this.props.context == 'productInMenu';
		const visible = this.state.list && this.state.list.length;

		let showDisableLabel = false;
		for (let category in this.props.categorySupplement
			.categorySupplementExtra) {
			this.props.categorySupplement.categorySupplementExtra[category].find(
				cat => {
					if (cat.key_ === 'disabled' && cat.value === '1') {
						showDisableLabel = true;
					}
				}
			);
		}
		for (let supplement in this.props.categorySupplement.supplementExtra) {
			this.props.categorySupplement.supplementExtra[supplement].find(supp => {
				if (supp.key_ === 'disabled' && supp.value === '1') {
					showDisableLabel = true;
				}
			});
		}

		let categories =
			this.props.product && this.props.product.category_supplement
				? this.props.product.category_supplement
						.filter(cat => !!cat) //blindage si la cat n'a pas de supp associé
						.map(category => {
							let categoryExtra = this.props.categorySupplement
								.categorySupplementExtra[category.id];
							if (categoryExtra) {
								let extraName = categoryExtra.find(cat => {
									return cat.key_ === 'name' && cat.value;
								});
								if (extraName) {
									return extraName.value;
								}
								return category.name;
							}
							return category.name;
						})
				: [];
		if (categories.length >= 4) {
			categories = categories.splice(0, 3);
		}
		if (categories.length > 0) {
			categories = ' ( ' + categories.join(', ') + ', ... )';
		}

		let containerClasses = ['supplement-modal-container'];
		if (this.state.open) {
			containerClasses.push('block');
		} else {
			containerClasses.push('hidden');
		}
		if (productInMenu) {
			containerClasses.push('supplement-modal-container-product-in-menu');
		}

		return (
			<div>
				{this.props.product.product_type !== 2 ? (
					<React.Fragment>
						{productInMenu ? (
							<Tooltip
								title={I18n.t(
									'partner.productForm.tooltip_product_in_menu_category_supplement'
								)}>
								<PlaylistAdd onClick={this.openSupplements} />
							</Tooltip>
						) : (
							<React.Fragment>
								{' '}
								<p className={'form-product-title'}>
									{I18n.t(`partner.productForm.supplement`) + categories}
								</p>
								<div
									onClick={this.openSupplements}
									className="supplement-button-panel">
									<p>{I18n.t(`partner.productForm.supplement_button`)}</p>
									<Arrow />
								</div>
							</React.Fragment>
						)}
						<div className={containerClasses.join(' ')}>
							<div className={'supplement-modal-actions'}>
								<div onClick={this.closeSupplements} className="back-button">
									<ArrowLeft />
									<p>{I18n.t(`partner.productForm.back`)}</p>
								</div>
								{!this.props.product.catSuppExtraEmpty ? (
									<Button
										onClick={this.onDelete}
										color={'secondary'}
										variant="outlined"
										startIcon={<RotateLeftIcon />}
										className="remove-extras-button">
										<p>{I18n.t(`partner.productForm.reset_button`)}</p>
									</Button>
								) : null}

								{visible ? (
									<div className="save-button">
										<Button
											variant={'contained'}
											color={'primary'}
											className={'button-save'}
											onClick={this.save}
											disabled={deepEqual(
												this.reduceCats(
													this.props.product.category_supplement || [],
													this.state.list
												),
												this.state.selected
											)}>
											{I18n.t(`general.save`)}
										</Button>
									</div>
								) : null}
							</div>
							{visible ? (
								<React.Fragment>
									{showDisableLabel ? (
										<p>
											{I18n.t(
												'partner.productForm.category_supplement_disabled'
											)}
										</p>
									) : null}
									{productInMenu && (
										<span className="supplement-modal-product-name">
											{this.props.product.name}
										</span>
									)}
									<CategorySupplement
										selected={this.state.selected}
										list={this.state.list}
										onChange={this.onChange}
										onDeselect={this.onDeselect}
										categoryExtra={this.state.categoryExtra}
										supplementExtra={this.state.supplementExtra}
									/>
								</React.Fragment>
							) : (
								<h3 style={{ textAlign: 'center' }}>
									{I18n.t(`partner.productForm.empty`)}
								</h3>
							)}
						</div>{' '}
					</React.Fragment>
				) : (
					<div />
				)}
			</div>
		);
	}
}

SupplementContainer.propTypes = {
	idExport: PropTypes.number.isRequired,
	idSection: PropTypes.number.isRequired,
	product: PropTypes.object,
};

const mapStateToProps = (state, props) => {
	return {
		categorySupplement: {
			...state.exports.supplement,
			categorySupplementExtra: filterByPrefix(
				state.exports.supplement.categorySupplementExtra,
				props.prefix
			),
			supplementExtra: filterByPrefix(
				state.exports.supplement.supplementExtra,
				props.prefix
			),
		},
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(action, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplementContainer);
