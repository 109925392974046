import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { I18n } from 'react-redux-i18n';

const LocationChoice = ({ onValid, list, onCancel, open }) => {
	return (
		<Dialog
			onClose={onCancel}
			open={open}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}>
			<DialogTitle>{I18n.t('info.address.choose_location')}</DialogTitle>
			<div>
				<List>
					{list.map((address, i) => (
						<ListItem button onClick={() => onValid(i)} key={i}>
							<ListItemText primary={address.text} />
						</ListItem>
					))}
				</List>
			</div>
		</Dialog>
	);
};

LocationChoice.propTypes = {
	onValid: PropTypes.func.isRequired,
	list: PropTypes.array.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default LocationChoice;
