import React from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	check_access_admin_menu: 1,
	set_ipad_token: 1,
	set_api_data: 1,
	check_liveness: 1,
	set_auth_link: 1,
	set_api_mask: 1,
	set_api_menu_online: 1,
	trigger_synchro: 1,
	success: 2,
};

const LadditionMenu = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default LadditionMenu;
