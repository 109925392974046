import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';

import {
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	watchClickAndCollectLink,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';
import { activeStockManagement } from '../../../Components/ProductQuantity/service/saga';
import { refreshBindings } from '../../../../../service/fetcher.saga';

const PARTNER_PREFIX = 'celladdition';

function* configCommandeEnLigne() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_CELLADDITION');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.celladdition
				? partners.celladdition.external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'init_partner';
			bodySetStep.step = 'init_partner';
			success = true;
		}

		if (currentStep === 'init_partner') {
			const resultInit = yield call(watchClickAndCollectLink, true);
			if (resultInit.status === 'success' && resultInit.result) {
				external_id = resultInit.result;

				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
				yield take('PATCH_PARTNER');

				bodySetStep.step = 'set_auth_link';
				currentStep = 'set_auth_link';
				success = true;
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);

					bodySetStep.step = 'documentation';
					success = true;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_entities') {
			const { binding } = yield select();
			let paiementType = binding.paymentType.find(f => f.id === 'celladdition');
			let table = binding.floor.find(f => f.id === 'celladdition-1');
			let priceCategory = binding.paymentType.find(
				f => f.id === 'celladdition'
			);

			let needInsert = !paiementType || !table || !priceCategory;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			if (!needInsert || insertSuccess) {
				let resultPaiementType;
				let resultFloor;
				let resultPriceCategory;
				if (needInsert) {
					yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

					let body = {
						prefix: PARTNER_PREFIX,
						id_payment_type: 'celladdition',
					};

					resultPaiementType = yield call(patchPartnerPaiementType, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_table: 'celladdition-1',
					};

					resultFloor = yield call(patchPartnerFloor, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_price_category: 'celladdition',
					};

					resultPriceCategory = yield call(patchPartnerPriceCategory, body);
				}

				if (
					!needInsert ||
					(resultPaiementType &&
						resultPaiementType.result &&
						resultFloor &&
						resultFloor.result &&
						resultPriceCategory &&
						resultPriceCategory.result)
				) {
					success = true;
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}
		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_CELLADDITION_FINISHED',
			step: bodySetStep.step,
		});
	}
}

export default function* indexSaga() {
	yield all([configCommandeEnLigne()]);
}
