import React from 'react';
import { List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { I18n, Translate } from 'react-redux-i18n';
import { ALL } from '../utils';
import {
	parseCategoryByMenu,
	getCategorySelected,
	getProductsList,
	getNbProductMenu,
} from '../selector';
import { connect } from 'react-redux';
import Section from './Section';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as actions from '../../../service/categories/actions';

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
		height: '100%',
	},
	sectionList: { selected: { backgroundColor: '#e2f5fd' } },
});

class SectionList extends React.Component {
	onSelect = id => {
		if (id != this.props.selected) {
			this.props.select(id);
		}
	};

	renderContentPlaceholder() {
		const nb = 14;
		const width = 200;
		const height = 30;
		const margin = 5;

		return (
			<ContentLoader
				rtl
				height={620}
				width={240}
				speed={1}
				primaryColor="#f3f3f3"
				secondaryColor="#ecebeb">
				{renderContent(nb)}
			</ContentLoader>
		);

		function renderContent(count) {
			const content = [];
			for (let x = 0; x < count; x++) {
				content.push(
					<rect
						x="9"
						y={x * (height + 10 + margin) + 15}
						rx="15"
						ry="15"
						width={width}
						height={height}
					/>
				);
			}
			return content;
		}
	}

	render() {
		const { selected } = this.props;

		let allSections = { ...this.props.sections };
		if (this.props.nbMenus > 0) {
			allSections = {
				...allSections,
				menuId: {
					id: 'menuId',
					id_global: 'menuId',
					id_menu: 'idMenu',
					name: I18n.t('partner.products_selector.section_list.menus'),
				},
			};
		}

		return (
			<React.Fragment>
				<h2>{I18n.t('partner.products_selector.section_list.title')}</h2>
				<List
					className={
						`${this.props.classes.root}` +
						' ' +
						`${this.props.classes.sectionList}` +
						' ' +
						'products-category-container'
					}>
					{this.props.loaded ? (
						<React.Fragment>
							<Section
								key={ALL}
								id={ALL}
								name={I18n.t('partner.products_selector.section_list.all')}
								selected={selected === ALL}
								onClick={this.onSelect}
								primary={true}
							/>
							{allSections && Object.keys(allSections).length ? (
								<React.Fragment>
									<Section
										key={0}
										id={0}
										name={I18n.t(
											'partner.products_selector.section_list.no_category'
										)}
										selected={selected === 0}
										onClick={this.onSelect}
										primary={true}
									/>

									{Object.values(allSections).map(section => {
										let itemSelected = selected === section.id;
										return (
											<Section
												key={section.id}
												id={section.id}
												name={section.name}
												count={section.count}
												selected={itemSelected}
												onClick={this.onSelect}
											/>
										);
									})}
								</React.Fragment>
							) : (
								<p className={'section-empty'}>
									<Translate
										value={'partner.products_selector.section_list.none'}
									/>
								</p>
							)}
						</React.Fragment>
					) : (
						this.renderContentPlaceholder()
					)}
				</List>
			</React.Fragment>
		);
	}
}

SectionList.propTypes = {
	loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	return {
		selected: getCategorySelected(state),
		sections: parseCategoryByMenu(state),
		nbMenus: getNbProductMenu(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		select: bindActionCreators(actions.select, dispatch),
	};
};

export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(SectionList)
);
