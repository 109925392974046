import React, { Component } from 'react';
import { ListItem, ListItemText, Switch } from '@material-ui/core';
import { List } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import InputEdit from '../../../../../../../../SupplementExtra/Components/InputEdit';
import Checkbox from '../../../../../../../../../../../Components/CheckBox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
	sortableContainer,
	sortableElement,
	sortableHandle,
} from 'react-sortable-hoc';
import MenuIcon from '@material-ui/icons/Menu';
import arrayMove from 'array-move';
import Visibility from '../../Inputs/visible';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Hint from '../../../../../../../../../../../Components/Hint';
import SupplementContainer from '../../Supplement';

const DragHandle = sortableHandle(() => (
	<span>
		<MenuIcon className="menu-icon" />
	</span>
));

const SortableItem = sortableElement(({ value }) => (
	<li className="list-item">{value}</li>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <ul className="under-level-container">{children}</ul>;
});

const DragProductHandle = sortableHandle(() => (
	<span>
		<MenuIcon className="menu-product-icon" />
	</span>
));

const SortableProductItem = sortableElement(({ value }) => (
	<li className="list-item">{value}</li>
));

const SortableProductContainer = sortableContainer(({ children }) => {
	return <ul>{children}</ul>;
});

class ListMenuLevel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuLevelOpen: 0,
			menus: [],
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.menuLevels !== prevState.menus) {
			return {
				menus: nextProps.menuLevels,
			};
		}
		return null;
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const newTable = arrayMove([...this.state.menus], oldIndex, newIndex).map(
			(menu, index) => {
				menu.weight = index;
				return menu;
			}
		);
		this.props.onChange(newTable);
	};

	onProductSortEnd = ({ oldIndex, newIndex }) => {
		let newMenus = [...this.state.menus];
		const menu = newMenus.find(menu => menu.id === this.state.menuLevelOpen);
		const newTable = arrayMove(menu.data, oldIndex, newIndex).map(
			(item, index) => {
				item.weight = index;
				return item;
			}
		);
		menu.data = newTable;
		this.props.onChange(newMenus);
	};

	onClick = id => {
		this.setState({
			menuLevelOpen: this.state.menuLevelOpen === id ? 0 : id,
		});
	};

	isEmpty = (value = '') => {
		if (!value || value === '' || value.trim() === '') {
			return true;
		}
		return false;
	};

	alertCantbeEmpty = () => {
		this.props.notify(
			I18n.t('partner.brandChannel.mandatory_field'),
			'warning'
		);
	};

	onChange = (
		prop,
		value,
		menuLevelId,
		productId,
		byPassEmptyCheck = false
	) => {
		if (!byPassEmptyCheck) {
			if (this.isEmpty(value)) {
				this.alertCantbeEmpty();
				return;
			}
		}

		let newMenus = [...this.state.menus];
		const menu = newMenus.find(menu => menu.id === menuLevelId);
		let product = menu.data.find(p => p.id === productId);
		if (prop === 'name' || prop === 'disabled') {
			product[prop] = value;
		}
		if (prop === 'priceSupplement') {
			product.priceSupplement = value * 100;
		}
		this.props.onChange(newMenus);
	};

	onChangeMenuLevel = (value, menuLevelId) => {
		if (this.isEmpty(value)) {
			this.alertCantbeEmpty();
			return;
		}
		let newMenus = [...this.state.menus];
		const menu = newMenus.find(menu => menu.id === menuLevelId);
		menu.name = value;
		this.props.onChange(newMenus);
	};

	onChangeActive = (active, menuLevelId, productId) => {
		let newMenus = [...this.state.menus];
		const menu = newMenus.find(menu => menu.id === menuLevelId);
		let product = menu.data.find(p => p.id === productId);
		product.active = active;
		this.props.onChange(newMenus);
	};

	onChangeMenuLevelHub = menuLevelId => {
		let newMenus = [...this.state.menus];
		let menuLevel = newMenus.find(ml => ml.id === menuLevelId);
		let isHub = !!!menuLevel.isHub;
		let disableOtherMenuLevel = !!isHub;

		newMenus = newMenus.map(menu => {
			if (menu.id === menuLevelId) {
				menu.isHub = isHub;
			} else if (disableOtherMenuLevel) {
				menu.isHub = false;
			}
			return menu;
		});
		this.props.onChange(newMenus);
	};
	render() {
		let productList = [];
		let list = [];
		this.props.menuLevels.map(m => {
			let extra = this.props.extras
				? this.props.extras.find(ext => ext.id === m.id)
				: '';
			let data = m.data;
			data.sort((a, b) => {
				return a.weight - b.weight;
			});

			productList = data.map(d => {
				let productExtra = extra ? extra.data.find(ex => ex.id === d.id) : '';
				let catSuppExtra = null;
				const suppCatExtraExist =
					this.props.product.product_in_menu_category_supplement &&
					this.props.product.product_in_menu_category_supplement[m.id] &&
					this.props.product.product_in_menu_category_supplement[m.id][d.id];

				if (suppCatExtraExist) {
					catSuppExtra =
						this.props.product.product_in_menu_category_supplement[m.id][
							d.id
						] || [];
				} else if (
					this.props.categorySupplementForProduct &&
					this.props.categorySupplementForProduct[d.id]
				) {
					// sinon on cherche dans les extras du produit a la carte
					catSuppExtra = this.props.categorySupplementForProduct[d.id] || [];
				} else {
					catSuppExtra = this.props.categorySupplementDefault[d.id] || [];
				}

				let attrDisable = {};
				if (this.props.disableVisibility) {
					attrDisable = {
						disabled: true,
						labelDisabled: I18n.t(
							'partner.productForm.tooltip_visible_disabled'
						),
					};
				}

				return (
					<div className="product-menu-item">
						<ListItem>
							<ListItemIcon>
								<DragProductHandle />
							</ListItemIcon>
							<ListItemText
								className={`list-item-container ${
									d.disabled ? 'list-item-product-disabled' : ''
								}`}
								primary={
									<div className="menu-level-item">
										<InputEdit
											value={productExtra ? productExtra.name : d.name}
											inputProps={{ type: 'text' }}
											onChange={value => {
												this.onChange('name', value, m.id, d.id);
											}}
										/>
										<InputEdit
											value={
												productExtra.priceSupplement
													? productExtra.priceSupplement / 100
													: d.priceSupplement / 100
											}
											className={'supp-menu-price'}
											inputProps={{
												type: 'number',
												min: 0,
												step: '0.01',
											}}
											onChange={value => {
												this.onChange('priceSupplement', value, m.id, d.id);
											}}
											sigle={this.props.sigle}
										/>
									</div>
								}
							/>

							<SupplementContainer
								{...this.props}
								context="productInMenu"
								product={{
									...d,
									id_product: d.id,
									category_supplement: catSuppExtra,
									id_menu: this.props.selectorProduct.id_menu,
									id_menu_level: m.id,
									catSuppExtraEmpty: !suppCatExtraExist,
								}}
							/>
							<Checkbox
								selected={
									productExtra.active ? !!productExtra.active : !!d.active
								}
								onClick={e =>
									this.onChangeActive(d.active === 0 ? 1 : 0, m.id, d.id)
								}
							/>
							{this.props.prefix !== 'deliveroo' ? (
								<Visibility
									invert={true}
									value={
										d.disabled || this.props.disabledProducts.includes(d.id)
									}
									onChange={(prop, value) =>
										this.onChange('disabled', value, m.id, d.id, true)
									}
									{...attrDisable}
								/>
							) : null}
						</ListItem>
						<Divider variant="middle" className="list-divider" />
					</div>
				);
			});
			list.push(
				<div className="under-level-title-container">
					<ListItem key={m.id} id={m.id} onClick={e => this.onClick(m.id)}>
						<ListItemIcon>
							<DragHandle />
						</ListItemIcon>
						<ListItemText
							primary={
								<InputEdit
									value={extra ? extra.name : m.name}
									inputProps={{ type: 'text' }}
									onChange={value => {
										this.onChangeMenuLevel(value, m.id);
									}}
								/>
							}
						/>
						{this.props.prefix === 'deliverect' ? (
							<span className="under-level-hub-checkbox">
								{I18n.t('exports.menuLevel.checkbox_isHub')}

								<Switch
									checked={!!m.isHub}
									onClick={e => e.stopPropagation()}
									onChange={e => {
										this.onChangeMenuLevelHub(m.id);
									}}
								/>
								<Hint label={I18n.t('exports.menuLevel.hint_isHub')} />
							</span>
						) : null}
						{this.state.menuLevelOpen === m.id && m.data.length > 0 ? (
							<ExpandLess className="expandless-icon" />
						) : (
							<ExpandMore />
						)}
					</ListItem>
					<Divider className="list-divider" />
					<Collapse
						in={this.state.menuLevelOpen === m.id}
						timeout="auto"
						unmountOnExit>
						<List
							component="div"
							disablePadding
							className="product-menu-list-container">
							<SortableProductContainer
								onSortEnd={this.onProductSortEnd}
								useDragHandle>
								{productList.map((value, index) => (
									<SortableProductItem
										key={`item-${index}`}
										index={index}
										value={value}
									/>
								))}
							</SortableProductContainer>
						</List>
					</Collapse>
				</div>
			);
		});

		return (
			<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
				{list.map((value, index) => (
					<SortableItem key={`item-${index}`} index={index} value={value} />
				))}
			</SortableContainer>
		);
	}
}

export default ListMenuLevel;
