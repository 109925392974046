import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { getVariableServer } from '../../../../../utils';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import { preGenerateLinkPartnerToCustomer, setPartnerMedDefaut } from '../saga';
import {
	PRE_CHANGE_PARTNER_STEP,
	PRE_CHANGE_PARTNER_STATUS,
	PRE_POST_PARTNER_TO_MED_DEFAULT,
} from '../types';

const PARTNER_PREFIX = 'barratio';

function* configBarratio() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_BARRATIO');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.barratio ? partners.barratio.external_id : null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			success = true;
		}

		if (currentStep === 'set_token_barratio') {
			data.token_barratio = data.token_barratio
				? data.token_barratio.trim()
				: '';
			if (data.token_barratio) {
				if (data.token_barratio.includes('bearer')) {
					data.token_barratio = data.token_barratio.replace(
						'bearer ',
						'Bearer '
					);
				} else if (!data.token_barratio.includes('Bearer')) {
					data.token_barratio = 'Bearer ' + data.token_barratio;
				}

				external_id = data.token_barratio.replace('Bearer ', '');
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: {
						prefix: 'barratio',
						external_id,
					},
				});
				yield call(handlePatchVariable, {
					key: 'baratioToken',
					value: data.token_barratio,
				});

				success = true;
			} else {
				errorMessage = 'partner.mandatory_field';
			}
		}

		if (currentStep === 'call_inform_partner') {
			const { result: resultTicketInform } = yield call(
				requestHandler,
				'partners',
				'POST',
				{
					partnerPrefix: PARTNER_PREFIX,
				},
				'sendStock'
			);
			if (resultTicketInform && resultTicketInform.status === 'success') {
				success = true;
				bodySetStep.step = 'set_stock_online';
				currentStep = 'set_stock_online';
			} else {
				errorMessage = 'partner.onboarding_common_errors.set_auth_link';
				bodySetStep.step = 'set_token_barratio';
				currentStep = 'set_token_barratio';
				success = true;
			}
		}

		if (currentStep === 'set_stock_online') {
			const { result } = yield call(
				requestHandler,
				'partnersConfig',
				'POST',
				{},
				'apiStockOnlineInitVariables'
			);

			if (result && result.success) {
				success = true;
				bodySetStep.step = 'set_auth_link';
				currentStep = 'set_auth_link';
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}
		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					success = true;
					bodySetStep.status = 'actif';
					bodySetStep.step = 'success';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});

			if (bodySetStep.step === 'success') {
				yield put({
					type: PRE_CHANGE_PARTNER_STATUS,
					body: bodySetStep,
				});
				yield put({
					type: PRE_POST_PARTNER_TO_MED_DEFAULT,
					body: {
						id: data.partnerId,
						external_id: external_id,
						active: 1,
						prefix: PARTNER_PREFIX,
					},
				});
			}
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_BARRATIO_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([configBarratio()]);
}
