import React from 'react';
import { I18n } from 'react-redux-i18n';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import ImgLockOpen from '../../../img/lock_open.svg';
import './style.scss';

class CardToConfigure extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayLockPartnerInfo: false,
		};
	}

	logoContent = _ => {
		const logo = this.props.logo;
		if (logo) return <img src={logo} alt={`Logo ${this.props.username}`} />;

		return <p className="partnerconfig-partner-title">{this.props.username}</p>;
	};

	defaultContent = _ => {
		return (
			<div className="logo-and-description">
				<span className="princing-info">{this.props.price}</span>
				{this.logoContent()}
				<p>{this.props.description}</p>
			</div>
		);
	};

	connectorStatusContent = _ => {
		return (
			<div className="connector_configuration">
				{this.logoContent()}
				<p className="connector_configuration_title">
					{I18n.t('partner.configuration_connector.wait_title')}
				</p>

				<p>
					{this.props.isConfigurable
						? I18n.t('partner.configuration_connector.wait_label')
						: I18n.t(
								'partner.configuration_connector.wait_label_manual_onboarding'
						  )}
				</p>
			</div>
		);
	};

	lockPartnerInfoContent = _ => {
		return (
			<div className="connector_configuration">
				{this.logoContent()}
				<p>{I18n.t('partner.configuration_connector.payment_method')}</p>
				{this.button(
					I18n.t('partner.configuration_connector.pay_cb', {
						price: this.props.connectorData.price,
					}),
					() => this.props.connectorContactPayment('payment', this.props.prefix)
				)}
				{this.button(
					I18n.t('partner.configuration_connector.need_contact'),
					() => this.props.connectorContactPayment('contact', this.props.prefix)
				)}
			</div>
		);
	};

	handleClick = _ => {
		if (this.props.connectorData.access || this.props.connectorData.free) {
			this.props.configure();
		} else {
			this.setState({ displayLockPartnerInfo: true });
			this.props.connectorContactPayment('contact', this.props.prefix);
		}
	};

	button = (label, onClick) => {
		return (
			<div className="connector_configuration_button" onClick={onClick}>
				{label}
			</div>
		);
	};

	render() {
		let label = '';
		if (this.props.connectorData.access) {
			if (this.props.isConfigurable) {
				label = I18n.t('partner.configuration');
			} else {
				label = I18n.t('partner.more_info');
			}
		} else {
			label = I18n.t('partner.unlock');
		}

		return (
			<div className={'partnerconfig-export-container'}>
				<div className="partnerconfig-partner-card partner-configuration-card">
					{this.defaultContent()}

					<div className="bottom-card">
						<p className="configuration-button" onClick={this.handleClick}>
							{label}
							{!this.props.connectorData.free &&
								this.props.connectorData.access && (
									<img
										src={ImgLockOpen}
										alt={'unlocked'}
										className={'partner-config-lock-icon'}
									/>
								)}
							{!this.props.connectorData.free &&
								!this.props.connectorData.access && <Lock />}
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default CardToConfigure;
