import React from 'react';

import SimplePartner from '../index';

const Yokitup = props => {
	return (
		<SimplePartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}></SimplePartner>
	);
};

export default Yokitup;
