import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as actions from './service/actions';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CategorySupplement from './Components/CategorySupplement';
import './style.scss';
import { confirm, deepClone, getVariableServer } from '../../../../utils';
import { Select, MenuItem, Button } from '@material-ui/core';
import EditPopup from '../../../../Components/EditPopup';
import Modal from '../../../../Components/Modal';
import * as actionsPicture from '../../../Info/service/actions';
import Alert from '../../../../Components/Alert';

class SupplementExtra extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			menuSelected: '',
			openImageModal: false,
			testSupplementId: 0,
			testCategoryId: 0,
			testIdMenu: 0,
			openFullSizeModal: false,
			pathCroppedFullSize: '',
		};
	}

	openModal = () => {
		this.setState({ open: true });
	};

	closeModal = () => {
		this.setState({ open: false });
	};

	openFullSizeModal = path => {
		this.setState({ openFullSizeModal: true, pathCroppedFullSize: path });
	};

	closeFullSizeModal = () => {
		this.setState({ openFullSizeModal: false });
	};

	openImageModal = (supplementId, categoryId, idMenu) => {
		this.setState({
			openImageModal: true,
			testSupplementId: supplementId,
			testCategoryId: categoryId,
			testIdMenu: idMenu,
		});
	};

	closeImageModal = () => {
		this.setState({ openImageModal: false });
	};

	onChangeCategorySupplement = (id, id_menu, prop, value) => {
		let cat = this.props.list[id_menu][id];

		const extras = this.props.categoryExtra.hasOwnProperty(id_menu)
			? this.props.categoryExtra[id_menu]
			: null;

		for (let extra in extras) {
			cat[extras[extra['key_']]] = cat[extra['value']];
		}

		if (value == '') {
			this.props.action.deleteCategorySupplementExtra({
				id_category_supplement: cat.id,
				id_menu,
				key_: prop,
				partner_prefix: this.props.prefix,
			});
		} else {
			this.props.action.postCategorySupplementExtra({
				id_category_supplement: cat.id,
				id_menu,
				key_: prop,
				value,
				partner_prefix: this.props.prefix,
			});
		}
	};

	onChangeSupplement = (
		id,
		id_category,
		id_menu,
		prop,
		value,
		pathBase,
		metadata
	) => {
		let supp = deepClone(
			this.props.list[id_menu][id_category]['data'].find(s => s.id === id)
		);
		const extras = this.props.supplementExtra.hasOwnProperty(id)
			? this.props.supplementExtra[id]
			: null;

		//handle delete extra name / price
		if (value == '') {
			this.props.action.deleteSupplementExtra({
				id,
				id_category,
				id_menu,
				key_: prop,
				partner_prefix: this.props.prefix,
			});
			return true;
		}

		for (let extra in extras) {
			supp[extras[extra['key_']]] = extras[extra['value']];
		}

		if (prop === 'image') {
			let picture = {
				name: supp.name,
				path: pathBase,
				path_cropped: value,
				weight: 0,
				description: '',
				crop_properties: metadata,
				env: 'supplement',
				id_supplement: supp.id,
				id_category_supplement: id_category,
				id_menu,
				partner_prefix: this.props.prefix,
			};
			this.props.actionsPicture.addPicture(picture);
		}

		let val;
		if (prop === 'image') {
			val = value;
		} else if (prop === 'priceSupplement') {
			if (value != '') {
				val = parseInt(
					parseFloat(parseFloat(value).toFixed(2) * 100).toFixed(2)
				);
			} else {
				val = 0;
			}
		} else {
			val = value;
		}

		if (prop !== 'image') {
			this.props.action.postSupplementExtra({
				id_supplement: supp.id,
				id_category_supplement: id_category,
				id_menu,
				key_: prop,
				value: val,
				partner_prefix: this.props.prefix,
			});
		}
	};

	onDeleteCategorySupplement = (id, id_menu, id_export_active) => {
		if (id && id_menu) {
			confirm(I18n.t('partner.supplements.reset_confirm')).then(() => {
				this.props.action.resetCategorySupplementExtra({
					id,
					id_menu,
					partner_prefix: this.props.prefix,
				});
			});
		}
	};

	onSelectMenu = e => {
		this.setState({ menuSelected: e.target.value });
	};

	onSubmit = (cdnPath, cdnPathCropped, name, description, metadata) => {
		this.onChangeSupplement(
			this.state.testSupplementId,
			this.state.testCategoryId,
			this.state.testIdMenu,
			'image',
			cdnPathCropped,
			cdnPath,
			metadata
		);
	};

	onDeleteImage = (id, id_category, id_menu) => {
		confirm(I18n.t('info.picture.confirm_delete')).then(() => {
			this.props.action.deleteSupplementExtra({
				id,
				id_category,
				id_menu,
				key_: 'image',
				partner_prefix: this.props.prefix,
			});
		});
	};

	onSetSupplementExtraExistedPicture = picture => {
		this.props.action.postSupplementExtra({
			id_supplement: this.state.testSupplementId,
			id_category_supplement: this.state.testCategoryId,
			id_menu: this.state.testIdMenu,
			key_: 'image',
			value: picture.id,
			path: picture.path_cropped,
			partner_prefix: this.props.prefix,
		});
	};

	sortByWeight = list => {
		let sorted = list.sort((a, b) => a.weight - b.weight);
		sorted = sorted.map(cs => {
			cs.data = cs.data.sort((a, b) => a.weight - b.weight);
			return cs;
		});
		return sorted;
	};

	render() {
		const { menuSelected } = this.state;
		const { disabled = false } = this.props;
		let categorySupplementList = null;

		if (
			menuSelected &&
			this.props.list.hasOwnProperty(menuSelected) &&
			Object.values(this.props.list[menuSelected]).length
		) {
			categorySupplementList = this.sortByWeight(
				Object.values(this.props.list[menuSelected])
			);
		}

		if (
			this.props.export_disable == 1 ||
			(disabled && this.props.export_enable == 0)
		) {
			return null;
		}
		return (
			<div className="gestion-button-container">
				<Button variant={'outlined'} onClick={this.openModal}>
					{I18n.t('partner.supplements.manage')}
				</Button>
				<Dialog open={this.state.open} onClose={this.closeModal}>
					<DialogTitle>{I18n.t('partner.supplements.manage')}</DialogTitle>

					<DialogContent className="dialog-content-scroll">
						<p>{I18n.t('partner.supplements.description_1')}</p>

						<Alert>{I18n.t('partner.supplements.alert_extra_prefix')}</Alert>

						{this.props.loaded ? (
							this.props.list && Object.keys(this.props.list).length ? (
								<div>
									<div style={{ margin: 20 }}>
										<Select
											value={menuSelected || ''}
											onChange={this.onSelectMenu}
											displayEmpty>
											<MenuItem value={''}>
												{I18n.t('partner.supplements.no_select_menu')}
											</MenuItem>
											{Object.keys(this.props.list).map(id_menu => {
												if (this.props.menus[id_menu]) {
													return (
														<MenuItem value={id_menu} key={id_menu}>
															{this.props.menus[id_menu].name}
														</MenuItem>
													);
												}
												return null;
											})}
										</Select>
									</div>

									{categorySupplementList
										? categorySupplementList.map(categorySupplement => {
												return (
													<CategorySupplement
														key={categorySupplement.id}
														id_menu={menuSelected}
														categorySupplement={{ ...categorySupplement }}
														onChangeCategorySupplement={
															this.onChangeCategorySupplement
														}
														onChangeSupplement={this.onChangeSupplement}
														onDeleteCategorySupplement={
															this.onDeleteCategorySupplement
														}
														openImageModal={this.openImageModal}
														openFullSizeModal={this.openFullSizeModal}
														onDeleteImage={this.onDeleteImage}
														prefix={this.props.prefix}
													/>
												);
										  })
										: menuSelected == ''
										? ''
										: I18n.t('partner.supplements.empty')}
								</div>
							) : (
								I18n.t('partner.supplements.empty_menu')
							)
						) : (
							I18n.t('general.loading')
						)}
					</DialogContent>
					<DialogActions>
						<Button color={'primary'} onClick={this.closeModal}>
							{I18n.t('general.close')}
						</Button>
					</DialogActions>
					<EditPopup
						src={''}
						onSubmit={this.onSubmit}
						openModal={this.state.openImageModal}
						closeModal={this.closeImageModal}
						displayMetaData={false}
						onSetExistedPicture={this.onSetSupplementExtraExistedPicture}
					/>
					<Modal
						src={this.state.pathCroppedFullSize}
						openModal={this.state.openFullSizeModal}
						closeModal={this.closeFullSizeModal}
					/>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const exportDisabled = getVariableServer(
		state.variableServer,
		'partner_export_disable'
	);
	const exportEnable = getVariableServer(
		state.variableServer,
		'partner_export_enable'
	);
	return {
		categoryExtra: state.exports.supplement.categorySupplementExtra,
		supplementExtra: state.exports.supplement.supplementExtra,
		list: deepClone(state.exports.supplement.list),
		menus: state.exports.productsSelector.menus.list,
		loaded: state.exports.productsSelector.menus.loaded,
		pictures: state.info.pictures,
		export_disable: exportDisabled == '1' && props.prefix != 'celladdition',
		export_enable: exportEnable == '1',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(actions, dispatch),
		actionsPicture: bindActionCreators(actionsPicture, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplementExtra);
