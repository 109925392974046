import React, { Component } from 'react';
import './../style.scss';

class HeaderTabInfoRestau extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: props.color,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.color !== prevState.color) {
			return {
				color: nextProps.color,
			};
		}
		return null;
	}

	render() {
		return (
			<svg
				width="21px"
				height="19px"
				viewBox="0 0 21 19"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				className="info-restau-icon">
				<title>icon / nbr couvert blanc</title>
				<desc>Created with Sketch.</desc>
				<g
					id="Réservation-Desktop"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd">
					<g
						id="Réservation_Client_04"
						transform="translate(-373.000000, -385.000000)"
						fill={this.state.color}>
						<g id="form" transform="translate(72.000000, 378.000000)">
							<g id="timeline">
								<g id="couvert" transform="translate(224.333333, 0.000000)">
									<g
										id="icon-/-nbr-couvert-blanc"
										transform="translate(75.220974, 6.500000)">
										<g id="icon-/-nbr-couvert-filaire">
											<g
												id="fork"
												transform="translate(13.373781, 10.909963) rotate(-315.000000) translate(-13.373781, -10.909963) translate(9.970719, 0.645919)"
												fillRule="nonzero">
												<path
													d="M2.94031387,13.2200431 L2.7862109,18.7069911 C2.95453553,19.0751125 3.18522115,19.1845339 3.53202689,19.1845339 C3.88124322,19.1845339 4.04330449,19.1051887 4.16019029,18.8458117 L4.05825197,13.3278557 L2.94031387,13.2200431 Z M4.99483139,12.9678919 L5.12776766,18.9675851 C5.13031596,19.0825976 5.10907914,19.1968888 5.06540237,19.3032194 C4.79186791,19.9691363 4.23145187,20.3219079 3.47845398,20.3219079 C2.71369026,20.3219079 2.13556447,19.9142711 1.81286005,19.1684336 C1.76355812,19.0544805 1.74030839,18.9308597 1.74482445,18.8066754 L1.96048546,12.8763572 L4.99483139,12.9678919 Z"
													id="Path-5-Copy"
												/>
												<path
													d="M4.48318403,1.07449338 L2.3618403,1.07449338 L1.15636625,5.32214002 C1.13593076,5.39216561 1.16333207,5.4674007 1.22391299,5.50760138 L3.20992747,6.68719613 L3.10603549,9.54405248 L3.91547351,9.6189667 L3.86677325,6.72532291 L5.7129272,5.51678258 C5.77130562,5.47600453 5.79721012,5.40226534 5.7772538,5.33367184 L4.48318403,1.07449338 Z M0.677639533,6.33968608 C0.253573111,6.05828127 0.061763925,5.53163565 0.204812387,5.04145655 L1.48965916,0.638717632 C1.59292301,0.284867052 1.91582549,0.0417928618 2.28262071,0.0417928618 L4.65157575,0.0417928618 C5.01862398,0.0417928618 5.34168554,0.285196415 5.44472939,0.639376843 L6.72903816,5.05377942 C6.86873242,5.53393398 6.68740097,6.05010829 6.27875197,6.33555461 L4.86968572,7.31980462 L4.9268188,9.89834093 L2.06888097,9.89566118 L2.1623677,7.32493182 L0.677639533,6.33968608 Z"
													id="Path-5-Copy"
												/>
												<path
													d="M2.54999384,0.541004059 C2.54999384,0.35746268 2.69798825,0.208672994 2.8805486,0.208672994 C3.06310895,0.208672994 3.21110335,0.35746268 3.21110335,0.541004059 L3.21110335,4.29711796 C3.21110335,4.48065934 3.06310895,4.62944902 2.8805486,4.62944902 C2.69798825,4.62944902 2.54999384,4.48065934 2.54999384,4.29711796 L2.54999384,0.541004059 Z"
													id="Line-2"
												/>
												<path
													d="M3.61249127,0.541004059 C3.61249127,0.35746268 3.76048568,0.208672994 3.94304603,0.208672994 C4.12560638,0.208672994 4.27360079,0.35746268 4.27360079,0.541004059 L4.27360079,4.29711796 C4.27360079,4.48065934 4.12560638,4.62944902 3.94304603,4.62944902 C3.76048568,4.62944902 3.61249127,4.48065934 3.61249127,4.29711796 L3.61249127,0.541004059 Z"
													id="Line-2-Copy"
												/>
											</g>
											<g
												id="couteau"
												transform="translate(10.348409, 10.490995) rotate(-45.000000) translate(-10.348409, -10.490995) translate(7.532083, -1.306758)"
												fillRule="nonzero">
												<path
													d="M1.588356,11.8954954 L1.13686838e-13,11.3329457 L1.14274021e-13,5.11296484 C1.13393246e-13,3.48723885 0.810612233,1.96976738 2.15867206,1.07191645 L3.36024061,0.271633397 C3.96901419,-0.133829265 4.78945826,0.0336387766 5.19275373,0.645683738 C5.33619591,0.863372918 5.41269068,1.11871441 5.41269068,1.37983883 L5.41249223,11.2300897 L5.62303114,21.8088339 L5.55642585,21.9876135 C5.2027564,22.9369205 4.42380861,23.4700991 3.40758357,23.4700991 C2.38600458,23.4700991 1.6076147,22.9300895 1.26915594,21.971396 L1.20536922,21.7907184 L1.588356,11.8954954 Z M4.3335385,2.77025686 C4.3335385,2.56602221 4.26972316,2.36689155 4.15101279,2.20069996 C3.83645521,1.76032713 3.22446317,1.65833371 2.78409034,1.9728913 C1.81172507,2.66745019 1.23464217,3.78884305 1.23464217,4.98379336 L1.23464217,10.4275528 L2.69495963,10.9822358 L2.36689718,21.3317211 C2.54452776,21.8713266 2.90715257,22.1411293 3.45477162,22.1411293 C4.00239066,22.1411293 4.36992458,21.8713266 4.55737337,21.3317211 L4.3335385,11.031236 L4.3335385,2.77025686 Z"
													id="Path-5"
												/>
												<path
													d="M3.78301555,20.908609 C3.78301555,21.0003796 3.70901835,21.0747745 3.61773818,21.0747745 C3.526458,21.0747745 3.4524608,21.0003796 3.4524608,20.908609 L3.4524608,17.9711896 C3.4524608,17.8794189 3.526458,17.8050241 3.61773818,17.8050241 C3.70901835,17.8050241 3.78301555,17.8794189 3.78301555,17.9711896 L3.78301555,20.908609 Z"
													id="Line-3"
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}

export default HeaderTabInfoRestau;
