import { RECEIVE_MENU, SELECT_MENU } from './types';

const initialState = { list: {}, loaded: false, selected: '' };
const reducerMenu = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_MENU:
			if (action.data) {
				newState.list = action.data;
				newState.loaded = true;

				if (Object.keys(newState.list).length) {
					newState.selected = Object.keys(newState.list)[0];
				}
				return newState;
			}
			return newState;
		case SELECT_MENU:
			if (action.selected) {
				newState.selected = action.selected;
			}
			return newState;
		default:
			return newState;
	}
};

export default reducerMenu;
