import { PRE_SUBMIT_BINDING, SUBMIT_BINDING } from './types';
import { take, put, call, all } from 'redux-saga/effects';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { ENTITIES } from '../../../../../service/fetcher.saga';
const PRE_REQUEST_SUBMIT_BINDING = 'PRE_REQUEST_SUBMIT_BINDING';
const REQUEST_SUBMIT_BINDING_FINISHED = 'REQUEST_SUBMIT_BINDING_FINISHED';

function* preSubmitBinding() {
	while (true) {
		const action = yield take(PRE_SUBMIT_BINDING);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_SUBMIT_BINDING,
				body: action.body,
			});
			const { result } = yield take(REQUEST_SUBMIT_BINDING_FINISHED);
			yield put({ type: SUBMIT_BINDING, data: result });
		}
	}
}

function* watchSubmitBinding() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_SUBMIT_BINDING);
		const { result } = yield call(
			requestHandler,
			ENTITIES.BINDING.ROOT,
			'PATCH',
			body
		);
		yield put({ type: REQUEST_SUBMIT_BINDING_FINISHED, result });
	}
}

export default function* indexSaga() {
	yield all([preSubmitBinding(), watchSubmitBinding()]);
}
