import React from 'react';
import Proptypes from 'prop-types';
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	withStyles,
} from '@material-ui/core';
import Checkbox from '../../../../../../../Components/CheckBox';

const style = () => ({
	root: { borderBottom: '1px solid #f6f6f6' },
});

const ItemSelectable = props => {
	const onClick = e => {
		e.preventDefault();
		props.onChange(props.id, !props.selected, props.menuSelected);
	};

	return (
		<ListItem
			key={props.id}
			button
			onClick={onClick}
			classes={{ ...props.classes }}
			className="item-selectable">
			{' '}
			<ListItemText
				primary={
					<div className="item-selectable-container">
						{props.name}
						<span>
							{(props.price / 100).toFixed(2).replace('.', ',')}
							{props.sigle}
						</span>
					</div>
				}
				primaryTypographyProps={{
					style: {
						fontFamily: 'FuturaBook',
						fontSize: 15,
					},
				}}
			/>
			<ListItemSecondaryAction>
				<Checkbox selected={props.selected} onClick={onClick} />
			</ListItemSecondaryAction>
		</ListItem>
	);
};

ItemSelectable.propTypes = {
	id: Proptypes.string.isRequired,
	name: Proptypes.string.isRequired,
	selected: Proptypes.bool.isRequired,
	onChange: Proptypes.func.isRequired,
};

export default withStyles(style)(ItemSelectable);
