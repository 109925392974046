import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Button from '../../../../../../Components/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class AddButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			value: 0,
		};
	}

	handleClickAdd = () => {
		this.setState({ ...this.state, open: true });
	};

	handleChange = e => {
		this.props.onAdd(e.target.value);
		this.setState({ ...this.state, open: false });
	};

	render() {
		const { list, selected } = this.props;

		if (this.state.open) {
			return (
				<div className="brand-channel-search-container">
					<Select
						value={this.state.value}
						onChange={this.handleChange}
						onBlur={() => this.setState({ ...this.state, open: false })}>
						<MenuItem key={0} value={0}>
							{I18n.t('partner.brandChannel.select_placeholder')}
						</MenuItem>
						{Object.values(list).map(item => (
							<MenuItem
								key={item.id}
								value={item.id}
								disabled={selected.includes(item.id + '')}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</div>
			);
		} else {
			return (
				<Button onClick={this.handleClickAdd}>
					{this.props.children || I18n.t('general.add')}
				</Button>
			);
		}
	}
}

export default AddButton;
