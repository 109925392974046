import React, { Component, PureComponent } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	update_nuc: 2,
	check_overpaid: 3,
	set_paiement: 4,
	call_laddition_ticket: 5,
	set_api_variables: 5,
	set_auth_link: 5,
	set_link_reconciliation: 5,
	set_link_laddition_print: 5,
	set_partner_options: 5,
	set_api_mask: 5,
	success: 6,
};

const LadditionTicket = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default LadditionTicket;
