import {
	SHOW_LOADER,
	HIDE_LOADER,
	RECEIVED_ALL,
	INIT_RECEIVED_ALL,
} from './types';

const initialState = {
	isLoading: false,
	receivedAll: false,
	text: '',
};

const reducer = function loadingReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_LOADER:
			return {
				...state,
				isLoading: true,
				text: action.text || '',
			};
		case HIDE_LOADER:
			return {
				...state,
				isLoading: false,
				text: '',
			};
		case RECEIVED_ALL:
			return {
				...state,
				receivedAll: true,
			};
		case INIT_RECEIVED_ALL:
			return {
				...state,
				receivedAll: false,
			};
		default:
			return state;
	}
};

export default reducer;
