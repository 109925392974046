import React, { Component } from 'react';
import './style.scss';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '../../../../../Components/Button';
import PaymentType from '../../Binding/Components/PaymentType';
import Table from '../../Binding/Components/Table';
import PriceCategory from '../../Binding/Components/PriceCategory';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

class Channel extends Component {
	render() {
		const { channel, onDelete, onChange, isBrand = false } = this.props;

		return (
			<TableRow key={channel.id}>
				<TableCell>{channel.name}</TableCell>
				<TableCell>
					{' '}
					<Table
						selected={channel.id_table || ''}
						list={this.props.floors}
						onChange={(prop, value) => {
							onChange('id_table', value, channel.id);
						}}
						hideLabel={true}
						required={true}
					/>
				</TableCell>
				<TableCell>
					{' '}
					<PaymentType
						selected={channel.id_payment_type || ''}
						list={this.props.paymentTypes}
						onChange={(prop, value) => {
							onChange('id_payment_type', value, channel.id);
						}}
						hideLabel={true}
						required={isBrand ? false : true}
					/>
				</TableCell>
				<TableCell>
					<PriceCategory
						selected={channel.id_price_category || ''}
						list={this.props.priceCategories}
						onChange={(prop, value) => {
							onChange('id_price_category', value, channel.id);
						}}
						hideLabel={true}
					/>
				</TableCell>
				<TableCell>
					<Button onClick={() => onDelete('channel', channel.id)}>
						{I18n.t('general.delete')}
					</Button>
				</TableCell>
			</TableRow>
		);
	}
}

const mapStateToProps = state => {
	return {
		paymentTypes: state.binding.paymentType,
		floors: state.binding.floor,
		priceCategories: state.binding.priceCategory,
	};
};

export default connect(mapStateToProps)(Channel);
