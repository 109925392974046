import { call, race, take, put, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import jwtDecode from 'jwt-decode';
import { LOGOUT } from './types';
import { fetchAll, sendUnloadBeacon } from '../fetcher.saga';
import { setUser } from '../user/saga';
import moment from 'moment';
import firstLogin from '../firstLogin/saga';
import { requestHandler } from '../fetcher.saga';

const getRefreshToken = () => window.localStorage.refresh_token;
const getAccessToken = () => window.localStorage.access_token;
const decodeAccessToken = access_token => jwtDecode(access_token);
const getTokenInUrl = () => extractGetVariables(document.location.toString());
const getRefreshTokenInCookies = () => getCookies('refreshToken');

const setAuthToken = token => {
	window.localStorage.access_token = token;
};
const setRefreshToken = token => {
	window.localStorage.refresh_token = token;
};

export default function* authAutoFlowSaga() {
	const getVar = yield call(getTokenInUrl);
	const param_refresh_token = getVar.refreshToken;

	const cookie = yield call(getRefreshTokenInCookies);
	const cookie_refresh_token = cookie.value;

	let refresh_token = yield call(getRefreshToken);

	if (cookie_refresh_token || param_refresh_token) {
		refresh_token = cookie_refresh_token || param_refresh_token;
		setRefreshToken(refresh_token);
	}
	let access_token = yield call(getAccessToken);

	if (!refresh_token) {
		window.localStorage.lastVisited = window.location.pathname;
		window.location.replace(getAuthUrl(true));
		return;
	}

	while (true) {
		access_token = yield call(authorize, refresh_token);
		let decoded = yield call(decodeAccessToken, access_token);
		if (!decoded.med_db_name) {
			const successInit = yield call(
				firstLogin,
				decoded.db_name,
				refresh_token
			);
			if (successInit) {
				access_token = yield call(authorize, refresh_token);
			} else {
				return false;
			}
		}

		yield fork(fetchAll);

		let userSignedOut = false;
		while (!userSignedOut) {
			const decoded = yield call(decodeAccessToken, access_token);
			yield call(setUser, decoded, access_token);
			const token_expires_timestamp = decoded.exp;
			const CurrentDate = moment().unix();
			const token_expires_wait_before_update =
				token_expires_timestamp - CurrentDate;
			const token_expires_wait_before_update_millisecond =
				token_expires_wait_before_update * 1000;
			const { expired } = yield race({
				expired: delay(token_expires_wait_before_update_millisecond),
				signout: take(LOGOUT),
			});
			// token expired first
			if (expired) {
				access_token = yield call(authorize, refresh_token);
				// authorization failed, either by the server or the user signout
				if (!access_token) {
					userSignedOut = true; // breaks the loop
					yield call(signout);
				}
			} else {
				// user signed out before token expiration
				userSignedOut = true; // breaks the loop
				yield call(signout);
			}
		}
	}
}

function* authorize(refreshToken) {
	const { response } = yield race({
		response: call(authService, refreshToken),
		signout: take(LOGOUT),
	});
	if (response && response.accessToken) {
		yield call(setAuthToken, response.accessToken);
		return response.accessToken;
	} else {
		yield call(signout);

		return false;
	}
}

function* authService(token) {
	const { result } = yield call(
		requestHandler,
		'auth/access-token',
		'GET',
		{ refreshToken: token },
		''
	);
	return result;
}

export function getAuthUrl(withScope = false) {
	let authUrl = '';
	if (window.location && window.location.hostname === 'localhost') {
		const scope = withScope ? '?scope=mediation-local' : '';
		authUrl = `http://localhost:3000/${scope}`;
	} else if (
		window.location &&
		window.location.hostname.includes('laddit.io')
	) {
		const scope = withScope ? '?scope=mediation' : '';
		authUrl = `https://auth.laddit.io/${scope}`;
	} else {
		const scope = withScope ? '?scope=mediation' : '';
		authUrl = `https://auth.laddition.com/${scope}`;
	}

	return authUrl;
}

export function signout() {
	sendUnloadBeacon();
	window.localStorage.removeItem('access_token');
	window.localStorage.removeItem('refresh_token');
	window.localStorage.lastVisited = window.location.pathname;
	document.location.replace(getAuthUrl() + 'logout');
}

function extractGetVariables(urlString) {
	let getData = {};

	if (urlString.indexOf('?') !== -1) {
		let query = urlString
			.replace(/^.*?\?/, '')
			.replace(/#.*$/, '') // remove any existing hash string
			.split('&');

		for (let i = query.length; i--; ) {
			let component = decodeURIComponent(query[i]).split('=');
			getData[component[0]] = component[1];
		}
	}

	return getData;
}

export function getCookies(name = null) {
	try {
		const cookiesRaw = document.cookie;
		if (!cookiesRaw) {
			return {};
		}

		let cookies = document.cookie.split(';').map(cookie => {
			let [key, value] = cookie.split('=');
			return { key: key.trim(), value: value.trim() };
		});

		if (name) {
			return cookies.find(cookie => cookie.key === name);
		}

		return cookies;
	} catch (e) {
		console.error(e);
		return {};
	}
}

export function getPayplusUrl() {
	let url = '';
	if (window.location && window.location.hostname === 'localhost') {
		url = `http://localhost:1340`;
	} else if (
		window.location &&
		window.location.hostname.includes('laddit.io')
	) {
		url = `https://pay.laddition.com`;
	} else {
		url = `https://payplus.laddition.com`;
	}

	return url;
}
