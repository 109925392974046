import React, { Component } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	set_api_data: 0,
	set_api_get_timeout: 0,
	documentation: 1,
	set_token_tabesto: 3, //TODO: renomer en set_cusomer_id quand dood sera fait (pour harmoniser)
	set_entities: 3,
	set_auth_link: 3,
	set_partner_options: 3,
	set_api_mask: 3,
	success: 4,
};

const Tabesto = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Tabesto;
