import React, { Component } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	set_account_id: 2,
	set_location_id: 3,
	set_table_and_paiement: 4,
	set_variable_config_channel: 4,
	set_link_deliverect: 4,
	set_partner_options: 4,
	unlink_partners: 4,
	set_api_mask: 4,
	success: 5,
};

const Deliverect = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Deliverect;
