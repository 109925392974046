import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '@material-ui/core/Button';
import '../style.scss';
import Collapse from '@material-ui/core/Collapse';
import Input from '../../../../../Components/Input';

const TIMEOUT = 10000;

class urlTester extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			inputOpen: true,
			disabledTestButton: false,
			btnTimeout: 0,
		};
	}

	onChange = e => {
		this.setState({
			value: e.target.value,
			inputOpen: false,
		});
	};

	onClickTestButton = () => {
		if (this.state.btnTimeout > 0) return true;
		this.setState(
			{
				inputOpen: false,
				value: '',
				disabledTestButton: true,
				btnTimeout: TIMEOUT / 1000,
			},
			() => {
				this.props.sendTestRequest();
				let handleBtnTestState = setInterval(() => {
					this.setState({ btnTimeout: this.state.btnTimeout - 1 });
				}, 1000);

				setTimeout(() => {
					clearInterval(handleBtnTestState);
					this.setState({
						disabledTestButton: false,
					});
				}, TIMEOUT);
			}
		);
	};

	onClickValidButton = () => {
		this.props.validTokenForm(this.state.value);
		this.setState({
			value: '',
			inputOpen: true,
		});
	};

	render() {
		return (
			<Collapse in={this.props.open} className="collapse-container">
				<div className="test-content">
					<p>{I18n.t('partner.url_tester_description')}</p>
					<Button
						variant="outlined"
						onClick={this.onClickTestButton}
						disabled={this.state.disabledTestButton}>
						{I18n.t('partner.to_test') +
							(this.state.btnTimeout ? ' (' + this.state.btnTimeout + ')' : '')}
					</Button>
				</div>
				<div className="test-result-content">
					<Input
						type="text"
						value={this.state.value}
						onChange={this.onChange}
						id={'testInput'}
						label={I18n.t('partner.token')}
					/>
					<Button
						variant={'contained'}
						color={'primary'}
						size={'large'}
						className={'button-bold'}
						disabled={this.state.inputOpen || this.state.value.length === 0}
						onClick={this.onClickValidButton}>
						{I18n.t('general.valid')}
					</Button>
				</div>
			</Collapse>
		);
	}
}

export default urlTester;
