import React from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	set_customer_id: 2,
	set_ipad_token: 3,
	set_api_data: 3,
	check_liveness: 3,
	set_auth_link: 3,
	set_api_mask: 3,
	set_auth_group: 3,
	set_entities: 3,
	trigger_synchro: 3,
	success: 4,
};

const Eviivo = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Eviivo;
