import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import * as actions from '../../../service/menus/actions';
import { bindActionCreators } from 'redux';
import Proptypes from 'prop-types';
import { getMenusList, getMenuSelected } from '../selector';
import { withStyles } from '@material-ui/core/styles';
import Arrow from '@material-ui/icons/KeyboardArrowDown';
import ContentLoader from 'react-content-loader';
import { confirm } from '../../../../../../../utils';
import * as actionSelectedItem from '../../../service/products/actions';

const style = () => ({
	root: {
		backgroundColor: '#fff',
		padding: '5px 10px',
		width: 250,
		color: 'red',
		fontFamily: 'FuturaHeavy',
		borderBottom: 'none',
	},
	icon: { color: 'red' },
});

class MenuSelector extends React.Component {
	onChangeMenu = e => {
		if (e.target.value && e.target.value != this.props.selected) {
			confirm(
				I18n.t('partner.products_selector.menu_select_reset_select')
			).then(() => {
				this.props.setSelected({});
				this.props.selectMenu(e.target.value);
			});
		}
	};

	renderContentPlaceholder() {
		return (
			<ContentLoader
				rtl
				height={42}
				width={270}
				speed={1}
				primaryColor="#f3f3f3"
				secondaryColor="#ecebeb">
				<rect x="0" y={0} rx="5" ry="5" width={270} height={42} />
			</ContentLoader>
		);
	}

	render() {
		return (
			<div className={'menu-selector'}>
				{this.props.loaded ? (
					<Select
						value={this.props.selected}
						classes={this.props.classes}
						onChange={this.onChangeMenu}
						IconComponent={Arrow}
						disabled={this.props.disabled}
						className={this.props.disabled ? 'menu-selector-disabled' : ''}>
						{Object.values(this.props.menus).map(menu => (
							<MenuItem key={menu.id} value={menu.id}>
								{menu.name}
							</MenuItem>
						))}
					</Select>
				) : (
					this.renderContentPlaceholder()
				)}
			</div>
		);
	}
}

MenuSelector.propTypes = {
	loaded: Proptypes.bool.isRequired,
};

const mapStateToProps = state => {
	return {
		menus: getMenusList(state),
		selected: getMenuSelected(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		selectMenu: bindActionCreators(actions.select, dispatch),
		setSelected: bindActionCreators(actionSelectedItem.setSelected, dispatch),
	};
};

export default withStyles(style)(
	connect(mapStateToProps, mapDispatchToProps)(MenuSelector)
);
