import {
	PRE_ADD_WEBHOOK,
	ADD_WEBHOOK,
	PRE_PATCH_WEBHOOK,
	PATCH_WEBHOOK,
	PRE_REMOVE_WEBHOOK,
	REMOVE_WEBHOOK,
	PRE_URL_TESTER,
} from './types';
import { take, put, call, all } from 'redux-saga/effects';
import { requestHandler, ENTITIES } from '../../../service/fetcher.saga';
import { enqueueSnackbar } from '../../../Components/Notifier/services/actions';

const PRE_REQUEST_ADD_WEBHOOK = 'PRE_REQUEST_ADD_WEBHOOK';
const REQUEST_ADD_WEBHOOK_FINISHED = 'REQUEST_ADD_WEBHOOK_FINISHED';

const PRE_REQUEST_PATCH_WEBHOOK = 'PRE_REQUEST_PATCH_WEBHOOK';
const REQUEST_PATCH_WEBHOOK_FINISHED = 'REQUEST_PATCH_WEBHOOK_FINISHED';

const PRE_REQUEST_REMOVE_WEBHOOK = 'PRE_REQUES_REMOVE_WEBHOOK';
const REQUEST_REMOVE_WEBHOOK_FINISHED = 'REQUEST_REMOVE_WEBHOOK_FINISHED';

const PRE_REQUEST_URL_TESTER = 'PRE_REQUEST_URL_TESTER';
const REQUEST_URL_TESTER_FINISHED = 'REQUEST_RURL_TESTER_FINISHED';

export function* preAddWebhook() {
	while (true) {
		const action = yield take(PRE_ADD_WEBHOOK);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_ADD_WEBHOOK,
			body,
		});

		const { result } = yield take(REQUEST_ADD_WEBHOOK_FINISHED);
		yield put({
			type: ADD_WEBHOOK,
			webhook: { ...result },
			event: body.event,
		});
	}
}

export function* watchAddWebhook() {
	while (true) {
		const action = yield take(PRE_REQUEST_ADD_WEBHOOK);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EVENTS,
			'POST',
			action.body
		);
		yield put({ type: REQUEST_ADD_WEBHOOK_FINISHED, result });
	}
}

export function* prePatchWebhook() {
	while (true) {
		const action = yield take(PRE_PATCH_WEBHOOK);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_WEBHOOK,
			body,
		});
		const { result } = yield take(REQUEST_PATCH_WEBHOOK_FINISHED);
		yield put({
			type: PATCH_WEBHOOK,
			webhook: result,
		});
	}
}

export function* watchPatchWebhook() {
	while (true) {
		const action = yield take(PRE_PATCH_WEBHOOK);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EVENTS,
			'PATCH',
			action.body
		);
		yield put({ type: REQUEST_PATCH_WEBHOOK_FINISHED, result });
	}
}

export function* preRemoveWebhook() {
	while (true) {
		const action = yield take(PRE_REMOVE_WEBHOOK);
		const { id } = action;
		yield put({
			type: PRE_REQUEST_REMOVE_WEBHOOK,
			id,
		});
		const { result } = yield take(REQUEST_REMOVE_WEBHOOK_FINISHED);
		yield put({ type: REMOVE_WEBHOOK, id: result });
	}
}

export function* watchRemoveWebhook() {
	while (true) {
		const action = yield take(PRE_REMOVE_WEBHOOK);
		const { result } = yield call(requestHandler, ENTITIES.EVENTS, 'DELETE', {
			id: action.id,
		});
		yield put({ type: REQUEST_REMOVE_WEBHOOK_FINISHED, result });
	}
}

export function* preRequestUrlTester() {
	while (true) {
		const action = yield take(PRE_URL_TESTER);
		const { data, labels } = action;
		yield put({
			type: PRE_REQUEST_URL_TESTER,
			data,
		});

		const { result } = yield take(REQUEST_URL_TESTER_FINISHED);
		let notif = {};
		if (result) {
			notif = { message: labels.success, options: { variant: 'success' } };
		} else {
			notif = { message: labels.error, options: { variant: 'error' } };
		}
		yield put(enqueueSnackbar(notif));
	}
}

export function* watchRequestUrlTester() {
	while (true) {
		const { data } = yield take(PRE_REQUEST_URL_TESTER);
		const { result } = yield call(
			requestHandler,
			ENTITIES.EVENTS,
			'POST',
			data,
			'urlTester',
			{},
			false
		);
		yield put({ type: REQUEST_URL_TESTER_FINISHED, result });
	}
}

export default function* indexSaga() {
	yield all([
		preAddWebhook(),
		watchAddWebhook(),
		prePatchWebhook(),
		watchPatchWebhook(),
		preRemoveWebhook(),
		watchRemoveWebhook(),
		preRequestUrlTester(),
		watchRequestUrlTester(),
	]);
}
