import reducerInfo from './Containers/Info/service/reducer';
import reducerMenu from './Containers/Menu/service/reducer';
import reducerService from './service/reducer';
import reducerBinding from './Containers/Partners/Components/Binding/service/reducer';
import reducerEvents from './Containers/EventWebhook/service/reducer';
import reducerExport from './Containers/Partners/service/reducer';
import reducerVariableServer from './Containers/VariableServer/service/reducer';
import { i18nReducer } from 'react-redux-i18n';
import { combineReducers } from 'redux';
import reducerLogin from './Components/Loader/service/reducer';
import { connectRouter } from 'connected-react-router';
import snackbarReducer from './Components/Notifier/services/reducer';
import PartnersReducer from './Containers/Partners/service/partners/reducer';
import ErrorReducer from './Components/ErrorBoundary/reducer';
import PartnersConfigReducer from './Containers/Partners/service/partnersConfig/reducer';
import PartnersDataReducer from './Containers/Partners/service/partnersData/reducer';
import ConfirmReducer from './Components/ConfirmRedux/service/reducer';
import reducerExternalDataAssignation from './Containers/Partners/Components/ExternalDataAssignation/service/reducer';

export default history =>
	combineReducers({
		loading: reducerLogin,
		i18n: i18nReducer,
		router: connectRouter(history),
		info: reducerInfo,
		menu: reducerMenu,
		service: reducerService,
		binding: reducerBinding,
		events: reducerEvents,
		partners: PartnersReducer,
		partnersConfig: PartnersConfigReducer,
		partnersData: PartnersDataReducer,
		exports: reducerExport,
		snackbar: snackbarReducer,
		variableServer: reducerVariableServer,
		externalData: reducerExternalDataAssignation,
		errors: ErrorReducer,
		confirm: ConfirmReducer,
	});
