import React, { Component } from 'react';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Translate, I18n } from 'react-redux-i18n';
import './style.scss';
import ErrorPicture from '../imageError.png';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

export default class Gallery extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	selectPicture = picture => {
		this.props.handleClose();
		this.props.handleSelectPicture(picture);
	};

	addDefaultSrc = ev => {
		ev.target.src = ErrorPicture;
	};

	render() {
		const miniatures = this.props.displayMetaData
			? [
					...this.props.pictures.map(pic => {
						if (this.props.picture.find(pictur => pictur.id === pic.id)) {
							pic.disabled = true;
							return pic;
						} else {
							return pic;
						}
					}),
			  ]
			: [
					...this.props.pictures.map(pic => {
						if (this.props.picture.find(pictur => pictur.id === pic.id)) {
							pic.disabled = false;
							return pic;
						} else {
							return pic;
						}
					}),
			  ];
		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.handleClose}
				TransitionComponent={Transition}>
				<DialogContent className={'selector-modal-content'}>
					<h1>Mes images</h1>
					<div>
						{miniatures.map((picture, index) => {
							return (
								<img
									className={
										picture.disabled
											? 'item-disabled gallery-item'
											: 'gallery-item'
									}
									src={picture.path_cropped}
									onClick={
										picture.disabled ? null : () => this.selectPicture(picture)
									}
									onError={this.addDefaultSrc}
									key={index}
								/>
							);
						})}
					</div>
					<div>
						<Button
							onClick={this.props.handleClose}
							aria-label="Close"
							className={'button-cancel'}>
							{I18n.t('general.cancel')}
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}
