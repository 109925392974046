import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { HeaderLaddition } from '@capps/laddition-storybook';
import { SnackbarProvider } from 'notistack';

import Routes from './route';
import theme from './theme';
import config from './config';
import CustomBubbles from './Components/CustomBubbles';
import BackgroundCircle from './Components/backgroundCircle';
import Loader from './Components/Loader';
import Notifier from './Components/Notifier';
import VariableModal from './Components/VariableModal';
import AllReadyInUse from './Components/AllReadyInUse';
import ErrorBoundary from './Components/ErrorBoundary';
import ConfirmRedux from './Components/ConfirmRedux';
import { sendUnloadBeacon } from './service/fetcher.saga';
import { signout } from './service/auth/saga';
import { checkUserGroupAndType } from './utils';

class App extends Component {
	constructor(props) {
    super(props);
    this.state = {
      hasGroupEspaceClient: false,
			userType: "",
    };
  }

	componentDidMount() {
		window.addEventListener('beforeunload', this.beforeUnload, false);
		this.updateUserGroupAndType();
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.updateUserGroupAndType();
		}

		if (
			this.state.hasGroupEspaceClient &&
			!this.props.history.location.pathname.startsWith('/partners/') &&
			this.props.history.location.pathname !== '/partners' &&
			this.props.history.location.pathname !== '/menu' &&
			!this.props.history.location.pathname.startsWith('/redirect/')
		) {
			this.props.history.push('/partners');
		}
	}

	updateUserGroupAndType = () => {
    const { user } = this.props;
    if (user) {
      const userInfo = checkUserGroupAndType(user);
      if (userInfo) {
        this.setState({
          hasGroupEspaceClient: userInfo.hasGroupEspaceClient,
          userType: userInfo.userType,
        });
      }
    }
  };

	redirectToEC = () => {
    window.location.href = this.userType === "user_laddition" ? config.ESPACE_CLIENT_URL : config.AUTH_URL;
  };

	headerDependingOnUserGroup = () => {
		if (this.state.hasGroupEspaceClient) {
			return <>
				<HeaderLaddition
					buttonProps={{
						onClick: () => this.redirectToEC(),
						label: "Espace client",
					}}
				/>
			</>;
		} else {
			return <>
				<HeaderLaddition
					buttonProps={{
						onClick: () => signout(),
					}}
				/>
			</>
		}
	}

	beforeUnload() {
		sendUnloadBeacon();
	}
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={3}>
					<div className="App-mask">
						<div className="App">
							<Notifier />
							<BackgroundCircle />
							<CustomBubbles />
							{this.props.receivedAll ? (
								<React.Fragment>
									<>
										{this.headerDependingOnUserGroup()}
									</>
									<ToastContainer />
									<AllReadyInUse />
									<VariableModal />
									<ErrorBoundary>
										<Routes hasGroupEspaceClient={this.state.hasGroupEspaceClient}/>
									</ErrorBoundary>
									<ConfirmRedux />
								</React.Fragment>
							) : null}
							<Loader />
						</div>
					</div>
				</SnackbarProvider>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		receivedAll: state.loading.receivedAll,
		user: state.service.user,
	};
};

export default connect(mapStateToProps)(withRouter(App));
