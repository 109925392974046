import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select, fork } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';

import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import { setPartnerMedDefaut } from '../saga';
import { PRE_CHANGE_PARTNER_STATUS, PRE_CHANGE_PARTNER_STEP } from '../types';
import { showConfirm } from '../../../../../Components/ConfirmRedux/service/saga';
import { INIT_PARTNER_WITHOUT_MASK } from '../../export/types';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { getVariableServer } from '../../../../../utils';

const PARTNER_PREFIX = 'ladditionTapToPay';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_TAPTOPAY');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			currentStep = 'documentation';
			bodySetStep.step = 'documentation';
			success = true;
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'agree_cgv') {
			if (data.document) {
				success = yield call(callSignCGV, data.document.document_id);
				if (success)
					yield call(
						notify,
						I18n.t('partner.tap_to_pay.sign_success'),
						'success'
					);
				else errorMessage = 'partner.tap_to_pay.sign_error';
			} else {
				success = true; // rien a signer, c'est ok
			}
		}

		if (currentStep === 'check_existing_acount') {
			yield put({
				type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
				body: {
					partnerPrefix: PARTNER_PREFIX,
					id: data.userId,
					uuid: `${data.userId}`,
				},
			});
			const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');

			if (
				result &&
				result.status === 'success' &&
				result.responseData.exist === true &&
				result.responseData.isValid === true
			) {
				// on go a la derniere étape du coup
				bodySetStep.step = 'download_app';
				success = true;
			} else if (
				result.responseData &&
				result.responseData.exist === true &&
				result.responseData.isValid === false
			) {
				// on reste a cette étape en attente de la validation
				yield call(
					showConfirm,
					I18n.t('partner.tap_to_pay.in_validation'),
					'info'
				);
			} else if (result.responseData && result.responseData.exist === false) {
				// on reste a cette étape en attendant qu'il fasse son ob ttp
				const { variableServer } = yield select();
				const idForm = getVariableServer(variableServer, 'idFormTTP');
				if (idForm) {
					yield call(
						showConfirm,
						I18n.t('partner.tap_to_pay.need_onbaording'),
						'info'
					);
				} else {
					// on lui fait remplir le mail pour lui envoyer son ob ttp
					success = true;
					currentStep = 'init_client_payplus_account';
					bodySetStep.step = 'init_client_payplus_account';
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'init_client_payplus_account') {
			const { service } = yield select();
			yield call(handlePatchVariable, {
				key: 'payplysOnboardingEmail',
				value: service.user.email,
			});

			const idForm = yield call(getIDForm);

			if (idForm) {
				yield call(handlePatchVariable, {
					key: 'payplus_ttp_code',
					value: idForm,
				});

				success = true;
				bodySetStep.step = 'check_existing_acount';
			} else {
				errorMessage = 'partner.onboarding_common_errors.general';
			}
		}

		if (currentStep === 'download_app') {
			success = true;
			bodySetStep.status = 'actif';
			bodySetStep.step = 'success';
			yield call(callPayPlusFinish);
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_TAPTOPAY_FINISHED',
			step: bodySetStep.step,
		});
	}
}

function* getIDForm() {
	const { variableServer } = yield select();
	const idForm = getVariableServer(variableServer, 'payplus_ttp_code');
	if (idForm) return idForm;

	yield put({
		type: INIT_PARTNER_WITHOUT_MASK,
		partnerPrefix: PARTNER_PREFIX,
	});
	const resultInit = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
	if (
		resultInit &&
		resultInit.result &&
		resultInit.result.status === 'success' &&
		resultInit.result.data &&
		resultInit.result.data[0] &&
		resultInit.result.data[0].partnerData &&
		resultInit.result.data[0].partnerData.code
	) {
		const idForm = resultInit.result.data[0].partnerData.code;

		return idForm;
	}
}

function* callPayPlusFinish() {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{},
		'onboardingPayPlusFinished'
	);

	if (result) {
		return true;
	} else {
		return false;
	}
}

function* callSignCGV(document_id) {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ document_id, refreshToken: window.localStorage.refresh_token },
		'signCGV'
	);

	if (result) {
		return true;
	} else {
		return false;
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
