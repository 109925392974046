import React from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const itemSelectable = props => {
	let selectedClass = props.checked
		? 'one-menu-item item-selected'
		: 'one-menu-item';
	return (
		<div className={selectedClass} onClick={() => props.onChange(props.id)}>
			<div className="item-description">
				<h4>{props.name}</h4>
				{props.checked ? <CheckCircleIcon></CheckCircleIcon> : null}
				<p className="item-description-title">{I18n.t('partner.nb_product')}</p>
				<p className="item-description-response">{props.nbProduct || '0'}</p>
				<p className="item-description-title">{I18n.t('partner.type_carte')}</p>
				<p className="item-description-response">
					{props.primaryMenu === props.id
						? I18n.t('partner.primary')
						: I18n.t('partner.secondary')}
				</p>
			</div>
		</div>
	);
};

export default itemSelectable;
