import {
	RECEIVE_PARTNERS,
	CHANGE_ACTIVE_PARTNER,
	POST_OPTION,
	DELETE_OPTION,
	RECEIVE_PARTNER_KEYS,
	POST_MENU_TO_PARTNER,
} from './types';
import { SUBMIT_BINDING } from '../../Components/Binding/service/types';

const initialState = {};
const reducerPartners = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_PARTNERS:
			if (action.data) {
				newState = action.data;
			}
			return newState;
		case CHANGE_ACTIVE_PARTNER:
		case SUBMIT_BINDING:
			if (action.data) {
				newState[action.data.prefix] = {
					...newState[action.data.prefix],
					...action.data,
				};
			}
			return newState;

		case POST_OPTION:
			for (let prefix in newState) {
				if (newState[prefix].id === action.data.id_partner) {
					if (
						newState[prefix]['options'].find(o => o.name === action.data.option)
					) {
						// UPDATE
						newState[prefix]['options'] = newState[prefix]['options'].map(o => {
							if (o.name == action.data.option) {
								o.value = action.data.value;
							}
							return o;
						});
					} else {
						newState[prefix]['options'].push(action.data);
					}
				}
			}

			return newState;

		case DELETE_OPTION:
			for (let prefix in newState) {
				if (newState[prefix].id === action.data.id_partner) {
					newState[prefix].options = newState[prefix].options.map(o => {
						if (o.name === action.data.option) {
							o.value = null;
						}
						return o;
					});
				}
			}
			return newState;

		case RECEIVE_PARTNER_KEYS:
			if (action.data) {
				newState = Object.values(newState).reduce((acc, partner) => {
					const keys = action.data
						.filter(row => row.prefix == partner.prefix)
						.map(row => ({ required: row.required, key_: row.key_ }));
					acc[partner.prefix] = { ...partner, keys };
					return acc;
				}, {});
			}
			return newState;

		case 'PATCH_PARTNER':
			return {
				...newState,
				[action.data.prefix]: {
					...newState[action.data.prefix],
					[action.data.key]: action.data.value,
				},
			};
		default:
			return newState;
	}
};

export default reducerPartners;
