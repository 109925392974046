import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes } from 'prop-types';

const SupplementItem = React.memo(
	({ supplement, checked, idCategory, onCheck, key, disabled }) => {
		const handleClick = e => {
			if (!disabled) {
				onCheck(!checked, supplement.id, idCategory);
			}
		};

		let classes = 'form-supplement-item';
		if (disabled) {
			classes += ' form-supplement-item-disabled';
		}

		const displayOriginalName =
			supplement.originalName && supplement.originalName != supplement.name;
		return (
			<ListItem key={key} button className={classes} onClick={handleClick}>
				<Checkbox disabled={disabled} checked={checked} />
				<ListItemText
					inset
					primary={`${supplement.name} ${
						displayOriginalName ? ` (${supplement.originalName}) ` : ''
					}`}
					//   secondary={supp.price + "€"}
				/>
			</ListItem>
		);
	}
);

SupplementItem.propTypes = {
	supplement: PropTypes.object.isRequired,
	idCategory: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onCheck: PropTypes.func.isRequired,
};

export default SupplementItem;
