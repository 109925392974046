/**
 * Noramlize items but add a "id_menu" normalization first
 * @param {Object[]} data - array of object to normalize. must contain an "id" field
 * @param {(String)} key - OPTIONAL - key to index
 * @return {Object}
 */
export function normalizeByIdMenu(data, key = 'id') {
	return data.reduce((acc, row) => {
		if (row.hasOwnProperty('id_menu')) {
			if (!acc.hasOwnProperty(row.id_menu)) {
				acc[row.id_menu] = {};
			}
			if (row.hasOwnProperty(key)) {
				acc[row.id_menu][row[key]] = row;
			}
		}

		return acc;
	}, {});
}

/**
 *
 * @param {Object[]} data - array of object to normalize. must contain an "id" field
 * @param {(String)} key - OPTIONAL - key to index
 * @return {Object}
 */
export function normalize(data, key = 'id') {
	return data.reduce((acc, row) => {
		if (row.hasOwnProperty(key)) {
			acc[row[key]] = row;
		}
		return acc;
	}, {});
}

export function exportCategorySupplementByIdMenu(products) {
	const productByIdMenu = normalizeByIdMenu(products);

	return Object.keys(productByIdMenu).reduce((acc, menu) => {
		acc[menu] = Object.values(productByIdMenu[menu]).reduce((acc2, product) => {
			for (let category_supp of product.category_supplement) {
				if (!acc2.hasOwnProperty(category_supp.id)) {
					acc2[category_supp.id] = category_supp;
				}
			}

			return acc2;
		}, {});

		return acc;
	}, {});
}

export function downloadFile(blob, fileName) {
	let link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
}

export function b64Blob(b64Data, contentType, sliceSize) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

export const checkUUIDV4 = (uuid = '') => {
	uuid = uuid.trim();

	const reg = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
	return !!reg.test(uuid);
};
