import React from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	set_joining_item: 1,
	set_api_key: 2,
	set_branch_pos_id: 3,
	set_liaison_como: 3,
	set_liaison_webview_loyalty: 3,
	set_api_discount: 3,
	set_api_payment_type: 3,
	set_api_mask: 3,
	success: 4,
};

const Como = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Como;
