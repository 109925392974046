import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Channel from './Channel';

class ChannelList extends Component {
	render() {
		const {
			list,
			channels,
			onDelete,
			onChangeChannel,
			isBrand = false,
		} = this.props;
		return (
			<div className={'partner-channel-container'}>
				{list && Object.keys(list).length ? (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									{I18n.t('partner.brandChannel.channel_name')}
								</TableCell>
								<TableCell>
									{I18n.t('partner.brandChannel.channel_floor')}
								</TableCell>
								<TableCell>
									{I18n.t('partner.brandChannel.channel_payment_type')}
								</TableCell>
								<TableCell>
									{I18n.t('partner.brandChannel.channel_price_category')}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(list).map(channel_id => {
								let channel = list[channel_id];

								return (
									<Channel
										key={channel_id}
										channel={{
											id: channel_id,
											...channel,
										}}
										onDelete={() => onDelete('channel', channel_id)}
										onChange={(...params) => {
											onChangeChannel(...params);
										}}
										isBrand={isBrand}
									/>
								);
							})}
						</TableBody>
					</Table>
				) : (
					<p>{I18n.t('partner.brandChannel.no_channel_set')}</p>
				)}
			</div>
		);
	}
}

export default ChannelList;
