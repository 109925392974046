import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import imgTime from './img/time-red.svg';
import imgCalendar from './img/calendar-big.svg';
import imgArrowNext from './img/fleche-droit.svg';
import moment from 'moment';
import Button from '../../../../Components/Button';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { deepEqual } from '../../../../utils';
import ClosingDate from './Components/ClosingDate';

const daysOfWeek = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

class TabDates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openingHours: props.openingHours,
			openingHoursMorning: {
				title: 'midi',
				time1: '12:00',
				time2: '12:00',
				days: [],
			},
			openingHoursEvening: {
				title: 'soir',
				time1: '12:00',
				time2: '12:00',
				extras: '',
				days: [],
			},
			open: false,
			isValid: false,
			buttonSaveDisabled: true,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.openingHours !== state.openingHours) {
			return {
				openingHours: props.openingHours,
			};
		}
		return null;
	}

	componentDidMount() {
		const openingHours = this.props.openingHours;
		const { ...openingHoursMorning } = this.state.openingHoursMorning;
		const { ...openingHoursEvening } = this.state.openingHoursEvening;

		if (openingHours) {
			for (let key in openingHours) {
				if (
					(openingHours[key][0] && openingHours[key][0].morning) ||
					(openingHours[key] && openingHours[key].morning)
				) {
					openingHoursMorning.time1 = openingHours[key][0]
						? openingHours[key][0].morning.time1
						: openingHours[key].morning.time1;
					openingHoursMorning.time2 = openingHours[key][0]
						? openingHours[key][0].morning.time2
						: openingHours[key].morning.time2;
					openingHoursMorning.days.push(key);

					this.setState({
						openingHoursMorning,
						isValid: true,
					});
				}

				if (openingHours[key].length > 1) {
					if (
						(openingHours[key][1] && openingHours[key][1].evening) ||
						(openingHours[key] && openingHours[key].evening)
					) {
						let extras = openingHours[key][1]
							? openingHours[key][1].evening.extras
							: openingHours[key].evening.extras;

						let time2 = openingHours[key][1]
							? openingHours[key][1].evening.time2
							: openingHours[key].evening.time2;

						openingHoursEvening.time1 = openingHours[key][1]
							? openingHours[key][1].evening.time1
							: openingHours[key].evening.time1;
						openingHoursEvening.time2 = extras ? extras : time2;
						openingHoursEvening.days.push(key);

						this.setState({
							openingHoursEvening,
							isValid: true,
						});
					}
				} else {
					if (
						(openingHours[key][0] && openingHours[key][0].evening) ||
						(openingHours[key] && openingHours[key].evening)
					) {
						let extras = openingHours[key][0]
							? openingHours[key][0].evening.extras
							: openingHours[key].evening.extras;

						let time2 = openingHours[key][0]
							? openingHours[key][0].evening.time2
							: openingHours[key].evening.time2;

						openingHoursEvening.time1 = openingHours[key][0]
							? openingHours[key][0].evening.time1
							: openingHours[key].evening.time1;
						openingHoursEvening.time2 = extras ? extras : time2;
						openingHoursEvening.days.push(key);

						this.setState({
							openingHoursEvening,
							isValid: true,
						});
					}
				}
			}
		}
	}

	/**
	 * handle time picker for morning or evening range
	 * @param {String} value: new hour
	 * @param {String} StrPeriode: enum with  2 values : 'openingHoursMorning' and 'openingHoursEvening'
	 * @param {String} StrTimePeriode: enum with 2 values : 'time1' and 'time2'
	 */
	handleTimeChange = (value, StrPeriode, StrTimePeriode) => {
		const { ...openingHoursMorning } = this.state.openingHoursMorning;
		const { ...openingHoursEvening } = this.state.openingHoursEvening;
		const newTime = value.format('HH:mm');

		if (newTime) {
			if (StrPeriode === 'openingHoursMorning') {
				openingHoursMorning[StrTimePeriode] = newTime;
				if (openingHoursMorning.time2 < openingHoursMorning.time1) {
					openingHoursMorning.time2 = openingHoursMorning.time1;
				}
				let isValid =
					openingHoursMorning.days.length &&
					openingHoursMorning.time1 &&
					openingHoursMorning.time2
						? true
						: false;
				this.setState(
					{
						openingHoursMorning,
						isValid,
					},
					() => {
						this.buttonDisabled();
					}
				);
			}
			if (StrPeriode === 'openingHoursEvening') {
				openingHoursEvening[StrTimePeriode] = newTime;
				openingHoursEvening.extras = '';
				let isValid =
					openingHoursEvening.days.length &&
					openingHoursEvening.time1 &&
					openingHoursEvening.time2
						? true
						: false;
				this.setState(
					{
						openingHoursEvening,
						isValid,
					},
					() => this.buttonDisabled()
				);
			}
		}
	};

	submitOpeningDate = e => {
		e.preventDefault();
		const openingHours = this.openingDate();
		this.props.onUpdateOpeningDate(openingHours);
		this.setState({
			buttonSaveDisabled: true,
		});
	};

	/**
	 * create the object for submit
	 */
	openingDate = () => {
		const { openingHoursMorning, openingHoursEvening } = this.state;
		let OpeningHoursForBackApi = {
			monday: [],
			tuesday: [],
			wednesday: [],
			thursday: [],
			friday: [],
			saturday: [],
			sunday: [],
		};

		for (let key in OpeningHoursForBackApi) {
			let findDateMorning = openingHoursMorning.days.some(day => day === key);
			let findDateEvening = openingHoursEvening.days.some(day => day === key);
			if (findDateMorning) {
				OpeningHoursForBackApi[key].morning = {
					time1: openingHoursMorning.time1,
					time2: openingHoursMorning.time2,
				};
			}
			if (findDateEvening) {
				let time2 = openingHoursEvening.time2;
				let time1 = openingHoursEvening.time1;
				if (time2 < time1) {
					time2 = '00:00';
					OpeningHoursForBackApi[key].evening = {
						time1: time1,
						time2: time2,
						extras: openingHoursEvening.time2,
					};
				} else {
					OpeningHoursForBackApi[key].evening = {
						time1: time1,
						time2: time2,
						extras: '',
					};
				}
			}
		}
		return OpeningHoursForBackApi;
	};

	/**
	 * handle days
	 */
	handleDays = (e, strPeriode, day) => {
		e.preventDefault();
		const { ...openingHoursMorning } = this.state.openingHoursMorning;
		const { ...openingHoursEvening } = this.state.openingHoursEvening;
		if (strPeriode === 'openingHoursMorning') {
			const find = openingHoursMorning.days.some(item => item === day);
			if (!!find) {
				openingHoursMorning.days.splice(
					openingHoursMorning.days.indexOf(day),
					1
				);
			} else {
				openingHoursMorning.days.push(day);
			}
			this.setState({
				openingHoursMorning,
			});
			this.buttonDisabled();
		}
		if (strPeriode === 'openingHoursEvening') {
			const find = openingHoursEvening.days.some(item => item === day);
			if (!!find) {
				openingHoursEvening.days.splice(
					openingHoursEvening.days.indexOf(day),
					1
				);
			} else {
				openingHoursEvening.days.push(day);
			}
			this.setState({
				openingHoursEvening,
			});
			this.buttonDisabled();
		}
	};

	buttonDisabled = () => {
		const data = this.openingDate();
		const formatedNewData = Object.keys(data).reduce((acc, day) => {
			let morning = data[day]['morning']
				? { morning: data[day]['morning'] }
				: false;
			let evening = data[day]['evening']
				? { evening: data[day]['evening'] }
				: false;

			if (morning && !evening) {
				acc[day] = [morning];
			} else if (!morning && evening) {
				acc[day] = [evening];
			} else if (morning && evening) {
				acc[day] = [morning, evening];
			} else {
				acc[day] = [];
			}
			return acc;
		}, {});
		if (
			(this.state.openingHoursEvening.time1 &&
				this.state.openingHoursEvening.time2) ||
			(this.state.openingHoursMorning.time1 &&
				this.state.openingHoursMorning.time2)
		) {
			if (!deepEqual(formatedNewData, this.props.openingHours)) {
				this.setState({
					buttonSaveDisabled: false,
				});
			} else {
				this.setState({
					buttonSaveDisabled: true,
				});
			}
		} else {
			this.setState({
				buttonSaveDisabled: true,
			});
		}
	};

	submitClosingDate = newExclusion => {
		this.props.onAddClosingDates(newExclusion);
	};

	removeClosingDate = id => {
		this.props.onRemoveClosingDate(id);
	};

	submitClosingDateUpdate = data => {
		this.props.onUpdateClosingDates(data);
	};

	render() {
		/**
		 * display range of opening hours
		 * @param {String} StrPeriode: enum openingHoursEvening or openingHoursMorning
		 * @param {Object} time1: start range
		 * @param {Object} time2: end range
		 */
		const timeRangeLine = (StrPeriode, time1, time2) => {
			return (
				<div className="time-picker">
					<div className="start">
						<img src={imgTime} className="time" alt="time" />
						<div className="content-time">
							<p className="subtitle">{I18n.t('info.dates.start')}</p>
							<div className="select-styles">
								<TimePicker
									value={
										time1 ? moment(time1, 'HH:mm') : moment('12:00', 'HH:mm')
									}
									showSecond={false}
									minuteStep={15}
									className="select"
									onChange={value =>
										this.handleTimeChange(value, StrPeriode, 'time1')
									}
								/>
							</div>
						</div>
					</div>
					<div className="end">
						<img src={imgArrowNext} className="arrow" alt="arrow" />
						<div className="content-time">
							<p className="subtitle">{I18n.t('info.dates.end')}</p>
							<div className="select-styles">
								<TimePicker
									value={
										time2 ? moment(time2, 'HH:mm') : moment('12:00', 'HH:mm')
									}
									showSecond={false}
									minuteStep={15}
									className="select"
									onChange={value =>
										this.handleTimeChange(value, StrPeriode, 'time2')
									}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		};

		const dayRangeLine = (strPeriode, days) => {
			return (
				<div className="content-days">
					<img src={imgCalendar} className="calendar" alt="calendar" />
					<div className="select-days">
						<p className="subtitle">{I18n.t('info.dates.days')}</p>
						<div>{renderDays(strPeriode, days)}</div>
					</div>
				</div>
			);
		};

		const renderDays = (strPeriode, days) => {
			let selectedDays = [];
			const { openingHoursMorning, openingHoursEvening } = this.state;
			if (strPeriode === 'openingHoursMorning') {
				selectedDays = openingHoursMorning.days;
			}
			if (strPeriode === 'openingHoursEvening') {
				selectedDays = openingHoursEvening.days;
			}
			return days.map((day, index) => {
				const find = selectedDays.some(
					item => item.toString() === day.toString()
				);
				return (
					<div
						key={index}
						className={`bloc-day ${!!find ? 'selected' : ''}`}
						onClick={e => this.handleDays(e, strPeriode, day)}>
						<span>{I18n.t('general.' + `${day}`)}</span>
					</div>
				);
			});
		};

		return (
			<div id="hours">
				<h1>{I18n.t('info.dates.reserv_dates')}</h1>
				<form
					onSubmit={this.submitOpeningDate}
					noValidate
					className="form-hours">
					<div className="submit">
						<Button
							type="submit"
							className="is-red"
							disabled={this.state.buttonSaveDisabled}>
							{I18n.t('general.save')}
						</Button>
					</div>
					<div className="hour-container">
						<div>
							<h4>{I18n.t('info.dates.noon')}</h4>

							{timeRangeLine(
								'openingHoursMorning',
								this.state.openingHoursMorning.time1,
								this.state.openingHoursMorning.time2
							)}

							{dayRangeLine('openingHoursMorning', daysOfWeek)}
						</div>
						<div>
							<h4>{I18n.t('info.dates.evening')}</h4>
							{timeRangeLine(
								'openingHoursEvening',
								this.state.openingHoursEvening.time1,
								this.state.openingHoursEvening.time2
							)}
							{dayRangeLine('openingHoursEvening', daysOfWeek)}
						</div>
					</div>
				</form>
				<div>
					<div>
						<h1>{I18n.t('info.dates.close_period')}</h1>
						<ClosingDate
							submitClosingDate={this.submitClosingDate}
							cancelButton={false}
							closingDate={this.props.closingDate}
							removeClosingDate={this.removeClosingDate}
						/>
					</div>
				</div>
			</div>
		);
	}
	static defaultProps = {
		openingHours: {},
		closingDate: [],
	};
}

TabDates.propTypes = {
	openingHours: PropTypes.object.isRequired,
	closingDate: PropTypes.array.isRequired,
};

export default TabDates;
