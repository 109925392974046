import React from 'react';
import './style.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

const CardConfig = props => {
	return (
		<div className="partnerconfig-configuration-container partnerconfig-export-container">
			<div className="partnerconfig-partner-card">
				{props.children}
				<div
					className={
						props.configLaunch
							? 'configuration-bottom configuration-bottom-active'
							: 'configuration-bottom'
					}>
					<div className="progress-bar-container">
						<span>
							{props.step ? props.step : 0}/{props.maxStep}
						</span>
						<LinearProgress
							className="progress-bar"
							variant="determinate"
							value={props.step !== 0 ? (props.step / props.maxStep) * 100 : 0}
						/>
					</div>
					{props.nextStepButton}
				</div>
			</div>
		</div>
	);
};

export default React.memo(CardConfig);
