import React, { Component } from 'react';
import Module from '../../Components/Module';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { getPartnerRoute } from '../Partners/utils';
import './style.scss';

class exportModule extends Component {
	MODULES = {
		partners: { title: I18n.t('dashboard.partners'), link: '/partners' },
		infos: { title: I18n.t('dashboard.infos'), link: '/info' },
		menu: { title: I18n.t('dashboard.menu'), link: `/menu` },
		eventWebhook: {
			title: I18n.t('dashboard.event_webhook'),
			link: '/eventWebhook',
		},
	};

	render() {
		const modules = [];

		if (
			this.props.user &&
			this.props.user.id &&
			this.props.user.hasOwnProperty('isPartner')
		) {
			if (this.props.user.isPartner) {
				modules.push(this.MODULES.eventWebhook);
				const url = getPartnerRoute(this.props.user.id);
				if (url) {
					modules.push({
						title: I18n.t('dashboard.partner_export'),
						link: url,
					});
				}
			} else {
				modules.push(this.MODULES.partners);
				modules.push(this.MODULES.menu);
			}
		}
		return (
			<div className="content-container">
				<h1>{I18n.t('dashboard.dashboard')}</h1>
				<div className="module-container">
					{modules.map((module, index) => (
						<Module
							key={'module-' + index}
							title={module.title}
							link={module.link}
						/>
					))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.service.user,
	};
};
export default connect(mapStateToProps)(exportModule);