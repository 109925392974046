import {
  PRE_REMOVE_PICTURE_ONLINE,
  PRE_PATCH_PICTURE_ONLINE,
  PRE_ADD_PICTURE_ONLINE,
  PRE_ADD_PICTURE_ONLINE_REUSED,
  INIT_LOAD_QR_CODE,
  PRE_LOAD_QR_CODE,
} from "./types";

export const patchPicture = (body) => {
  return {
    type: PRE_PATCH_PICTURE_ONLINE,
    body,
  };
};

export const removePicture = (id) => {
  return {
    type: PRE_REMOVE_PICTURE_ONLINE,
    id,
  };
};

export const addPictureOnline = (body) => {
  return {
    type: PRE_ADD_PICTURE_ONLINE,
    body,
  };
};

export const addPictureReused = (body) => {
  return {
    type: PRE_ADD_PICTURE_ONLINE_REUSED,
    body,
  };
};

export const loadQrCode = (body) => {
  return {
    type: PRE_LOAD_QR_CODE,
    body,
  };
};

export const initLoadingQrcode = () => {
  return {
    type: INIT_LOAD_QR_CODE,
  };
};
