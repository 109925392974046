import { call, put, take, all, actionChannel } from 'redux-saga/effects';
import { requestHandler, ENTITIES } from '../../../service/fetcher.saga';
import {
	PRE_PATCH_VARIABLE,
	PATCH_VARIABLE,
	PRE_UPDATE_SESSION,
} from './types';
import { SET_MULTI_SESSION } from '../../../service/auth/types';
import { buffers } from 'redux-saga';

const PRE_REQUEST_PATCH_VARIABLE = 'PRE_REQUEST_PATCH_VARIABLE';
const REQUEST_PATCH_VARIABLE_FINISHED = 'REQUEST_PATCH_VARIABLE_FINISHED';
const PRE_REQUEST_UPDATE_SESSION = 'PRE_REQUEST_UPDATE_SESSION';
const REQUEST_UPDATE_SESSION_FINISHED = 'REQUEST_UPDATE_SESSION_FINISHED';

//TODO: au lieu de retrive toutes les variables, faire la'pi retourner la ligne et simplement ajouter au store
export function* prePatchVariable() {
	const requestChannel = yield actionChannel(
		PRE_PATCH_VARIABLE,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		const { body } = action;
		yield put({
			type: PRE_REQUEST_PATCH_VARIABLE,
			body,
		});

		const { result } = yield take(REQUEST_PATCH_VARIABLE_FINISHED);

		yield put({ type: PATCH_VARIABLE, patch: result });
		yield put({ type: 'PATCH_VARIABLE_RESULT', success: !!result });
	}
}

export function* watchPatchVariable() {
	while (true) {
		const action = yield take(PRE_REQUEST_PATCH_VARIABLE);
		const { result } = yield call(
			requestHandler,
			ENTITIES.VARIABLE,
			'PATCH',
			action.body
		);

		yield put({ type: REQUEST_PATCH_VARIABLE_FINISHED, result });
	}
}

export function* preUpdateSession() {
	while (true) {
		const action = yield take(PRE_UPDATE_SESSION);
		yield put({
			type: PRE_REQUEST_UPDATE_SESSION,
			body: { guid: sessionStorage.getItem('guid') },
		});

		yield take(REQUEST_UPDATE_SESSION_FINISHED);
		yield put({ type: SET_MULTI_SESSION, multiSession: false });
		window.location.reload();
	}
}

export function* watchUpdateSession() {
	while (true) {
		const action = yield take(PRE_REQUEST_UPDATE_SESSION);
		const { result } = yield call(
			requestHandler,
			ENTITIES.VARIABLE,
			'PATCH',
			action.body,
			'/update-session'
		);
		yield put({ type: REQUEST_UPDATE_SESSION_FINISHED });
	}
}

export function* handlePatchVariable(body) {
	yield put({
		type: PRE_PATCH_VARIABLE,
		body,
	});
	const { success } = yield take('PATCH_VARIABLE_RESULT');

	return success;
}

export default function* indexSaga() {
	yield all([
		prePatchVariable(),
		watchPatchVariable(),
		preUpdateSession(),
		watchUpdateSession(),
	]);
}
