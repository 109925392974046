import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Portal from '@material-ui/core/Portal';

const paymentType = props => {
	return (
		<div>
			{props.list.length ? (
				<div className="label-paiement-select">
					{!props.hideLabel ? (
						<DialogContentText>
							<Translate value={'binding.select_payment_type'} /> :
						</DialogContentText>
					) : null}

					<Select
						value={props.selected}
						onChange={e => {
							props.onChange('selectedPaymentType', e.target.value);
						}}
						displayEmpty
						onOpen={() =>
							props.removeListener ? props.removeListener() : null
						}
						onClose={props.addListener ? () => props.addListener() : null}>
						<MenuItem value="" disabled={!!props.required}>
							<i>{I18n.t('binding.none')}</i>
						</MenuItem>
						{props.list.map(p => (
							<MenuItem key={p.id} value={p.id}>
								{p.name}
							</MenuItem>
						))}
					</Select>
				</div>
			) : (
				<p className="paragraph-without-margin">
					<Translate value={'binding.empty_payment_type'} />
				</p>
			)}
		</div>
	);
};

/* PaymentType.defaultProps = {
  selected: "0",
  list: [],
};

PaymentType.propTypes = {
  selected: PropTypes.string,
  list: PropTypes.array.isRequired,

  onChange: PropTypes.func.isRequired,
}; */

export default paymentType;
