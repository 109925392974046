import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import Alert from '../../../../../../Components/Alert';

class PosStatus extends Component {
	render() {
		const { status } = this.props;

		const state =
			status == true ? 'enabled' : status == false ? 'disabled' : 'unknow';
		const label = I18n.t('partner.ubereats_pos_status.' + state);

		let classes = ['ubereats_pos_status', 'ubereats_pos_status_' + state];

		return (
			<div className="ubereats_pos_status_container">
				{I18n.t('partner.ubereats_pos_status.title')} :{' '}
				<span className={classes.join(' ')}>{label}</span>
				{state !== 'enabled' ? (
					<Alert type={state === 'disabled' ? 'info' : 'warning'}>
						{I18n.t('partner.ubereats_pos_status.label_' + state)}
					</Alert>
				) : null}
			</div>
		);
	}
}

export default PosStatus;
