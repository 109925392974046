import React from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	init_partner: 0,
	set_auth_link: 0,
	documentation: 1,
	set_entities: 1,
	set_partner_options: 1,
	set_api_mask: 1,
	success: 2,
};

const CommandeEnLigne = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default CommandeEnLigne;
