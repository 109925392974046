import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { PRE_PATCH_VARIABLE } from '../../../../VariableServer/service/types';
import { INIT_PARTNER_WITHOUT_MASK } from '../../export/types';
import {
	initApiVariable,
	isMulti,
	patchPartnerPaiementType,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setPartnerMedDefaut,
	triggerSynchroIpad,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP, PRE_SET_OPTION_PARTNER } from '../types';
import { showConfirm } from '../../../../../Components/ConfirmRedux/service/saga';

const PARTNER_PREFIX = 'ladditionTicket';

function* configLadditionTicket() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_LADDITIONTICKET');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: PARTNER_PREFIX,
				partnerId: data.partnerId,
			});
			success = true;
		}

		if (
			['documentation', 'update_nuc', 'check_overpaid'].includes(currentStep)
		) {
			success = true;
			if (data.nextStep === 'update_nuc') {
				let resIsMulti = yield call(isMulti);
				if (!resIsMulti) {
					bodySetStep.step = 'check_overpaid';
					yield call(
						notify,
						I18n.t('partner.update_nuc_ignored'),
						'info',
						10000
					);
				}
			}
		}

		if (currentStep === 'set_paiement') {
			let body = {
				prefix: PARTNER_PREFIX,
				id_payment_type: data.id_payment_type,
			};

			const resultPatchPartnerPaiementType = yield call(
				patchPartnerPaiementType,
				body
			);
			if (resultPatchPartnerPaiementType) {
				success = true;
			}
		}

		if (currentStep === 'call_laddition_ticket') {
			yield put({
				type: INIT_PARTNER_WITHOUT_MASK,
				partnerPrefix: PARTNER_PREFIX,
			});
			const resultInit = yield take('INIT_PARTNER_WITHOUT_MASK_FINISHED');
			if (
				resultInit &&
				resultInit.result &&
				resultInit.result.status == 'success'
			) {
				external_id = resultInit.result.data[0].responseData.id;
				yield put({
					type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
					body: { prefix: PARTNER_PREFIX, external_id },
				});
				success = true;
				currentStep = 'set_api_variables';
				bodySetStep.step = 'set_api_variables';
			}
			// message d'erreur géré par la saga
		}

		if (currentStep === 'set_api_variables') {
			const result = yield call(initLadditionTicketVariables);
			if (result.status === 'success') {
				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
			} else {
				errorMessage =
					'partner.onboarding_ladditionticket_errors.call_laddition_ticket';
			}
		}

		if (currentStep === 'set_auth_link') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_link_reconciliation';
					bodySetStep.step = 'set_link_reconciliation';
					success = true;
				}
			} else {
				errorMessage =
					'partner.onboarding_ladditionticket_errors.call_laddition_ticket';
			}
		}

		if (currentStep === 'set_link_reconciliation') {
			let res = yield call(initLadditionReconciliation);
			if (res && res.success) {
				currentStep = 'set_link_laddition_print';
				bodySetStep.step = 'set_link_laddition_print';
				success = true;
			} else {
				errorMessage =
					'partner.onboarding_ladditionticket_errors.call_laddition_ticket';
			}
		}
		if (currentStep === 'set_link_laddition_print') {
			let res = yield call(initLadditionPrint);
			if (res && res.success) {
				currentStep = 'set_partner_options';
				bodySetStep.step = 'set_partner_options';
				success = true;
			} else {
				errorMessage =
					'partner.onboarding_ladditionticket_errors.call_laddition_ticket';
			}
		}

		if (currentStep === 'set_partner_options') {
			yield put({
				type: PRE_SET_OPTION_PARTNER,
				body: { prefix: PARTNER_PREFIX },
			});
			const { result: resultInitOption } = yield take(
				'REQUEST_OPTION_PARTNER_FINISHED'
			);
			if (resultInitOption) {
				success = true;
				bodySetStep.step = 'set_api_mask';
				currentStep = 'set_api_mask';
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';

				yield call(
					showConfirm,
					I18n.t('partner.ladditionTicket.activate_payplus_popup'),
					'confirm',
					{
						eventOnValid: 'ACTIVATE_PAY_PLUS',
					}
				);
			} else {
				bodySetStep.status = 'configuration';
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_LADDITIONTICKET_FINISHED',
			step: bodySetStep.step,
		});
	}
}

function* initLadditionTicketVariables() {
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{},
		'ladditionTicketInitVariables'
	);
	return result;
}

function* initLadditionPayPlus() {
	while (true) {
		const { prefix } = yield take('ACTIVATE_PAY_PLUS');
		const { result } = yield call(
			requestHandler,
			'partnersConfig',
			'POST',
			{ prefix },
			'initLadditionPayPlus'
		);
		if (result && result.activation && result.generateId && result.exist) {
			const variableKey =
				prefix == 'ladditionTicket'
					? 'ladditionPayplusActivated'
					: 'ladditionPayplusActivatedClickAndCollect';
			yield put({
				type: PRE_PATCH_VARIABLE,
				body: { key: variableKey, value: 1 },
			});
			yield call(
				notify,
				I18n.t('partner.ladditionTicket.payplus_activated'),
				'success'
			);
		} else {
			let label = '';
			if (!result.exist) {
				label = I18n.t(
					'partner.ladditionTicket.payplus_activation_fail_no_account'
				);
			} else {
				label = I18n.t('partner.ladditionTicket.payplus_activation_fail');
			}
			yield call(notify, label, 'error');
		}
	}
}

function* initLadditionReconciliation() {
	const refresh_token = window.localStorage.refresh_token;
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ refresh_token },
		'initLadditionReconciliation'
	);
	return result;
}

function* initLadditionPrint() {
	const refresh_token = window.localStorage.refresh_token;
	const { result } = yield call(
		requestHandler,
		'partnersConfig',
		'POST',
		{ refresh_token },
		'initLadditionPrint'
	);
	return result;
}

export function* setPrintQr() {
	while (true) {
		const { value } = yield take('SET_PRINT_QR');
		const success = yield call(
			initApiVariable,
			'variable',
			'laddition_ticket_qr',
			value
		);

		if (success) {
			yield call(triggerSynchroIpad);
		}
	}
}

export default function* indexSaga() {
	yield all([configLadditionTicket(), initLadditionPayPlus(), setPrintQr()]);
}
