export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';
export const CHANGE_ACTIVE_PARTNER = 'CHANGE_ACTIVE_PARTNER';
export const PRE_CHANGE_ACTIVE_PARTNER = 'PRE_CHANGE_ACTIVE_PARTNER';
export const PRE_POST_OPTION = 'PRE_POST_OPTION';
export const POST_OPTION = 'POST_OPTION';
export const PRE_DELETE_OPTION = 'PRE_DELETE_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';
export const RECEIVE_PARTNER_KEYS = 'RECEIVE_PARTNER_KEYS';
export const PRE_POST_MENU_TO_PARTNER = 'PRE_POST_MENU_TO_PARTNER';
export const POST_MENU_TO_PARTNER = 'POST_MENU_TO_PARTNER';
