import React from 'react';
import { I18n } from 'react-redux-i18n';
import { PropTypes } from 'prop-types';
import { Switch } from '@material-ui/core';
import './style.scss';

const BooleanForm = props => {
	const onChange = e => {
		props.onChange(e.target.name, e.target.checked);
	};

	return (
		<div className={'form-product-switch'}>
			<p
				className={
					props.toComplete
						? 'form-product-title pastille-after'
						: 'form-product-title'
				}>
				{I18n.t(`partner.productForm.${props.name}`)}
			</p>
			<Switch
				name={props.name}
				checked={props.value || false}
				onChange={onChange}
			/>
		</div>
	);
};

BooleanForm.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default React.memo(BooleanForm);
