export const RECEIVE_EXPORT = 'RECEIVE_EXPORT';
export const RECEIVE_PRODUCT_EXTRA = 'RECEIVE_PRODUCT_EXTRA';
export const RECEIVE_PARTNER_KEYS = 'RECEIVE_PARTNER_KEYS';
export const ADD_SECTION = 'ADD_SECTION';
export const PRE_ADD_SECTION = 'PRE_ADD_SECTION';
export const CHANGE_PROP = 'CHANGE_PROP';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const ADD_PRODUCTS_TO_SECTION = 'ADD_PRODUCT_TO_SECTION';
export const PRE_ADD_PRODUCTS_TO_SECTION = 'PRE_ADD_PRODUCT_TO_SECTION';
export const DELETE_PRODUCT_TO_SECTION = 'DELETE_PRODUCT_TO_SECTION';
export const PRE_DELETE_PRODUCT_TO_SECTION = 'PRE_DELETE_PRODUCT_TO_SECTION';
export const SAVE_PRODUCTS_TO_SECTION = 'SAVE_PRODUCTS_TO_SECTION';
export const PRE_SAVE_PRODUCT_TO_SECTION = 'PRE_SAVE_PRODUCT_TO_SECTION';
export const PRE_SAVE_EXTRA_PRODUCT = 'PRE_SAVE_EXTRA_PRODUCT';
export const SAVE_EXTRA_PRODUCT = 'SAVE_EXTRA_PRODUCT';
export const PRE_DELETE_SECTION = 'PRE_DELETE_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const PATCH_SECTION = 'PATCH_SECTION';
export const PRE_PATCH_SECTION = 'PRE_PATCH_SECTION';
export const PRE_PATCH_EXPORT = 'PRE_PATCH_EXPORT';
export const PATCH_EXPORT = 'PATCH_EXPORT';
export const PRE_PATCH_SECTION_WEIGHT = 'PRE_PATCH_SECTION_WEIGHT';
export const PATCH_SECTION_WEIGHT = 'PATCH_SECTION_WEIGHT';
export const PRE_PATCH_PRODUCT_WEIGHT = 'PRE_PATCH_PRODUCT_WEIGHT';
export const PATCH_PRODUCT_WEIGHT = 'PATCH_PRODUCT_WEIGHT';
export const PRE_GENERATE_EXPORT = 'PRE_GENERATE_EXPORT';
export const GENERATE_EXPORT = 'GENERATE_EXPORT';
export const PRE_ACTIVE_EXPORT = 'PRE_ACTIVE_EXPORT';
export const ACTIVE_EXPORT = 'ACTIVE_EXPORT';
export const DELETE_EXPORT = 'DELETE_EXPORT';
export const PRE_DELETE_EXPORT = 'PRE_DELETE_EXPORT';
export const ADD_EXPORT = 'ADD_EXPORT';
export const PRE_ADD_EXPORT = 'PRE_ADD_EXPORT';
export const SET_SUPPLEMENT_FORM_VALUES = 'SET_SUPPLEMENT_FORM_VALUES';
export const SET_SUPPLEMENT_MINIMUM = 'SET_SUPPLEMENT_MINIMUM';
export const SET_SUPPLEMENT_MAXIMUM = 'SET_SUPPLEMENT_MAXIMUM';
export const SET_CATEGORY_ACTIVE = 'SET_CATEGORY_ACTIVE';
export const SET_CATEGORY_INACTIVE = 'SET_CATEGORY_INACTIVE';
export const SET_SUPPLEMENT_ACTIVE = 'SET_SUPPLEMENT_ACTIVE';
export const SET_SUPPLEMENT_INACTIVE = 'SET_SUPPLEMENT_INACTIVE';
export const DELETE_PRODUCT_EXTRA = 'DELETE_PRODUCT_EXTRA';
export const PRE_DELETE_PRODUCT_EXTRA = 'PRE_DELETE_PRODUCT_EXTRA';
export const PRE_POST_IPAD_CHANGE = 'PRE_POST_IPAD_CHANGE';
export const POST_IPAD_CHANGE = 'POST_IPAD_CHANGE';
export const COPY_EXPORT = 'COPY_EXPORT';
export const ACTIVATE_UBEREATS_LINK = 'ACTIVATE_UBEREATS_LINK';
export const INIT_PARTNER_WITHOUT_MASK = 'INIT_PARTNER_WITHOUT_MASK';
export const RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE =
	'RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE';
export const RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS =
	'RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS';
export const PRE_RESET_PRODUCT_EXTRA = 'PRE_RESET_PRODUCT_EXTRA';
