import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const isExternalLink = (url) => /^https?:\/\//.test(url);

export default ({ title, link, disabled }) => (
  <div className="dashboard-module">
    {disabled ? (
      <p className="disabled">{title}</p>
    ) : isExternalLink(link) ? (
      <a href={link} rel="noopener noreferrer">
        <p>{title}</p>
      </a>
    ) : (
      <Link to={link}>
        <p>{title}</p>
      </Link>
    )}
  </div>
);
