import {
	SELECT_PRODUCTS,
	DESELECT_PRODUCTS,
	SET_SELECTED_PRODUCT,
} from './types';

export const select = (ids, idMenu) => ({
	type: SELECT_PRODUCTS,
	ids,
	idMenu,
});
export const deselect = (ids, idMenu) => ({
	type: DESELECT_PRODUCTS,
	ids,
	idMenu,
});
export const setSelected = body => ({
	type: SET_SELECTED_PRODUCT,
	body,
});
