import React, { Component } from 'react';
import KeyboardBackspace from '@material-ui/icons/ArrowBack';
import PartnerContact from '../../../../Components/PartnerContact';
import Button from "../../../../Components/Button";
import { I18n } from 'react-redux-i18n';
import './style.scss';

class PartnerContainer extends Component {
	render() {
		if (!this.props.id) {
			window.location = '/';
			return '';
		}
		return (
			<div className="content-container">
				<div className="subHeader-container">
					<div className="left-container">
						<Button
							onClick={() => {
								window.location.href = '/partners';
							}}
							color="primary"
							icon={KeyboardBackspace}
						>
							{I18n.t("general.back")}
						</Button>
					</div>
					<div className="center-container">
						<h1>{this.props.name}</h1>
					</div>
					<div className="right-container"></div>
				</div>
				<div className="partners-container">
					<div className="partners-subcontainer">
						<PartnerContact prefix={this.props.prefix} />
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default PartnerContainer;
