import React, { Component } from 'react';
import './style.scss';
import Logo from '../../img/logo.png';
import { Button } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';

class quatreCentQuatre extends Component {
	goHome = () => {
		window.location = '/';
	};
	render() {
		return (
			<div className="notFoundContainer">
				<div className="number">
					<span className="number">4</span> <img src={Logo} />{' '}
					<span className="number">4</span>
				</div>
				<div>{I18n.t('404.not_found')}</div>
				<div>
					{' '}
					<Button variant="contained" color="primary" onClick={this.goHome}>
						{I18n.t('404.go_home')}
					</Button>{' '}
				</div>
			</div>
		);
	}
}

quatreCentQuatre.propTypes = {};

export default quatreCentQuatre;
