import React, { Component, PureComponent } from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import logo from './assets/logo.png';
import { deepEqual } from '../../utils.js';
import './style.scss';

class Loader extends Component {
	constructor() {
		super();
	}

	shouldComponentUpdate(nextProps) {
		if (deepEqual(this.props.loading, nextProps.loading)) {
			return false;
		}

		return true;
	}

	render() {
		const loading =
			this.props.loading.isLoading || !this.props.loading.receivedAll;
		let loaderClassname = 'laddition-loader-container-med ';
		if (!loading) {
			loaderClassname += 'laddition-loader-container-med__hidden';
		}
		return (
			<Portal>
				<div
					className={
						loading ? 'loader-container-med' : 'loader-container-med-hidden'
					}>
					<div className={loaderClassname}>
						<div className="pulse-med">
							<img alt={"loader L'addition"} src={logo} width={150} />
						</div>

						<p className={'loader-text-med'}>{this.props.loading.text || ''}</p>
					</div>
				</div>
			</Portal>
		);
	}
}

const mapStateToProps = state => {
	const { loading } = state;
	return {
		loading,
	};
};

export default connect(mapStateToProps)(Loader);
