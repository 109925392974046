import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Alert from '../../../../../Components/Alert';
import './index.css';

class CancelNote extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pmsId: '',
			proccessing: false,
		};
	}

	onChange = e => {
		this.setState({ pmsId: e.target.value });
	};

	onValid = _ => {
		this.props.cancelNote('misterBooking', this.state.pmsId);
	};

	render() {
		return (
			<div>
				<h2>{I18n.t('partner.misterBooking.cancelNoteTitle')}</h2>
				<Alert>{I18n.t('partner.misterBooking.cancelNoteDescription')}</Alert>
				<div className="misterBooking-cancelNote-container-input">
					<TextField
						readOnly={this.state.proccessing}
						label="PMS ID"
						type="text"
						value={this.state.pmsId}
						onChange={e => this.setState({ pmsId: e.target.value })}
					/>

					<Button
						color="primary"
						variant="contained"
						disabled={this.state.proccessing}
						onClick={this.onValid}>
						{I18n.t('partner.misterBooking.cancelNoteButton')}
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
	cancelNote: (prefix, pmsId) =>
		dispatch({ type: 'CANCEL_NOTE_PMS', prefix, pmsId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelNote);
