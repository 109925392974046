import React, { Component } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { history } from '../store';
import modulePartners from '../Containers/Partners';
import dashboard from '../Containers/Dashboard';
import moduleInfo from '../Containers/Info';
import ModuleMenu from '../Containers/Menu';
import moduleEventWebhook from '../Containers/EventWebhook';
import quatreCentQuatre from '../Components/404';
import GlobalPartner from '../Containers/Partners/Containers/index';
import config from '../config';

export default class route extends Component {
	render() {
		const { hasGroupEspaceClient } = this.props;
		const espaceClientUrl = config.ESPACE_CLIENT_URL;

		return (
			<Router history={history}>
				<Switch>
					<Route exact path={'/'} component={dashboard} />
					<Route exact path={'/partners'} component={modulePartners} />
					<Route exact path={'/info'} component={moduleInfo} />
					<Route
						exact
						path={'/menu'}
						render={() => {
							if (hasGroupEspaceClient) {
								window.location.href = `${espaceClientUrl}menu`;
								return null;
							}

							return <ModuleMenu />;
						}}
					/>
					<Route exact path={'/eventWebhook'} component={moduleEventWebhook} />
					<Route path={'/redirect'} component={''} />
					<Route path={'/partners/:prefix'} component={GlobalPartner} />

					<Route component={quatreCentQuatre} />
				</Switch>
			</Router>
		);
	}
}
