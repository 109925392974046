import React from 'react';
import { Button } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import './style.scss';

import imgError from './imageError1.png';
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, text: '' };
	}

	componentDidCatch(error, info) {
		console.log('Error', error, info);
		this.props.report({
			name: error.name,
			message: error.message,
			stack: info.componentStack,
		});
		this.setState({
			hasError: true,
		});
	}

	reload = () => {
		window.location.reload();
	};

	getInterjection = () => {
		let interjections = I18n.t('errorBoundary.interjections');
		const random = Math.floor(Math.random() * interjections.length - 1) + 1;
		return interjections[random];
	};

	render() {
		if (this.props.hasError || this.state.hasError) {
			return (
				<div className={'error-boundary'}>
					<h1>
						{this.props.text
							? this.props.text
							: this.getInterjection() + ' ' + I18n.t('errorBoundary.title')}
					</h1>
					<div>
						<img src={imgError} />
					</div>
					<h4>{I18n.t('errorBoundary.keepError')}</h4>
					{this.props.error ? (
						<p>
							{I18n.t('errorBoundary.errorCode') + ' : ' + this.props.error}
						</p>
					) : null}
					<Button variant="contained" color="primary" onClick={this.reload}>
						{I18n.t('errorBoundary.reload')}
					</Button>
				</div>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = state => {
	return { ...state.errors };
};

const mapDispatchToProps = dispatch => {
	return {
		report: data => dispatch({ type: 'REPORT_ERROR', data }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
