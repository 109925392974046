import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import PaymentAsso from '../../Components/Binding';
import { I18n } from 'react-redux-i18n';
import ProductThreshold from '../../Components/ProductQuantity';
import Options from '../../Components/Options';
import Config from '../../Components/Config';
import { getPartner, getExportActive } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import Export from '../../Components/Export';
import SupplementManager from '../../Components/SupplementExtra';
import { deepEqual } from '../../utils';
import ButtonPayPlus from './Components/ButtonPayPlus';
import Print from './Components/Print';
import Tip from './Components/Tip';
import OptionPrintQR from './Components/OptionPrintQR';

class LadditionTicket extends Component {
	shouldComponentUpdate(nextProps) {
		if (deepEqual(this.props.list, nextProps.list)) {
			return false;
		}

		return true;
	}

	render() {
		const { partner } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<div className="gestion-button">
					<PaymentAsso
						prefix={partner.prefix}
						hidePriceCategory={true}
						hideTable={true}
					/>
					<ButtonPayPlus prefix={partner.prefix} />
				</div>
				<Print />

				<Tip />
				<Options prefix_partner={partner.prefix || ''}>
					<OptionPrintQR />{' '}
				</Options>
				<Config prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
	};
};

export default connect(mapStateToProps)(LadditionTicket);
