const intialState = {
	text: '',
	error: '',
	hasError: false,
};
export default function errorBoundaryReducer(state = intialState, action) {
	switch (action.type) {
		case 'THROW_ERROR':
			return {
				hasError: true,
				text: action.text || '',
				error: action.error || '',
			};
	}
	return state;
}
