import React from 'react';
import { connect } from 'react-redux';
import Confirm from '../Confirm';
import { CANCEL_CONFIRM, VALID_CONFIRM } from './service/types';

class ConfirmRedux extends React.Component {
	render() {
		return (
			<Confirm
				confirmation={this.props.text}
				show={this.props.show}
				proceed={this.props.valid}
				dismiss={this.props.cancel}
				cancel={this.props.cancel}
				options={this.props.options}
				media={this.props.media}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.confirm,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cancel: () => dispatch({ type: CANCEL_CONFIRM }),
		valid: () => dispatch({ type: VALID_CONFIRM }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRedux);
