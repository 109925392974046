import { RECEIVE_VARIABLE_SERVER, PATCH_VARIABLE } from './types';

const reducerVariable = (state = [], action) => {
	let newState = [...state];
	switch (action.type) {
		case RECEIVE_VARIABLE_SERVER:
			if (typeof action.data === 'object') {
				newState = action.data;
			}
			return newState;
		case PATCH_VARIABLE:
			if (action.patch) {
				let indexVar = newState.findIndex(v => v.key_ === action.patch.key_);
				if (indexVar === -1) {
					newState.push(action.patch);
				} else {
					newState[indexVar] = action.patch;
				}
			}
			return newState;
		default:
			return newState;
	}
};

export default reducerVariable;
