import * as React from 'react';
import Brand from './Brand';

export default function BrandList({
	channels,
	list,
	onAddChannel,
	onDelete,
	onChangeChannel,
}) {
	return (
		<div>
			{list && Object.keys(list).length ? (
				Object.values(list).map(brand => (
					<Brand
						key={brand.id}
						brand={brand}
						channels={channels}
						onAddChannel={onAddChannel}
						onDelete={onDelete}
						onChangeChannel={(...params) => {
							onChangeChannel(...params);
						}}
					/>
				))
			) : (
				<p>-</p>
			)}
		</div>
	);
}
