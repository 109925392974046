import React from 'react';
import Proptypes from 'prop-types';
import {
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';

const style = () => ({
	selected: {
		backgroundColor: '#e2f5fd !important',
	},
	root: { borderBottom: '1px solid rgba(0,0,0,0.04)' },
});

const Section = React.memo(props => {
	return (
		<ListItem
			button
			onClick={() => props.onClick(props.id)}
			selected={props.selected}
			classes={{ ...props.classes }}>
			<ListItemText
				primary={props.name}
				primaryTypographyProps={{
					style: {
						fontFamily: props.selected ? 'FuturaHeavy' : 'FuturaBook',
						fontSize: props.primary ? 20 : 15,
					},
				}}
			/>

			<ListItemSecondaryAction>
				<Arrow />
			</ListItemSecondaryAction>
		</ListItem>
	);
});

Section.propTypes = {
	id: Proptypes.string.isRequired,
	name: Proptypes.string.isRequired,
	count: Proptypes.string.isRequired,
	selected: Proptypes.bool.isRequired,
	onChange: Proptypes.func.isRequired,
	primary: Proptypes.bool,
};

export default withStyles(style)(Section);
