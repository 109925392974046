import React, { PureComponent } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TabPictures from "./Components/TabPictures";
import { I18n } from "react-redux-i18n";
import * as actions from "./service/actions";
import * as color from "../../constant";
import HeaderTabPictures from "./Components/Header/TabPictures";
import { withSnackbar } from "notistack";
import { deepEqual } from "../Partners/utils";
import { compareObjects } from "../../utils";
import { getApiHeaders, fetcher } from "../../service/fetcher.saga";

const TABS = { PICTURES: "PICTURES" };

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  selected: {
    color: color.RED_ADDITION,
  },
  indicator: {
    backgroundColor: color.RED_ADDITION,
  },
});

class Menu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: TABS.INFORESTAU,
    };
  }

  handleTabChange = (value) => {
    /* event.preventDefault(); */
    this.setState({ currentTab: value });
  };

  onAddPictureOnline = (picture) => {
    if (picture) {
      this.props.action.addPictureOnline(picture);
    }
  };

  onAddPictureReused = (picture) => {
    if (picture) {
      this.props.action.addPictureReused(picture);
    }
  };

  onDeletePicture = (idPicture) => {
    if (idPicture) {
      this.props.action.removePicture(idPicture);
    }
  };

  compareOldDataWithNewData = (oldArray, newArray) => {
    const changes = [];
    oldArray.forEach((item, index) => {
      const changement = compareObjects(oldArray[index], newArray[index]);
      if (Object.keys(changement).length !== 0) {
        changes.push({ ...changement, id: item.id });
      }
    });
    return changes;
  };

  onUpdatePicture = (data) => {
    let finalArray = this.props.menu.picture.map((oldItem) => {
      return data.find((newItem) => oldItem.id === newItem.id);
    });
    const update = this.compareOldDataWithNewData(
      this.props.menu.picture,
      finalArray
    );
    if (update && update.length) {
      update.forEach((upd) => {
        this.props.action.patchPicture(upd);
      });
    }
  };

  showSuccess = () => {
    this.props.enqueueSnackbar(I18n.t("info.changements_success"), {
      variant: "success",
    });
  };

  render() {
    const { classes, menu } = this.props;
    return (
      <div className="tabs-container">
        <div className={classes.root}>
          <div className="header-container">
            <div className={"active"}>
              <a>
                <HeaderTabPictures color={"#e84249"} />
                <p>{I18n.t("info.picture.menu")}</p>
                <hr />
              </a>
            </div>
          </div>
          <TabContainer>
            <TabPictures
              pictures={menu.picture}
              savePictures={menu.savePictures}
              qrcode={menu.qrcode}
              onAddPictureOnline={this.onAddPictureOnline}
              onAddPictureReused={this.onAddPictureReused}
              onUpdatePicture={this.onUpdatePicture}
              onDeletePicture={this.onDeletePicture}
              initLoadingQrcode={this.props.action.initLoadingQrcode}
              loadQrCode={this.props.action.loadQrCode}
              loadingQrCode={menu.loadingQrCode}
            />
          </TabContainer>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { menu: state.menu };
};

const mapDispatchToProps = (dispatch) => {
  return { action: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(Menu)));
