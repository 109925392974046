import React from 'react';
import FormProduct from '../..';
import SupplementContainer from '../Supplement';
import InputMaster from '../Inputs/inputMaster';
import MenuLevel from '../MenuLevels';
import { I18n } from 'react-redux-i18n';

class UberEatsForm extends React.Component {
	render() {
		return (
			<FormProduct {...this.props}>
				<div>
					<InputMaster
						type="visibility"
						invert={true}
						labelVisible={I18n.t('partner.productForm.visible')}
						labelNotVisible={I18n.t('partner.productForm.notVisible')}
						name="disabled"
						{...this.props}
					/>
				</div>
				<div>
					<InputMaster type="text" name="name" {...this.props} />
				</div>
				<div>
					<InputMaster type="description" name="description" {...this.props} />
				</div>
				<div>
					<InputMaster type="price" name="price" {...this.props} />
				</div>
				<div>
					<InputMaster type="image" name="image" {...this.props} />
				</div>
				<div>
					<SupplementContainer {...this.props} />
				</div>
				<div>
					<MenuLevel name="menu_levels" {...this.props} />
				</div>
			</FormProduct>
		);
	}
}

export default UberEatsForm;
