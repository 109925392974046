import { take, put, call, all } from 'redux-saga/effects';
import { ENTITIES, requestHandler } from '../../../../../service/fetcher.saga';
import { UPDATE_PARTNER_STOCK_MANAGEMENT_STATUS } from '../../../../../service/user/types';
import { RECEIVE_VARIABLE_SERVER } from '../../../../VariableServer/service/types';
import { PRE_SUBMIT_PRODUCT_THRESHOLD } from './types';

function* preSubmitProductThresold() {
	while (true) {
		const { product_threshold, active, prefix } = yield take(
			PRE_SUBMIT_PRODUCT_THRESHOLD
		);

		const { result } = yield call(
			requestHandler,
			ENTITIES.VARIABLE,
			'PATCH',
			{ value: product_threshold },
			'product-threshold'
		);

		if (result) {
			const { result } = yield call(requestHandler, ENTITIES.VARIABLE);
			if (result) {
				yield put({ type: RECEIVE_VARIABLE_SERVER, data: result });
			}
		}

		if (prefix) {
			yield call(activeStockManagement, active, prefix);
		}
	}
}

export function* activeStockManagement(active, prefix) {
	const resultStatus = yield call(
		requestHandler,
		ENTITIES.STOCK_MANAGEMENT.ROOT,
		'POST',
		{ active, prefix },
		'status'
	);

	if (
		resultStatus &&
		resultStatus.result &&
		resultStatus.result === 'success'
	) {
		yield put({
			type: UPDATE_PARTNER_STOCK_MANAGEMENT_STATUS,
			prefix,
			active,
		});
	}
	return (
		resultStatus && resultStatus.result && resultStatus.result === 'success'
	);
}

export default function* indexSaga() {
	yield all([preSubmitProductThresold()]);
}
