import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.scss';
import ErrorPicture from './imageError.png';

const Card = props => {
	const addDefaultSrc = ev => {
		ev.target.src = ErrorPicture;
	};

	return (
		<div className={'form-input-image-card'}>
			<img src={props.src} onClick={props.onClick} onError={addDefaultSrc} />
			<button onClick={props.onDelete}>
				<DeleteIcon />
			</button>
		</div>
	);
};

export default Card;
