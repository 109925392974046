import React, { Component } from 'react';

import ComplexPartner from '../complexPartner';

const steps = {
	initial: 0,
	documentation: 1,
	uuid_at_client: 2,
	set_entities: 2,
	set_partner_options: 2,
	set_api_mask: 2,
	success: 3,
};

const UberEats = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default UberEats;
