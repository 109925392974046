import React from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import './style.scss';
export default ({ onClick }) => (
	<div className={'webhook-add-container'}>
		<Button
			variant={'outlined'}
			color={'primary'}
			className={'webhook-add-button'}
			onClick={onClick}>
			<AddIcon />
			<span>{I18n.t('general.add')}</span>
		</Button>
	</div>
);
