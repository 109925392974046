import { createSelector } from 'reselect';

const findProduct = (state, props) => {
	const exp = state.exports.list.find(e => e.id === props.idExport);
	const section = exp.sections.find(s => s.id === props.idSection);
	const product = section.products.find(p => p.id_product === props.idProduct);

	return product;
};

const getProductSelector = (state, props) => props.products;

export const getProduct = createSelector(
	[findProduct, getProductSelector],
	(product, products) => {
		const pSelector = products[product.id_product] || false;
		return {
			...product,
			name: pSelector ? pSelector.name : '',
			rate: pSelector ? pSelector.rate : -1,
			rate_take_away: pSelector ? pSelector.rate_take_away : -1,
		};
	}
);

export const findProductInProductsSelector = (state, idMenu, idProduct) => {
	const exp = state.exports.productsSelector.products.list[idMenu] || {};
	const product = Object.values(exp).find(p => p.id === idProduct);
	return product || {};
};

/**
 * Renvoie la liste des catt supp par default des produits pour une liste de produits dans des menus
 */
export const getDefaultCategorySupplementForProductInMenu = (
	state,
	productMenu
) => {
	const idsProductsInMenu = productMenu.menuLevel
		.map(ml => ml.data.map(p => p.id))
		.flat();
	return idsProductsInMenu.reduce((acc, id) => {
		if (!acc[id]) {
			if (
				state.exports.productsSelector.products.list[productMenu.id_menu] &&
				state.exports.productsSelector.products.list[productMenu.id_menu][id]
			) {
				let catSuppIds =
					state.exports.productsSelector.products.list[productMenu.id_menu][id][
						'category_supplement'
					];
				acc[id] = catSuppIds.map(id =>
					state.exports.supplement.list[productMenu.id_menu]
						? state.exports.supplement.list[productMenu.id_menu][id]
						: {}
				);
			}
		}
		return acc;
	}, {});
};

/**
 * Renvoie la liste des catt supp extra des produits pour une liste de produits dans des menus
 */
export const getProductCategorySupplementForProductInMenu = (
	state,
	productMenu
) => {
	const idsProductsInMenu = productMenu.menuLevel
		.map(ml => ml.data.map(p => p.id))
		.flat();

	let catSupps = {};

	for (let exp of state.exports.list) {
		for (let section of exp.sections) {
			for (let product of section.products) {
				if (idsProductsInMenu.includes(product.id_product)) {
					catSupps[product.id_product] = product.category_supplement;
				}
			}
		}
	}

	return catSupps;
};
