import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import {
	refreshBindings,
	requestHandler,
} from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import {
	initPartner,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	preGenerateLinkPartnerToCustomer,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	setActiveAuthLink,
	checkIfPartnerCustomerExist,
	initApiData,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'dishop';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_DISHOP');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			currentStep = 'set_api_data';
			bodySetStep.step = 'set_api_data';
			success = true;
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				bodySetStep.step = 'set_customer_id';
			}
		}

		if (currentStep === 'set_customer_id') {
			if (validatorFormat(data.customer_id)) {
				yield call(handlePatchVariable, {
					key: 'dishopCustomerId',
					value: data.customer_id,
				});

				yield put({
					type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
					body: {
						partnerPrefix: PARTNER_PREFIX,
						id: data.userId,
					},
				});
				const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');
				if (result && result.status && result.status === 'success') {
					let resCheck = yield call(checkIfPartnerCustomerExist, {
						partner_id: data.partnerId,
						partner_customer_id: data.customer_id,
					});

					if (!resCheck) {
						errorMessage = 'partner.link_partner_check_error';
					} else if (resCheck && resCheck.exist === false) {
						bodySetStep.step = 'set_auth_link';
						success = true;
						yield put({
							type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
							body: { prefix: PARTNER_PREFIX, external_id: data.customer_id },
						});
						yield take('PATCH_PARTNER');

						yield call(
							notify,
							I18n.t('partner.dishop_credientials_verified'),
							'success'
						);

						const split = data.customer_id.split('_');

						yield all([
							call(handlePatchVariable, {
								key: 'dishopCompanyId',
								value: split[0],
							}),
							call(handlePatchVariable, {
								key: 'dishopShopId',
								value: split[1],
							}),
						]);
					} else {
						errorMessage = 'partner.link_partner_customer_allready_exist';
					}
				} else {
					yield call(
						notify,
						I18n.t('partner.onboarding_dishop_errors.check_token'),
						'error',
						10000
					);
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.input_validator';
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: external_id,
			});

			if (res && res.success) {
				bodySetStep.step = 'set_entities';
				success = true;
			}
		}

		if (currentStep === 'set_entities') {
			const { binding } = yield select();
			let paiementType = binding.paymentType.find(f => f.id === 'dishop');
			let table = binding.floor.find(f => f.id === 'dishop-1');
			let priceCategory = binding.paymentType.find(f => f.id === 'dishop');

			let needInsert = !paiementType || !table || !priceCategory;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			if (!needInsert || insertSuccess) {
				let resultPaiementType;
				let resultFloor;
				let resultPriceCategory;
				if (needInsert) {
					yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

					let body = {
						prefix: PARTNER_PREFIX,
						id_payment_type: 'dishop',
					};

					resultPaiementType = yield call(patchPartnerPaiementType, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_table: 'dishop-1',
					};

					resultFloor = yield call(patchPartnerFloor, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_price_category: 'dishop',
					};

					resultPriceCategory = yield call(patchPartnerPriceCategory, body);
				}

				if (
					!needInsert ||
					(resultPaiementType &&
						resultPaiementType.result &&
						resultFloor &&
						resultFloor.result &&
						resultPriceCategory &&
						resultPriceCategory.result)
				) {
					success = true;
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
				bodySetStep.status = 'inactif';
			} else {
				bodySetStep.status = 'configuration';
			}
		}
		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({ type: 'PUT_CONFIG_DISHOP_FINISHED', step: bodySetStep.step });
	}
}

export const validatorFormat = (uuid = '') => {
	uuid = uuid.trim();

	const reg = /^.+_.+$/;
	return !!reg.test(uuid);
};

export default function* indexSaga() {
	yield all([config()]);
}
