import {
	PRE_ADD_WEBHOOK,
	PRE_PATCH_WEBHOOK,
	PRE_REMOVE_WEBHOOK,
	PRE_URL_TESTER,
} from './types';

export const addWebhook = body => {
	return {
		type: PRE_ADD_WEBHOOK,
		body,
	};
};

export const patchWebhook = body => {
	return {
		type: PRE_PATCH_WEBHOOK,
		body,
	};
};

export const removeWebhook = id => {
	return {
		type: PRE_REMOVE_WEBHOOK,
		id,
	};
};

export const requestUrlTester = (data, labels) => {
	return {
		type: PRE_URL_TESTER,
		data,
		labels,
	};
};
