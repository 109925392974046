import {
	RECEIVE_EVENTS,
	ADD_WEBHOOK,
	PATCH_WEBHOOK,
	REMOVE_WEBHOOK,
} from './types';

const initialState = [];

const partnerEventsReducer = (state = initialState, action) => {
	let newState = [...state];
	switch (action.type) {
		case RECEIVE_EVENTS:
			if (action.data) {
				return (newState = action.data);
			}
			return newState;
		case PATCH_WEBHOOK:
			if (action.webhook) {
				newState = newState.map(event => {
					if (event.webhook.id === action.webhook.id) {
						event.webhook = { ...action.webhook };
					}
					return event;
				});
			}
			return newState;
		case ADD_WEBHOOK:
			if (action.webhook) {
				newState = newState.map(event => {
					if (event.event === action.event) {
						event.webhook = action.webhook;
					}
					return event;
				});
			}
			return newState;
		case REMOVE_WEBHOOK: {
			newState = newState.map(event => {
				if (event.webhook.id === action.id) {
					event.webhook = {};
					event.id_webhook = null;
				}
				return event;
			});
			return newState;
		}
		default:
			return newState;
	}
};

export default partnerEventsReducer;
