import React from 'react';
import { Translate } from 'react-redux-i18n';
import AddIcon from '@material-ui/icons/Add';
import './style.scss';

const AddExport = ({ onAddExport }) => {
	return (
		<div className="add-export-container" onClick={onAddExport}>
			<p>
				<Translate value={'partner.export.new_export'} />
			</p>
			<p className="icon-add-export-card">
				<AddIcon />
			</p>
		</div>
	);
};
export default AddExport;
