import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { getPartnerAction, getPartnerRoute } from './utils';
import { I18n } from 'react-redux-i18n';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import CardActifInactif from '../../Components/PartnerCard/CardActifInactif';
import CardToConfigure from '../../Components/PartnerCard/CardToConfigure';

import {
	validPartnerChecking,
	putPartnerStatusToMedDefault,
	putPartnerStep,
	redirectToExternalConfig,
	putPartnerConnectorStatus,
	redirectToConnectorPayment,
} from '../../Containers/Partners/service/partnersConfig/actions';
import { bindActionCreators } from 'redux';
import { confirm, deepEqual } from '../../utils';
import { getPartnersSelector } from './selector';
import { InView } from 'react-intersection-observer';
import { animateScroll as scroll, Element } from 'react-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GeneralOptions from './Components/GeneralOptions';
import { prefixToMachine } from './config';

const prefixExternalConfig = ['laddition', 'ouilink'];

let searchTimeOut;

class partnersModule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			partners: [],
			partnersDisabled: [],
			partnersConfiguration: [],
			partnersActifInactif: [],
			tags: [],
			tagSelected: '',
			backgroundColor: '',
			open: false,
			blurBackground: false,
			searchInputActive: false,
			displayInputSearch: false,
		};
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		this.setState({
			partners: [...this.props.partnersConfig],
		});

		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!deepEqual(prevProps.partnersConfig, this.props.partnersConfig)) {
			this.setState({
				partners: this.props.partnersConfig,
			});
		}

		if (!deepEqual(prevState.partners, this.state.partners)) {
			let tags = this.state.partners.reduce((acc, p) => {
				if (acc.indexOf(p.type) === -1) {
					acc.push(p.type);
				}
				if (p.versionCheck && p.state === 'initial') {
					this.changeStatus(p.partner_id, 'configuration', p.prefix);
				}
				return acc;
			}, []);
			this.setState({
				tags,
			});
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (
			this.wrapperRef &&
			!this.wrapperRef.contains(event.target) &&
			this.state.open
		) {
			this.setState(prevState => ({ open: !prevState.open }));
		}
	}

	searchBar = e => {
		if (searchTimeOut) {
			clearTimeout(searchTimeOut);
		}
		let partnerSearched = this.props.partnersConfig.reduce((acc, part) => {
			let partenaire = I18n.t(`partner.prefix.${part.prefix}`);
			if (
				partenaire.toLowerCase().search(e.target.value.toLowerCase()) !== -1
			) {
				acc.push(part);
			}
			return acc;
		}, []);
		searchTimeOut = setTimeout(() => {
			if (!deepEqual(partnerSearched, this.state.partners)) {
				this.setState({
					partners: partnerSearched,
					tagSelected: '',
				});
			}
		}, 300);
	};

	setTag = tag => {
		let partnerSearched = {};
		if (tag) {
			partnerSearched = this.props.partnersConfig.reduce((acc, part) => {
				if (part.type === tag) {
					acc.push(part);
				}
				return acc;
			}, []);
		} else {
			partnerSearched = [...this.props.partnersConfig];
		}
		if (!deepEqual(partnerSearched, this.state.partners)) {
			this.setState({
				partners: partnerSearched,
				tagSelected: tag,
			});
		}
	};

	changeStatus = (id, status, prefix = null) => {
		if (status === 'actif') {
			status = 'inactif';
		} else if (status === 'inactif') {
			status = 'actif';
		} else {
			scroll.scrollToTop();
		}
		const data = {
			id_user: this.props.user.id,
			partner_id: id,
			status,
			prefix,
		};

		this.props.putStatusMedDefault(data);
	};

	setConfiguration = (min_version, prefix, id, status) => {
		if (min_version) {
			this.checkIfPartnerChecking(prefix, id);
		} else {
			this.changeStatus(id, status, prefix);
		}
		let partner = this.props.partnersConfig.find(p => p.prefix === prefix);
		if (partner && partner.step == null) {
			this.props.putPartnerStepAction({
				id_user: this.props.user.id,
				partner_id: id,
				step: 'initial',
			});
		}
	};

	checkIfPartnerChecking = (prefix, id) => {
		this.props.validationPartnerChecking({
			prefix,
			id,
		});
	};

	setBlurBackground = (value, element) => {
		if (element) {
			element.scrollIntoView();
		}

		this.setState({
			blurBackground: value,
		});
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			!deepEqual(nextProps.partnersConfig, this.props.partnersConfig) ||
			!deepEqual(nextState, this.state)
		) {
			return true;
		}
		return false;
	}

	showPopupContactSupport = _ => {
		confirm(I18n.t('partner.call_partner_support'), {
			title: I18n.t('general.information'),
			confirmLabel: I18n.t('general.close'),
			hideCancel: true,
		});
	};

	getEspaceClientUrl = _ => {
		let url = '';
		if (window.location && window.location.hostname === 'localhost') {
			url = `http://localhost:3300/`;
		} else if (
			window.location &&
			window.location.hostname.includes('laddit.io')
		) {
			url = `https://espace-client.laddit.io/`;
		} else {
			url = `https://espace-client.laddition.com/`;
		}

		return url;
	};

	handleConnectorContactPayment = (type, prefix) => {
		const partner = this.state.partners.find(p => p.prefix === prefix);
		this.props.notifPartnerConnector(partner.prefix);
		// this.props.putPartnerConnectorStatusAction({
		// 	status: type === 'payment' ? 'waiting_payment' : 'waiting_call',
		// 	partner_id: partner.partner_id,
		// 	id_user: this.props.user.id,
		// });

		// if (type === 'payment') {
		// 	this.props.redirectToConnectorPaymentAction();
		// }
	};

	render() {
		const classNameSearchTag = this.state.blurBackground
			? 'z-index0'
			: 'z-index5';

		const partnersInConfig = this.state.partners.filter(
			partner => partner.status === 'configuration'
		);
		const partnersActiveInactive = this.state.partners.filter(
			partner => partner.status === 'actif' || partner.status === 'inactif'
		);
		const partnersToConfigure = this.state.partners.filter(
			partner => partner.status === 'disabled' || partner.status === undefined
		);

		let seachBarClass = 'search-bar';
		if (this.state.searchInputActive) {
			seachBarClass += ' search-bar-active';
		}

		const espaceClientUrl = this.getEspaceClientUrl();
		return (
			<div className="partners-container">
				{/* <p className="partners-link-espace-client">
					<a href={espaceClientUrl}>
						<ArrowBackIcon />{' '}
						<span>{I18n.t('partner.link_espace_client')}</span>
					</a>
				</p> */}
				<div
					className={seachBarClass}
					onClick={() => {
						this.setState({ searchInputActive: true });
						setTimeout(() => this.setState({ displayInputSearch: true }), 500);
						// oui, il y a surrement un moyen de le faire en css, mais je galere
					}}>
					<SearchIcon />
					{this.state.displayInputSearch ? (
						<input
							type="text"
							className="form-control"
							placeholder={I18n.t('partner.search')}
							onKeyUp={e => this.searchBar(e)}
							autoFocus={true}
							onBlur={() =>
								this.setState({
									searchInputActive: false,
									displayInputSearch: false,
								})
							}
						/>
					) : null}
				</div>

				<InView threshold={[1]}>
					{({ inView, ref }) => {
						return (
							<React.Fragment>
								<div ref={ref} id="searchTag" />
								<div
									className={
										inView
											? 'search-tag-content ' + classNameSearchTag
											: 'search-tag-content white ' + classNameSearchTag
									}>
									<div className="search-tag-container">
										{this.state.tags.map((t, i) => {
											return (
												<div
													key={i}
													onClick={
														this.state.tagSelected === t
															? null
															: () => this.setTag(t)
													}
													className={
														this.state.tagSelected === t
															? 'tag-container tag-selected'
															: 'tag-container'
													}>
													<p>{t}</p>
													{this.state.tagSelected === t ? (
														<CloseIcon onClick={() => this.setTag('')} />
													) : null}
												</div>
											);
										})}
									</div>
								</div>
							</React.Fragment>
						);
					}}
				</InView>

				{partnersInConfig.length > 0 ? (
					<div>
						<div className="title-content">
							<h2>{I18n.t('partner.configuration_in_progress')}</h2>
						</div>
						<Element name="scrollToExpand" className="element" />
						<TransitionGroup className="partnerconfig-content-container">
							{partnersInConfig.map((part, index) => {
								const Partner = prefixToMachine[part.prefix];
								if (!Partner) return null;
								return (
									<CSSTransition
										key={part.partner_id}
										timeout={1000}
										classNames="item">
										<Partner
											key_={part.partner_id}
											index={index}
											setBlurBackground={this.setBlurBackground}
										/>
									</CSSTransition>
								);
							})}
						</TransitionGroup>
					</div>
				) : null}

				{partnersActiveInactive.length > 0 && partnersInConfig.length > 0 ? (
					<div className="divider" />
				) : null}

				{partnersActiveInactive.length > 0 ? (
					<div>
						<Element name="scrollToPartnersActif" className="element" />
						<div className="title-content">
							<h2>{I18n.t('dashboard.partners')}</h2>
						</div>

						<TransitionGroup className="partnerconfig-content-container">
							{this.state.partners
								.filter(
									partner =>
										partner.status === 'actif' || partner.status === 'inactif'
								)
								.map(part => {
									const action = getPartnerAction(part.prefix);
									return (
										<CSSTransition
											key={part.partner_id}
											timeout={1000}
											classNames="item">
											<CardActifInactif
												key={part.partner_id}
												isActive={part.status === 'actif' ? 1 : 0}
												username={I18n.t(`partner.prefix.${part.prefix}`)}
												prefix={part.prefix}
												logo={part.logo}
												error={part.id_error}
												changeStatus={() =>
													this.changeStatus(
														part.partner_id,
														part.status,
														part.prefix
													)
												}
												action={action}
											/>
										</CSSTransition>
									);
								})}
						</TransitionGroup>
					</div>
				) : null}

				{(partnersToConfigure.length > 0 &&
					partnersActiveInactive.length > 0) ||
				(partnersToConfigure.length > 0 && partnersInConfig.length > 0) ? (
					<div className="divider" />
				) : null}

				{partnersToConfigure.length > 0 ? (
					<div>
						<div className="title-content">
							<h2>{I18n.t('partner.soon')}</h2>
						</div>
						<TransitionGroup className="partnerconfig-content-container">
							{partnersToConfigure.map(part => {
								const isConfigurable =
									!!prefixToMachine[part.prefix] ||
									prefixExternalConfig.includes(part.prefix);

								const configure = part => {
									const internalConfig = prefixToMachine[part.prefix];
									const externalConfig = prefixExternalConfig.includes(
										part.prefix
									);
									if (!!internalConfig) {
										this.setConfiguration(
											part.min_version,
											part.prefix,
											part.partner_id,
											'configuration'
										);
									} else if (!!externalConfig) {
										this.props.redirectToExternalConfig(part.prefix);
									} else {
										this.showPopupContactSupport();
									}
								};

								return (
									<CSSTransition
										key={part.partner_id}
										timeout={1000}
										classNames="item">
										<CardToConfigure
											key={part.partner_id}
											username={I18n.t(`partner.prefix.${part.prefix}`)}
											logo={part.logo}
											prefix={part.prefix}
											description={part.description}
											price={part.pricingInfo}
											isConfigurable={isConfigurable}
											configure={() => configure(part)}
											connectorData={
												part.connectorData || { access: true, free: true }
											}
											connectorContactPayment={
												this.handleConnectorContactPayment
											}
										/>
									</CSSTransition>
								);
							})}
						</TransitionGroup>
					</div>
				) : null}
				<div id="blur" className={this.state.blurBackground ? 'blury' : ''} />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		partnersConfig: getPartnersSelector(state),
		user: state.service.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		putStatusMedDefault: bindActionCreators(
			putPartnerStatusToMedDefault,
			dispatch
		),
		putPartnerStepAction: bindActionCreators(putPartnerStep, dispatch),
		putPartnerConnectorStatusAction: bindActionCreators(
			putPartnerConnectorStatus,
			dispatch
		),
		validationPartnerChecking: bindActionCreators(
			validPartnerChecking,
			dispatch
		),
		redirectToExternalConfig: bindActionCreators(
			redirectToExternalConfig,
			dispatch
		),
		redirectToConnectorPaymentAction: bindActionCreators(
			redirectToConnectorPayment,
			dispatch
		),
		notifPartnerConnector: prefix =>
			dispatch({ type: 'NOTIF_PARTNER_CONNECTOR', prefix }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(partnersModule);
