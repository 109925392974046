import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { requestHandler } from '../../../../../service/fetcher.saga';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { displayInfoPartner } from '../../partners/saga';

import {
	checkIfPartnerCustomerExist,
	generateIpadToken,
	initApiData,
	initApiLoyaltyDiscount,
	initApiLoyaltyPaymentType,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

const PARTNER_PREFIX = 'obypay';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_OBYPAY');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners[PARTNER_PREFIX]
				? partners[PARTNER_PREFIX].external_id
				: null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: PARTNER_PREFIX,
				partnerId: data.partnerId,
			});

			currentStep = 'set_ipad_token';
			bodySetStep.step = 'set_ipad_token';
			success = true;
		}

		if (currentStep === 'set_ipad_token') {
			const ipadToken = yield call(generateIpadToken);
			if (ipadToken) {
				success = true;
				currentStep = 'set_api_data';
				bodySetStep.step = 'set_api_data';
			}
		}

		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				bodySetStep.step = 'set_customer_id';
				success = true;
			}
		}

		if (currentStep === 'set_customer_id') {
			//TODO: maybe we can check

			yield put({
				type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
				body: { prefix: PARTNER_PREFIX, external_id: data.customer_id },
			});

			yield call(handlePatchVariable, {
				key: 'obypayCustomerId',
				value: data.customer_id,
			});

			yield put({
				type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
				body: {
					partnerPrefix: PARTNER_PREFIX,
					id: data.userId,
				},
			});
			const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');
			if (result && result.status && result.status === 'success') {
				external_id = data.customer_id;
				let resCheck = yield call(checkIfPartnerCustomerExist, {
					partner_id: data.partnerId,
					partner_customer_id: external_id,
				});

				if (!resCheck) {
					errorMessage = 'partner.link_partner_check_error';
				} else if (resCheck && resCheck.exist === false) {
					yield call(
						notify,
						I18n.t('partner.obypay_credientials_verified'),
						'success'
					);
					success = true;
					currentStep = 'set_auth_link';
					bodySetStep.step = 'set_auth_link';

					yield put({
						type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
						body: { prefix: PARTNER_PREFIX, external_id },
					});
				} else {
					errorMessage = 'partner.link_partner_customer_allready_exist';
				}
			} else {
				yield call(
					notify,
					I18n.t('partner.onboarding_obypay_errors.check_token'),
					'error',
					10000
				);
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: external_id,
			});

			if (res && res.success) {
				yield call(setActiveAuthLink, false, external_id, data.partnerId);

				currentStep = 'set_api_payment_type';
				bodySetStep.step = 'set_api_payment_type';
				success = true;
			}
		}

		if (currentStep === 'set_api_payment_type') {
			const insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			if (insertSuccess) {
				currentStep = 'set_api_discount';
				bodySetStep.step = 'set_api_discount';
				success = true;
			} else {
				errorMessage = 'partner.onboarding_common_errors.set_entities';
			}
		}

		// on fait ca parce que dans l'ob api c'est séparé mais que sur le client on utilise le initEntities qui gere tout
		if (currentStep === 'set_api_discount') {
			currentStep = 'set_paiement';
			bodySetStep.step = 'set_paiement';
			success = true;
		}

		if (currentStep === 'set_paiement') {
			let body = {
				prefix: PARTNER_PREFIX,
				id_payment_type: 'online',
			};

			const resultPatchPartnerPaiementType = yield call(
				patchPartnerPaiementType,
				body
			);
			if (resultPatchPartnerPaiementType) {
				currentStep = 'set_table_floor';
				bodySetStep.step = 'set_table_floor';
				success = true;
			}
		}

		if (currentStep === 'set_table_floor') {
			let body = {
				prefix: PARTNER_PREFIX,
				id_table: 'takeaway_1',
			};

			const resultPatchCloor = yield call(patchPartnerFloor, body);

			if (resultPatchCloor) {
				currentStep = 'set_partner_options';
				bodySetStep.step = 'set_partner_options';
				success = true;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				currentStep = 'success';
				bodySetStep.step = 'success';
				bodySetStep.status = 'actif';
				success = true;
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error');
		}

		yield put({ type: 'PUT_CONFIG_OBYPAY_FINISHED', step: bodySetStep.step });
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
