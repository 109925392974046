export const RECEIVE_CATEGORY_SUPPLEMENT = 'RECEIVE_CATEGORY_SUPPLEMENT';
export const RECEIVE_CATEGORY_SUPPLEMENT_EXTRA =
	'RECEIVE_CATEGORY_SUPPLEMENT_EXTRA';
export const RECEIVE_SUPPLEMENT_EXTRA = 'RECEIVE_SUPPLEMENT_EXTRA';

export const PRE_POST_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_POST_CATEGORY_SUPPLEMENT_EXTRA';
export const POST_CATEGORY_SUPPLEMENT_EXTRA = 'POST_CATEGORY_SUPPLEMENT_EXTRA';

export const PRE_POST_SUPPLEMENT_EXTRA = 'PRE_POST_SUPPLEMENT_EXTRA';
export const POST_SUPPLEMENT_EXTRA = 'POST_SUPPLEMENT_EXTRA';

export const PRE_DELETE_SUPPLEMENT_EXTRA = 'PRE_DELETE_SUPPLEMENT_EXTRA';
export const DELETE_SUPPLEMENT_EXTRA = 'DELETE_SUPPLEMENT_EXTRA';

export const PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_DELETE_CATEGORY_SUPPLEMENT_EXTRA';
export const DELETE_CATEGORY_SUPPLEMENT_EXTRA =
	'DELETE_CATEGORY_SUPPLEMENT_EXTRA';

export const PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA =
	'PRE_RESET_CATEGORY_SUPPLEMENT_EXTRA';
export const RESET_CATEGORY_SUPPLEMENT_EXTRA =
	'RESET_CATEGORY_SUPPLEMENT_EXTRA';

export const CHANGE_CHANGEMENT_IN_EXPORT = 'CHANGE_CHANGEMENT_IN_EXPORT';
