import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import './style.scss';
import ItemSelectedList from './Component/ItemSelectedList';
import ItemSelectableList from './Component/ItemSelectableList';
import SectionList from './Component/SectionList';
import MenuSelector from './Component/MenuSelector';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as actionSelectedItem from '../../service/products/actions';
import * as actionsCategories from '../../service/categories/actions';
import * as actionsMenus from '../../service/menus/actions';
import { bindActionCreators } from 'redux';
import {
	getLoaded,
	getMenusList,
	getProductsSelected,
	getProductsList,
} from './selector';
import { ALL } from './utils';
import ExportName from '../../../ExportName';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class ProductsSelectorModal extends PureComponent {
	handleClose = () => {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
		this.resetModal();
	};

	/* shouldComponentUpdate(nextProps) {
    if (deepEqual(this.props.menus, nextProps.menus)) {
      return false;
    }
    if (deepEqual(this.props.productsSelected, nextProps.productsSelected)) {
      return false;
    }
    if (deepEqual(this.props.exports, nextProps.exports)) {
      return false;
    }

    return true;
  } */

	onValid = () => {
		this.props.onValid(this.props.productsSelected);
		this.resetModal();
	};

	resetModal = () => {
		this.props.actionSelectedItem.setSelected({});
		if (this.props.menus.list) {
			this.props.selectMenu(Object.keys(this.props.menus.list)[0]);
		}
		this.props.selectCategory(ALL);
	};

	componentWillUnmount() {
		this.resetModal();
	}

	render() {
		const { loaded, menuSelectorDisabled } = this.props;
		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.handleClose}
				TransitionComponent={Transition}>
				<DialogContent className={'selector-modal-content'}>
					<h1>
						<Translate value={'partner.products_selector.title'} />
					</h1>
					<div className="menu-and-name-container">
						<MenuSelector loaded={loaded} disabled={!!menuSelectorDisabled} />

						{this.props.idExport ? (
							<ExportName idExport={this.props.idExport} />
						) : null}
					</div>

					<div className={'products-selector-modal-container'}>
						<div className={'section-category'}>
							<SectionList loaded={loaded} />
						</div>

						<div className={'section-products'}>
							<ItemSelectableList loaded={loaded} />
						</div>

						<div className={'section-recap'}>
							<ItemSelectedList loaded={loaded} />
							<div id={'products-selector-modal-action'}>
								<Button
									onClick={this.handleClose}
									aria-label="Close"
									className={'button-cancel'}>
									{I18n.t('general.cancel')}
								</Button>
								<Button
									id={'products-selector-modal-valid'}
									color="primary"
									variant="contained"
									onClick={this.onValid}
									className={'button-valid'}>
									{I18n.t('general.valid')}
								</Button>
							</div>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}

ProductsSelectorModal.propTypes = {
	onValid: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	menuSelectorDisabled: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		products: getProductsList(state),
		menus: getMenusList(state),
		loaded: getLoaded(state),
		productsSelected: getProductsSelected(state),
		exports: state.exports.list,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actionSelectedItem: bindActionCreators(actionSelectedItem, dispatch),
		selectMenu: bindActionCreators(actionsMenus.select, dispatch),
		selectCategory: bindActionCreators(actionsCategories.select, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductsSelectorModal);
