import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { EXPORT_TYPE } from '../../../../../config';
import { PropTypes } from 'prop-types';
import { getPartnerRequiredKeys } from '../../../../../service/partners/selector';

class RecapExport extends Component {
	render() {
		const { type } = this.props;
		let recap = {};
		if (
			type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS ||
			type === EXPORT_TYPE.SECTION_WITH_EXTRAS
		) {
			this.props.sections.map(section => {
				section.products.map(product => {
					let cell = {};
					for (let key of this.props.partnerKeys) {
						cell[key] = product.extras.hasOwnProperty(key);
					}

					let productExtraKeys = Object.keys(product.extras);
					if (
						this.props.partnerKeys.some(key => {
							return !productExtraKeys.includes(key);
						})
					) {
						cell['completed'] = false;
					} else {
						cell['completed'] = true;
					}
					recap[product.id_product] = cell;
				});
			});
		}

		const allProductCompleted = Object.values(recap).some(item => {
			return item.completed === false;
		});

		let list = [];
		this.props.sections.map(section => {
			list.push(
				<div key={section.id}>
					<p className="section-title">{section.name}</p>
					<ul>
						{section.products.length > 0 ? (
							section.products.map(product => {
								let itemClass = '';
								if (
									type === EXPORT_TYPE.UNIQUE_WITH_EXTRAS ||
									type === EXPORT_TYPE.SECTION_WITH_EXTRAS
								) {
									if (recap[product.id_product].completed === false) {
										itemClass = 'empty-item';
									} else {
										itemClass = 'complete-item';
									}
									if (product.extras.disabled == 1) {
										itemClass += ' disabled-item';
									}
								}

								const ps = this.props.products
									? this.props.products[product.id_product]
									: null;
								const name = ps ? ps.name : '';

								return (
									<li key={product.id} className={itemClass}>
										<p>{product.extras.name ? product.extras.name : name}</p>
									</li>
								);
							})
						) : (
							<li className="empty-section">
								{I18n.t('exports.recapExport.empty')}
							</li>
						)}
					</ul>
				</div>
			);
		});

		return (
			<div className="recap-export-container">
				{allProductCompleted ? (
					<p className="incomplete-string">
						{I18n.t('exports.recapExport.incompleted_product')}
					</p>
				) : (
					''
				)}
				<div>{list}</div>
			</div>
		);
	}
}

RecapExport.propTypes = {
	idExport: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
	const exp = state.exports.list.find(e => e.id === props.idExport);
	return {
		sections: exp ? exp.sections : [],
		partnerKeys: getPartnerRequiredKeys(state.partners, props.prefix),
	};
};

export default connect(mapStateToProps)(RecapExport);
