import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select, fork } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { refreshBindings } from '../../../../../service/fetcher.saga';
import { getVariableServer } from '../../../../../utils';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';

import {
	checkIfPartnerCustomerExist,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	unLinkOrderPartners,
	unlinkPartnerWS,
} from '../saga';
import { PRE_CHANGE_PARTNER_STEP } from '../types';

function* configDeliverect() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_DELIVERECT');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		const { partners } = yield select();
		let external_id =
			partners && partners.deliverect ? partners.deliverect.external_id : null;

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});

			let res1 = yield call(handlePatchVariable, {
				key: 'timezone',
				value: 'Europe/Paris',
			});
			let res2 = yield call(handlePatchVariable, {
				key: 'tmpManufacturingByApp',
				value: '1',
			});
			if (res1 && res2) {
				success = true;
			}
		} else if (currentStep === 'documentation') {
			success = true;
		} else if (currentStep === 'set_account_id') {
			data.account_id = data.account_id ? data.account_id.trim() : '';
			if (data.account_id) {
				let res1 = yield call(handlePatchVariable, {
					key: 'deliverectAccountId',
					value: data.account_id,
				});

				if (res1) {
					success = true;
				}
			} else {
				errorMessage = 'partner.onboarding_deliverect_errors.mandatory_field';
			}
		} else if (currentStep === 'set_location_id') {
			const { variableServer } = yield select();
			let accountId = getVariableServer(variableServer, 'deliverectAccountId');
			data.location_id = data.location_id ? data.location_id.trim() : '';
			if (data.location_id) {
				if (accountId && accountId == data.location_id) {
					yield call(
						notify,
						I18n.t(
							'partner.onboarding_deliverect_errors.same_account_location_id'
						),
						'error',
						10000
					);
					success = true;
					bodySetStep.step = 'set_account_id';
				} else {
					yield call(handlePatchVariable, {
						key: 'deliverectLocationId',
						value: data.location_id,
					});
					external_id = data.location_id;
					yield put({
						type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
						body: { prefix: 'deliverect', external_id },
					});
					yield take('PATCH_PARTNER');

					yield put({
						type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
						body: {
							partnerPrefix: 'deliverect',
							id: data.userId,
							account_id: '',
							location_id: data.location_id,
						},
					});
					const { result } = yield take(
						'REQUEST_POST_PARTNER_CHECKING_FINISHED'
					);

					if (
						result &&
						result.status === 'success' &&
						result.responseData &&
						result.responseData.locations &&
						result.responseData.locations.includes(data.location_id)
					) {
						let resCheck = yield call(checkIfPartnerCustomerExist, {
							partner_id: data.partnerId,
							partner_customer_id: external_id,
						});

						if (!resCheck) {
							errorMessage = 'partner.link_partner_check_error';
						} else if (resCheck && resCheck.exist === false) {
							success = true;

							yield call(
								notify,
								I18n.t('partner.deliverect_credientials_verified'),
								'success'
							);
						} else {
							errorMessage = 'partner.link_partner_customer_allready_exist';
						}
					} else {
						yield call(
							notify,
							I18n.t(
								'partner.onboarding_deliverect_errors.check_acount_location'
							),
							'error',
							10000
						);

						success = true;
						bodySetStep.step = 'set_account_id';
					}
				}
			} else {
				errorMessage = 'partner.onboarding_deliverect_errors.mandatory_field';
			}
		}

		if (currentStep === 'set_table_and_paiement') {
			const { binding } = yield select();
			let tableDeliverect = binding.floor.find(f => f.id === 'deliverect-1');
			let paiementTypeDeliverect = binding.paymentType.find(
				f => f.id === 'deliverect'
			);

			let needInsert = !tableDeliverect || !paiementTypeDeliverect;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, 'deliverect');
			}

			// si la table et le moyen de paiement sont deja la OU qu'ils ont bien été insérés
			if (!needInsert || insertSuccess) {
				if (needInsert) yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

				let body = {
					prefix: 'deliverect',
					id_table: 'deliverect-1',
				};

				const resultFloor = yield call(patchPartnerFloor, body);

				body = {
					prefix: 'deliverect',
					id_payment_type: 'deliverect',
				};

				const resultPaiementType = yield call(patchPartnerPaiementType, body);

				if (
					resultFloor &&
					resultFloor.result &&
					resultPaiementType &&
					resultPaiementType.result
				) {
					success = true;
					currentStep = 'set_variable_config_channel';
					bodySetStep.step = 'set_variable_config_channel';
				} else {
					errorMessage = 'partner.onboarding_deliverect_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_deliverect_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_variable_config_channel') {
			let res1 = yield call(handlePatchVariable, {
				key: 'deliverectOrderConfigChannel',
				value: '{"payment":{"1":"def0-4"}}',
			});

			if (res1) {
				success = true;
				currentStep = 'set_link_deliverect';
				bodySetStep.step = 'set_link_deliverect';
			} else {
				errorMessage = 'partner.onboarding_como_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_link_deliverect') {
			if (external_id) {
				let res = yield call(preGenerateLinkPartnerToCustomer, {
					partnerId: data.partnerId,
					uuid: external_id,
				});

				if (res && res.success) {
					yield call(
						setActiveAuthLink,
						false,
						bodySetStep.id_user,
						data.partnerId
					);
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
					success = true;
				}
			} else {
				errorMessage = 'partner.onboarding_deliverect_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, 'deliverect');
			if (resultInitOption) {
				success = true;
				bodySetStep.step = 'unlink_partners';
				currentStep = 'unlink_partners';
			}
		}

		if (currentStep === 'unlink_partners') {
			yield call(unLinkOrderPartners, data.userId);

			currentStep = 'set_api_mask';
			bodySetStep.step = 'set_api_mask';
			success = true;
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: 'deliverect',
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
			} else {
				bodySetStep.status = 'configuration';
			}
		}

		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_DELIVERECT_FINISHED',
			step: bodySetStep.step,
		});
	}
}

export default function* indexSaga() {
	yield all([configDeliverect()]);
}
