import React from 'react';
import InputEdit from './InputEdit';
import { connect } from 'react-redux';
import { deepEqual } from '../../../utils';
import Checkbox from '../../../../../Components/CheckBox';
import AddIcon from '@material-ui/icons/Add';
import InputImage from '../../../../Partners/Components/Export/Component/ExportModal/Component/FormProduct/Components/Inputs/image';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import { filterByPrefix } from './CategorySupplement';

class Supplement extends React.Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		if (deepEqual(this.props.supplementExtra, nextProps.supplementExtra)) {
			return false;
		}

		return true;
	}

	onChangeDisabled = disabled => {
		this.props.onChangeSupplement(
			this.props.supplement.id,
			this.props.id_category,
			this.props.id_menu,
			'disabled',
			disabled
		);
	};

	onChange = (prop, value) => {
		this.props.onChangeSupplement(
			this.props.supplement.id,
			this.props.id_category,
			this.props.id_menu,
			prop,
			value
		);
	};

	openImageModal = () => {
		this.props.openImageModal(
			this.props.supplement.id,
			this.props.id_category,
			this.props.id_menu
		);
	};

	fullSizeImage = path => {
		this.props.openFullSizeModal(path);
	};

	deleteImage = (id, id_category, id_menu, path) => {
		this.props.onDeleteImage(id, id_category, id_menu, path);
	};

	render() {
		let suppToDisplay = { ...this.props.supplement };
		if (this.props.supplementExtra.hasOwnProperty(this.props.supplement.id)) {
			let finded = this.props.supplementExtra[this.props.supplement.id];

			if (finded) {
				suppToDisplay.originalName = this.props.supplement.name;
				for (let prop in finded) {
					suppToDisplay[finded[prop]['key_']] = finded[prop]['value'];
					suppToDisplay['id_category_supplement'] =
						finded[prop]['id_category_supplement'];
					suppToDisplay['id_menu'] = finded[prop]['id_menu'];
				}
			}
		}

		return (
			<div className={'supplement-info'}>
				<div className={'check-name-supplement'}>
					<Checkbox
						selected={!suppToDisplay.disabled}
						onClick={() => this.onChangeDisabled(!suppToDisplay.disabled)}
					/>
					<InputEdit
						value={suppToDisplay.name}
						originalValue={suppToDisplay.originalName}
						inputProps={{ type: 'text' }}
						onChange={value => {
							this.onChange('name', value);
						}}
					/>
				</div>

				<div className="supplement-image-container">
					{suppToDisplay.image ? (
						<div>
							<img src={suppToDisplay.image} className={'supplement-image'} />
							<div className="supplement-image-overlay">
								<PictureInPicture
									onClick={() => this.fullSizeImage(suppToDisplay.image)}
								/>
								<DeleteIcon
									onClick={() =>
										this.deleteImage(
											suppToDisplay.id,
											suppToDisplay.id_category_supplement,
											suppToDisplay.id_menu,
											suppToDisplay.image ? suppToDisplay.image : ''
										)
									}
								/>
							</div>
						</div>
					) : (
						<button onClick={this.openImageModal}>
							<p className="icon-add-export">
								<AddIcon />
							</p>
						</button>
					)}
				</div>

				<InputEdit
					value={suppToDisplay.priceSupplement / 100}
					className={'supp-price'}
					inputProps={{
						type: 'number',
						min: 0,
						step: '0.01',
					}}
					onChange={value => {
						this.onChange('priceSupplement', value);
					}}
					sigle={this.props.sigle}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		supplementExtra: filterByPrefix(
			state.exports.supplement.supplementExtra,
			props.prefix
		),
		sigle: state.info.variable ? state.info.variable.sigle : '€',
	};
};

export default connect(mapStateToProps)(Supplement);
