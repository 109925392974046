import {
	RECEIVE_PARTNERS_CONFIG,
	RECEIVE_PARTNERS_CONFIG_CLIENT,
	RECEIVE_PARTNERS_CONFIG_STEP,
	CHANGE_PARTNER_STEP,
	CHANGE_PARTNER_STATUS,
	GENERATE_TOKEN,
	VALIDATION_LINK_PARTNER_TO_CUSTOMER,
	VALIDATION_CONNEXION_WITH_PARTNER_OK,
	VALIDATION_PARTNER_CHECKING,
	GET_CHECK_MENU,
	SET_UBEREATS_STORES,
	CHANGE_PARTNER_CONNECTOR_STATUS,
} from './types';

const initialState = {};
const reducerPartnersConfig = (state = initialState, action) => {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_PARTNERS_CONFIG:
			if (action.data) {
				newState = action.data;
			}
			return newState;
		case RECEIVE_PARTNERS_CONFIG_CLIENT:
			let newStateAfterConfig = {};
			if (action.data) {
				let configByPartner = action.data.reduce((acc, row) => {
					if (row.hasOwnProperty('partner_id')) {
						if (!acc.hasOwnProperty(row.partner_id)) {
							acc[row.partner_id] = { [row.key_]: row.value };
						} else {
							acc[row.partner_id][row.key_] = row.value;
						}
					}
					return acc;
				}, {});
				for (let stt in newState) {
					newStateAfterConfig[stt] = {
						...newState[stt],
						...configByPartner[stt],
					};
				}
				newState = newStateAfterConfig;
			}
			return newState;
		case RECEIVE_PARTNERS_CONFIG_STEP:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (
						newState[partner].state === 'initial' ||
						newState[partner].state === 'generate_token'
					) {
						newState[partner].token = '';
					}

					result[partner] = {
						...newState[partner],
						steps: [],
					};

					if (action.data['_all']) {
						result[partner].steps.push(...action.data['_all']);
					}

					if (action.data[newState[partner].prefix]) {
						result[partner].steps.push(
							...action.data[newState[partner].prefix]
						);
					}
				}
				newState = result;
			}
			return newState;
		case CHANGE_PARTNER_STEP:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (parseInt(partner) === action.data.partner_id) {
						result[partner] = { ...newState[partner] };
						result[partner].state = action.data.step;
					} else {
						result[partner] = { ...newState[partner] };
					}
				}
				newState = result;
			}
			return newState;
		case CHANGE_PARTNER_STATUS:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (parseInt(partner) === action.data.partner_id) {
						result[partner] = { ...newState[partner] };
						result[partner].status = action.data.status;
					} else {
						result[partner] = { ...newState[partner] };
					}
				}
				newState = result;
			}
			return newState;

		case CHANGE_PARTNER_CONNECTOR_STATUS:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (parseInt(partner) === action.data.partner_id) {
						result[partner] = { ...newState[partner] };
						result[partner].connector_status = action.data.status;
					} else {
						result[partner] = { ...newState[partner] };
					}
				}
				newState = result;
			}
			return newState;
		case GENERATE_TOKEN:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (parseInt(partner) === action.data.partnerId) {
						result[partner] = { ...newState[partner] };
						result[partner].token = action.data.token;
					} else {
						result[partner] = { ...newState[partner] };
					}
				}
				newState = result;
			}
			return newState;
		case VALIDATION_PARTNER_CHECKING:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					if (parseInt(partner) === action.data.id) {
						result[partner] = { ...newState[partner] };
						result[partner].versionCheck = true;
					} else {
						result[partner] = { ...newState[partner] };
					}
				}
				newState = result;
			}
			return newState;
		case VALIDATION_LINK_PARTNER_TO_CUSTOMER:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					result[partner] = { ...newState[partner] };
					if (partner === action.data.partnerId) {
						result[partner].partnerCustomerId = action.data.partnerCustomerId;
						result[partner].token = action.data.token;
					}
				}

				newState = result;
			}
			return newState;
		case VALIDATION_CONNEXION_WITH_PARTNER_OK:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					result[partner] = { ...newState[partner] };
					if (parseInt(partner) === action.data.partnerId) {
						result[partner].connexionPartner = action.data.value;
					}
				}
				newState = result;
			}
			return newState;
		case GET_CHECK_MENU:
			if (action.data) {
				let result = {};
				for (let partner in newState) {
					result[partner] = { ...newState[partner] };
					if (parseInt(partner) === action.data.partnerId) {
						result[partner].checkMenu = action.data;
					}
				}
				newState = result;
			}
			return newState;
		case SET_UBEREATS_STORES:
			if (action.stores) {
				let result = {};
				for (let partnerId in newState) {
					result[partnerId] = { ...newState[partnerId] };
					if (result[partnerId].prefix === 'ubereats') {
						result[partnerId].stores = action.stores;
					}
				}
				newState = result;
			}
			return newState;

		default:
			return newState;
	}
};

export default reducerPartnersConfig;
