export const PRE_REMOVE_PICTURE_ONLINE = "PRE_REMOVE_PICTURE_ONLINE";
export const REMOVE_PICTURE_ONLINE = "REMOVE_PICTURE_ONLINE";
export const PATCH_PICTURE_ONLINE = "PATCH_PICTURE_ONLINE";
export const PRE_PATCH_PICTURE_ONLINE = "PRE_PATCH_PICTURE_ONLINE";
export const PRE_ADD_PICTURE_ONLINE = "PRE_ADD_PICTURE_ONLINE";
export const ADD_PICTURE_ONLINE = "ADD_PICTURE_ONLINE";
export const PRE_ADD_PICTURE_ONLINE_REUSED = "PRE_ADD_PICTURE_ONLINE_REUSED";
export const ADD_PICTURE_ONLINE_REUSED = "ADD_PICTURE_ONLINE_REUSED";
export const RECEIVE_PICTURES_ONLINE = "RECEIVE_PICTURES_ONLINE";
export const PRE_LOAD_QR_CODE = "PRE_LOAD_QR_CODE";
export const PRE_REQUEST_LOAD_QR_CODE = "PRE_REQUEST_LOAD_QR_CODE";
export const REQUEST_LOAD_QR_CODE = "REQUEST_LOAD_QR_CODE";
export const REQUEST_LOAD_QR_CODE_FINISHED = "REQUEST_LOAD_QR_CODE_FINISHED";
export const LOAD_QR_CODE = "LOAD_QR_CODE";
export const RECEIVE_VARIABLE = "RECEIVE_VARIABLE";
export const INIT_LOAD_QR_CODE = "INIT_LOAD_QR_CODE";
