import React, { Component } from 'react';

import ComplexPartner from './complexPartner';

const steps = {
	initial: 0,
	set_customer_id: 1,
	set_auth_link: 1,
	success: 1,
};

const Melba = props => {
	return (
		<ComplexPartner
			key_={props.key_}
			index={props.index}
			setBlurBackground={props.setBlurBackground}
			steps={steps}
		/>
	);
};

export default Melba;
